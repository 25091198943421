import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectActions } from 'src/app/platform';
import { ListItem } from 'src/app/shared/components/list-items/list-item.model';
import { SnackbarService, SnackbarType } from 'src/app/shared/components/snackbar/snackbar.service';
@Component({
  selector: 'shai-project-files-icon-more',
  template: `
    <div
     cdkOverlayOrigin
     #trigger="cdkOverlayOrigin"
     (click)="openFloating($event)" class="height-action">
        <shai-icon-more-vertical-16 *ngIf="!this.data.disabled">
        </shai-icon-more-vertical-16>
        <shai-icon-more-vertical-disabled-16 *ngIf="this.data.disabled">
        </shai-icon-more-vertical-disabled-16>
        <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="isMoreFloatingActive"
            [cdkConnectedOverlayHasBackdrop]="true"
            [cdkConnectedOverlayPositions]="positionPairs"
            [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
            (backdropClick)="isMoreFloatingActive = false">

            <shai-floating-menu #floatingElement (handleItemClick)="onListItemClick($event)"
                [listItems]="listItems"
                width="180"
                [adaptedTop]="false"
                (onChange)="this.isMoreFloatingActive=$event"
                [active]="isMoreFloatingActive">
            </shai-floating-menu>
        </ng-template>
    </div>
  `,
  styleUrls: ['./project-files-icon-more.component.scss']
})
export class ProjectFilesIconMore implements OnInit {
    @Input() data: any;
    listItems: Array<ListItem> = [];
    currentRow: number | undefined;
    isMoreFloatingActive: boolean = false;
    positionPairs: ConnectionPositionPair[] = [
        {
            offsetX: 50,
            offsetY: 15,
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'top'
        },
    ];

    constructor(
        private snackbarService: SnackbarService,
        private store: Store
    ) {}

    ngOnInit(): void {
        this.listItems = [
            // {
            //   'type': 'body',
            //   'title': 'Select',
            //   'value': 'select',
            //   'iconLeft': 'icon-checkmark-16',
            //   'disabled': true
            // },
            {
              'type': 'body',
              'title': 'Delete',
              'value': 'delete',
              'iconLeft': 'icon-trash-16',
              'disabled': this.data.disabled
            },
        ] as ListItem[];
    }

    openFloating(event: any) {
        if (this.data.disabled) {
            return;
        }
        ///event.stopPropagation();
        //9event.preventDefault();
        this.isMoreFloatingActive = true;
    }

    onListItemClick(value: number) {
        this.isMoreFloatingActive = false;
        this.data.value = value;
        this.snackbarService.show(
            'Delete ' + this.data.name + '?',
            SnackbarType.twoButtonsDestructive,
            'This cannot be undone.',
            false,
            'YES, DELETE IT',
            'CANCEL',
            () => this.deleteFile(),
        );
    }

    deleteFile(): void {
        this.store.dispatch(ProjectActions.deleteFile({ suid: this.data.suid, uuid: this.data.uuid }));
    }
}
