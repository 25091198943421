import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

export declare type TipState = 'disabled' | 'rest';
export declare type TipType = 'oneLine' | 'twoLines';

@Component({
  selector: 'shai-tip',
  template: `
  <div class="tip-container">
    <div class="tip-heading" [ngClass]="{'tip-heading-margin': (type === 'twoLines')}">
      <shai-icon-tip></shai-icon-tip>
      <span class="paragraph-small-bold">Tip:</span>
      <span *ngIf="type === 'oneLine'" class="paragraph-small tip-text-one-line" [innerHtml]="text"></span>
    </div>
    <div *ngIf="type === 'twoLines'" class="tip-body">
      <span class="paragraph-small" [innerHtml]="text"></span>
    </div>
  </div>
  `,
  styleUrls: [`./tip.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TipComponent {
  @Input() text: string = '';
  @Input() type: TipType = 'twoLines';
  @Input() state: TipState = 'rest';
  @HostBinding('class.disabled') get disabledClass() {
    return this.state === 'disabled';
  }
}
