import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './core/containers/app.component';
import { ROOT_REDUCERS, metaReducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProjectEffects } from './platform/effects/project.effects';
import { TrainingEffects } from './platform/effects/training.effects';
import { AuthEffects } from './auth/state/effects/auth.effects';
import { ConfigEffects } from './platform/effects/config.effects';
import { LottieModule } from 'ngx-lottie';
import { tokenInterceptor } from './auth/token-interceptor';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { LogEffects } from './platform/effects/log.effects';
import { NodeEffects } from './platform/effects/node.effects';
import { NotFoundComponent } from './error-pages/not-found.component';
import { ErrorTemplateComponent } from './error-pages/components/error-template/error-template.component';
import { SharedModule } from './shared/shared.module';
import { UnderMaintainanceComponent } from './error-pages/under-maintainance.component';
import { OrganizationEffects } from './platform/effects/organization.effects';
import { TrainingSessionEffects } from './platform/effects/training-session.effects';
import { InferenceEffects } from './platform/effects/inference.effects';

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument(): [],
    CoreModule,
    BrowserAnimationsModule,
    EffectsModule.forRoot([ProjectEffects, AuthEffects, ConfigEffects, TrainingEffects, TrainingSessionEffects, LogEffects, NodeEffects, OrganizationEffects, InferenceEffects]),
    LottieModule.forRoot({ player: playerFactory }),
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatMomentModule,
    SharedModule
  ],
  providers: [
  {
    provide: "windowObject",
    useValue: window
  },
  {
    provide: 'env', // you can also use InjectionToken
    useValue: environment
  },
  tokenInterceptor,
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: RequestInterceptor,
  //   multi: true,
  // }
],
  bootstrap: [AppComponent],
  declarations: [
    ErrorTemplateComponent,
    NotFoundComponent,
    UnderMaintainanceComponent
],
  exports: [NotFoundComponent, UnderMaintainanceComponent]
})
export class AppModule { }
