import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WorkoNgGridModule } from 'worko-ng-grid';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TruncatePipe } from './pipes/truncate.pipe';
import { DragDropFileUploadDirective } from './directives/drag-drop-file-upload.directive';
import { ToFileSize } from './pipes/to-file-size.pipe';
import { SecondsFormatterPipe } from './pipes/seconds-formatter.pipe';
import { NgxMatDateAdapter, NgxMatDateFormats, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgOtpInputModule } from 'ng-otp-input';
import { CustomDateAdapter } from '../adapters/custom-date-adapter';
import { CheckboxService } from '../shared/services/checkbox.service';

import { COMPONENTS } from './shared.dependencies.component';
import { SVGS } from './shared.dependencies.svg';
import { MATERIAL } from './shared.dependencies.material';
import { AdDirective } from './directives/ad.directive';
import { SharedRoutingModule } from './shared-routing.module';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { DropdownService } from './services/dropdown.service';
import { LottieModule } from 'ngx-lottie';
import { NewAccessControlDirective } from './directives/new-access-control.directive';
import { ProjectsGroupsService } from './services/projects-groups.service';
import { FormatDateUtilsService } from './services/format-date-utils.service';

// import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

export const DIRECTIVES = [
  DragDropFileUploadDirective,
  AdDirective,
  NewAccessControlDirective
];

export const PIPES = [
  TruncatePipe,
  ToFileSize,
  SecondsFormatterPipe
];

// If using Moment
const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'l, LTS'
  },
  display: {
    dateInput: 'DD/MM/YYYY HH:mm',
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@NgModule({
  declarations: [
    ...SVGS,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES
  ],
  imports: [
    CdkStepperModule,
    CommonModule,
    RouterModule,
    WorkoNgGridModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMatMomentModule,
    NgxPaginationModule,
    NgOtpInputModule,
    SharedRoutingModule,
    LottieModule,
    ...MATERIAL
  ],
  providers: [
    DropdownService,
    CheckboxService,
    ProjectsGroupsService,
    FormatDateUtilsService,
    { provide: NgxMatDateAdapter, useClass: CustomDateAdapter},
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},
  ],
  exports: [
    ...SVGS,
    ...COMPONENTS,
    ...PIPES,
    RouterModule,
    WorkoNgGridModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ...MATERIAL,
    ...DIRECTIVES
  ],
  entryComponents: []
})

export class SharedModule { }
