import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { LottieModule } from 'ngx-lottie';
import { WorkoNgGridModule } from 'worko-ng-grid';
import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginFormComponent } from './components/login-form.component';
import { ChangePasswordComponent } from './containers/change-password.component';
import { EnableTfaComponent } from './containers/enable-tfa.component';
import { QRCodeModule } from 'angularx-qrcode';
import { PasswordChangedComponent } from './containers/password-changed.component';
import { PasswordVerifiedComponent } from './containers/password-verified.component';
import { SigninPageComponent } from './containers/sign-in-page.component';
import { VerifyTfaComponent } from './containers/verify-tfa.component';
import * as fromAuth from '../auth/state/selectors/auth.selectors';
import { SignInTfaComponent } from './containers/sign-in-tfa.component';
import { QrFormatterPipe } from '../shared/pipes/qr-formatter.pipe';
import { ResetPasswordComponent } from './containers/reset-password.component';
import { ResetPasswordStepOneComponent } from './components/reset-password-step-one.component';
import { ResetPasswordStepTwoComponent } from './components/reset-password-step-two.component';
import { ResetPasswordChangedComponent } from './components/reset-password-changed.component';
import { ResetMfaComponent } from './containers/reset-mfa.component';
import { ResetMfaSuccessComponent } from './containers/reset-mfa-success.component';
import { ResetMfaErrorComponent } from './containers/reset-mfa-error.component';
import { RequestAccessComponent } from './components/request-access/request-access.component';
import { RequestAccessOverlayComponent } from './components/request-access-overlay/request-access-overlay.component';
import { RequestAccessFormComponent } from './components/request-access-form/request-access-form.component';

@NgModule({
  declarations: [
    LoginFormComponent,
    SigninPageComponent,
    ResetPasswordComponent,
    ResetPasswordStepOneComponent,
    ResetPasswordStepTwoComponent,
    ResetPasswordChangedComponent,
    ChangePasswordComponent,
    PasswordChangedComponent,
    PasswordVerifiedComponent,
    EnableTfaComponent,
    VerifyTfaComponent,
    SignInTfaComponent,
    ResetMfaComponent,
    ResetMfaSuccessComponent,
    ResetMfaErrorComponent,
    QrFormatterPipe,
    RequestAccessComponent,
    RequestAccessOverlayComponent,
    RequestAccessFormComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    WorkoNgGridModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    LottieModule,
    StoreModule.forFeature({
      name: fromAuth.authFeatureKey,
      reducer: fromAuth.reducers
    }),
    QRCodeModule
  ]
})
export class AuthModule {}
