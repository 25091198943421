import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Config } from 'ng-otp-input/lib/models/config';

@Component({
  selector: 'shai-otp-input',
  template: `
    <div #otpInputContainer class="otp-input-container">
      <ng-otp-input
        #otpInput
        (onInputChange)="onOtpChange($event)"
        [config]="otpConfig"
      ></ng-otp-input>
    </div>
  `,
  styleUrls: ['./otp-input.component.scss'],
})
export class OtpInputComponent implements OnInit, AfterViewInit {
  @ViewChildren('otpInputContainer') otpInputContainer!: QueryList<any>;
  @ViewChild('otpInput', { static: false }) otpInputRef: any;
  @Output() onOtpCompleted = new EventEmitter();
  @Input() otpLength: number = 4;
  @Input() allowNumbersOnly: boolean = true;
  @Input() isPasswordInput: boolean = false;
  firstInput!: HTMLElement;

  otpConfig: Config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '-'
  }

  constructor() {}

  ngOnInit(): void {
    this.otpConfig = {
      allowNumbersOnly: this.allowNumbersOnly,
      length: this.otpLength,
      isPasswordInput: this.isPasswordInput,
      disableAutoFocus: false,
      placeholder: '-',
    };
  }

  ngAfterViewInit(): void {
    this.firstInput = this.otpInputContainer.toArray()[0].nativeElement.childNodes[0].childNodes[0].firstChild;
  }

  onOtpChange(value: any) {
    if (value.length === this.otpLength) {
      this.onOtpCompleted.emit(value);
      this.otpInputRef.otpForm.disable();
      setTimeout(()=> {
        this.firstInput.focus();
      }, 2000);
    }
  }
}
