import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable({providedIn: 'root'})
export class CheckboxData {
    checked: boolean = false;
    value: string | undefined;
    formGroup: string | undefined;
}
export class CheckboxService {
    private checkboxSource = new BehaviorSubject({
        checked: false,
        value: '',
        formGroup: ''
    } as CheckboxData);
    currentCheckbox = this.checkboxSource.asObservable();

    constructor() { }

    changeCheckbox(checkbox: CheckboxData) {
      this.checkboxSource.next(checkbox);
    }
}
