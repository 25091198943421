import { ChangeDetectionStrategy, Component, Input, OnInit, ViewContainerRef, ComponentFactoryResolver, ViewChild, AfterViewInit, Output, EventEmitter, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import { ListItem } from './list-item.model';
import { IconArrowRight16 } from '../../icons/16/icon-arrow-right';
import { IconClose16 } from '../../icons/16/icon-close';
import { IconSoundOn16 } from '../../icons/16/icon-sound-on';
import { IconSoundOff16 } from '../../icons/16/icon-sound-off';
import { IconInfo16 } from '../../icons/16/icon-info';
import { IconWarning16 } from '../../icons/16/icon-warning';
import { IconDocument16 } from '../../icons/16/icon-document';
import { IconCheckmark16 } from '../../icons/16/icon-checkmark';
import { IconPrivacy16 } from '../../icons/16/icon-privacy';
import { IconTrash16 } from '../../icons/16/icon-trash';
import { IconHelp16 } from '../../icons/16/icon-help';
import { IconSettings16 } from '../../icons/16/icon-settings';
import { IconLogout16 } from '../../icons/16/icon-logout';
import { IconModel16 } from '../../icons/16/icon-model';
import { IconUserNew16 } from '../../icons/16/icon-user-new';
import { IconUserGroup16 } from '../../icons/16/icon-user-group';
import { IconDuplicate16 } from '../../icons/16/icon-duplicate';
import { IconLocation16 } from '../../icons/16/icon-location';
import { IconSecure16 } from '../../icons/16/icon-secure';
import { IconChart16 } from '../../icons/16/icon-chart';
// Add new icons at list-time.model.ts,  here ...

@Component({
    selector: 'shai-list-item-singletext',
    template: `
        <div class="list-item-container" [ngClass]="{'error': (listItem?.style === 'error'), 'disabled': listItem?.disabled, 'filter': listItem?.filter, 'unclickable': listItem?.unclickable }" (click)="onListItemClick()">
            <div class="list-item">
                <div [ngClass]="!listItem?.iconLeft ? 'invisible' : 'icon-left'">
                    <ng-container #leftIcon></ng-container>
                </div>
                <ng-content></ng-content>
                <span class="paragraph" #text
                    *ngIf="!listItem?.filter"
                    [ngStyle]="{'cursor': listItem?.unclickable ? 'default' : ''}"
                    [ngClass]="listItem?.title && (listItem.title.length > 32) ? 'truncate-1-line' : ''"
                    title="{{ listItem?.title && (listItem.title.length > 32) ? listItem?.title : '' }}"
                >{{ listItem?.optItem ? '&nbsp; &nbsp; &nbsp;' : '' }} {{ listItem?.title }}</span>
                <div [ngClass]="!listItem?.iconRight ? 'invisible' : 'icon-right'">
                    <ng-container #rightIcon></ng-container>
                </div>
            </div>
        </div>
    `,
    styleUrls: [`./list-item-singletext.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListItemSingletextComponent implements AfterViewInit, OnChanges {
    @ViewChild('leftIcon', { read: ViewContainerRef}) leftIcon: ViewContainerRef | undefined;
    @ViewChild('rightIcon', { read: ViewContainerRef}) rightIcon: ViewContainerRef | undefined;
    @ViewChild('text') text: ElementRef<HTMLInputElement> | undefined;
    @Output() handleItemClick = new EventEmitter();
    @Input() listItem!: ListItem;
    @Input() length: number | undefined;

    modals = { // ... and here
        'icon-checkmark-16': IconCheckmark16,
        'icon-close-16': IconClose16,
        'icon-sound-on-16': IconSoundOn16,
        'icon-sound-off-16': IconSoundOff16,
        'icon-document-16': IconDocument16,
        'icon-info-16': IconInfo16,
        'icon-arrow-right-16': IconArrowRight16,
        'icon-privacy-16': IconPrivacy16,
        'icon-warning-16': IconWarning16,
        'icon-trash-16': IconTrash16,
        'icon-location-16': IconLocation16,
        'icon-settings-16': IconSettings16,
        'icon-help-16': IconHelp16,
        'icon-logout-16': IconLogout16,
        'icon-model-16': IconModel16,
        'icon-user-new-16': IconUserNew16,
        'icon-user-group-16': IconUserGroup16,
        'icon-duplicate-16': IconDuplicate16,
        'icon-secure-16': IconSecure16,
        'icon-chart-16': IconChart16
    };

    constructor(
        public viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) { }

    ngAfterViewInit() {
        if (this.listItem?.iconRight) {
            this.rightIcon?.clear();
            let component = this.modals[this.listItem.iconRight];
            const cFactory = this.componentFactoryResolver.resolveComponentFactory(component);
            const componentRef2 = this.rightIcon!.createComponent(cFactory);
            componentRef2.changeDetectorRef.detectChanges();
        }
        if (this.listItem?.iconLeft) {
            this.leftIcon?.clear();
            let component = this.modals[this.listItem.iconLeft];
            const cFactory = this.componentFactoryResolver.resolveComponentFactory(component);
            const componentRef = this.leftIcon!.createComponent(cFactory);
            componentRef.changeDetectorRef.detectChanges();
        }
    }

    onListItemClick() {
        const itemContent = this.text?.nativeElement.textContent;
        if (!this.listItem?.disabled && !this.listItem?.filter && !this.listItem?.unclickable) {
            this.handleItemClick.emit(itemContent);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.length) {
            this.length = changes?.length?.currentValue;
            if (this.length !== undefined) {
                if (this.length > 0 && this.listItem && this.listItem.value === 'select_multiple') {
                    this.listItem.disabled = false;
                } else if (this.length === 0 && this.listItem.value === 'select_multiple') {
                    this.listItem.disabled = true;
                }
            }
        }
    }
}
