import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

export declare type FormInputTextSize = 'normal' | 'large' | 'small';
export declare type FormInputTextType = 'textfield' | 'textarea';
export interface InputErrorMessage {
  type: string;
  message: string;
}

@Component({
  selector: 'shai-form-input-text',
  template: `
    <div #fieldContainer class="field-container">
      <mat-form-field
        #matFormField
        [ngClass]="[
          fieldSize === 'normal' ? 'paragraph' : 'heading',
          !label ? 'top-zero' : ''
        ]"
        [appearance]="appeareance"
      >
        <mat-label
          *ngIf="label && fieldSize !== 'small'"
          class="caption"
          [ngClass]="isDisabled ? 'disabled' : ''"
          >{{ label }}</mat-label>
        <div class="input-container">
        <input
          *ngIf="type === 'textfield'"
          class="paragraph"
          matInput
          [type]="showPasswordText ? 'text' : 'password'"
          [maxLength]="maxLength"
          [id]="fieldName"
          [name]="fieldName"
          [value]="value"
          [disabled]="isDisabled"
          (keydown)="onKeyDown($event)"
          (input)="onChange($event)"
          (blur)="touched()"
          (focus)="onFocus()"
          (focusout)="onFocusOut()"
          [placeholder]="fieldSize === 'small' ? label || '' : ''"
        />
        <textarea
          *ngIf="type === 'textarea'"
          matInput
          [placeholder]="label || ''"
          [maxLength]="maxLength"
          [id]="fieldName"
          [name]="fieldName"
          [value]="value"
          [disabled]="isDisabled"
          (input)="onChange($event)"
          (blur)="touched()"
          (focus)="onFocus()"
          (focusout)="onFocusOut()"
          [matTextareaAutosize]="true"
          [matAutosizeMinRows]="minRows"
          [matAutosizeMaxRows]="100"
        ></textarea>
        <button type="button" class="secure-button" (click)="toggleEye($event)" *ngIf="isSecureField">
          <shai-icon-eyeon-16 *ngIf="!showPasswordText" > </shai-icon-eyeon-16>
          <shai-icon-eyeoff-16 *ngIf="showPasswordText"> </shai-icon-eyeoff-16>
        </button>
        </div>
      </mat-form-field>
      <span class="caption error" *ngIf="errorStatus() || customErrorMessage">{{
        getErrorMessage() || customErrorMessage
      }}</span>
    </div>
  `,
  styleUrls: [`./form-input-text.component.scss`],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputTextComponent),
      multi: true,
    },
  ],
})
export class FormInputTextComponent implements OnInit, ControlValueAccessor {
  @Input() label: string | undefined;
  @Input() isSecureField: boolean = false;
  @Input() maxLength: string = '50';
  @Input() appeareance: MatFormFieldAppearance = 'fill';
  @Input() fieldSize: FormInputTextSize = 'normal';
  @Input() parentForm: FormGroup = new FormGroup({});
  @Input() fieldName: string = '';
  @Input() isDisabled: boolean = false;
  @Input() type: FormInputTextType = 'textfield';
  @Input() minRows: number = 1
  @Input() errorMessages: InputErrorMessage[] = [];
  @Input() customErrorMessage: string | undefined;
  @Input() hasSubmitAttemps: boolean = false;
  @Input() value: string = '';
  @Output() onInputChange = new EventEmitter();
  @Output() onSubmitKeyDown = new EventEmitter();
  @ViewChild('fieldContainer', { static: true }) fieldContainerRef?: ElementRef;
  @ViewChild('matFormField', { static: true }) matFormFieldRef?: ElementRef;

  showPasswordText = false;

  changed: (value: string) => void = () => {};
  touched: () => void = () => {};

  constructor(private render: Renderer2) {}

  @HostBinding('class.small-field') get smallFieldClass() {
    return this.fieldSize === 'small';
  }

  get formField(): FormControl {
    return this.parentForm?.get(this.fieldName) as FormControl;
  }

  errorStatus(): boolean {
    return (
      !this.formField?.valid && this.hasSubmitAttemps && this.formField?.enabled
    );
  }

  getErrorMessage(): string | undefined {
    let message: string | undefined;
    if (this.formField.errors) {
      const errorKey = Object.keys(this.formField.errors)[0];
      if (errorKey) {
        if (this.errorMessages.length > 0){
          message = this.errorMessages.find(error => error.type === errorKey)?.message;
        } else { // Custom validator
          message = this.formField.errors.message
        }
      }
    }

    return message;
  }

  ngOnInit(): void {
    this.showPasswordText = this.isSecureField ? false : true;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  onChange(event: Event): void {
    const value: string = (<HTMLInputElement>event.target).value;
    this.changed(value);
    this.onInputChange.emit(value);
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onFocus() {
    this.render.addClass(this.fieldContainerRef?.nativeElement, 'focus-in');
  }

  onFocusOut() {
    this.render.removeClass(this.fieldContainerRef?.nativeElement, 'focus-in');
  }

  toggleEye(event: any) {
    event.preventDefault();
    this.showPasswordText = !this.showPasswordText;
  }

  onKeyDown(event: any): void {
    if (this.fieldName === 'password' && event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.onSubmitKeyDown.emit(true);
    }
  }
}
