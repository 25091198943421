import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-disable-16',
  template: `
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m1 8c0-3.86599 3.13401-7 7-7 3.866 0 7 3.13401 7 7 0 3.866-3.134 7-7 7-3.86599 0-7-3.134-7-7zm2.41849 3.8744c-.8849-1.0453-1.41849-2.39754-1.41849-3.8744 0-3.31371 2.68629-6 6-6 1.47686 0 2.8291.53359 3.8744 1.41849zm.70711.7071c1.04534.8849 2.39756 1.4185 3.8744 1.4185 3.3137 0 6-2.6863 6-6 0-1.47684-.5336-2.82906-1.4185-3.8744z" fill="#1f1f1f" fill-rule="evenodd"/></svg>
  `
})
export class IconDisable16 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
