import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-logout-16',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.8535 5.14648L11.1464 5.85359L13.2928 8L3.49994 8L3.49994 9L13.2929 9L11.1464 11.1465L11.8535 11.8536L15.207 8.50004L11.8535 5.14648Z" fill="#1F1F1F"/>
      <path d="M8.99988 5L8.99994 1L-6.10352e-05 0.999999L-6.16908e-05 16L8.99994 16L8.99988 12L7.99988 12L7.99994 15L0.999938 15L0.999939 2L7.99994 2L7.99988 5L8.99988 5Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class IconLogout16 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}