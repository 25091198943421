import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { fadeIn } from 'src/app/shared/animations';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { InputErrorMessage } from 'src/app/shared/components/form-controls/input-text/form-input-text.component';
import { select, Store } from '@ngrx/store';
import * as fromPlatform from 'src/app/platform/reducers';
import { BreackpointService } from 'src/app/platform/services/breackpoint.service';
import {
  SnackbarService,
  SnackbarType,
} from 'src/app/shared/components/snackbar/snackbar.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { OnDestroy } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { hasClass } from 'src/app/utils';
import { ListItem } from 'src/app/shared/components/list-items/list-item.model';
import { TableColumn } from 'src/app/shared/components/table/models/table.model';
import { TableCellSingleComponent } from 'src/app/shared/components/table/rows/cells/table-cell-single.component';
import { UserIconMore } from 'src/app/projects/components/user-icon-more.component';
import { CheckboxComponent } from 'src/app/shared/components/checkbox.component';
import { NewTableComponent } from 'src/app/shared/components/table/new-table.component';
import { first } from 'rxjs/operators';

const USERS_GROUP = 'users_group';

@Component({
  selector: 'shai-user-role-overlay',
  animations: [fadeIn],
  template: `
    <div #locked></div>
    <shai-overlay
      id="user-role-overlay-wrapper"
      (onChange)="onChangeOverlay($event)"
      [active]="isActive"
      [complete]="isComplete"
      [hasHeader]="false"
      [fullWidth]="false"
      (onLeave)="closeChangePasswordOverlay()"
      [showActionBar]="false"
    >
      <div>
        <mat-grid-list cols="12" rowHeight="fit">
          <mat-grid-tile
            colspan="1"
            *ngIf="(_bkp.obsScreen$ | async) === 'desktop'"
          >
          </mat-grid-tile>
          <mat-grid-tile
            [colspan]="(_bkp.obsScreen$ | async) === 'desktop' ? 10 : 12"
          >
            <shai-title-bar
              title="User & Role Management"
              style="padding-left: 1px;"
            >
            </shai-title-bar>

            <div class="loading-content" *ngIf="loadingUsers">
              <ng-lottie
                class="loading-lottie"
                [options]="blackLoaderAnimation"
              ></ng-lottie>
            </div>

            <div class="container-form" *ngIf="!loadingUsers">
              <shai-table-header [headerTabs]="['Users']">
                <div
                  right
                  class="table-header-right-contextual"
                  *ngIf="!selectMultipleActive"
                >
                  <shai-button
                    [type]="'button'"
                    [disabled]="dataset.length === 0"
                    iconName="icon-checkmark-16"
                    (click)="onSelectMultiple()"
                    [buttonStyle]="'contextual'"
                    tooltipText="Select multiple"
                    >Select multiple</shai-button
                  >
                </div>

                <div
                  right
                  *ngIf="selectMultipleActive"
                  class="table-header-right-contextual"
                >
                  <shai-button
                    [type]="'button'"
                    iconName="icon-trash-16"
                    [buttonStyle]="'contextual'"
                    (click)="onDeleteClick()"
                    [disabled]="dataset.length === 0 || disabledDeleteBtn"
                    tooltipText="Delete"
                  >
                  </shai-button>
                  <shai-button
                    [type]="'button'"
                    (click)="onCancelClick()"
                    iconName="icon-close-16"
                    [buttonStyle]="'contextual'"
                    tooltipText="Cancel"
                    >Cancel</shai-button
                  >
                </div>
              </shai-table-header>
              <shai-new-table
                #userList
                class="table-row-clickable"
                [dataset]="dataset"
                [columns]="manageColumns"
                [showPaginator]="true"
                [pageSize]="5"
                (onRowClicked)="clickOnRow($event)"
              >
              </shai-new-table>

              <div *ngIf="showNewUser">
                <div
                  class="mat-table"
                  *ngFor="let userRow of userRows; let i = index"
                >
                  <div class="mat-row">
                    <div class="mat-cell-left">
                      <shai-form-input-text
                        fieldName="email"
                        [(value)]="userRow.email"
                        label="Type Email"
                        fieldSize="small"
                        (onInputChange)="handleInputChange($event, i)"
                      >
                      </shai-form-input-text>
                    </div>
                    <div class="mat-cell-left">
                      <shai-dropdown
                        [data]="{
                          title: 'Choose',
                          listItems: roles
                        }"
                        (onListItemChange)="handleDropdownChange($event, i)"
                      ></shai-dropdown>
                    </div>

                    <div class="mat-cell-right">
                      <div class="plus-container">
                        <shai-button
                          [type]="'button'"
                          iconName="icon-minus-16"
                          [buttonStyle]="'contextual'"
                          tooltipText="Delete user row"
                          (click)="deleteUserRow(i)"
                        ></shai-button>
                      </div>
                      <div class="plus-container">
                        <shai-button
                          [type]="'button'"
                          iconName="icon-plus-16"
                          [buttonStyle]="'contextual'"
                          tooltipText="Add user row"
                          (click)="addUserRow()"
                        ></shai-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="align-center bottom-space">
                <shai-button
                  *ngIf="!showNewUser"
                  [type]="'button'"
                  (click)="showNewUserField()"
                  buttonStyle="secondary"
                  >Add New User</shai-button
                >
                <shai-button
                  *ngIf="showNewUser"
                  [type]="'button'"
                  (click)="addUsersToOrg()"
                  buttonStyle="primary"
                  >Add</shai-button
                >
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </shai-overlay>
  `,
  styleUrls: ['./user-role-overlay.component.scss'],
})
export class UserRoleOverlayComponent implements OnInit, OnDestroy {
  @ViewChild('locked', { static: false }) lockedRef: any;
  @ViewChild('userList', { read: NewTableComponent })
  userListTableRef!: NewTableComponent;

  @Output() changePassEmitter = new EventEmitter();
  @Output() changePassDataComplete = new EventEmitter();
  @Input() userName: string | undefined;
  @Input() userOrg: string | undefined;

  _users: any[] = [];
  checkboxArray: any[] = [];
  disabledDeleteBtn: boolean = true;

  blackLoaderAnimation: AnimationOptions = {
    path: '/assets/lotties/loader.json',
    loop: true,
  };
  changingPassword: boolean = false;
  changePasswordForm = this.formBuilder.group({
    oldPassword: ['', [Validators.required]],
    newPassword: ['', [Validators.required]],
  });
  hasSubmitAttemps = false;
  passwordErrorMessages: InputErrorMessage[] = [
    { type: 'required', message: 'The password field is required.' },
  ];
  rowHeight = 'fit';
  isActive: boolean = true;
  isComplete: boolean = false;
  private usersSubscription?: Subscription;
  private plan?: any
  private planSubscription?: Subscription;

  private plan$: Observable<any> = new Observable();

  public loadingUsers: boolean = true;
  @HostListener('window:resize', ['$event']) onResize() {
    this.rowHeight = window.innerHeight - 144 - 44 + 'px';
  }

  showNewUser: boolean = false;
  selectMultipleActive = false;
  userRows: any[] = [];

  roles: ListItem[] = [
//     {
//       title: 'Admin',
//       type: 'body',
//       value: 'HUMAN',
//       checked: true,
//       filter: false,
//     },
    {
      title: 'User',
      type: 'body',
      value: 'EXPERIMENTAL_PRO',
      checked: false,
      filter: false,
    },
  ];

  manageColumns: TableColumn[] = [];

  dataset: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    public _bkp: BreackpointService,
    private _snackbarService: SnackbarService,
    private cdRef: ChangeDetectorRef,
    private _authService: AuthService,
    private render: Renderer2
  ) {
    this.plan$ = this.store.pipe(select(fromPlatform.getPlan)) as Observable<any>;
    this.onResize();
  }

  ngOnInit() {
    this._setPlanSubscription();
    this._setUsersSubscription();
  }

  private _setUsersSubscription() {
    this.usersSubscription = this._authService.loadUserList(this.userOrg!).subscribe((users) => {
      if (users?.items?.length) {
        this.loadingUsers = false;
        this.users = users.items;
        this._generateColumns();
        this._loadDataset();
        this.cdRef.detectChanges();
      }
    });
  }

  private _setPlanSubscription() {
    this.planSubscription = this.plan$.subscribe((plan) => {
      if(plan) {
        this.plan = plan;
      }
    })
  }


  handleInputChange(value: string, index: number) {
    this.userRows[index].email = value;
    this.userRows[index].username = this.getUserName(value);
  }

  handleDropdownChange(event: any, index: number) {
    this.userRows[index].role = event.value;
  }

  getUserName(username: string) {
    return username.split('@')[0];
  }

  addUserRow() {
    this.userRows.push({
      email: '',
      role: '',
      username: ''
    });
  }

  deleteUserRow(i: any) {
    this.userRows.splice(i, 1);
    if(this.userRows.length === 0) {
      this.showNewUser = false;
    }
  }

  get users(): any[] {
    return this._users;
  }

  set users(value: any[]) {
    this._users = [...value];
    this._loadDataset();
    console.log(this._users)
  }

  private _loadDataset() {
    this.dataset = [];
    this.users?.forEach((user, index) => {
      this.dataset.push({
        row: index,
        email: user.email,
        username: user.username,
        role: user.role,
        checked: false,
        emitToParent: this.selectMultipleActive ? false : true,
        formGroup: USERS_GROUP,
        disabled: this.selectMultipleActive,
      });
    });
  }

  ngOnDestroy(): void {
    this.usersSubscription?.unsubscribe();
    this.planSubscription?.unsubscribe();
  }

  closeOverlay() {
    setTimeout(() => {
      this.isComplete = true;
      this.cdRef.detectChanges();
    }, 1000);
  }

  onChangeOverlay(event: boolean) {
    if (!event) {
      this.finishProcess();
    } else {
      this.isActive = true;
    }
  }

  deleteSelectedUsers(): void {
    const users = this._getSelectedUsers();
    for (let i = 0; i < users.length; i++) {
      this._authService
        .deleteUserByUsername(this.userOrg!, this.getUsername(users[i]))
        .pipe(first())
        .subscribe();
    }
    this.users = this.users.filter(
      (fdt) => !users.some((st) => st === fdt.email)
    );
    this.userListTableRef.setOriginalData(this.dataset);
    this.cdRef.detectChanges();
    this.onCancelClick();
  }

  private _getSelectedUsers(): string[] {
    return this.dataset
      .filter((item) => item.checked)
      .map((item) => item.email);
  }

  showNewUserField() {
    this.showNewUser = true;
    this.addUserRow();
  }

  addUsersToOrg() {
    if (this.isValid()) {
      const totalUsers = [...this.userRows, ...this.users];
      if(totalUsers.length <= this.plan.usersIncluded) {
        this.users = [...this.userRows, ...this.users];
        this.userListTableRef.setOriginalData(this.dataset);
        setTimeout(() => {
          this.userRows = [];
          this.showNewUser = false;
        }, 300);
      } else {
        this._authService.upgradePlan("You can't add more users", "to include it");
        return
      }
      for (let i = 0; i < this.userRows.length; i++) {
          const email = this.userRows[i].email;
          const role = this.userRows[i].role;
          this._authService
            .addUserToOrg(this.userOrg!, email, role)
            .pipe(first())
            .subscribe();
        }
      }
  }

  isValid() {
    const isAnyFieldIncomplete = this.userRows.some(
      (userRow) => !userRow.email || !userRow.role
    );

    if (isAnyFieldIncomplete) {
      setTimeout(() => {
        this._snackbarService.show(
          'Please complete all fields',
          SnackbarType.simple
        );
      }, 300);
      return false;
    } else if (!this.areEmailsValid()) {
      setTimeout(() => {
        this._snackbarService.show(
          'Email format is invalid',
          SnackbarType.simple
        );
      }, 300);
      return false;
    } else {
      return true;
    }
  }

  areEmailsValid() {
    const emailRegex = new RegExp(
      '^([A-Za-z0-9]+[._-])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\\.[A-Z|a-z]{2,})+$'
    );
    return this.userRows.every((userRow) => emailRegex.test(userRow.email));
  }

  closeChangePasswordOverlay() {
    this.isActive = false;
  }

  private finishProcess() {
    this.changePassEmitter.emit(false);
  }

  onSelectMultiple() {
    this.selectMultipleActive = true;
    this._generateColumns();
    this.setEmitToParentDataset();
    this.disabledDeleteBtn = this._isUserSelected() ? false : true;
  }

  onDeleteClick(): void {
    if (!this.disabledDeleteBtn && this._isUserSelected()) {
      this._snackbarService.show(
        'Delete this user(s)?',
        SnackbarType.twoButtonsDestructive,
        'This cannot be undone.',
        false,
        'YES, DELETE IT',
        'CANCEL',
        () => this.deleteSelectedUsers()
      );
    }
  }

  getUsername(email: string) {
    const index = this.users.findIndex((user) => email === user.email);
    if (index === -1) return '';
    return this.users[index].username;
  }

  onCancelClick() {
    this.selectMultipleActive = false;
    this._generateColumns();
    this.setEmitToParentDataset();
    this.setCheckedDataset(false);
    this._refreshFilesTable();
  }

  private _refreshFilesTable() {
    this.users = [...this.users];
    this.manageColumns = Object.assign([], this.manageColumns);
  }

  setCheckedDataset(value: boolean) {
    const clone = [...this.dataset];
    clone.forEach((item) => {
      item.checked = value;
    });
    this.dataset = [...clone];
  }

  setEmitToParentDataset() {
    const clone = [...this.dataset];
    clone.forEach((item) => {
      item.emitToParent = this.selectMultipleActive ? false : true;
    });
    this.dataset = [...clone];
  }

  clickOnRow(rowClicked: any) {
    this.checkboxArray = this.userListTableRef.checkboxArray;
    if (rowClicked && !rowClicked?.disabled) {
      rowClicked.checked = !rowClicked.checked;
      const index =
        this.dataset.findIndex((element) => element.row === rowClicked.row) % 5;
      const checkReference =
        this.checkboxArray[index].nativeElement.childNodes[0].childNodes[0]
          .childNodes[0].childNodes[0];
      if (!rowClicked.checked && hasClass(checkReference, 'checked')) {
        this.render.removeClass(checkReference, 'checked');
      } else if (rowClicked.checked && !hasClass(checkReference, 'checked')) {
        this.render.addClass(checkReference, 'checked');
      }
    }

    this.disabledDeleteBtn = this._isUserSelected() ? false : true;
  }

  private _isUserSelected(): boolean {
    let response = false;
    const ids = this.dataset
      .filter((item) => item.checked)
      .map((item) => item.uuid);
    if (ids.length > 0) {
      response = true;
    }
    return response;
  }

  getRoleTitle(role: string): string {
    switch (role) {
      case 'HUMAN':
        return 'Admin';
      default:
        return 'User';
    }
  }

  private _generateColumns() {
    this.manageColumns = [
      {
        columnDef: 'email',
        header: 'Email',
        width: 'auto',
        sortingDisabled: false,
        component: TableCellSingleComponent,
        cellData: (element: any) => {
          return { text: element.email };
        },
      },
      {
        columnDef: 'role',
        header: 'Role',
        width: 'auto',
        sortingDisabled: false,
        component: TableCellSingleComponent,
        cellData: (element: any) => {
          return { text: this.getRoleTitle(element.role) };
        },
      },
      {
        columnDef: 'more',
        header: '',
        width: '40px',
        sortingDisabled: true,
        component: UserIconMore,
        cellData: (element: any) => {
          return {
            username: element.username,
            org: this.userOrg,
            disabled: this.selectMultipleActive,
          };
        },
      },
    ];

    if (this.selectMultipleActive) {
      this.manageColumns.unshift({
        columnDef: 'checkbox',
        width: '72px',
        header: '',
        sortingDisabled: true,
        component: CheckboxComponent,
        cellData: (element: any) => {
          return {
            value: element.row,
            checked: false,
            cellAlign: 'flex-start',
            formGroup: element.formGroup,
          };
        },
      });
    }
  }
}
