import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { catchError, map, switchMap } from "rxjs/operators";
import { ConfigActions } from "..";
import { ConfigService } from "../services/config.service";

@Injectable()
export class ConfigEffects {

  loadConfig$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.loadConfig),
    switchMap(() => this._config.loadConfig().pipe(
      map(config => ConfigActions.loadConfigSuccess({config})),
      catchError(error => [ConfigActions.loadConfigFailure({error})])
    )))
  )

  constructor(
    private actions$: Actions,
    private store: Store,
    private _config: ConfigService
  ) {}
}