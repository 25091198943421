import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-aggregator-w-24',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DCDDE0"/>
    <path d="M8.9865 15.8H10.0305L11.9745 9.092H12.0225L13.9305 15.8H14.9865L17.0745 7.52V7.4H16.0425L14.4465 14.168H14.3985L12.5265 7.4H11.4465L9.5985 14.168H9.5505L7.9425 7.4H6.9225V7.52L8.9865 15.8Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class AggregatorW24 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
