import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { fadeIn } from 'src/app/shared/animations';
import { Store, select } from '@ngrx/store';
import * as fromAuth from '../state/selectors/auth.selectors';
import { AuthActions } from '../state/actions';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { AuthStatus, AuthResponse, User } from '../models/auth.models';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'shai-signin-page',
  animations: [fadeIn],
  template: `
    <div class="page-container" @fadeIn>
      <div class="left-container">
        <div class="div-spacer"></div>
        <div class="brand-container">
          <div class="brand">
            <img src="assets/images/logo-welcome.png" />
          </div>
          <h1 class="h3">
            Privacy-Preserving<br />Artificial Intelligence<br />to Accelerate
            your Business
          </h1>
          <p class="caption">
            Our goal is to improve your organization's results through AI with the world’s most advanced Federated Learning platform.
          </p>
          <div class="icons-wrapper">
            <img class="icons" src="assets/icons/welcome-icons.svg" />
          </div>
        </div>
        <div class="div-spacer"></div>
        <shai-footer ngClass="dark"></shai-footer>
      </div>
      <div class="right-container">
        <div class="right-content">
          <div class="bottom-wrapper">
            <shai-login-form
              *ngIf="!(user$ | async)"
              [authStatus$]="authStatus$"
              (user$)="(user$)"
              (sendCredential)="getCredentialEmail($event)"
              (onSubmit)="handleSubmit($event)"
              (onForgotPasswordOverlay)="this.forgotPasswordOverlayActive = true"
            ></shai-login-form>

            <shai-reset-password
              *ngIf="forgotPasswordOverlayActive"
              (onChange)="closeForgotPasswordOverlay()"
            >
            </shai-reset-password>
          </div>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./sign-in-page.component.scss'],
})
export class SigninPageComponent implements OnInit {
  user$: Observable<User> = new Observable();
  authStatus$: Observable<AuthStatus> = new Observable();
  email = '';
  forgotPasswordOverlayActive = false;
  private subscription?: Subscription;

  constructor(
    private authService: AuthService,
    private store: Store,
    private cdRef: ChangeDetectorRef,
    private router: Router
  ) {
    this.store.dispatch(AuthActions.clearReset());
    this.user$ = this.store.pipe(select(fromAuth.getUser)) as Observable<User>;
    this.authStatus$ = this.store.pipe(
      select(fromAuth.getAuthStatus)
    ) as Observable<AuthStatus>;
    const authFlow = sessionStorage.getItem('AUTH_FLOW');
    if (authFlow) {
      sessionStorage.setItem('USER_FLOW', authFlow);
    }
  }

  ngOnInit(): void {
    this._checkAuth();
  }

  private _checkAuth() {
    this.subscription = this.store
      .pipe(select(fromAuth.getLoginResponse))
      .subscribe((loginResponse) => {
        if (loginResponse) {
          switch (loginResponse.authFlow) {
            case 'NEW_PASSWORD_REQUIRED':
              // Usuario sin password definitivo ni MFA activado
              console.log('[Login] Cambie su contraseña!');
              this.router.navigate(['/auth/change-password']);
              this.subscription?.unsubscribe();
              break;
            case 'SOFTWARE_TOKEN_MFA':
              // Usuario con password definitivo y MFA activado
              console.log(
                '[Login] Obtenemos la sesión. Falta que incluyas tu codigo de 6 digitos!'
              );
              this.router.navigate(['/auth/sign-in-tfa']);
              this.subscription?.unsubscribe();
              break;
            case 'ENABLE_SOFTWARE_TOKEN_MFA':
              // Usuario con password definitivo y sin MFA activado
              console.log('[Login] Active el MFA!');
              this.router.navigate(['/auth/enable-tfa']);
              this.subscription?.unsubscribe();
              break;
            default:
              // User without MFA
              this.authService.setAuthDataInSessionStorage(loginResponse);
              const user = this.authService.getUserFromIdToken();
              this.router.navigate(['/']);
              this.store.dispatch(AuthActions.signInMFASuccess({ authResponse: loginResponse, user }));
              break;
          }
        }
      });
  }

  handleSubmit(credentials: AuthResponse) {
    this.store.dispatch(AuthActions.login({ credentials }));
  }

  getCredentialEmail(email: string) {
    this.email = email;
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

  registerUser(email: string) {
    this.authService.registerUser(email).subscribe(
      (resp) => {
        if (resp === null) {
          console.log(
            '[Login] Usuario creado correctamente. Revise su correo!'
          );
        } else {
          console.log('[Login] resp', resp);
        }
      },
      (error) => {
        if (error) {
          if (error.status === 409) {
            console.log('[Login] El usuario que intenta crear ya existe');
          } else {
            console.log('[Login] error', error);
          }
        }
      }
    );
  }

  deleteUser(email: string) {
    this.authService.deleteUser(email).subscribe(
      (resp) => {
        if (resp === null) {
          console.log('[Login] Usuario borrado correctamente');
          sessionStorage.clear();
        } else {
          console.log('[Login] resp', resp);
        }
      },
      (error) => {
        if (error) {
          if (error.status === 409) {
            console.log('[Login] Error al borrar el usuario');
          } else {
            console.log('[Login] error', error);
          }
        }
      }
    );
    this.store.dispatch(AuthActions.logout());
  }

  closeForgotPasswordOverlay() {
    setTimeout(() => {
      this.forgotPasswordOverlayActive = false;
      this.cdRef.detectChanges();
    }, 700);
  }
}
