import {
  ChangeDetectionStrategy,
  Component,
  Output,
  EventEmitter,
  Input,
  HostListener,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { InputErrorMessage } from 'src/app/shared/components/form-controls/input-text/form-input-text.component';
import { AuthStatus, User } from '../models/auth.models';
import { RequestAccessMode, RequestAccessUIBlock, getInitialBlocks } from './request-access/request-access.model';

@Component({
  selector: 'shai-login-form',
  template: `
    <div class="login-form-container">
      <div class="login-form-header">
        <h4>Sign in or request access</h4>
        <p class="separation-description">
          Sign in details should have been emailed to you by our sales team. If you are new to Sherpa.ai, get started by registering below.
        </p>
      </div>
      <div class="form-container">
        <form
          autocomplete="off"
          [formGroup]="loginForm"
        >
          <div class="form-field-group">
            <shai-form-input-text
              [parentForm]="loginForm"
              formControlName="organizationSuid"
              fieldName="organizationSuid"
              label="Organization SUID"
              fieldSize="small"
              [errorMessages]="suidErrorMessages"
            >
            </shai-form-input-text>
          </div>
          <div class="form-field-group">
            <shai-form-input-text
              [parentForm]="loginForm"
              formControlName="username"
              fieldName="username"
              label="Your email"
              fieldSize="small"
              (onInputChange)="getEmail($event)"
              [errorMessages]="usernameErrorMessages"
            >
            </shai-form-input-text>
            <shai-form-input-text
              [parentForm]="loginForm"
              formControlName="password"
              fieldName="password"
              label="Password"
              fieldSize="small"
              [isSecureField]="true"
              [errorMessages]="passwordErrorMessages"
              (onSubmitKeyDown)="onSubmitKeydown($event)"
            >
            </shai-form-input-text>
          </div>
          <mat-error *ngIf="hasSubmitAttemps && loginForm.invalid">
            Invalid form data
          </mat-error>
          <mat-error
            *ngIf="
              hasSubmitAttemps && loginForm.valid && (authStatus$ | async)?.error
            "
          >
            This details don't match our database.
          </mat-error>
          <span
            *ngIf="
              hasSubmitAttemps && loginForm.valid && (authStatus$ | async)?.error
            "
          >
            Please double-check for typos or
            <a target="_blank" [href]="mailto">contact us</a>.
          </span>
          <div class="forgot-text">
            <span
              class="caption link underline"
              (click)="openForgotPasswordOverlay()"
              >Forgot your password?</span
            >
          </div>
          <div class="forgot-text">
            <span
              class="caption"
              >If you have any problem accessing the platform <a target=“_blank” [href]="'mailto:support@sherpa.ai'">contact us</a></span
            >
          </div>
          <div class="bottom-btns">
            <shai-button
              [type]="'button'"
              (click)="handleSubmit($event)"
              buttonStyle="secondary">
                Sign In
            </shai-button>
            <shai-button
              (click)="openOverlay()">
                Request access
            </shai-button>
          </div>
        </form>
      </div>
    </div>

    <shai-request-access-overlay
      [requestAccessMode]="selectedBlock"
      *ngIf="showOverlay"
      (toggleOnChange)="closeOverlay()"
    >
    </shai-request-access-overlay>
  `,
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent {
  @Output() onForgotPasswordOverlay = new EventEmitter();
  @Output() onSubmit = new EventEmitter();
  @Output() sendCredential = new EventEmitter();
  @Input() user$: Observable<User> = new Observable();
  @Input() authStatus$: Observable<AuthStatus> = new Observable();

  showOverlay = false;
  selectedBlock: RequestAccessMode = RequestAccessMode.Enterprise;

  mailto = 'mailto:engineering@sherpa.ai';
  hasSubmitAttemps = false;
  loggingIn = false;

  passwordErrorMessages: InputErrorMessage[] = [
    { type: 'required', message: 'Password is required' },
    { type: 'minlength', message: 'Should have 4 chars' },
  ];

  usernameErrorMessages: InputErrorMessage[] = [
    { type: 'required', message: 'Email is required' },
    { type: 'email', message: 'Invalid email format' },
  ];

  suidErrorMessages: InputErrorMessage[] = [
    { type: 'required', message: 'Organization SUID is required' },
  ];

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.getCredentialEmail();
  }

  loginForm = this.formBuilder.group({
    organizationSuid: ['', [Validators.required]],
    username: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(3)]],
  });

  onSubmitKeydown(event: Event) {
    if (this.loginForm.valid) {
      this.handleSubmit(event);
    }
  }

  handleSubmit(event: Event) {
    // event.preventDefault();
    this.hasSubmitAttemps = true;
    if (this.loginForm.valid) {
      // Password without spaces
      this.loginForm.value.password = this.loginForm.value.password
        .replace(/\s+/g, '')
        .trim();
      this.onSubmit.emit({
        authFlow: 'USER_PASSWORD_AUTH',
        ...this.loginForm.value,
      });
    }
  }

  openOverlay(): void {
    this.showOverlay = true;
  }

  closeOverlay(): void {
    this.showOverlay = false;
  }

  getCredentialEmail() {
    if (this.loginForm.valid) {
      this.sendCredential.emit(this.loginForm.get('username')?.value);
    }
  }

  getEmail(value: any) {
    this.sendCredential.emit(value);
  }

  openForgotPasswordOverlay() {
    this.onForgotPasswordOverlay.emit(true);
  }
}
