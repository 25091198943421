import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, combineLatest, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  Inference,
  InferenceFile,
  InferenceFiles,
  InferencesList,
  InfrastructureConfig,
  NewInferenceResponse,
  NodeLinkInferenceResponse,
  NodeMappingInferenceResponse,
} from '../../platform/models';
import { catchError, map, tap } from 'rxjs/operators';
import { sortObjectArrayByConfigName } from 'src/app/utils';
import { Config } from '../../platform/models/organization';

const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

@Injectable({
  providedIn: 'root',
})
export class InferenceService {

  private deleteModelSubject = new Subject<string>();
  deleteModel$ = this.deleteModelSubject.asObservable();
  baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) {}

  emitDeleteModel(modelId: string) {
    this.deleteModelSubject.next(modelId);
  }

  startInference(
    orgId: any,
    projectId: any,
    inferenceId: any
  ) {
    return this.http.post(
      `${this.baseUrl}orgs/${orgId}/projects/${projectId}/inferences/${inferenceId}/start`,
      {},
      { headers }
    )
    .pipe(
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }

  stopInference(
    orgId: any,
    projectId: any,
    inferenceId: any
  ) {
    return this.http.post(
      `${this.baseUrl}orgs/${orgId}/projects/${projectId}/inferences/${inferenceId}/stop`,
      {},
      { headers }
    )
    .pipe(
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }

  deployInfra(
    org: string,
    projectId: string,
    inferenceId: string
  ): Observable<any | never> {
    return this.http.post(
      `${this.baseUrl}orgs/${org}/projects/${projectId}/inferences/${inferenceId}/infrastructure`,
      {},
      { headers }
    )
    .pipe(
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }

  loadInference(
    orgId: string | undefined,
    projectId: string | undefined,
    inferenceId: string | null
  ): Observable<Inference | never> {
    return this.http.get(
      `${this.baseUrl}orgs/${orgId}/projects/${projectId}/inferences/${inferenceId}`,
      { headers }
    )
    .pipe(
      map((inference: any) => {
        return inference;
      }),
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }

  getAllInferences(
    orgId: string,
    projectId: string
  ): Observable<InferencesList> {

    const params = new HttpParams()
      .set('page', '0')
      .set('pageSize', '100')
      .set('pagination', false);

    return this.http
      .get<InferencesList>(
        `${this.baseUrl}orgs/${orgId}/projects/${projectId}/inferences`,
        { headers, params }
      )
      .pipe(
        map((list: InferencesList) => {
          list.items = sortObjectArrayByConfigName(list.items);
          return list;
        }),
        catchError((res) => {
          return throwError(res?.error);
        })
      );
  }

  createInference(
    orgId: string,
    projectId: string,
    name: string,
    description: string,
    files: InferenceFiles
  ): Observable<NewInferenceResponse> {

    const config: Config = {
      name: name,
      description: description,
      tags: [],
    };

    const infrastructureConfig: InfrastructureConfig = {
      timeout: 300,
    };

    const params = { config, infrastructureConfig, files: files.files };

    return this.http
      .post<NewInferenceResponse>(
        `${this.baseUrl}orgs/${orgId}/projects/${projectId}/inferences`,
        params
      )
      .pipe(
        tap((res: NewInferenceResponse) => res),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  linkNodeInference(
    orgId: string,
    projectId: string,
    inferenceId: string,
    nodeId: string
  ): Observable<NodeLinkInferenceResponse> {

    const params = {
      projectSuid: projectId,
      inferenceSuid: inferenceId,
    };

    return this.http
      .post<NodeLinkInferenceResponse>(
        `${this.baseUrl}orgs/${orgId}/nodes/${nodeId}/link`,
        params
      )
      .pipe(
        tap((res: NodeLinkInferenceResponse) => res),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  mapNodeInference(
    orgId: string,
    projectId: string,
    inferenceId: string,
    nodeId: string,
    nodeRole: string,
    file: InferenceFile
  ): Observable<NodeMappingInferenceResponse> {

    const params = {
      nodeSuid: nodeId,
      dataOrigin: file.file.metaData.dataOrigin,
      file: {
        path: file.file.path,
        uuid: file.file.uuid
      },
      role: nodeRole
    };

    return this.http
      .post<NodeMappingInferenceResponse>(
        `${this.baseUrl}orgs/${orgId}/projects/${projectId}/inferences/${inferenceId}/nodes/${nodeId}/mappings`,
        params
      )
      .pipe(
        tap((res: NodeMappingInferenceResponse) => res),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveFilesForInference(
    orgId: string,
    projectId: string,
    sessionId: any,
    round: number,
    node: string,
    paradigmId: string
  ): Observable<InferenceFiles[]> {

    const params = {};

    return this.http
      .post<InferenceFiles[]>(
        `${this.baseUrl}orgs/${orgId}/projects/${projectId}/sessions/${sessionId}/rounds/${round}/parameters/${node}?evaluationPointOrder=0&saveModel=0&paradigm=${paradigmId}`,
        params
      )
      .pipe(
        tap((res: InferenceFiles[]) => res),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getSavedFilesByParadigmForInference(
    orgId: string,
    projectId: string,
    paradigmId: string
  ): Observable<InferenceFiles> {

    return this.http
      .get<InferenceFiles>(
        `${this.baseUrl}orgs/${orgId}/projects/${projectId}/savedModels?evaluationPointOrder=0&paradigm=${paradigmId}`,
      )
      .pipe(
        tap((res: InferenceFiles) => res),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  deleteSavedModel(
    orgId: string,
    projectId: string,
    savedModel: string
  ): Observable<InferenceFiles> {
    const url = `${this.baseUrl}orgs/${orgId}/projects/${projectId}/savedModels`;
    const body = {
      "uuid": savedModel
    }
    const options = {
      body: body
    };

    return this.http.delete<InferenceFiles>(url, options);
  }

  deleteInference(orgId: any, projectId: any, inferenceId: string) {
    return this.http.delete(
      `${this.baseUrl}orgs/${orgId}/projects/${projectId}/inferences/${inferenceId}`,
      { headers }
    )
    .pipe(
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }

  deleteInferences(orgId: any, inferences: any[]) {
    const deleteCalls = inferences.map((inference) => {
      return this.deleteInference(orgId, inference.projectSuid, inference.inferenceSuid);
    });
    return combineLatest(deleteCalls);
  }
}
