import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ExtraCellElement } from '../../models/extra-cell-element';
import { AdComponent } from '../../models/table.model';

@Component({
    selector: 'shai-table-cell-double',
    template: `
        <div class="table-cell-double-container" [ngClass]="{'disabled': data?.disabledLook || disabledLook }">
            <div class="table-cell-double">
                <ng-content select="[left]"></ng-content>
                <p><ng-content></ng-content></p>
                <div class="multitext">
                    <div *ngIf="data?.label || label" [innerHTML]="data?.label || label" class="caption"></div>
                    <div [ngClass]="{'error': data?.error}" *ngIf="data?.subText || subText" [innerHTML]="data?.text || text" class="paragraph truncate-1-line"></div>
                    <div [ngClass]="{'error': data?.error}" *ngIf="!data?.subText && !subText" [innerHTML]="data?.text || text" class="paragraph truncate-1-line"></div>
                    <div *ngIf="data?.subText || subText" [innerHTML]="data?.subText || subText" class="paragraph-small truncate-1-line" title="{{data?.subText}}"></div>
                </div>
                <shai-extra-cell-elements *ngIf="data" [data]="data" [elements]="extraCellElements"> </shai-extra-cell-elements>
            </div>
        </div>
    `,
    styleUrls: [`./table-cell-double.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCellDoubleComponent implements AdComponent {
    @Input() text : string = '';
    @Input() subText : string = '';
    @Input() label : string = '';
    @Input() error : boolean = false;
    @Input() data: any = null;
    @Input() disabledLook: boolean = false;
    @Input() extraCellElements: ExtraCellElement[] = [];
}
