import { ItemFile } from './data-model';
import { EntityStatus, Stamp } from './node';
import {
  DifferentialPrivacyData,
  NodeMetrics,
  ParadigmResponse,
  Model,
  TrainingEntityResolution
} from 'src/app/platform/models/training';
import { Config } from 'src/app/platform/models/organization';

export interface TrainingSession {
  trainingSuid: string;
  trainingSessionTimestamp: string;
  mappings?: MappingNode[];
  infrastructure?: InfrastructureInfo;
  entityStatus?: EntityStatus[];
  stopConditions?: StopCondition[];
  linkedNodes?: Node[];
  summaryTrainingSessionExecution?: any;
  stamp?: Stamp;
  state?: TrainingSessionState;
  deployed?: string;
  started?: string;
  finished?: string;
  rounds?: any;
  maxRounds?: number;
  maxEpochs?: number;
  status?: any;
  metrics?: NodeMetrics[][];
  metricsConfig?: MetricsConfig;
  notes?: string;

  paradigm?: ParadigmResponse;
  config?: Config;
  model?: Model;
  aggregation?: ParadigmResponse;
  differentialPrivacy?: DifferentialPrivacyData;
  entityResolution?: TrainingEntityResolution;

  training: any;
  entityType?: string;
  protected?: boolean;
  trainingSessionNumber?: number;
  trainingSessionStatus?: string;
  analyticsQueries?: any
}

export enum TrainingSessionState {
  incomplete = 'INCOMPLETE',
  mapped = 'MAPPED',
  deployed = 'DEPLOYED',
  running = 'RUNNING',
  aborted = 'ABORTED',
  stopped = 'STOPPED',
  finished = 'FINISHED',
  paused = 'PAUSED',
  pending = 'PENDING'
}
export interface InfrastructureInfo {
  aggregatorUuid: string;
  timeout: number;
  minNodeResponses: number;
  backOff: number;
}

export enum MapNodesType {
  ORG,
  HYSTORY,
  LINKED,
}

export enum TrainingSessionPhaseEnum {
  UNDEFINED = 5,
  READY_TO_SETUP = 10,
  READY_TO_DEPLOY = 15,
  DEPLOYING_INFRA = 20,
  READY_TO_START = 25,
  TRAINING_RUNNING = 30,
  TRAINING_ABORTED = 35,
  TRAINING_STOPPED = 40,
  TRAINING_FINISHED = 45,
  TRAINING_ERROR_STATE = 50,
  STOPPING = 55
}
export interface TrainingSessionStatus {
  summaryTrainingSessionExecution: any;
  summaryEntityResolutionExecution: any;
}

export interface TrainingSessionRounds {
  page: number;
  pageSize: number;
  totalItemCount: number;
  items: TrainingSessionRound[];
  improvementInLastRound?: string;
  startTime?: string;
  currentRound?: TrainingSessionRound;
  startTimestampFirstRound?: string;
  endTimestampLastRound?: string;
  nextItem?: string;
}

export interface TrainingSessionRound {
  trainingSuid: string;
  trainingSessionTimestamp: string;
  round: number;
  epoch: number;
  summaryRound?: any;
  entityStatus?: EntityStatus[];
  stamp?: Stamp;
  startTimestamp?: string;
  endTimestamp?: string;
}

export interface TrainingSessionList {
  page: number;
  pageSize: number;
  totalItemCount: number;
  items: TrainingSession[];
}

export interface GenericParameter {
  name: string;
  value: string;
}

export interface TrainingModel {
  id: string;
  hyperparameters: GenericParameter[];
}

export interface TrainingParadigm {
  id: string;
  parameters: GenericParameter[];
}

export interface StopCondition {
  operator: string;
  order: number;
  eval: string;
  condition: string;
  value: number;
  isAdded?: boolean;
}

export interface MappingNode {
  erParams?: ErParams;
  nodeSuid: string;
  file?: ItemFile;
  role: string;
  isMapped?: boolean;
  softDeletePolicy?: boolean;
  differentialPrivacy?: DifferentialPrivacyData
}

export interface ErParams {
  idxConfig?: string;
  serverSupplicant?: boolean;
  serverDonor?: serverDonor;
}

export interface serverDonor {
  nodeSuid: string;
}

export interface PrivateAttrs {
  trainingDatasetLength: number;
  entityStatus?: EntityStatus[];
}

export interface RocMetricsUrl {
  url: string;
}

export enum ParadigmType {
  hfl = 'hfl',
  vfl = 'vfl',
  bvfl = 'bvfl'
}

export enum MetricEvaluationInterval {
  round = 'round',
  epoch = 'epoch',
}
export interface EpochMetric {
  epoch: number;
  round: number;
}
export interface MetricsConfig {
  evaluationInterval: MetricEvaluationInterval;
  roundWithEpochMetricsArray?: EpochMetric[];
  maxRounds?: number;
  isAdded?: boolean;
}
