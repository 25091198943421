import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from "@angular/router";
import { of } from "rxjs";
import { ProjectActions, OrganizationActions, TrainingSessionActions } from 'src/app/platform';
import { select, Store } from '@ngrx/store';
import * as fromPlatform from 'src/app/platform/reducers';
import { take } from "rxjs/operators";
import { fromAuth } from "../auth/state";
import { ProjectService } from 'src/app/platform/services/project.service';
import { TrainingSessionService } from "../platform/services/training-session.service";
import { SnackbarService, SnackbarType } from "../shared/components/snackbar/snackbar.service";
import { AuthService } from "../auth/services/auth.service";

@Injectable({
    providedIn: 'root',
})

export class TrainingResolver implements Resolve<boolean> {
  constructor(
    private store: Store,
    private router: Router,
    public projectService: ProjectService,
    public trainingSessionService: TrainingSessionService,
    private snackbarService: SnackbarService,
    private authService: AuthService
  ) {}

  getParamValue(url: any, entity: any) {
    if(url && entity) {
      const urlParts = url.split('/');
      const entityIndex = urlParts.indexOf(entity);
      return urlParts[entityIndex + 1];
    } else {
      return ''
    }
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const isLogged = this.authService.isLogged()
    if(!isLogged) {
      window.location.reload()
      return of(true)
    }

    let project, session;

    const paramProject = this.getParamValue(state.url, 'projects');
    const paramSession = this.getParamValue(state.url, 'sessions') || this.getParamValue(state.url, 'analytics');

    const org = await this.store.pipe(select(fromAuth.getOrg),take(1)).toPromise();
    const stateSession = await this.store.pipe(select(fromPlatform.getCurrentTrainingSessionTimestamp),take(1)).toPromise();

    const plan = await this.store.pipe(select(fromPlatform.getPlan),take(1)).toPromise();

    if(!paramSession) {
      this.store.dispatch(TrainingSessionActions.clearTrainingSession());
    }

    if(org && !plan) {
      this.store.dispatch(OrganizationActions.loadPlan());
    }

    if(org && paramProject) {
      try {
        project = await this.projectService.loadProject(paramProject, org).toPromise()
        this.store.dispatch(ProjectActions.loadProjectSuccess({ project }))
      } catch(error) {
        this.snackbarService.show(
          'Project does not exist',
          SnackbarType.simple
        )
        this.store.dispatch(ProjectActions.clearCurrent({ route: '/' }));
        return of(true)
      }
    }

    if(org && paramProject && paramSession && paramSession !== stateSession) {
      try {
        session = await this.trainingSessionService.getTrainingSession(org, paramProject, paramSession).toPromise()
        this.store.dispatch(TrainingSessionActions.getTrainingSessionSuccess({ session }))
      } catch(error) {
        this.snackbarService.show(
          'Training session does not exist',
          SnackbarType.simple
        )
        this.router.navigate([
          '/trainings'
        ]);
        return of(true)
      }
    }
    return of(true)
  }
}
