import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-aggregator-rl-24',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DCDDE0"/>
    <path d="M11.833 12.344C12.925 12.032 13.645 11.168 13.645 9.92C13.645 8.492 12.697 7.4 10.981 7.4H7.65703V15.8H8.70103V12.476H10.741L12.949 15.8H14.089V15.68L11.833 12.344ZM8.70103 8.36H10.921C11.977 8.36 12.553 8.948 12.553 9.932C12.553 10.94 11.977 11.516 10.921 11.516H8.70103V8.36ZM15.5761 15.8H16.5841V6.92H15.5761V15.8Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class AggregatorRl24 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
