import { createReducer, on } from "@ngrx/store";
import * as ProjectActions from '../actions/project.actions';
import { Status, ProjectList, ProjectResponse, FileList, Project} from "../models";
import { createRehydrateReducer } from "./rehydrate.reducer";

export const projectStatusKey = 'projects';
const projectStorageKey = 'flp_storage_project_state';

export interface State {
  current: Project | null;
  list?: ProjectList | null;
  currentStatus: Status;
  deleteStatus: Status,
  listStatus: Status;
  filesListStatus: Status,
  filesList?: FileList | null,
}

const initialState: State = {
  current: null,
  deleteStatus: { pending: false },
  currentStatus: {
    pending: false
  },
  listStatus: {
    pending: false
  },
  filesListStatus: { pending: false },
  filesList: null,
};

export const reducer = createRehydrateReducer(
  projectStorageKey,
  initialState,
  on(ProjectActions.createProject, state => ({ ...state, currentStatus: { pending: true, error: null } })),
  on(ProjectActions.createProjectSuccess, (state, {projectId}) => ({ ...state, currentStatus: { pending: false }, response: projectId })),
  on(ProjectActions.createProjectFailure, (state, {error}) => ({ ...state, currentStatus: { pending: false, error }, current: null})),

  on(ProjectActions.updateProject, state => ({ ...state, currentStatus: { pending: true, error: null } })),
  on(ProjectActions.updateProjectSuccess, (state) => ({ ...state, currentStatus: { pending: false }, current: null })),
  on(ProjectActions.updateProjectFailure, (state, {error}) => ({ ...state, currentStatus: { pending: false, error }, current: null})),

  on(ProjectActions.deleteProject, state => ({ ...state, deleteStatus: { pending: true, error: null } })),
  on(ProjectActions.deleteProjectSuccess, state => ({ ...state, deleteStatus: { pending: false, error: null } })),
  on(ProjectActions.deleteProjectFailure, (state, {error}) => ({ ...state, deleteStatus: { pending: false, error }})),

  on(ProjectActions.resetCurrent, state => ({ ...state, current: initialState.current})),

  // on(ProjectActions.setProject, (state, {project}) => ({...state, current: project})),

  // Get all files
  on(ProjectActions.getAllFiles, (state) => ({ ...state, filesListStatus: {...state.filesListStatus, pending: true}})),
  on(ProjectActions.getAllFilesSuccess, (state, {filesList}) => ({...state, filesList, filesListStatus: {...state.filesListStatus, pending: false}})),
  on(ProjectActions.getAllFilesFailure, (state, error) => ({ ...state, filesListStatus: {...state.filesListStatus, pending: false, error}})),

  // Delete file
  on(ProjectActions.deleteFile, state => ({ ...state, deleteStatus: { pending: true, error: null } })),
  on(ProjectActions.deleteFileSuccess, state => ({ ...state, deleteStatus: { pending: false, error: null } })),
  on(ProjectActions.deleteFileFailure, (state, {error}) => ({ ...state, deleteStatus: { pending: false, error }, current: null})),

  // LIST ACTIONS
  on(ProjectActions.loadProjectList, (state) => ({
    ...state,
    current: null,
    filesList: null,
    listStatus: {
      ...state.listStatus,
      pending: true
    }
  })),

  on(ProjectActions.loadProjectListSuccess, (state, { list }) => ({
    ...state,
    listStatus: {
      ...state.listStatus,
      pending: false
    },
    list
  })),

  on(ProjectActions.loadProjectListFailure, (state, { error }) => ({
    ...state,
    listStatus: {
      ...state.listStatus,
      pending: false,
      error
    }
  })),

  // LOAD PROJECT ACTIONS
  on(ProjectActions.loadProject, (state, { suid }) => ({
    ...state,
    current: null,
    filesList: null,
    currentStatus: {
      ...state.currentStatus,
      pending: true
    }
  })),

  on(ProjectActions.loadProjectSuccess, (state, { project }) => ({
    ...state,
    current: project,
    currentStatus: {
      ...state.currentStatus,
      pending: false
    }
  })),

  on(ProjectActions.countModelsByProjectSuccess, (state, { project }) => {
    const index = state.list?.items.findIndex(i => i.projectSuid === project.projectSuid)
    if(state.list?.items && index !== undefined && index !== -1) {
      const listCopy = [...state.list?.items];
      listCopy[index] = project
      return {
        ...state,
        list: {
          ...state.list,
          items: listCopy
        }
      }
    }
    return state;
  }),

  on(ProjectActions.clearCurrent, state => {
    return {
      ...state,
      current: null
    }
  })
);

export const getCurrentProjectName = (state: State) => state?.current?.config?.name || null;
export const getCurrentProject = (state: State) => state?.current;
export const getCurrentProjectId = (state: State) => state?.current?.projectSuid;
export const isCurrentProjectPending = (state: State) => state.currentStatus.pending;
export const getProjectError = (state: State) => state?.currentStatus?.error;
export const getProjectList = (state: State) => state?.list;
export const getProjectListError = (state: State) => state?.listStatus?.error;
export const isProjectListPending = (state: State) => state?.listStatus.pending;
export const getProjectFileList = (state: State) => state?.filesList;
export const getDeleteProjectError = (state: State) => state?.deleteStatus.error;
