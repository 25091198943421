import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { HelperService } from 'src/app/platform/services/helper.service';

@Component({
    selector: 'shai-list-item-header',
    template: `
        <div #headerItem class="list-item-header-container">
            <div class="list-item-header">
                <span class="paragraph"><ng-content></ng-content></span>
            </div>
        </div>
    `,
    styleUrls: [`./list-item-header.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListItemHeaderComponent implements AfterViewInit, OnDestroy {
    @ViewChild('headerItem', { static: false }) headerItemRef!: ElementRef;
    headerHeight: number = 0;

    constructor(
        public _hs: HelperService
    ) { }

    ngAfterViewInit(): void {
        if (this.headerItemRef.nativeElement?.offsetHeight > 0) {
            this.setHeaderHeight(this.headerItemRef.nativeElement?.offsetHeight);
        }
    }

    ngOnDestroy(): void {
        this.setHeaderHeight(0);
    }

    setHeaderHeight(height: number) {
        this._hs.setHeaderHeight(height);
    }
}
