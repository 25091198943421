import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  SnackbarService,
  SnackbarType,
} from 'src/app/shared/components/snackbar/snackbar.service';
import { BreackpointService } from 'src/app/platform/services/breackpoint.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { select, Store } from '@ngrx/store';
import { DockerImage, NodeActions } from 'src/app/platform';
import * as fromPlatform from 'src/app/platform/reducers';
import * as fromAuth from '../../../auth/state/selectors/auth.selectors';
import { from, Observable, Subscription } from 'rxjs';
import { Organization } from 'src/app/platform/models/organization';
import { NodeService } from 'src/app/platform/services/node.service';
import { ListItem } from '../list-items/list-item.model';
import { ValidDockerImages } from 'src/app/platform/models/plan';
import { ThrowStmt } from '@angular/compiler';
import { concatMap, finalize } from 'rxjs/operators';
import { getTimestampFormatted } from 'src/app/utils';

@Component({
  selector: 'shai-node-instalation',
  template: `
    <shai-overlay
      [active]="isActive"
      [complete]="isComplete"
      [fullWidth]="false"
      [hasHeader]="true"
      heading=""
      (onChange)="onChangeOverlay($event)"
      [isSecondaryOverlay]="isSecondaryOverlay"
    >
      <mat-grid-list cols="12" rowHeight="fit">
        <mat-grid-tile colspan="12">
          <shai-title-bar title="Node installation"> </shai-title-bar>
          <shai-docker-note> </shai-docker-note>

          <div class="mat-table" style="margin-top: 24px;">
            <div class="mat-row">
              <div class="mat-cell-left">
                <div *ngIf="!showDockerInfo" class="paragraph">Node can be installed in differents OS. Select one to download an image compatible with your device.</div>
                <div *ngIf="showDockerInfo" class="paragraph">All the nodes can be installed with Docker, choose an image based on compatibility with your device resources.</div>
              </div>
              <div class="mat-cell-right">
                <shai-dropdown
                  (onListItemChange)="onDockerImageChange($event)"
                  [data]="dataDockerImages"
                ></shai-dropdown>
              </div>
            </div>
          </div>

          <mat-grid-list
            class="card-grid"
            cols="12"
            [rowHeight]="rHeight"
            gutterSize="24px"
            *ngIf="showDockerInfo"
          >
            <mat-grid-tile [colspan]="(_bkp.obsScreen$ | async) === 'mobile' ? 12 : 4">
              <div class="elevated-table card-instructions">
                <figure>
                  <shai-install-s1-svg></shai-install-s1-svg>
                </figure>
                <span class="paragraph-bold">Download this image</span>
                <span class="paragraph-small"
                  >Or send the link to the person responsible for installing the
                  software on each node.</span
                >

                <shai-button
                  [disabled]="dockerImageLinkDisabled"
                  (click)="downloadDockerImage()"
                  [type]="'button'"
                  iconName="icon-download-16"
                  [buttonStyle]="'contextual'"
                  >Download</shai-button
                >
                <shai-button
                  [disabled]="dockerImageLinkDisabled"
                  (click)="copyDownloadLink()"
                  [type]="'button'"
                  iconName="icon-link-16"
                  [buttonStyle]="'contextual'"
                  >Copy download link</shai-button
                >
              </div>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="(_bkp.obsScreen$ | async) === 'mobile' ? 12 : 4">
              <div class="elevated-table card-instructions">
                <figure>
                  <shai-install-s2-svg></shai-install-s2-svg>
                </figure>
                <span class="paragraph-bold">Import the image</span>
                <span class="paragraph-small"
                  >Import the downloaded image into your registry.</span
                >
                <shai-button
                  [href]="viewImportImageDocumentation()"
                  target="_blank"
                  [type]="'button'"
                  iconName="icon-document-16"
                  [buttonStyle]="'contextual'"
                  tooltipText="View documentation"
                  >View documentation</shai-button
                >
              </div>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="(_bkp.obsScreen$ | async) === 'mobile' ? 12 : 4">
              <div class="elevated-table card-instructions">
                <figure>
                  <shai-install-s3-svg></shai-install-s3-svg>
                </figure>
                <span class="paragraph-bold">Run the image</span>
                <span class="paragraph-small"
                  >Run the container over the image and provide the company key
                  and secret along with the
                  <a [href]="requiredVariablesUrl" target="_blank" class="paragraph link">required variables.</a> The node
                  will connect automatically to Sherpa.ai.</span
                >
              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-grid-tile>
      </mat-grid-list>
      <!--
      <shai-action-bar>
        <ng-container left-cell>
          <shai-button
            [type]="'button'"
            [disabled]="true"
            (click)="emailInstructions()"
            buttonStyle="secondary"
          >Email instructions</shai-button>
        </ng-container>
        <ng-container right-cell>
          <shai-button
            [type]="'button'"
            [disabled]="true"
            (click)="copyInstructionsLink()"
            buttonStyle="secondary"
          >Copy link to instructions</shai-button>
        </ng-container>
      </shai-action-bar>
      -->
    </shai-overlay>
  `,
  styleUrls: ['./node-instalation.component.scss'],
})
export class NodeInstalationComponent implements OnInit, OnDestroy {
  @Output() onChange = new EventEmitter();
  private linkToInstructions = 'todo-link-to-instructions';
  private downloadLink = '';
  isActive: boolean = true;
  isComplete: boolean = false;
  isSecondaryOverlay: boolean = false;
  rHeight = 400;
  @Input() set isOpenOverOverlay(value: boolean) {
    if (value === true) {
      this.isSecondaryOverlay = true;
    }
  }

  listDockerImages: ListItem[] = [];
  showDockerInfo: boolean;
  dockerImageSelected: string;
  dockerImageSelectedView: string;
  listDockerImagesLoading: boolean;
  dataDockerImages: any = {};
  dockerImageLinkDisabled: boolean;
  isEmptyDockerImagesList: boolean;
  private userOrg$: Observable<string> = new Observable();
  private subscriptionOrg!: Subscription;

  requiredVariablesUrl = 'https://docs.sherpa.ai/_downloads/env.txt';

  constructor(
    private store: Store,
    private snackbar: SnackbarService,
    private clipboard: Clipboard,
    public _bkp: BreackpointService,
    private nodeService: NodeService
  ) {
    this.isEmptyDockerImagesList = true;
    this.dockerImageLinkDisabled = true;
    this.showDockerInfo = false;
    this.dockerImageSelected = '';
    this.dockerImageSelectedView = '';
    this.listDockerImagesLoading = true;
    this.dataDockerImages = {
        title: 'Loading docker images ...',
        listItems: [],
        disabled: true
    };
    this.userOrg$ = this.store.pipe(select(fromAuth.getOrg)) as Observable<string>;
  }

  ngOnInit(): void {
    this.subscriptionOrg = this.userOrg$.subscribe((orgSuid) => {
      if (orgSuid) {
        this.nodeService.getPlanByOrg(orgSuid).subscribe((result) => {
          const list: ValidDockerImages[] = result.details.validImages;
          if (list.length > 0) {
            this.loadDataSource(orgSuid, list);
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptionOrg.unsubscribe();
  }

  onChangeOverlay(event: boolean) {
    if (!event) {
      this.onChange.emit(event);
    }
  }

  copyInstructionsLink() {
    this.clipboard.copy(this.linkToInstructions);
    this.snackbar.show(
      'Link copied',
      SnackbarType.simple,
      'This link will expire in 8 hours.',
      true
    );
  }

  copyDownloadLink(): void {
    if (this.downloadLink) {
      this.clipboardCopy(this.downloadLink);
    }
  }

  downloadDockerImage(): void {
    if (this.downloadLink) {
      const link = document.createElement('a');
        link.href = this.downloadLink;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    this.showSnackBarFeedback();
  }

  primaryButtonHandler() {
    this.cancelButtonHandler()
    setTimeout(() => {
      this.downloadDockerImage();
    }, 700)
  }

  cancelButtonHandler() {
  }

  viewImportImageDocumentation() {
    return 'https://docs.sherpa.ai/config-node.html';
  }

  onDockerImageChange(event: any): void {
    if (event.value) {
      this.dockerImageLinkDisabled = true;
      const imgSelected = this.listDockerImages.filter((img) => {
        return img.value === event.value;
      })[0];
      this.dockerImageSelected = event.value;
      this.dockerImageSelectedView = imgSelected.title;
      this.showDockerInfo = true;
      this.getDockerImageUrl();
    }
  }

  private loadDataSource(orgSuid: string, list : ValidDockerImages[]) : void {

    this.isEmptyDockerImagesList = true;
    let iterations = 0;

    list.forEach((dockerImg) => {
      this.nodeService.getDockerImageInfo(orgSuid, dockerImg.name).pipe(finalize(() => {
        iterations = iterations + 1;
        if (this.isEndOfCalls(iterations, list.length, this.isEmptyDockerImagesList)) {
          this.listDockerImages.push(
            { type: 'footer',  iconLeft: 'icon-info-16', title: 'It has not been possible to retrieve the list of images available for your organization.', style: 'info', value: '' }
          );
          this.dataDockerImages = {
            title: 'Choose an image',
            listItems: this.listDockerImages,
            disabled: false
          };
        }
      })).subscribe((imgDesc) => {
        this.addDockerImageArray(imgDesc, dockerImg);
      });
    });
  }

  private isEndOfCalls(iterations: number, maxiumCalls: number, isEmptyList: boolean): boolean {
    let response = false;
    if (iterations === maxiumCalls && isEmptyList) {
      response = true;
    }
    return response;
  }

  private addDockerImageArray(imgDesc: DockerImage, dockerImg: ValidDockerImages): void {
    if (imgDesc.description) {
      this.isEmptyDockerImagesList = false;
      this.listDockerImages.push(
        { type: 'body', title: `${imgDesc?.description} - ${getTimestampFormatted(imgDesc?.stamp?.created, true)}`, value: dockerImg.name }
      );
      this.dataDockerImages = {
        title: 'Choose an image',
        listItems: this.listDockerImages,
        disabled: false
      };
    }
  }

  private getDockerImageUrl(): void {
    this.store.dispatch(NodeActions.clearLoadDockerImageUrl());
    this.downloadLink = '';

    this.store.dispatch(NodeActions.loadDockerImageUrl({ imageSuid: this.dockerImageSelected }));

    this.store.pipe(select(fromPlatform.getDockerImage)).subscribe( resp => {
      if (resp && resp.url) {
        this.downloadLink = resp.url;
        this.dockerImageLinkDisabled = false;
      }
    });
  }

  private showSnackBarFeedback(): void {
    this.store.pipe(select(fromPlatform.getDockerImageError)).subscribe( resp => {
      if (resp) {
        this.snackbar.show(
          'The download failed',
          SnackbarType.twoButtons,
          'Looks like your browser blocked the download. Please make sure you enable opening pop-up windows in your browser settings.',
          false,
          'Try Again',
          'Ok',
          () => this.primaryButtonHandler(),
          () => this.cancelButtonHandler()
        );
      }
    });
  }

  private clipboardCopy(param: string): void {
    const pending = this.clipboard.beginCopy(param);
    const attempt = () => {
      const result = pending.copy();

      if (!result) {
        this.snackbar.show(
          'We couldn’t automatically copy the link to your clipboard',
          SnackbarType.simple,
          `You can copy and send this link: ${param}`,
          false
        );
      } else {
        this.snackbar.show(
          'Link copied',
          SnackbarType.simple,
          'This link will expire in 8 hours.',
          true
        );
        // Remember to destroy when you're done!
        pending.destroy();
      }
    };
    attempt();
  }

}
