import { createAction, props, union } from "@ngrx/store";
import { LogItemList, LogList } from "../models";

export const loadLogs = createAction('[Log] Load logs');
export const loadLogsSuccess = createAction('[Log] Load logs success', props<{ list: LogList }>());
export const loadLogsFailure = createAction('[Log] Load logs failure', props<{ error: any }>());

export const getAllLogs = createAction('[Log] Get all logs');
export const getAllLogsSuccess = createAction('[Log] Get all logs success', props<{ logs: LogItemList }>());
export const getAllLogsFailure = createAction('[Log] Get all logs failure', props<{ error: any }>());

const actionsUnion = union({
    loadLogs,
    loadLogsSuccess,
    loadLogsFailure,
    getAllLogs,
    getAllLogsSuccess,
    getAllLogsFailure
})

export type LogActionsUnion = typeof actionsUnion