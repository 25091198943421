import { createAction, props, union } from "@ngrx/store";
import { ChangePassOrg, Organization } from "../models/organization";

export const loadOrganization = createAction('[Org] Load Organization')
export const loadOrganizationSuccess = createAction('[Org] Load Organization success', props<{ organization: Organization }>())
export const loadOrganizationFailure = createAction('[Org] Load Organization failure', props<{ error: any }>())

export const loadCapabilities = createAction('[Org] Load Capabilities')
export const loadCapabilitiesSuccess = createAction('[Org] Load Capabilities success', props<{ capabilities: string[] }>())
export const loadCapabilitiesFailure = createAction('[Org] Load Capabilities failure', props<{ error: any }>())

export const loadPlan = createAction('[Org] Load Plan');
export const loadPlanSuccess = createAction('[Org] Load Plan success', props<{ plan: any }>());
export const loadPlanFailure = createAction('[Org] Load Plan failure', props<{ error: any }>());

export const updateSecret = createAction('[Org] Update secret', props<{ secret: string }>())
export const updateSecretSuccess = createAction('[Org] Update secret success')
export const updateSecretFailure = createAction('[Org] Update secret failure', props<{ error: any }>())

export const clearUpdateSecret = createAction('[Org] Clear update secret')

export const changePasswordOrg = createAction('[Org] Change password Org', props<{ changePassword: ChangePassOrg }>());
export const changePasswordOrgSuccess = createAction('[Org]  Change password Org success');
export const changePasswordOrgFailure = createAction('[Org]  Change password Org failure', props<{ error: any }>());

export const clearChangePassword = createAction('[Org] Clear change password')

const actionsUnion = union({
  loadOrganization,
  loadOrganizationSuccess,
  loadOrganizationFailure,
  loadCapabilities,
  loadCapabilitiesSuccess,
  loadCapabilitiesFailure,
  loadPlan,
  loadPlanSuccess,
  loadPlanFailure,
  updateSecret,
  updateSecretSuccess,
  updateSecretFailure,
  clearUpdateSecret,
  changePasswordOrg,
  changePasswordOrgSuccess,
  changePasswordOrgFailure,
  clearChangePassword
})

export type OrganizationActionsUnion = typeof actionsUnion
