import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-launch-16',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 11V15H1V4H6" stroke="#1F1F1F"/>
      <path d="M10.75 1.37132L10.75 2.37132L13.7855 2.37132L6.86091 9.2959L7.56802 10.003L14.4927 3.07837L14.4927 6.11396L15.4927 6.11396L15.4927 1.37132L10.75 1.37132Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class IconLaunch16 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}



