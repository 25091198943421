import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { fadeIn } from 'src/app/shared/animations';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'shai-password-changed',
  animations: [fadeIn],
  template: `
    <div id="password-changed-wrapper" @fadeIn>
      <div id="stablish-min-height">
        <ng-lottie [options]="animationOptions"></ng-lottie>
      </div>
      <shai-title-bar title="Password changed"
        bottomDescription="From now on you'll sign in with your new password." [showExtraSpace]="false">
      </shai-title-bar>
      <shai-action-bar>
        <ng-container left-cell>
          <shai-button
            [type]="'button'"
            [stretch]="true"
            (click)="goToHome()"
            buttonStyle="primary"
          >Finish</shai-button>
        </ng-container>
      </shai-action-bar>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./password-changed.component.scss']
})
export class PasswordChangedComponent {
  animationOptions: AnimationOptions = {
    path: '/assets/lotties/checkmark.json',
    loop: false
  };
  constructor(
    private _authService: AuthService,
    private router: Router) {}

  goToHome() {
    this._authService.getUserFromIdToken();
    window.location.reload();
  }
}
