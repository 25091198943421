import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { fromProject } from 'src/app/platform';
import { configMock } from 'src/app/platform/mocks/global-config';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { BreackpointService } from 'src/app/platform/services/breackpoint.service';
@Component({
  selector: 'shai-upload-new-files-overlay',
  template: `
    <div id="upload-new-files-overlay-wrapper">
      <mat-grid-list cols="12" rowHeight="fit">
        <mat-grid-tile
          colspan="1"
          *ngIf="(_bkp.obsScreen$ | async) === 'desktop'"
        >
        </mat-grid-tile>

        <shai-table-header
          [headerTabs]="['Upload new (' + filesInProject + ')']"
        >
          <div class="table-header-right-contextual" right>
            <shai-button
              [type]="'button'"
              iconName="icon-plus-16"
              [buttonStyle]="'contextual'"
              (click)="uploadFile()"
              >Upload file</shai-button
            >
          </div>
        </shai-table-header>
        <div class="model-upload-container">
          <shai-new-model-upload
            [orgId]="orgId"
            [projectId]="projectId"
            #uploadComponentRef
            (fileCountChange)="this.filesInProject = $event"
            [resetCount]="clkCount"
            (validFileSelectionOnChange)="handleFileSelectionStatus($event)"
            (uploadCompleted)="handleUploadCompleted()"
            [algorithms]="algorithms"
          >
          </shai-new-model-upload>
          <shai-upload-files-tip
            tip
            text="Before uploading a model, please verify that it can be compiled. Otherwise, distributed nodes will fail when using the model."
          >
          </shai-upload-files-tip>
        </div>

        <mat-grid-tile
          colspan="1"
          *ngIf="(_bkp.obsScreen$ | async) === 'desktop'"
        >
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  `,
  styleUrls: ['./upload-new-files.component.scss'],
})
export class UploadNewFilesComponent implements OnInit, OnDestroy {
  @Input() set clickCount(value: Number) {
    if (value) {
      this.clkCount++;
    }
  }
  @ViewChild('uploadComponentRef', { static: false }) uploadComponentRef: any;
  @Output() validFileSelectionOnChange = new EventEmitter();
  @Output() uploadCompleted = new EventEmitter();

  @HostListener('window:resize', ['$event']) onResize() {
    this.rowHeight = window.innerHeight - 144 - 44 + 'px'; // 44px => header, 144px => bottom-button
  }

  project$: Observable<any> = new Observable();
  organization$: Observable<any> = new Observable();
  projectId!: string;
  orgId!: string;
  algorithms = configMock.algorithms;
  filesInProject = 0;
  rowHeight = 'fit';
  private subscription?: Subscription;
  public clkCount: number = 0;

  constructor(private store: Store, public _bkp: BreackpointService) {
    this.project$ = this.store.pipe(
      select(fromProject.getCurrentProjectId)
    ) as Observable<any>;
    this.organization$ = this.store.pipe(
      select(fromProject.getOrganization)
    ) as Observable<any>;
  }

  ngOnInit(): void {
    this.subscription = combineLatest([
      this.organization$,
      this.project$,
    ]).subscribe(([orgId, project]) => {
      if (orgId && project) {
        this.orgId = orgId.organizationSuid;
        this.projectId = project;
        this.subscription?.unsubscribe();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  uploadFile() {
    this.uploadComponentRef?.openFileWindow();
  }

  handleFileSelectionStatus(value: boolean) {
    this.validFileSelectionOnChange.emit(value);
  }

  handleUploadCompleted(): void {
    this.uploadCompleted.emit();
  }
}
