import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-more-vertical-16',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 2.5C9.25 1.80964 8.69036 1.25 8 1.25C7.30964 1.25 6.75 1.80964 6.75 2.5C6.75 3.19036 7.30964 3.75 8 3.75C8.69036 3.75 9.25 3.19036 9.25 2.5ZM8 6.75C8.69036 6.75 9.25 7.30964 9.25 8C9.25 8.69036 8.69036 9.25 8 9.25C7.30964 9.25 6.75 8.69036 6.75 8C6.75 7.30964 7.30964 6.75 8 6.75ZM8 12.25C8.69036 12.25 9.25 12.8096 9.25 13.5C9.25 14.1904 8.69036 14.75 8 14.75C7.30964 14.75 6.75 14.1904 6.75 13.5C6.75 12.8096 7.30964 12.25 8 12.25Z" fill="#0C0E11"/>
    </svg>
  `
})
export class IconMoreVertical16 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}