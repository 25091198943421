import { Component, HostBinding, Input } from '@angular/core';
@Component({
  selector: 'shai-icon-federated-vertical-24',
  template: `
    <svg
      *ngIf="!disabled"
      width="26"
      height="26"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25 21.0281V11.5H23V21.0281C13.8003 21.5464 6.5 29.1706 6.5 38.5V40.5H8.5V38.5C8.5 30.2755 14.9056 23.5472 23 23.0317V40.5H25V23.0317C33.0944 23.5472 39.5 30.2755 39.5 38.5V40.5H41.5V38.5C41.5 29.1706 34.1997 21.5464 25 21.0281Z"
        fill="url(#paint0_linear_3511_2445)"
      />
      <rect
        x="15.5148"
        y="6"
        width="12"
        height="12"
        rx="6"
        transform="rotate(-45 15.5148 6)"
        fill="#0087E0"
      />
      <rect
        x="4"
        y="46"
        width="7"
        height="7"
        transform="rotate(-90 4 46)"
        fill="#0C0E11"
      />
      <rect
        x="20"
        y="46"
        width="8"
        height="8"
        rx="4"
        transform="rotate(-90 20 46)"
        fill="#0C0E11"
      />
      <path d="M40.5 38L36 46L45 46L40.5 38Z" fill="#0C0E11" />
      <defs>
        <linearGradient
          id="paint0_linear_3511_2445"
          x1="24"
          y1="12"
          x2="23.8646"
          y2="40.9994"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DCDDE0" />
          <stop offset="1" stop-color="#EBEBEB" />
        </linearGradient>
      </defs>
    </svg>

    <svg
      *ngIf="disabled"
      width="26"
      height="26"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25 21.0281V11.5H23V21.0281C13.8003 21.5464 6.5 29.1706 6.5 38.5V40.5H8.5V38.5C8.5 30.2755 14.9056 23.5472 23 23.0317V40.5H25V23.0317C33.0944 23.5472 39.5 30.2755 39.5 38.5V40.5H41.5V38.5C41.5 29.1706 34.1997 21.5464 25 21.0281Z"
        fill="url(#paint0_linear_3744_3007)"
      />
      <rect
        x="15.5148"
        y="6"
        width="12"
        height="12"
        rx="6"
        transform="rotate(-45 15.5148 6)"
        fill="#DCDDE0"
      />
      <rect
        x="4"
        y="46"
        width="7"
        height="7"
        transform="rotate(-90 4 46)"
        fill="#BCBFC4"
      />
      <rect
        x="20"
        y="46"
        width="8"
        height="8"
        rx="4"
        transform="rotate(-90 20 46)"
        fill="#BCBFC4"
      />
      <path d="M40.5 38L36 46L45 46L40.5 38Z" fill="#BCBFC4" />
      <defs>
        <linearGradient
          id="paint0_linear_3744_3007"
          x1="24"
          y1="12"
          x2="23.8646"
          y2="40.9994"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EBEBEB" />
          <stop offset="1" stop-color="#FAFAFA" />
        </linearGradient>
      </defs>
    </svg>
  `,
})
export class IconFederatedVertical24 {
  @HostBinding('class.shai-icon') get iconClass() {
    return true;
  }
  @Input() disabled: boolean = false;
}
