import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-link-16',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 7.5C1 5.84315 2.34315 4.5 4 4.5H6V3.5H4C1.79086 3.5 0 5.29086 0 7.5C0 9.70914 1.79086 11.5 4 11.5H6V10.5H4C2.34315 10.5 1 9.15685 1 7.5Z" fill="#1F1F1F"/>
      <path d="M10 3.5V4.5H12C13.6569 4.5 15 5.84315 15 7.5C15 9.15685 13.6569 10.5 12 10.5H10V11.5H12C14.2091 11.5 16 9.70914 16 7.5C16 5.29086 14.2091 3.5 12 3.5H10Z" fill="#1F1F1F"/>
      <path d="M4 8H12V7H4V8Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class IconLink16 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
