export const getNativeElement = (element: any, i: number) => {
  return element.toArray()[i].nativeElement as HTMLElement;
}

export const hasClass = (element: any, elemClass: string) => {
    return element.classList.contains(elemClass) ? true : false;
}

export const sortObjectArrayByConfigName = (array: any[]) => {
    let sortedArray: any[]= [];
    sortedArray = array.sort((a, b) => {
        const nameA = a['config']['name'] ? a['config']['name'] : '';
        const nameB = b['config']['name'] ? b['config']['name'] : '';
        return nameA.localeCompare(nameB);
    });
    return sortedArray;
}

export const onlyNumberKeys = (event: any) => {
    return (onlyNumbers(event) && (event.key !== ' ')) ? true : false;
}

export const onlyNumbers = (event: any) => {
    return ((Number(event.key) >= 0) && (Number(event.key) <= 9)) ? true : false;
}

export const capitalizeFirstLetter = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
}