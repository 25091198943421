import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { User } from 'src/app/auth/state';
import { AuthService } from 'src/app/auth/services/auth.service';
import * as fromProject from '../../platform/reducers';
import { AuthActions } from 'src/app/auth/state/actions';
import * as fromAuth from 'src/app/auth/state/selectors/auth.selectors';
import { Organization } from 'src/app/platform/models/organization';

@Component({
  selector: 'shai-layout',
  template: `
    <shai-header (onLogout)="logout()" [userName]="(user$ | async)?.preferred_username" [creationProjectName]="currentProjectName$ | async" *ngIf="router.url.startsWith('/auth')"></shai-header>
    <shai-nav-bar (onLogout)="logout()" [organization]="(organization$ | async)" [user]="(user$ | async)" *ngIf="!router.url.startsWith('/auth')"></shai-nav-bar>
    <main>
      <ng-content></ng-content>
    </main>
    <!-- <shai-footer></shai-footer> -->
  `,
  // styles: [`
  //   :host {
  //     min-height: 100vh;
  //     display: flex;
  //     width: 100%;
  //     flex-direction: column;
  //     main {
  //       flex-grow: 1;
  //     }
  //   }
  // `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent {
  organization$: Observable<Organization>;
  user$: Observable<User>;
  userSubscription: Subscription;
  currentProjectName$ : Observable<string | null> = this.store.pipe(select(fromProject.getCurrentProjectName))

  constructor(
    private store: Store,
    public router: Router,
    private authService: AuthService
  ) {
    this.organization$ = this.store.pipe(select(fromProject.getOrganization)) as Observable<Organization>;
    this.user$ = this.store.pipe(select(fromAuth.getUser)) as Observable<User>;
    this.userSubscription = this.user$.subscribe(user => {
      const authData = this.authService.getAuthDataInSessionStorage();
      if(user && authData) {
        this.store.dispatch(AuthActions.extendUserSession());
        this.store.dispatch(AuthActions.timeOut());
      }
      if(!user && authData) {
        this.store.dispatch(AuthActions.autologin());
      }
    })
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }
}
