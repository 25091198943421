import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-naive-bayes-32',
  template: `
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="3.59313"
        y1="15.8961"
        x2="10.2598"
        y2="6.56276"
        stroke="#1F1F1F"
      />
      <line
        y1="-0.5"
        x2="11.4698"
        y2="-0.5"
        transform="matrix(-0.581238 -0.813733 -0.813733 0.581238 20 16.1867)"
        stroke="#1F1F1F"
      />
      <line x1="12.3" y1="18.7466" x2="12.3" y2="6.7466" stroke="#1F1F1F" />
      <path
        d="M14.4333 21.1466C14.4333 22.3432 13.4633 23.3133 12.2666 23.3133C11.07 23.3133 10.1 22.3432 10.1 21.1466C10.1 19.95 11.07 18.9799 12.2666 18.9799C13.4633 18.9799 14.4333 19.95 14.4333 21.1466Z"
        stroke="#83D0DE"
      />
      <path
        d="M4.83333 17.9466C4.83333 19.1432 3.86328 20.1133 2.66667 20.1133C1.47005 20.1133 0.5 19.1432 0.5 17.9466C0.5 16.75 1.47005 15.78 2.66667 15.78C3.86328 15.78 4.83333 16.75 4.83333 17.9466Z"
        stroke="#83D0DE"
      />
      <path
        d="M23.5 17.9466C23.5 19.1432 22.53 20.1133 21.3334 20.1133C20.1367 20.1133 19.1667 19.1432 19.1667 17.9466C19.1667 16.75 20.1367 15.78 21.3334 15.78C22.53 15.78 23.5 16.75 23.5 17.9466Z"
        stroke="#83D0DE"
      />
      <circle
        cx="12"
        cy="4.18673"
        r="3.71154"
        fill="#0073BF"
        stroke="#0073BF"
        stroke-width="0.576924"
      />
    </svg>
  `,
})
export class IconNaiveBayes32 {
  @HostBinding('class.shai-icon') get iconClass() {
    return true;
  }
}
