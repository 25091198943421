import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-secure-16',
  template: `
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><g fill="#1f1f1f"><path d="m8.75 11.5493c.44835-.2593.75-.7441.75-1.2993 0-.82843-.67157-1.5-1.5-1.5s-1.5.67157-1.5 1.5c0 .5552.30165 1.04.75 1.2993v2.4507h1.5z"/><path clip-rule="evenodd" d="m12 7v-2.63636c0-2.40997-1.349-4.36364-4-4.36364-2.65097 0-4 1.95367-4 4.36364v2.63636h-2v9h12v-9zm-6.23094-5.07572c-.48091.56796-.76906 1.41089-.76906 2.43936v2.63636h6v-2.63636c0-1.02846-.2882-1.8714-.7691-2.43936-.4621-.54581-1.17296-.92428-2.2309-.92428s-1.7688.37847-2.23094.92428zm-2.76906 6.07572v7h10v-7z" fill-rule="evenodd"/></g></svg>
  `
})
export class IconSecure16 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
