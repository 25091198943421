export const confusionMatrixReferenceValues = [
  {
    color: '#005695',
    value: '100 %',
  },
  {
    color: '#0073BF',
    value: '80 %',
  },
  {
    color: '#0087E0',
    value: '60 %',
  },
  {
    color: '#C5DFF6',
    value: '40 %',
  },
  {
    color: '#EDF7FC',
    value: '20 %',
  },
  {
    color: '#FFFFFF',
    value: '0 %',
  },
];

export const lossChartReferenceValues = [
  {
    color: '#0C0E11',
    value: 'Train',
  },
  /*
  {
    color: '#C45200',
    value: 'Test',
  },
  */
];

export const getConfusionMatrixColorFromResult = (value: number) => {
  switch (true) {
    case value <= 1 && value > 0.8:
      return {background: '#005695', text: '#FFFFFF'};
    case value <= 0.8 && value > 0.6:
      return {background: '#0073BF', text: '#FFFFFF'};
    case value <= 0.6 && value > 0.4:
      return {background: '#0087E0', text: '#FFFFFF'};
    case value <= 0.4 && value > 0.2:
      return {background: '#C5DFF6', text: '#5F6167'};
    case value <= 0.2 && value > 0:
      return  {background: '#EDF7FC', text: '#5F6167'};
    case value == 0:
      return {background:'#FFFFFF', text: '#5F6167'};
    default:
      return {background:'#FFFFFF', text: '#5F6167'};
  }
};
