import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-node-paid-12',
  template: `
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H12V12H0V0Z" fill="#C45200"/>
    </svg>
  `
})
export class IconNodePaid12 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}