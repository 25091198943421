import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { AuthService } from './services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private store: Store, private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentAccessToken = this.authService.getAuthDataInSessionStorage()?.accessToken;
    if (!request.headers.has("InterceptorSkipHeader")) {
      request = this.addToken(request, currentAccessToken);
    }
    if (request.headers.has("InterceptorUploadFile")) {
      request = this.addTokenFile(request, currentAccessToken);
    }
    return next.handle(request).pipe(
      catchError((error) => {
        // if (error instanceof HttpErrorResponse && error.status === 401) {
        //   return this.handle401Error(request, next);
        // } else {
          return throwError(error);
        // }
      })
    );
  }

  private addTokenFile(request: HttpRequest<any>, token: string) {
    let headers = request.headers;
   
    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`);
    } 

    return request.clone({
      headers: headers
    });
  }

  private addToken(request: HttpRequest<any>, token: string) {
    let headers = { 'Content-Type': 'application/json' }
    // this.store.dispatch(extendTimeout());
    if (token) {
      headers = {
        ...headers,
        ... {'Authorization': `Bearer ${token}`}
      };
    } else {
      // Para el caso del registro de un usuario
      headers = {
        ...headers,
        ... {'Authorization': `Bearer ${environment.bearerAuth}`}
      };
    }
    return request.clone({
      headers: new HttpHeaders(headers)
    });
  }

  // private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

  //   if (!this.isRefreshing) {
  //     this.isRefreshing = true;
  //     this.refreshTokenSubject.next(null);

  //     return this.authService.refreshToken().pipe(
  //       switchMap((token: any) => {
  //         this.isRefreshing = false;
  //         this.refreshTokenSubject.next(token.accessToken);
  //         return next.handle(this.addToken(request, token.accessToken));
  //       })
  //     );
  //   } else {
  //     return this.refreshTokenSubject.pipe(
  //       filter((token) => token != null),
  //       take(1),
  //       switchMap((jwt) => {
  //         return next.handle(this.addToken(request, jwt));
  //       })
  //     );
  //   }
  // }
}

export const tokenInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true,
};
