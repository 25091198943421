import { createReducer, on } from "@ngrx/store";
import { OrganizationActions } from "../actions";
import { Organization } from "../models/organization";
import { createRehydrateReducer } from "./rehydrate.reducer";

export const organizationFeatureKey = 'organization'
const organizationStorageKey = 'flp_storage_organization_state';


export interface State {
  organization?: Organization;
  pending: boolean;
  error?: any;
  updateSecretCompleted: boolean;
  updateSecretError?: any,
  changePasswordCompleted: boolean;
  changePasswordError?: any,
  capabilities: string[],
  plan: any
}

const initialState: State = {
  pending: false,
  updateSecretCompleted: false,
  changePasswordCompleted: false,
  updateSecretError: undefined,
  changePasswordError: undefined,
  capabilities: [],
  plan: null
}

export const reducer = createRehydrateReducer(
  organizationStorageKey,
  initialState,
  on(OrganizationActions.loadOrganization, state => ({...state, pending: true})),
  on(OrganizationActions.loadOrganizationSuccess, (state, {organization}) => ({...state, organization, pending: false})),
  on(OrganizationActions.loadOrganizationFailure, (state, {error}) => ({...state, pending: false, error})),

  on(OrganizationActions.loadCapabilities, state => ({...state, pending: true})),
  on(OrganizationActions.loadCapabilitiesSuccess, (state, {capabilities}) => ({...state, capabilities, pending: false})),
  on(OrganizationActions.loadCapabilitiesFailure, (state, {error}) => ({...state, pending: false, error})),

  on(OrganizationActions.loadPlan, state => ({...state, pending: true})),
  on(OrganizationActions.loadPlanSuccess,(state, {plan}) => ({...state, plan, pending: false})),
  on(OrganizationActions.loadPlanFailure, (state, {error}) => ({...state, pending: false, error})),

  on(OrganizationActions.updateSecret, state => ({ ...state, updateSecretCompleted: false, updateSecretError: undefined })),
  on(OrganizationActions.updateSecretSuccess, (state) => ({ ...state, updateSecretCompleted: true, updateSecretError: undefined })),
  on(OrganizationActions.updateSecretFailure, (state, {error}) => ({ ...state, updateSecretCompleted: false, updateSecretError: error })),

  on(OrganizationActions.clearUpdateSecret, (state) => ({ ...state, updateSecretCompleted: false, updateSecretError: undefined })),

  on(OrganizationActions.changePasswordOrg, state => ({...state, changePasswordCompleted: false, changePasswordError: undefined })),
  on(OrganizationActions.changePasswordOrgSuccess, (state) => ({...state, changePasswordCompleted: true, changePasswordError: undefined })),
  on(OrganizationActions.changePasswordOrgFailure, (state, {error}) => ({...state, changePasswordCompleted: false, changePasswordError: error })),

  on(OrganizationActions.clearChangePassword, (state) => ({ ...state, changePasswordCompleted: false, changePasswordError: undefined })),
);

export const getOrganization = (state: State) => state?.organization;
export const isOrganizationPending = (state: State) => state?.pending;
export const isOrganizationError = (state: State) => state?.error;
export const getOrganizationName = (state: State) => state?.organization?.config?.name;
export const getOrganizationPlan = (state: State) => state?.organization?.planSuid;
export const getOrganizationDescription = (state: State) => state?.organization?.config?.description;
export const getCapabilities = (state: State) => {
  return state?.capabilities
};
export const getPlan = (state: State) => state?.plan;
export const isUpdateSecretCompleted = (state: State) => state?.updateSecretCompleted;
export const getUpdateSecretError = (state: State) => state?.updateSecretError;
export const isChangePasswordCompleted = (state: State) => state?.changePasswordCompleted;
export const getChangePasswordError = (state: State) => state?.changePasswordError;

