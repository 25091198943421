import { ChangeDetectionStrategy, Component, Input, OnInit, ViewContainerRef, ComponentFactoryResolver, ViewChild, AfterViewInit, EventEmitter, Output, ElementRef, AfterContentChecked, AfterViewChecked, OnDestroy } from '@angular/core';
import { HelperService } from 'src/app/platform/services/helper.service';
import { IconArrowRight16 } from '../../icons/16/icon-arrow-right';
import { IconCheckmark16 } from '../../icons/16/icon-checkmark';
import { IconClose16 } from '../../icons/16/icon-close';
import { IconSoundOn16 } from '../../icons/16/icon-sound-on';
import { IconSoundOff16 } from '../../icons/16/icon-sound-off';
import { IconDocument16 } from '../../icons/16/icon-document';
import { IconHelp16 } from '../../icons/16/icon-help';
import { IconInfo16 } from '../../icons/16/icon-info';
import { IconLogout16 } from '../../icons/16/icon-logout';
import { IconModel16 } from '../../icons/16/icon-model';
import { IconPrivacy16 } from '../../icons/16/icon-privacy';
import { IconSettings16 } from '../../icons/16/icon-settings';
import { IconTrash16 } from '../../icons/16/icon-trash';
import { IconUserNew16 } from '../../icons/16/icon-user-new';
import { IconWarning16 } from '../../icons/16/icon-warning';
import { ListItem } from './list-item.model';
import { IconDuplicate16 } from '../../icons/16/icon-duplicate';
import { IconLocation16 } from '../../icons/16/icon-location';
@Component({
    selector: 'shai-list-item-footer',
    template: `
        <div #footerItem class="list-item-footer-container"
            (click)="onFooterClick(listItemFooter, $event)"
            [ngClass]="listItemFooter?.style ? 'li-footer-' + listItemFooter.style : 'li-footer' "
            [ngStyle]="{'cursor': listItemFooter?.onClick ? 'pointer' : 'default'}"
        >
            <div class="list-item-footer">
                <div [ngClass]="!listItemFooter?.iconLeft ? 'invisible' : 'icon-left'">
                    <ng-container #leftIcon></ng-container>
                </div>
                <span class="paragraph">{{ listItemFooter?.title }}</span>
                <div [ngClass]="!listItemFooter?.iconRight ? 'invisible' : 'icon-right'">
                    <ng-container #rightIcon></ng-container>
                </div>
            </div>
        </div>
    `,
    styleUrls: [`./list-item-footer.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListItemFooterComponent implements AfterViewInit, OnDestroy {
    @ViewChild('leftIcon', { read: ViewContainerRef}) leftIcon: ViewContainerRef | undefined;
    @ViewChild('rightIcon', { read: ViewContainerRef}) rightIcon: ViewContainerRef | undefined;
    @Input() listItemFooter!: ListItem;
    @Output() handleItemClick = new EventEmitter();
    @ViewChild('footerItem', { static: false }) footerItemRef!: ElementRef;
    footerHeight: number = 0;
    modals = { // ... and here
        'icon-arrow-right-16': IconArrowRight16,
        'icon-checkmark-16': IconCheckmark16,
        'icon-close-16': IconClose16,
        'icon-sound-on-16': IconSoundOn16,
        'icon-sound-off-16': IconSoundOff16,
        'icon-document-16': IconDocument16,
        'icon-info-16': IconInfo16,
        'icon-privacy-16': IconPrivacy16,
        'icon-warning-16': IconWarning16,
        'icon-trash-16': IconTrash16,
        'icon-location-16': IconLocation16,
        'icon-settings-16': IconSettings16,
        'icon-help-16': IconHelp16,
        'icon-logout-16': IconLogout16,
        'icon-model-16': IconModel16,
        'icon-user-new-16': IconUserNew16,
        'icon-duplicate-16': IconDuplicate16
    };

    constructor(
        public viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        public _hs: HelperService
    ) { }

    ngAfterViewInit() {
        if (this.footerItemRef.nativeElement?.offsetHeight > 0) {
            this.setFooterHeight(this.footerItemRef.nativeElement?.offsetHeight);
        }
        if (this.listItemFooter?.iconRight) {
            this.rightIcon?.clear();
            let component = this.modals[this.listItemFooter.iconRight];
            const cFactory = this.componentFactoryResolver.resolveComponentFactory(component);
            const componentRef2 = this.rightIcon!.createComponent(cFactory);
            componentRef2.changeDetectorRef.detectChanges();
        }
        if (this.listItemFooter?.iconLeft) {
            this.leftIcon?.clear();
            let component = this.modals[this.listItemFooter.iconLeft];
            const cFactory = this.componentFactoryResolver.resolveComponentFactory(component);
            const componentRef = this.leftIcon!.createComponent(cFactory);
            componentRef.changeDetectorRef.detectChanges();
        }
    }

    ngOnDestroy(): void {
        this.setFooterHeight(0);
    }

    setFooterHeight(height: number) {
        this._hs.setFooterHeight(height);
    }

    onFooterClick(item: ListItem, event: any) {
      if (item && item.onClick) {
        item.onClick();
        this.handleItemClick.emit();

      } else {
        event.preventDefault();
      }
    }
}
