import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-warning',
  template: `<svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.1384 13.5H0.86159L8 1.00778L15.1384 13.5Z" stroke="#C45200" />
    <rect x="7.5" y="6" width="1" height="3" fill="#C45200" />
    <rect x="7" y="10" width="2" height="2" rx="1" fill="#C45200" />
  </svg> `,
})
export class IconWarning {
  @HostBinding('class.shai-icon') get iconClass() {
    return true;
  }
}
