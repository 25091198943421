import { HttpEventType } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Output,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  ConfigItem,
  ModelFile,
  ModelFileUploaded,
  ModelFileUploadStatus,
} from 'src/app/platform';
import { UploadFileService } from 'src/app/platform/services/upload-file.service';
import { ListItem } from '../list-items/list-item.model';

@Component({
  selector: 'shai-model-upload',
  template: `
    <div class="upload-wrapper elevated-table">
      <div class="uploaded-files-list">
        <ul>
          <li class="li-drag">
            <div
              shaiDragDropFileUpload
              [fileCount]="totalFilesCount"
              (click)="this.fileField.click()"
              (fileDropped)="prepareBufferFiles($event)"
              class="upload-content"
            >
              <shai-icon-document-32></shai-icon-document-32>
              <span class="paragraph">Upload or drop file here</span>
              <div class="help">
                <span class="paragraph-small">Supports:</span>
                <span class="badge paragraph-small">.py</span>
                <!--<span class="badge paragraph-small">.zip</span>-->
              </div>
              <input
                type="file"
                name="fileField"
                accept=".py"
                #fileField
                hidden
              />
              <input
                type="file"
                name="fileField"
                accept=".py"
                #fileField
                hidden
              />
            </div>
          </li>
          <li
            class="li-file-item buffer-file"
            *ngFor="let file of bufferFiles; let i = index"
          >
            <shai-icon-document-24></shai-icon-document-24>
            <div class="item-text-container">
              <span class="paragraph">{{ file.file?.name }}</span>
              <span
                [ngClass]="{
                  'error-status': file.status === uploadStatus.Failed
                }"
                class="paragraph-small"
              >
                {{ getFileUploadStatusMessage(file).text }}
              </span>
            </div>
            <div class="item-actions-container">
              <div *ngIf="file.status !== uploadStatus.Completed" class="actions-right-container">
                <shai-button
                  *ngIf="file.status === uploadStatus.Failed"
                  [type]="'button'"
                  (click)="localCancel(i)"
                  [buttonStyle]="'tertiary'"
                  >Cancel</shai-button
                >
                <shai-button
                  *ngIf="
                    file.status === uploadStatus.Failed ||
                    file.status === uploadStatus.Started
                  "
                  [type]="'button'"
                  (click)="retryOrCancel(i)"
                  [buttonStyle]="'tertiary'"
                  >{{ getFileUploadStatusMessage(file).button }}</shai-button
                >
              </div>
            </div>
          </li>
          <li
            class="li-file-item"
            *ngFor="let file of uploadedFiles; let i = index"
          >
            <shai-icon-document-24></shai-icon-document-24>
            <div class="item-text-container">
              <span class="paragraph">{{ file.filename }}</span>
            </div>
            <div class="item-actions-container">
              <div class="actions-right-container">
                <shai-dropdown
                  [metadata]="{ index: i }"
                  (onListItemChange)="updateAlgorithm($event)"
                  [data]="{
                    title: 'Select model type... ',
                    listItems: algorithmsListItems
                  }"
                >
                </shai-dropdown>
                <shai-icon-trash-16 class="delete-icon" (click)="deleteFile(i)">
                </shai-icon-trash-16>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  `,
  styleUrls: ['./model-upload.component.scss'],
})
export class ModelUploadComponent implements OnInit, OnDestroy {
  @Output() fileCountChange = new EventEmitter();
  @Input() hasSubmitAttempts: boolean = false;
  @Input() algorithms: ConfigItem[] = [];
  @ViewChild('fileField', { static: false }) fileField!: ElementRef;
  @Output() validFileSelectionOnChange = new EventEmitter();
  @Output() uploadedFilesOnChange = new EventEmitter();

  @Input() projectId!: string;
  @Input() orgId!: string;

  algorithmsListItems: ListItem[] = [];
  bufferFiles: ModelFile[] = [];
  uploadedFiles: ModelFileUploaded[] = [];

  private bufferSub?: Subscription;

  constructor(
    private cdRef: ChangeDetectorRef,
    private uploadFileService: UploadFileService,
  ) {}

  public get uploadStatus(): typeof ModelFileUploadStatus {
    return ModelFileUploadStatus;
  }

  // Used to pass a number to shaiDragDropFileUpload directive to handle the dashed blue border
  public get totalFilesCount(): number {
    return this.bufferFiles.length + this.uploadedFiles.length;
  }

  ngOnInit() {
    this.generateAlgoritmsListItems();
  }

  ngOnDestroy() {}

  prepareBufferFiles(incomingFiles: any[]) {
    for (let i = 0; i < incomingFiles.length; i++) {
      let incomingFile = incomingFiles[i];
      let selectedFile: ModelFile = {
        file: incomingFile,
        status: ModelFileUploadStatus.Ready,
        error: null,
        progress: null,
        model: 'undefined',
      };

      this.bufferFiles.push(selectedFile);
    }
    this.fileField.nativeElement.value = '';
    this.uploadBuffer(this.bufferFiles);
  }

  retryOrCancel(index: number) {
    let file = this.bufferFiles[index];
    if (file.status === ModelFileUploadStatus.Started) {
      console.log('server cancel ...');
      // Cancel request currently not allowed
      // file.uploadSub?.unsubscribe();
      // this.removeFromBufferFiles(index);
    } else if (file.status === ModelFileUploadStatus.Failed) {
      console.log('should retry.. ');
      this.uploadBuffer([file]);
    }
  }

  localCancel(index: number) {
    console.log('local cancel.. ');
    this.removeFromBufferFiles(index);
  }

  private checkValidFileSelection() {
    if (this.uploadedFiles.length === 0) {
      this.validFileSelectionOnChange.emit(null);
      return;
    }

    for (let i = 0; i < this.uploadedFiles.length; i++) {
      let file = this.uploadedFiles[i];
      if (!file.model || file.model === 'undefined') {
        this.validFileSelectionOnChange.emit(false);
        return;
      }
    }

    this.validFileSelectionOnChange.emit(true);
  }

  private updateProgress(bufferFiles:ModelFile[], value: number) {
    bufferFiles.forEach((file) => {
      file.progress = value;
    });
  }

  private updateUploadStatus(bufferFiles:ModelFile[], status: ModelFileUploadStatus) {
    bufferFiles.forEach((file) => {
      file.status = status;
    });
  }

  private updateErrorMessage(bufferFiles:ModelFile[], error: string) {
    bufferFiles.forEach((file) => {
      file.error = error;
    });
  }

  private removeFromBuffer(toRemoveFiles: ModelFile[]) {
    this.bufferFiles = this.bufferFiles.filter(function(x) {
      return toRemoveFiles.indexOf(x) < 0;
    });
  }

  private uploadBuffer(bufferFiles: ModelFile[]) {
    let filesForUpload = bufferFiles.map((element) => element.file!);

    this.bufferSub = this.uploadFileService
      .uploadFiles(this.orgId,this.projectId,'undefined', filesForUpload)
      .pipe(
        finalize(() => {
          // Upload finalize
          this.bufferSub?.unsubscribe();
          this.cdRef.detectChanges();
          this.checkValidFileSelection();
        })
      )
      .subscribe(
        (event) => {
          if (event.type == HttpEventType.UploadProgress && event.total) {
            const uploadProgress = Math.round(
              100 * (event.loaded / event.total)
            );
            this.updateProgress(bufferFiles, uploadProgress);
            console.log(`Posting in progress! ${uploadProgress}% \n
          Bytes being upload: ${event.loaded} \n
          Total no. of bytes to upload: ${event.total}`);
          } else if (event.type == HttpEventType.Response) {
            if (event.body) {
              this.updateUploadStatus(bufferFiles, ModelFileUploadStatus.Completed);
              this.removeFromBuffer(bufferFiles);
              this.uploadedFiles.push(...event.body);

              this.fileCountChange.emit(this.uploadedFiles.length);
              this.uploadedFilesOnChange.emit([...this.uploadedFiles]);
            }
          } else if (event.type == HttpEventType.Sent) {
            this.updateUploadStatus(bufferFiles, ModelFileUploadStatus.Started);
          }
        },
        (error) => {
          if (error) {
            console.log('[Upload_File] ERROR ', error);
            this.updateUploadStatus(bufferFiles, ModelFileUploadStatus.Failed);
            if (error.status === 409) {
              this.updateErrorMessage(bufferFiles, 'resource conflict.');
            }
          }
        }
      );
  }

  private updateFileModel(index: number, model: string) {
    let fileForUpdate = this.uploadedFiles[index];

    this.uploadFileService
      .updateFileModel(this.orgId, this.projectId, model, fileForUpdate.uuid)
      .subscribe(
        (response) => {
          fileForUpdate.model = model;
          this.cdRef.detectChanges();
          this.checkValidFileSelection();
        },
        (error) => {
          if (error) {
            console.log('[Update_File] ERROR ', error);
          }
        }
      );
  }

  private generateAlgoritmsListItems() {
    this.algorithmsListItems = this.algorithms.map((algorithm) => {
      return {
        type: 'body',
        title: algorithm.name,
        value: algorithm.id,
        code: algorithm.id,
      };
    });
  }

  handleCancel() {}

  deleteFile(index: number) {
    let fileToDelete = this.uploadedFiles[index];
    this.uploadFileService
      .deleteFileModel(this.orgId, this.projectId, fileToDelete.uuid)
      .subscribe(() => {
        this.removeFromUploadedFiles(index);
        this.checkValidFileSelection();
      });
  }

  private removeFromUploadedFiles(index: number) {
    this.uploadedFiles.splice(index, 1);
    this.cdRef.detectChanges();
    this.fileCountChange.emit(this.uploadedFiles.length);
    this.uploadedFilesOnChange.emit([...this.uploadedFiles]);
  }

  private removeFromBufferFiles(index: number) {
    this.bufferFiles.splice(index, 1);
    this.cdRef.detectChanges();
  }

  updateAlgorithm(event: any) {
    this.validFileSelectionOnChange.emit(false);
    console.log('event', event);
    const { value, metadata } = event;
    this.updateFileModel(metadata.index, value);
  }

  getFileUploadStatusMessage(model: ModelFile) {
    switch (model.status) {
      case ModelFileUploadStatus.Ready:
        return { text: 'ready', button: 'btnReady' };
      case ModelFileUploadStatus.Completed:
        return { text: `${model.file?.size} kb`, button: 'btnCompleted' };
      case ModelFileUploadStatus.Failed:
        return {
          text: `Upload failed ${model.error ?? ''}`,
          button: 'try again',
        };
      case ModelFileUploadStatus.Requested:
        return { text: 'requested', button: 'btnRequested' };
      case ModelFileUploadStatus.Started:
        return {
          text: `${model.file?.size} kb, Uploading (${model.progress}%)`,
          button: 'Cancel',
        };
    }
  }

  openFileWindow() {
    this.fileField?.nativeElement.click();
  }
}
