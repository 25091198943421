import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { InputErrorMessage } from 'src/app/shared/components/form-controls/input-text/form-input-text.component';
import { PasswordStrengthValidator } from 'src/app/utils/password-strength-validator';
import { fromAuth } from '../state';
import { AuthActions } from '../state/actions';
import { AuthService } from 'src/app/auth/services/auth.service';
@Component({
  selector: 'shai-change-password',
  template: `
    <div id="change-password-wrapper">
      <form [formGroup]="loginForm" (submit)="handleSubmit($event)">
        <mat-grid-list cols="12" rowHeight="fit">
          <mat-grid-tile colspan="2"></mat-grid-tile>
          <mat-grid-tile colspan="8">
            <shai-title-bar title="Change password" topDescription="STEP 1 OF 2"
              bottomDescription="Your temporary password needs to be updated for a password of your own." [showExtraSpace]="false">
            </shai-title-bar>
            <div class="form-field-group">
              <shai-form-input-text
                  [parentForm]="loginForm"
                  formControlName="password"
                  fieldName="password"
                  label="New password"
                  maxLength="99"
                  [isSecureField]="true"
                  [errorMessages]="passwordErrorMessages"
                  [hasSubmitAttemps]="hasSubmitAttemps">
              </shai-form-input-text>
            </div>
            <div id="password-conditions" class="paragraph">

              <ul>
                <li>
                  At least 12 characters
                  <span *ngIf="authService.passwordHasValue(this.loginForm)">
                    <shai-icon-checkmark-16 *ngIf="authService.checkMinChars(this.loginForm)"></shai-icon-checkmark-16>
                    <shai-icon-warning-16 *ngIf="!authService.checkMinChars(this.loginForm)"></shai-icon-warning-16>
                  </span>
                </li>
                <li>
                  A mixture of both uppercase and lowercase letters
                  <span *ngIf="authService.passwordHasValue(this.loginForm)">
                    <shai-icon-checkmark-16 *ngIf="authService.checkLowerAndUpper(this.loginForm)"></shai-icon-checkmark-16>
                    <shai-icon-warning-16 *ngIf="!authService.checkLowerAndUpper(this.loginForm)"></shai-icon-warning-16>
                  </span>
                </li>
                <li>
                  A mixture of letters and numbers
                  <span *ngIf="authService.passwordHasValue(this.loginForm)">
                    <shai-icon-checkmark-16 *ngIf="authService.checkLettersAndNumbers(this.loginForm)"></shai-icon-checkmark-16>
                    <shai-icon-warning-16 *ngIf="!authService.checkLettersAndNumbers(this.loginForm)"></shai-icon-warning-16>
                  </span>
                </li>
                <li>
                  Inclusion of at least one special characterS, e.g., ! @ # ? ]
                  <span *ngIf="authService.passwordHasValue(this.loginForm)">
                    <shai-icon-checkmark-16 *ngIf="authService.hasSpecialCharacters(this.loginForm)"></shai-icon-checkmark-16>
                    <shai-icon-warning-16 *ngIf="!authService.hasSpecialCharacters(this.loginForm)"></shai-icon-warning-16>
                  </span>
                </li>
                <li>
                  Does not include the following characters: $ < >
                  <span *ngIf="authService.passwordHasValue(this.loginForm)">
                    <shai-icon-checkmark-16 *ngIf="authService.checkInvalidCharacters(this.loginForm)"></shai-icon-checkmark-16>
                    <shai-icon-warning-16 *ngIf="!authService.checkInvalidCharacters(this.loginForm)"></shai-icon-warning-16>
                  </span>
                </li>
              </ul>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2"></mat-grid-tile>
        </mat-grid-list>
        <shai-action-bar>
          <ng-container left-cell>
            <shai-button
              [type]="'button'"
              [stretch]="true"
              [type]="'submit'"
              buttonStyle="primary"
            >Next</shai-button>
          </ng-container>
        </shai-action-bar>
      </form>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  loginForm!: FormGroup;
  hasSubmitAttemps = false;
  private subscription?: Subscription;
  passwordErrorMessages: InputErrorMessage [] = [];
  minLength = 12;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      password: ['', [
        PasswordStrengthValidator
      ]]
    })
  }

  handleSubmit(event: Event) {
    event.preventDefault();
    this.hasSubmitAttemps = true;
    if(!this.loginForm.invalid) {
      let data: any = {
        authFlow: "NEW_PASSWORD_REQUIRED",
        ...this.loginForm.value
      }
      this.subscription = combineLatest([
        this.store.pipe(select(fromAuth.getCredentials)),
        this.store.pipe(select(fromAuth.getLoginResponse))
      ]).subscribe((([credentials, loginResponse]) => {
          data = {
            ...data,
            session: loginResponse?.session,
            organizationSuid: credentials?.organizationSuid,
            username: credentials?.username,
          }
          this.subscription?.unsubscribe();
        })
      )
      if (data && data.session && data.organizationSuid && data.username) {
        this.store.dispatch(AuthActions.changePassword({ changePassword: data }));
      }
    }
  }
}
