import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-logistic-regression-32',
  template: `
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 22.0104C6.5 21.8438 11.5 19.4104 11.5 11.0104C11.5 2.61043 18.8333 0.84376 22.5 1.01043"
        stroke="#F2A71B"
      />
      <circle cx="9" cy="3" r="1.5" stroke="#0073BF" />
      <circle cx="8" cy="10" r="1.5" stroke="#0073BF" />
      <circle cx="5" cy="16" r="1.5" stroke="#0073BF" />
      <circle cx="16" cy="19.5" r="1.5" stroke="#0073BF" />
      <circle cx="16" cy="13" r="1.5" stroke="#0073BF" />
      <circle cx="19" cy="6.5" r="1.5" stroke="#0073BF" />
      <line x1="0.5" y1="2.18557e-08" x2="0.499999" y2="24" stroke="#1F1F1F" />
      <line x1="24" y1="24.5" y2="24.5" stroke="#1F1F1F" />
    </svg>
  `,
})
export class IconLogisticRegression32 {
  @HostBinding('class.shai-icon') get iconClass() {
    return true;
  }
}
