import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BreackpointService } from 'src/app/platform/services/breackpoint.service';
import { NodeService } from 'src/app/platform/services/node.service';
import { TableCellSingleComponent } from 'src/app/shared/components/table/rows/cells/table-cell-single.component';
import { TableColumn } from 'src/app/shared/components/table/models/table.model';
@Component({
  selector: 'shai-node-overlay',
  template: `
    <shai-overlay
      [active]="isActive"
      [complete]="isComplete"
      [fullWidth]="false"
      [hasHeader]="true"
      heading=""
      (onChange)="onChangeOverlay($event)"
      [isSecondaryOverlay]="isSecondaryOverlay"
    >
      <mat-grid-list cols="12" rowHeight="fit">
        <mat-grid-tile colspan="12">
          <shai-title-bar
            [title]="node?.name"
            [bottomDescription]="node?.description"
          >
          </shai-title-bar>

          <div *ngIf="node?.isSherpa">
            <shai-table-header [headerTabs]="['Dataset Details']">
            </shai-table-header>

            <table class="custom-table">
              <tbody>
                <tr>
                  <td class="left-column">License</td>
                  <td class="right-column">
                    <div [innerHTML]="node?.type"></div>
                  </td>
                </tr>
                <tr>
                  <td class="left-column">Owner</td>
                  <td class="right-column">Sherpa</td>
                </tr>
                <tr>
                  <td class="left-column">Data Type</td>
                  <td class="right-column">{{ node?.dataType }}</td>
                </tr>
                <tr>
                  <td class="left-column">Quality</td>
                  <td class="right-column">Clean</td>
                </tr>
                <tr>
                  <td class="left-column">Row Number</td>
                  <td class="right-column">{{ node?.rowNumber }}</td>
                </tr>
                <tr>
                  <td class="left-column">Preprocessing</td>
                  <td class="right-column">{{ node?.preprocessing }}</td>
                </tr>
                <tr>
                  <td class="left-column">Shape</td>
                  <td class="right-column">{{ node?.shape }}</td>
                </tr>
              </tbody>
            </table>
            <p class="link-description">Link to original data set</p>
            <a [href]="node?.origin" class="link-url" target="_blank">
              {{ node?.origin }}
            </a>
            <p><img [src]="node?.image" class="node-sample-image" /></p>
          </div>

          <div *ngIf="!node?.isSherpa">
            <shai-table-header [headerTabs]="['Dataset Details']">
            </shai-table-header>
            <table class="custom-table">
              <tbody>
                <tr>
                  <td class="left-column">License</td>
                  <td class="right-column">{{ orgName }} node</td>
                </tr>
                <tr>
                  <td class="left-column">Owner</td>
                  <td class="right-column">{{ orgName }}</td>
                </tr>
              </tbody>
            </table>
            <br />
            <shai-table-header [headerTabs]="['Data Validation']">
            </shai-table-header>
            <table class="custom-table">
              <tbody>
                <tr>
                  <td class="left-column">Missing Values</td>
                  <td class="right-column">9.55%</td>
                </tr>
                <tr>
                  <td class="left-column">Number of outlayers</td>
                  <td class="right-column">0.65%</td>
                </tr>
              </tbody>
            </table>
            <br />
            <shai-table-header [headerTabs]="['Data Schema']">
            </shai-table-header>
            <shai-new-table
              #structureList
              class="table-row-clickable"
              [dataset]="nodeStructure"
              [columns]="columns"
            >
            </shai-new-table>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </shai-overlay>
  `,
  styleUrls: ['./node-overlay.component.scss'],
})
export class NodeOverlayComponent implements OnInit {
  @Output() onChange = new EventEmitter();
  @Input() node!: any;
  nodeStructure!: any;
  showNodeStructure: boolean = false;
  @Input() orgName!: string;

  private _orgSuid: string | undefined;
  @Input() set orgSuid(value: string) {
    this._orgSuid = value;
    if (this._orgSuid && this.node) {
      this.fetchNodeStructure();
    }
  }
  isActive: boolean = true;
  isComplete: boolean = false;
  isSecondaryOverlay: boolean = false;
  rHeight = 400;
  columns: TableColumn[] = [];

  private _generateColumns() {
    this.columns = [
      {
        columnDef: 'name',
        header: 'Name',
        width: 'auto',
        sortingDisabled: true,
        component: TableCellSingleComponent,
        cellData: (element: any) => {
          return { text: element.name };
        },
      },
      {
        columnDef: 'type',
        header: 'Type',
        width: 'auto',
        sortingDisabled: true,
        component: TableCellSingleComponent,
        cellData: (element: any) => {
          return { text: element.type };
        },
      },
    ];
  }

  @Input() set isOpenOverOverlay(value: boolean) {
    if (value === true) {
      this.isSecondaryOverlay = true;
    }
  }
  @Input() set selectedNode(value: any) {
    this.node = value;
    if (this._orgSuid && this.node) {
      this.fetchNodeStructure();
    }
  }

  private fetchNodeStructure() {
    this.nodeService.getNodeStructure(this.node.nodeSuid, this._orgSuid).subscribe((analytics) => {
      if (analytics?.structure && analytics.structure.length > 0) {
        this.nodeStructure = analytics.structure;
      }
    });
  }

  constructor(
    public _bkp: BreackpointService,
    private nodeService: NodeService
  ) {}

  ngOnInit(): void {
    this._generateColumns();
  }

  onChangeOverlay(event: boolean) {
    if (!event) {
      this.onChange.emit(event);
    }
  }
}
