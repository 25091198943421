import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-sort-arrow',
  template: `
<svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 5.625L4.5897e-07 0.375L6 0.375L3 5.625Z" fill="#5F6167"/>
</svg>
  `,
})
export class IconSortArrowComponent {
  @HostBinding('class.shai-icon') get iconClass() {
    return true;
  }
}
