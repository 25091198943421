import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'shai-docker-note',
  template: `
  <div class="docker-note-container">
    <shai-icon-docker> </shai-icon-docker>
    <span class="paragraph-small">Please note: It is required to run <a href="https://www.docker.com/" target="blank">Docker</a> to install and manage node images </span>
  </div>

  `,
  styleUrls: ['./docker-note.component.scss'],
})
export class DockerNoteComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
