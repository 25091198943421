import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-arrow-down-1-16',
  template: `
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m8.5 13.793v-13.793h-1v13.7929l-6.14641-6.14641-.707106.70711 7.353556 7.3536 7.35356-7.3536-.7071-.70711z" fill="#1f1f1f"/></svg>
  `
})
export class IconArrowDown116 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
