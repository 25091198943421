import { createReducer, on } from "@ngrx/store";
import * as LogActions from '../actions/log.actions';
import { Status, LogList } from "../models";
import { LogItemList } from '../models/log';

export const logFeatureKey = 'logs';

export interface State {
  list?: LogList | null;
  logs?: LogItemList | null;
  listStatus: Status;
  logsStatus: Status;
}

const initialState: State = {
  listStatus: {
    pending: false
  },
  logsStatus: {
    pending: false
  }
};

export const reducer = createReducer(
  initialState,
  on(LogActions.loadLogs, (state) => ({
      ...state,
      listStatus: {
        ...state.listStatus,
        pending: true
      }
    })
  ),

  on(LogActions.loadLogsSuccess, (state, { list }) => ({
      ...state,
      listStatus: {
        ...state.listStatus,
        pending: false
      },
      list
    })
  ),

  on(LogActions.loadLogsFailure, (state, { error }) => ({
    ...state,
    listStatus: {
      ...state.listStatus,
      pending: false,
      error
    }
  })),

  on(LogActions.getAllLogs, (state) => ({ ...state, logsStatus: { pending: true }})),
  on(LogActions.getAllLogsSuccess, (state, { logs }) => ({...state, logs, logsStatus: { pending: false }})),
  on(LogActions.getAllLogsFailure, (state, { error }) => ({ ...state, logsStatus: { pending: false, error } })),
);

export const getLogList = (state: State) => state?.list;
export const getLogListError = (state: State) => state?.listStatus?.error;
export const isLogListPending = (state: State) => state?.listStatus?.pending;
export const getAllLogs = (state: State) => state?.logs;
export const getAllLogsError = (state: State) => state?.logsStatus?.error;
export const getAllLogsPending = (state: State) => state?.logsStatus?.pending;