export const configMock = {
  "experiments": [
    {
      "tag":"MNIST",
      "shape": "(28,28,1)",
      "dataType": "Image",
      "rowNumber": "60000",
      "preprocessing": "Raw",
      "origin": "https://www.nist.gov/itl/products-and-services/emnist-dataset",
      "image": "assets/images/mnist.png"
    },
    {
      "tag":"CIFAR10",
      "shape": "(32,32,3)",
      "dataType": "Image",
      "rowNumber": "60000",
      "preprocessing": "Raw",
      "origin": "https://www.cs.toronto.edu/~kriz/cifar.html",
      "image": "assets/images/cifar10.png"
    },
    {
      "tag": "IRIS",
      "shape": "(4,)",
      "dataType": "Tabular",
      "rowNumber": "150",
      "preprocessing": "Raw",
      "origin": "https://archive.ics.uci.edu/dataset/53/iris",
      "image": "assets/images/iris.png"
    },
    {
      "tag":"CALIFORNIA",
      "shape": "(10,)",
      "dataType": "Tabular",
      "rowNumber": "20640",
      "preprocessing": "Raw",
      "origin": "https://www.kaggle.com/datasets/camnugent/california-housing-prices",
      "image": "assets/images/california-housing.png"
    },
    {
      "tag":"DIABETES",
      "shape": "(8,)",
      "dataType": "Tabular",
      "rowNumber": "768",
      "preprocessing": "Raw",
      "origin": "https://www.kaggle.com/datasets/akshaydattatraykhare/diabetes-dataset",
      "image": "assets/images/diabetes.jpg"
    },
    {
      "tag":"HEARTDISEASE",
      "shape": "(13,)",
      "dataType": "Tabular",
      "rowNumber": "918",
      "preprocessing": "Raw",
      "origin": "https://www.kaggle.com/datasets/johnsmith88/heart-disease-dataset",
      "image": "assets/images/heart_disease.png"
    },
    {
      "tag":"LYMPHOMA",
      "shape": "(96,96,3)",
      "dataType": "Image",
      "rowNumber": "327680",
      "preprocessing": "Raw",
      "origin": "https://github.com/basveeling/pcam",
      "image": "assets/images/lymphoma-hfl.png"
    },
    {
      "tag":"LYMPHOMANLP",
      "shape": "(96,96,3)",
      "dataType": "Image",
      "rowNumber": "9000",
      "preprocessing": "Raw",
      "origin": "https://github.com/basveeling/pcam",
      "image": "assets/images/lymphoma-hfl.png"
    },
    {
      "tag":"DEFAULTERS",
      "shape": "(24,)",
      "dataType": "Tabular",
      "rowNumber": "30000",
      "preprocessing": "Raw",
      "origin": "https://www.kaggle.com/datasets/uciml/default-of-credit-card-clients-dataset",
      "image": "assets/images/defaulters-hfl.png"
    },
    {
      "tag":"FRAUDDETECTION",
      "shape": "(11,)",
      "dataType": "Tabular",
      "rowNumber": "6362620",
      "preprocessing": "Feature selection",
      "origin": "https://www.kaggle.com/datasets/ealaxi/paysim1",
      "image": "assets/images/fraud.png"
    },
    {
      "tag":"BANKTELECOM",
      "shape": "(16,)",
      "dataType": "Tabular",
      "rowNumber": "45211",
      "preprocessing": "Raw",
      "origin": "https://www.kaggle.com/datasets/aakashverma8900/portuguese-bank-marketing",
      "image": "assets/images/telecom.png"
    },
    {
      "tag":"TELECOMINSURANCE",
      "shape": "(30,)",
      "dataType": "Tabular",
      "rowNumber": "40364",
      "preprocessing": "Normalized",
      "origin": "https://www.kaggle.com/datasets/aakashverma8900/portuguese-bank-marketing",
      "image": "assets/images/telecom.png"
    },
    {
      "tag":"FACEMASK",
      "shape": "Variable",
      "dataType": "Image",
      "rowNumber": "7553",
      "preprocessing": "Raw",
      "origin": "https://www.kaggle.com/datasets/saisindhuri/facemaskdetector",
      "image": "assets/images/facemask.png"
    },
    {
      "tag":"MALICIOUSURLS",
      "shape": "(20,)",
      "dataType": "Tabular",
      "rowNumber": "1781",
      "preprocessing": "Raw",
      "origin": "https://www.kaggle.com/datasets/lsingh4/malicious-and-benign-websites",
      "image": "assets/images/malicious-urls.png"
    },
    {
      "tag":"GEARBOX",
      "shape": "(5,)",
      "dataType": "Tabular",
      "rowNumber": "22212",
      "preprocessing": "Raw",
      "origin": "https://www.kaggle.com/datasets/brjapon/gearbox-fault-diagnosis-elaborated-datasets",
      "image": "assets/images/gearbox.png"
    },
    {
      "tag":"MACHINERY",
      "shape": "(8,)",
      "dataType": "Tabular",
      "rowNumber": "10000",
      "preprocessing": "Raw",
      "origin": "https://www.kaggle.com/datasets/shivamb/machine-predictive-maintenance-classification",
      "image": "assets/images/machinery.png"
    },
    {
      "tag":"POTATOCTRL",
      "shape": "(2976,2976,3)",
      "dataType": "Image",
      "rowNumber": "961",
      "preprocessing": "Raw",
      "origin": "https://www.kaggle.com/datasets/concaption/pepsico-lab-potato-quality-control",
      "image": "assets/images/potato-control.png"
    },
    {
      "tag":"CNCROOM",
      "shape": "(4,)",
      "dataType": "Tabular",
      "rowNumber": "1000",
      "preprocessing": "Vertically partitioned",
      "origin": "https://www.kaggle.com/datasets/shivamb/machine-predictive-maintenance-classification",
      "image": "assets/images/cncroom.png"
    },
    {
      "tag":"ner",
      "shape": "(9015,2001)",
      "dataType": "Tabular",
      "rowNumber": "47959",
      "preprocessing": "Raw",
      "origin": "https://www.kaggle.com/datasets/naseralqaydeh/named-entity-recognition-ner-corpus",
      "image": "assets/images/ner.png"
    },
    {
      "tag":"ner-dataset",
      "shape": "(9015,5001)",
      "dataType": "Tabular",
      "rowNumber": "1354149",
      "preprocessing": "Raw",
      "origin": "https://www.kaggle.com/datasets/abhinavwalia95/entity-annotated-corpus",
      "image": "assets/images/ner-dataset.png"
    },
    {
      "tag":"TOXIC",
      "shape": "(871, 1500)",
      "dataType": "Tabular",
      "rowNumber": "1000",
      "preprocessing": "Raw",
      "origin": "https://www.kaggle.com/competitions/jigsaw-toxic-comment-classification-challenge",
      "image": ""
    },
    {
      "tag":"TOXIC-100",
      "shape": "(871, 1500)",
      "dataType": "Tabular",
      "rowNumber": "1000",
      "preprocessing": "Raw",
      "origin": "https://www.kaggle.com/competitions/jigsaw-toxic-comment-classification-challenge",
      "image": ""
    },
    {
      "tag":"TOXIC-LSTM",
      "shape": "(969,100)",
      "dataType": "Tabular",
      "rowNumber": "1000",
      "preprocessing": "Raw",
      "origin": "https://www.kaggle.com/competitions/jigsaw-toxic-comment-classification-challenge",
      "image": ""
    },
    {
      "tag":"TOXIC-LSTM-100",
      "shape": "(969,100)",
      "dataType": "Tabular",
      "rowNumber": "1000",
      "preprocessing": "Raw",
      "origin": "https://www.kaggle.com/competitions/jigsaw-toxic-comment-classification-challenge",
      "image": ""
    },
    {
      "tag":"SENTIMENT",
      "shape": "(87358, 7691)",
      "dataType": "Tabular",
      "rowNumber": "1000",
      "preprocessing": "Raw",
      "origin": "https://www.kaggle.com/competitions/sentiment-analysis-on-movie-reviews/",
      "image": ""
    },
    {
      "tag":"SENTIMENT-100",
      "shape": "(87358, 7691)",
      "dataType": "Tabular",
      "rowNumber": "1000",
      "preprocessing": "Raw",
      "origin": "https://www.kaggle.com/competitions/sentiment-analysis-on-movie-reviews/",
      "image": ""
    },
    {
      "tag":"SENTIMENT-LSTM",
      "shape": "(117045, 30)",
      "dataType": "Tabular",
      "rowNumber": "1000",
      "preprocessing": "Raw",
      "origin": "https://www.kaggle.com/competitions/sentiment-analysis-on-movie-reviews/",
      "image": ""
    },
    {
      "tag":"SENTIMENT-LSTM-100",
      "shape": "(117045, 30)",
      "dataType": "Tabular",
      "rowNumber": "1000",
      "preprocessing": "Raw",
      "origin": "https://www.kaggle.com/competitions/sentiment-analysis-on-movie-reviews/",
      "image": ""
    },
    {
      "tag":"RANSOMWARE",
      "shape": "(117045, 30)",
      "dataType": "Tabular",
      "rowNumber": "1500",
      "preprocessing": "Raw",
      "origin": "https://github.com/manabu-hirano/RanSAP/",
      "image": "assets/images/ransomware-experiment.png"
    }
  ],
  "plans": {
    "0001": {
      "usersIncluded": 50,
      "ddbLimits": {
          "workspacesIncluded": 1,
          "projectsIncluded": 999,
          "trainingsIncluded": 999,
          "sessionsIncluded": 999,
          "nodesIncluded": 264,
          "modelsIncluded": 999,
          "singleModelSize": 999999999999,
          "modelsTotalSize": 999999999999
      },
      "executionLimits": {
          "concurrentSessions": 999,
          "sessionsADay": 999,
          "sessionsAnHour": 999
      },
      "totalStorage": 999999,
      "learningRetention": 365,
      "paradigmsSupported": [{"name": "hfl"}, {"name": "vfl"}, {"name": "ftl"}],
      "validImages": [{"name": "DEFAULT"}, {"name": "CUDA"}, {"name": "PLATFORM_NODE"}],
      "concurrentSessions": 999,
      "maxTrainingTime": 999999,
      "maxRounds": 9999,
      "availableModels": ["n", "nn", "nnr", "r", "lir", "lor", "svm", "c", "k-means", "e", "rf", "gb", "b", "nbayes", "tan", "d", "id3", "tf", "llms"],
      "availableAggregators": ["fedavg", "fedconcat", "defaultweightedfedavg", "weighted_fedrfvote", "weighted_fedgbdt", "clusterfedavg", "fedsum", "ddaba", "normclip", "bulyan", "multikrum", "robustfedmedian", "robustfedtrimmedmean", "robustlearningrate", "fednova", "pfedme", "svmaverage", "biasfedavg", "rfout", "smpvsecavg", "fedrfvote", "fedgbdt"],
      "showMetrics": ["accuracy"],
      "downloadableMetrics": true,
      "downloadableModels": true,
      "hyperparameters": true,
      "differentialPrivacy": false
    },
    "0002": {
      "usersIncluded": 1,
      "ddbLimits": {
          "workspacesIncluded": 1,
          "projectsIncluded": 1,
          "trainingsIncluded": 15,
          "sessionsIncluded": 15,
          "nodesIncluded": 2,
          "modelsIncluded": 3,
          "singleModelSize": 25000000,
          "modelsTotalSize": 50000000
      },
      "executionLimits": {
          "concurrentSessions": 1,
          "sessionsADay": 1,
          "sessionsAnHour": 1
      },
      "totalStorage": 10240,
      "learningRetention": 365,
      "paradigmsSupported": [{"name": "hfl"}],
      "validImages": [{"name": "DEFAULT"}],
      "concurrentSessions": 1,
      "maxTrainingTime": 600,
      "maxRounds": 20,
      "availableModels": ["n", "nn", "r", "lir"],
      "availableAggregators": ["fedavg"],
      "showMetrics": ["accuracy"],
      "downloadableMetrics": false,
      "downloadableModels": false,
      "hyperparameters": false,
      "differentialPrivacy": false
    },
    "0003": {
      "usersIncluded": 1,
      "ddbLimits": {
          "workspacesIncluded": 1,
          "projectsIncluded": 1,
          "trainingsIncluded": 15,
          "sessionsIncluded": 15,
          "nodesIncluded": 2,
          "modelsIncluded": 3,
          "singleModelSize": 25000000,
          "modelsTotalSize": 50000000
      },
      "executionLimits": {
          "concurrentSessions": 1,
          "sessionsADay": 1,
          "sessionsAnHour": 1
      },
      "totalStorage": 10240,
      "learningRetention": 365,
      "paradigmsSupported": [{"name": "hfl"}],
      "validImages": [{"name": "DEFAULT"}],
      "concurrentSessions": 1,
      "maxTrainingTime": 600,
      "maxRounds": 20,
      "availableModels": ["n", "nn", "r", "lir"],
      "availableAggregators": ["fedavg"],
      "showMetrics": ["accuracy"],
      "downloadableMetrics": false,
      "downloadableModels": false,
      "hyperparameters": false,
      "differentialPrivacy": false
    },
    "0004": {
      "usersIncluded": 5,
      "ddbLimits": {
          "workspacesIncluded": 1,
          "projectsIncluded": 1,
          "trainingsIncluded": 100,
          "sessionsIncluded": 100,
          "nodesIncluded": 10,
          "modelsIncluded": 30,
          "singleModelSize": 25000000,
          "modelsTotalSize": 50000000
      },
      "executionLimits": {
          "concurrentSessions": 999,
          "sessionsADay": 999,
          "sessionsAnHour": 999
      },
      "totalStorage": 10240,
      "learningRetention": 365,
      "paradigmsSupported": [{"name": "hfl"}],
      "validImages": [{"name": "DEFAULT"}],
      "concurrentSessions": 1,
      "maxTrainingTime": 999999,
      "maxRounds": 200,
      "availableModels": ["n", "nn", "r", "lir"],
      "availableAggregators": ["fedavg"],
      "showMetrics": ["accuracy"],
      "downloadableMetrics": true,
      "downloadableModels": true,
      "hyperparameters": false,
      "differentialPrivacy": false
    },
    "0006": {
      "usersIncluded": 5,
      "ddbLimits": {
          "workspacesIncluded": 1,
          "projectsIncluded": 1,
          "trainingsIncluded": 1500,
          "sessionsIncluded": 100,
          "nodesIncluded": 5,
          "modelsIncluded": 30,
          "singleModelSize": 25000000,
          "modelsTotalSize": 50000000
      },
      "executionLimits": {
          "concurrentSessions": 999,
          "sessionsADay": 999,
          "sessionsAnHour": 999
      },
      "totalStorage": 10240,
      "learningRetention": 365,
      "paradigmsSupported": [{"name": "hfl"}, {"name": "vfl"}],
      "validImages": [{"name": "DEFAULT"}],
      "concurrentSessions": 1,
      "maxTrainingTime": 999999,
      "maxRounds": 100,
      "availableModels": ["n", "nn", "r", "lir", "e", "rf", "gb"],
      "availableAggregators": ["fedavg"],
      "showMetrics": ["accuracy"],
      "downloadableMetrics": true,
      "downloadableModels": true,
      "hyperparameters": false,
      "differentialPrivacy": false
    }
  } as { [key: string]: any },
  "algorithms": [
    {
      "id": "n",
      "name": "Neural Networks",
      "optGroup": true
    },
    {
      "id": "nn",
      "name": "Neural Networks for Classification",
      "optItem": true
    },
    {
      "id": "nnr",
      "name": "Neural Networks for Regression",
      "optItem": true
    },
    {
      "id": "r",
      "name": "Regression",
      "optGroup": true
    },
    {
      "id": "lir",
      "name": "Linear Regression",
      "optItem": true
    },
    {
      "id": "lor",
      "name": "Logistic Regression",
      "optItem": true
    },
    {
      "id": "svm",
      "name": "Support Vector Machines (SVM)",
      "optItem": true
    },
    {
      "id": "c",
      "name": "Clustering",
      "optGroup": true
    },
    {
      "id": "k-means",
      "name": "K-Means",
      "optItem": true
    },
    {
      "id": "e",
      "name": "Ensemble",
      "optGroup": true
    },
    {
      "id": "rf",
      "name": "Random Forest",
      "optItem": true
    },
    {
      "id": "gb",
      "name": "Gradient Boosting",
      "optItem": true
    },
    {
      "id": "b",
      "name": "Bayesian",
      "optGroup": true
    },
    {
      "id": "nbayes",
      "name": "Naive Bayes",
      "optItem": true
    },
    {
      "id": "tan",
      "name": "Tree Augmented Naive (TAN)",
      "optItem": true
    },
    {
      "id": "d",
      "name": "Decision Tree",
      "optGroup": true
    },
    {
      "id": "id3",
      "name": "Iterative Dichotomiser 3 (ID3)",
      "optItem": true
    },
    {
      "id": "tf",
      "name": "Transformers",
      "optGroup": true
    },
    {
      "id": "llms",
      "name": "Large Language Models (LLMs)",
      "optItem": true
    }
  ],
  "aggregators": [
    {
      "id": "FedAvg",
      "name": "Federated Averaging",
      "description": "Average of parameters",
      "tip": "This is the most used aggregator. Works best when you have many nodes."
    },
    {
      "id": "FedSum",
      "name": "Federated Sum Aggregator",
      "description": "Sum of the parameters",
      "tip": "This is the most used aggregator. Works best when you have many nodes."
    },
    {
      "id": "ClusterFedAvg",
      "name": "Cluster Federated Average Aggregator",
      "description": "For K-means (Scikit-learn). Special aggregator for Clustering",
      "tip": "Great for Polls and Surveys."
    },
    {
      "id": "CDP",
      "name": "Central Differential Privacy Aggregator",
      "description": "To be used in sectors where the clients want privacy, but precision is not the maximal priority.",
      "tip": "For large companies that use FL to improve their knowledge of profits (e.g Banks, Hospitals...)."
    },
    {
      "id": "WeakDP",
      "name": "Weak Differential Privacy Aggregator",
      "description": "The precision is better than the central differential privacy aggregator setting.",
      "tip": "For medium and local companies to improve profits or prediction."
    },
    {
      "id": "IowaFederated",
      "name": "Secure Aggregator",
      "description": "Good for medical sector where maximal privacy is needed, even in the aggregator, but costs precision in training.",
      "tip": "Especially useful for Medical sector data such as radiographies (cancer imaging)"
    }
  ],
  "periodicity": [
    {
      "id": "monthly",
      "name": "Monthly"
    },
    {
      "id": "weekly",
      "name": "Weekly"
    },
    {
      "id": "daily",
      "name": "Daily"
    }
  ],
  "stopConditionTypes": [
    {
      "id": "number-of-iterations",
      "name": "Number of iterations"
    },
    // TODO
    // {
    //   "id": "accuracy-in-the-last-round",
    //   "name": "Accuracy in the last round"
    // },
    // {
    //   "id": "total-accuracy",
    //   "name": "Total accuracy"
    // },
    // {
    //   "id": "time-elapsed",
    //   "name": "Time Elapsed"
    // },
    // {
    //   "id": "error-messages",
    //   "name": "Error messages"
    // },
    // {
    //   "id": "attacks",
    //   "name": "Attacks"
    // },
    // {
    //   "id": "data-sent",
    //   "name": "Data sent"
    // }
  ],
  "stopConditionOperators": [
    {
      "id": "equals-to",
      "name": "Equals to"
    },
    // TODO
    // {
    //   "id": "is-less-than",
    //   "name": "Is less than"
    // }
  ],
  "chatSpecialities": {
    health: [
      {
        "id": "osakidetza",
        "row": 0,
        "checked": false,
        "specialty": "Osakidetza Assistant",
        "description": "Assistant for medical analysis and basic diagnosis for Osakidetza.",
        "sector": "health",
        "icon": 'assets/images/osakidetza-assistant.png',
        "headerReport": 'assets/images/cabecera_osakidetza.jpg',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "triage",
        "row": 1,
        "checked": false,
        "specialty": "Triage Assistant",
        "description": "Assistant for medical analysis and basic diagnosis.",
        "sector": "health",
        "icon": 'assets/images/health-assistant.png',
        "headerReport": 'assets/images/cabecera_osakidetza.jpg',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "triageen",
        "row": 2,
        "checked": false,
        "specialty": "Triage Assistant English",
        "description": "Assistant for medical analysis and basic diagnosis.",
        "sector": "health",
        "icon": 'assets/images/health-assistant.png',
        "headerReport": 'assets/images/cabecera_osakidetza.jpg',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "nhs",
        "row": 3,
        "checked": false,
        "specialty": "NHS Assistant",
        "description": "Assistant for medical analysis and basic diagnosis for NHS.",
        "sector": "health",
        "icon": 'assets/images/nhs-assistant.png',
        "headerReport": 'assets/images/header_nhs.jpg',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "anamnesis",
        "row": 4,
        "checked": false,
        "specialty": "Anamnesis Assistant",
        "description": "Assistant for medical analysis and anamnesis.",
        "sector": "health",
        "icon": 'assets/images/health-assistant.png',
        "headerReport": 'assets/images/cabecera_osakidetza.jpg',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "neurologist",
        "row": 5,
        "checked": false,
        "specialty": "Neurologist Assistant",
        "description": "Medical assistant specialized in neurology.",
        "sector": "health",
        "icon": 'assets/images/health-assistant.png',
        "headerReport": 'assets/images/cabecera_osakidetza.jpg',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "cardiologist",
        "row": 6,
        "checked": false,
        "specialty": "Cardiologist Assistant",
        "description": "Medical assistant specialized in cardiology.",
        "sector": "health",
        "icon": 'assets/images/health-assistant.png',
        "headerReport": 'assets/images/cabecera_osakidetza.jpg',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
    ],
    legal: [
      {
        "id": "civil",
        "row": 0,
        "checked": false,
        "specialty": "Civil Lawyer Assistant",
        "description": "Spanish civil lawyer assistant.",
        "sector": "legal",
        "icon": 'assets/images/legal-assistant.png',
        "headerReport": 'assets/images/cabecera_osakidetza.jpg',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "labour",
        "row": 1,
        "checked": false,
        "specialty": "Labour Lawyer Assistant",
        "description": "Spanish labor lawyer assistant.",
        "sector": "legal",
        "icon": 'assets/images/legal-assistant.png',
        "headerReport": 'assets/images/cabecera_osakidetza.jpg',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "criminal",
        "row": 2,
        "checked": false,
        "specialty": "Criminal Lawyer Assistant",
        "description": "Legal assistant specialized in criminal matters.",
        "sector": "legal",
        "icon": 'assets/images/legal-assistant.png',
        "headerReport": 'assets/images/cabecera_osakidetza.jpg',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
    ],
    tourism: [
      {
        "id": "museoprado",
        "row": 0,
        "checked": false,
        "specialty": "Museo del Prado Tourism Assistant",
        "description": "Assistant for Museo del Prado.",
        "sector": "tourism",
        "icon": 'assets/images/museoprado-assistant.png',
        "headerReport": 'assets/images/museoprado_header.png',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "madrid",
        "row": 1,
        "checked": false,
        "specialty": "Madrid Tourism Assistant",
        "description": "Tourism assistant in Madrid.",
        "sector": "tourism",
        "icon": 'assets/images/madrid-assistant.png',
        "headerReport": 'assets/images/header_madrid.jpg',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "bilbao",
        "row": 2,
        "checked": false,
        "specialty": "Bilbao Tourism Assistant",
        "description": "Tourism assistant in Bilbao.",
        "sector": "tourism",
        "icon": 'assets/images/bilbao-assistant.png',
        "headerReport": 'assets/images/header_bilbao.jpg',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "barcelona",
        "row": 3,
        "checked": false,
        "specialty": "Barcelona Tourism Assistant",
        "description": "Tourism assistant in Barcelona.",
        "sector": "tourism",
        "icon": 'assets/images/barcelona-assistant.png',
        "headerReport": 'assets/images/header_barcelona.jpg',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      }
    ],
    media: [
      {
        "id": "unidadeditorial",
        "row": 0,
        "checked": false,
        "specialty": "Unidad Editorial Assistant",
        "description": "Media assistant for Unidad Editorial.",
        "sector": "media",
        "icon": 'assets/images/unidadeditorial-assistant.png',
        "headerReport": 'assets/images/unidadeditorial_header.png',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "elmundo",
        "row": 1,
        "checked": false,
        "specialty": "El Mundo Assistant",
        "description": "Media assistant for El Mundo.",
        "sector": "media",
        "icon": 'assets/images/elmundo-assistant.png',
        "headerReport": 'assets/images/cabecera_elmundo.png',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "marca",
        "row": 2,
        "checked": false,
        "specialty": "Marca Assistant",
        "description": "Media assistant for Marca.",
        "sector": "media",
        "icon": 'assets/images/marca-assistant.png',
        "headerReport": 'assets/images/cabecera_marca.png',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "expansion",
        "row": 3,
        "checked": false,
        "specialty": "Expansión Assistant",
        "description": "Media assistant for Expansión.",
        "sector": "media",
        "icon": 'assets/images/expansion-assistant.png',
        "headerReport": 'assets/images/cabecera_expansion.png',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "prisa",
        "row": 4,
        "checked": false,
        "specialty": "Prisa Assistant",
        "description": "Media assistant for Prisa.",
        "sector": "media",
        "icon": 'assets/images/prisa-assistant.png',
        "headerReport": 'assets/images/prisa_header.png',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "elpais",
        "row": 5,
        "checked": false,
        "specialty": "El País Assistant",
        "description": "Media assistant for El País.",
        "sector": "media",
        "icon": 'assets/images/elpais-assistant.png',
        "headerReport": 'assets/images/cabecera_elpais.png',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "as",
        "row": 6,
        "checked": false,
        "specialty": "AS Assistant",
        "description": "Media assistant for AS.",
        "sector": "media",
        "icon": 'assets/images/as-assistant.png',
        "headerReport": 'assets/images/cabecera_as.png',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "cincodias",
        "row": 7,
        "checked": false,
        "specialty": "Cinco Días Assistant",
        "description": "Media assistant for Cinco Días.",
        "sector": "media",
        "icon": 'assets/images/cincodias-assistant.png',
        "headerReport": 'assets/images/cabecera_cincodias.png',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "vocento",
        "row": 8,
        "checked": false,
        "specialty": "Vocento Assistant",
        "description": "Media assistant for Vocento Group.",
        "sector": "media",
        "icon": 'assets/images/vocento-assistant.png',
        "headerReport": 'assets/images/header_madrid.jpg',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "okdiario",
        "row": 9,
        "checked": false,
        "specialty": "OkDiario Assistant",
        "description": "Media assistant for OkDiario.",
        "sector": "media",
        "icon": 'assets/images/okdiario-assistant.png',
        "headerReport": 'assets/images/header_madrid.jpg',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      }
    ],
    defense: [
      {
        "id": "estadomayordefensa",
        "row": 0,
        "checked": false,
        "specialty": "Estado Mayor de la Defensa Assistant",
        "description": "Assistant for Estado Mayor de la Defensa",
        "sector": "defense",
        "icon": 'assets/images/estadomayordefensa-assistant.png',
        "headerReport": 'assets/images/museoprado_header.png',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "armada",
        "row": 1,
        "checked": false,
        "specialty": "Armada Española Assistant",
        "description": "Assistant for Armada Española",
        "sector": "defense",
        "icon": 'assets/images/armada-assistant.png',
        "headerReport": 'assets/images/museoprado_header.png',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "ejercitoaire",
        "row": 2,
        "checked": false,
        "specialty": "Ejercito del Aire Assistant",
        "description": "Assistant for Ejercito del Aire",
        "sector": "defense",
        "icon": 'assets/images/ejercitoaire-assistant.png',
        "headerReport": 'assets/images/museoprado_header.png',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
      {
        "id": "ejercitotierra",
        "row": 3,
        "checked": false,
        "specialty": "Ejercito de Tierra Assistant",
        "description": "Assistant for Ejercito de Tierra",
        "sector": "defense",
        "icon": 'assets/images/ejercitotierra-assistant.png',
        "headerReport": 'assets/images/museoprado_header.png',
        "footerReport": 'assets/images/pie_osakidetza.jpg',
        "emitToParent": false
      },
    ],
    general: [
      {
        "id": "ikea",
        "row": 0,
        "checked": false,
        "specialty": "Ikea Assistant",
        "description": "Assistant for Ikea",
        "sector": "general",
        "icon": 'assets/images/ikea-assistant.png',
        "headerReport": 'assets/images/ikea_header.png',
        "footerReport": 'assets/images/ikea_footer.jpg',
        "emitToParent": false
      }
    ]
  }
}
