import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { TrainingActions, TrainingSessionActions } from 'src/app/platform/actions';
import { Router } from '@angular/router';

@Component({
  selector: 'shai-project-bar',
  template: `
    <div class="project-bar-wrap" *ngIf="showBackButton">
      <div class="back-wrap">
        <div class="back-container" (click)="backAction()">
          <button class="back-icon">
            <shai-icon-arrow-left></shai-icon-arrow-left>
          </button>
          <span title="{{ backTitle | uppercase }}" class="button-text-style" [ngClass]="{'truncate-text': showTitle === true}"> {{ backTitle | uppercase }} </span>
        </div>
      </div>
      <label *ngIf="showTitle" class="page-title paragraph"> {{ title }} </label>
    </div>
  `,
  styleUrls: ['./project-bar.component.scss'],
})
export class ProjectBarComponent implements OnInit {

  @Input() title: string | undefined | null = '';
  @Input() backTitle: string = 'Back to projects';
  @Input() showBackButton : boolean = true;
  @Input() showTitle: boolean = false;
  @Input() navigationUrl: string | undefined;

  constructor(
    private location: Location,
    private store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {}

  backAction(): void {
    if (this.navigationUrl) {
      this.router.navigate([this.navigationUrl]);
    }
    else {
      this.location.back();
    }
  }
}
