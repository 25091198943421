import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from '../reducers/auth.reducer';
import * as fromRoot from '../../../reducers';

export const authFeatureKey = 'auth';

export interface AuthState {
  [fromAuth.statusFeatureKey]: fromAuth.State
};

export interface State extends fromRoot.State {
  [authFeatureKey]: AuthState
}

export function reducers(state: AuthState | undefined, action: Action) {
  return combineReducers({
    [fromAuth.statusFeatureKey]: fromAuth.reducer,
  })(state, action);
}

export const selectAuthState = createFeatureSelector<AuthState>(authFeatureKey);

export const selectAuthStatusState = createSelector(
  selectAuthState,
  (state) => state.status
);

export const isLoginPending = createSelector(
  selectAuthStatusState,
  fromAuth.isLoginPending
);

export const isLoginError = createSelector(
  selectAuthStatusState,
  fromAuth.isLoginError
);

export const getAuthStatus = createSelector(
  selectAuthStatusState,
  fromAuth.getAuthStatus
);

export const getUser = createSelector(
  selectAuthStatusState,
  fromAuth.getUser
);

export const getAuthResponse = createSelector(
  selectAuthStatusState,
  fromAuth.getAuthResponse
);

export const getOrg = createSelector(
  selectAuthStatusState,
  fromAuth.getOrg
);

export const getAccessToken = createSelector(
  selectAuthStatusState,
  fromAuth.getOrg
);

export const isLoggedIn = createSelector(
  getUser,
  (user) => !!user
);

export const getCredentials = createSelector(
  selectAuthStatusState,
  fromAuth.getCredentials
);

export const getResetPassCredentials = createSelector(
  selectAuthStatusState,
  fromAuth.getResetPassCredentials
);

export const getResetPasswordCompleted = createSelector(
  selectAuthStatusState,
  fromAuth.getResetPasswordCompleted
);

export const getLoginResponse = createSelector(
  selectAuthStatusState,
  fromAuth.getLoginResponse
);

export const getResetMFAResponse = createSelector(
  selectAuthStatusState,
  fromAuth.getResetMFAResponse
);

export const getVerifyMFAResponse = createSelector(
  selectAuthStatusState,
  fromAuth.getVerifyMFAResponse
);

export const isRequestAcessCompleted = createSelector(
  selectAuthStatusState,
  fromAuth.getRequestAccessCompleted
);

export const getRequestAcessError = createSelector(
  selectAuthStatusState,
  fromAuth.getRequestAccessError
);
