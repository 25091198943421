import { EntityStatus } from "./node";

export interface Organization {
  organizationSuid?: string;
  config?: Config;
  planSuid?: string;
  aaa?: any;
}

export interface Config {
  name?: string;
  description?: string;
  tags?: string[];
  capabilities?: string[];
  project?: any;
}

export interface Secret {
  secret?: string;
}

export interface ChangePassOrg {
  accessToken?: string;
  organizationSuid?: string;
  username?: string;
  password?: string;
  newPassword?: string;
}

export enum TrainingFilter {
  FINISHED = 'FINISHED',
  ONGOING = 'ONGOING',
  CREATED = 'CREATED'
}

export interface MetricMenu {
  name: string;
  value: number;
}

export interface TrainingStatusMenu {
  code?: string;
  id?: number;
  message?: string;
  timestamp?: string;
}

export interface OrgTrainingStatus {
  epoch: number;
  finishStatus: EntityStatus;
  mainMetrics: {
    metric1: MetricMenu;
    metric2: MetricMenu;
  };
  model: string;
  paradigm: string;
  ongoingTime?: number;
  projectName: string;
  projectSuid: string;
  round: number;
  totalRounds: number;
  totalTime: number;
  trainingDescription:string;
  trainingName: string;
  trainingSessionNumber: number;
  trainingSessionStatus: string;
  trainingSessionTimestamp: string;
  trainingSuid: string;
  analyticsQueries?: any;
}

export interface TrainingsMenu {
  items: OrgTrainingStatus[];
  nextPage: string | null;
}
