import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { catchError, map, switchMap, withLatestFrom, concatMap, mergeMap } from "rxjs/operators";
import { Training, TrainingList } from '..';
import * as fromAuth from '../../auth/state/selectors/auth.selectors';
import * as fromPlatform from '../../platform/reducers';
import { TrainingActions, NewTrainingActions, TrainingSessionActions, NewTrainingSessionActions } from '../actions';
import { TrainingService } from "../services/training.service";
import { EMPTY, of } from 'rxjs';

@Injectable()
export class TrainingEffects {

  constructor(
    private actions$: Actions,
    private store: Store,
    private _trainingService: TrainingService
  ) { }

  createNewTraining$ = createEffect(() => this.actions$.pipe(
    ofType(NewTrainingActions.createNewTraining),
    withLatestFrom(
      this.store.pipe(select(fromAuth.getOrg)),
      this.store.pipe(select(fromPlatform.getCurrentProjectId))
    ),
    switchMap(([{ name, description, paradigm, model, differentialPrivacy, aggregation, entityResolution, metricsConfig }, orgId, projectId]) => {
      return this._trainingService.createNewTraining(orgId, projectId, name, description, paradigm, model, differentialPrivacy, aggregation, entityResolution, metricsConfig).pipe(
        switchMap((training) => {
          return [NewTrainingActions.createNewTrainingSuccess({ training })]
        }),
        catchError(error => [NewTrainingActions.createNewTrainingFailure({error})])
      )
    })
  ))

  createNewTrainingSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(NewTrainingActions.createNewTrainingSuccess),
    map(({ training }) =>
      TrainingSessionActions.createTrainingSession({
        trainingId: training.trainingSuid!,
        timestamp: training.trainingSessionTimestamp!
      })
    )
  )
);

  clearAllDataTraining$ = createEffect(() => this.actions$.pipe(
    ofType(NewTrainingActions.clearAllDataTraining),
    switchMap(() => {
      return [
        NewTrainingActions.clearDataNewTraining(),
        NewTrainingActions.clearDataParadigm(),
        NewTrainingActions.clearDataEntityResolution(),
        NewTrainingActions.clearDataModel(),
        NewTrainingActions.clearDataAggregator(),
        NewTrainingActions.clearDataDifferentialPrivacy(),
        NewTrainingActions.clearNewTraining(),
        NewTrainingSessionActions.clearDataStopCondition(),
        NewTrainingSessionActions.clearDataMetricsConfig(),
        NewTrainingSessionActions.clearDataParticipatingNodes(),
        NewTrainingSessionActions.clearDataMappings()
      ];
    })
  ))

  loadTrainingStatusSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TrainingActions.loadTrainingStatusSuccess),
    map(() => {
      return TrainingActions.loadTrainingStatus();
    })
  ))

  loadTraining$ = createEffect(() => this.actions$.pipe(
    ofType(TrainingActions.loadTraining),
    withLatestFrom(
      this.store.pipe(select(fromAuth.getOrg)),
    ),
    switchMap(([{ projectId, trainingSessionTimestamp }, orgId]) =>  {
      return this._trainingService.loadTraining(orgId, projectId, trainingSessionTimestamp).pipe(
        switchMap((training: Training) => {
            return [
              TrainingActions.loadTrainingSuccess({ training }),
            ]
        }),
        catchError((error) => {
          console.log(error);
          return [
            TrainingActions.loadTrainingFailure({error: error}),
          ]
        })
      )
    })
  ))

  deleteTrainings$ = createEffect(() => this.actions$.pipe(
    ofType(TrainingActions.deleteTrainings),
    withLatestFrom(
      this.store.pipe(select(fromAuth.getOrg)),
    ),
    switchMap(([{ trainings }, orgId]) => {
      return this._trainingService.deleteTrainings(orgId, trainings)
      .pipe(
        switchMap(() => [TrainingActions.deleteTrainingsSuccess()]),
        catchError(error => [TrainingActions.deleteTrainingsFailure({error})])
      )
    })
  ));
}
