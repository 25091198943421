import { createReducer, on } from '@ngrx/store';
import * as NewTrainingSessionActions from '../actions/new-training-session.actions';
import {
  TrainingParadigm,
  TrainingModel,
  TrainingSession,
  StopCondition,
  PrivateAttrs,
  Infrastructure,
  TrainingSessionRound,
  NodeMetrics,
  UrlModelParameters,
  Status,
  MetricsConfig,
} from '../models/';
import { Node } from 'src/app/platform';
import { createRehydrateReducer } from './rehydrate.reducer';
import { RocMetrics } from 'src/app/training/components/charts/models/chart.model';

export const newTrainingSessionFeatureKey = 'newTrainingSession';
const newTrainingSessionStorageKey = 'flp_storage_new_training_session_state';

export interface State {
  stopCondition: StopCondition | null;
  metricsConfig: MetricsConfig | null;
  participatingNodes: string[];
  mappings: any[];
}

const initialState: State = {
  stopCondition: null,
  metricsConfig: null,
  participatingNodes: [],
  mappings: []
};

export const reducer = createReducer(
  initialState,

  // Clear data
  on(NewTrainingSessionActions.clearDataStopCondition, state => ({ ...state, stopCondition: initialState.stopCondition})),
  on(NewTrainingSessionActions.clearDataMetricsConfig, state => ({ ...state, metricsConfig: initialState.metricsConfig})),
  on(NewTrainingSessionActions.clearDataParticipatingNodes, state => ({ ...state, participatingNodes: initialState.participatingNodes})),
  on(NewTrainingSessionActions.clearDataMappings, state => ({ ...state, mappings: initialState.mappings})),

  on(NewTrainingSessionActions.dataStopConditions, (state, { stopCondition }) => ({...state, stopCondition})),
  on(NewTrainingSessionActions.dataParticipatingNodes, (state, { participatingNodes }) => ({...state, participatingNodes})),
  on(NewTrainingSessionActions.dataMappings, (state, { mappings }) => ({...state, mappings})),

  on(NewTrainingSessionActions.chooseStopConditions, (state) => ({ ...state})),
  on(NewTrainingSessionActions.chooseStopConditionsSuccess, (state, { stopConditions }) => ({...state, stopConditions})),
  on(NewTrainingSessionActions.chooseStopConditionsFailure, (state) => ({ ...state })),

  on(NewTrainingSessionActions.chooseParticipatingNodes, (state) => ({ ...state})),
  on(NewTrainingSessionActions.chooseParticipatingNodesSuccess, (state, { participatingNodes }) => ({...state, participatingNodes})),
  on(NewTrainingSessionActions.chooseParticipatingNodesFailure, (state) => ({ ...state })),

  on(NewTrainingSessionActions.chooseMappings, (state) => ({ ...state })),
  on(NewTrainingSessionActions.chooseMappingsSuccess, (state) => ({ ...state })),
  on(NewTrainingSessionActions.chooseMappingsFailure, (state) => ({ ...state })),
);

export const getMetricsConfig = (state: State) => state?.metricsConfig;
export const getStopCondition = (state: State) => state?.stopCondition;
export const getParticipatingNodes = (state: State) => state?.participatingNodes;
export const getMappings = (state: State) => state?.mappings;
