import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Input,
  OnDestroy,
  HostBinding,
} from '@angular/core';
import { MapNodesType, Node, fromProject } from 'src/app/platform';
import { getCoordinatesFromString } from 'src/app/utils';
import { apikeys } from 'src/environments/apikeys';
import { environment } from 'src/environments/environment';
import { mapStyles } from './google-map.styles';
import { NodeService } from '../../../platform/services/node.service';
import { MapStateProps } from './google-map.models';
import { isAvailable, isEnabled, isLinked, isSherpaNode } from 'src/app/utils/node-utils';
import { Observable } from 'rxjs';
import { Organization } from 'src/app/platform/models/organization';
import { Store, select } from '@ngrx/store';

const nodeMarkerIconUrl =
  'data:image/svg+xml;charset=utf-8,' +
  encodeURIComponent(
    '<svg width="12" height="12" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="12" height="12" fill="#0073bf"/></svg>'
  );

const nodeMarkerIconCustomUrl =
  'data:image/svg+xml;charset=utf-8,' +
  encodeURIComponent(
    '<svg width="12" height="12" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="12" height="12" fill="#1D9781"/></svg>'
  );

const nodeMarkerIconPaidUrl =
  'data:image/svg+xml;charset=utf-8,' +
  encodeURIComponent(
    '<svg width="12" height="12" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="12" height="12" fill="#C45200"/></svg>'
  );

const nodeNotAvailableMarkerIconUrl =
  'data:image/svg+xml;charset=utf-8,' +
  encodeURIComponent(
    '<svg width="12" height="12" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="12" height="12" fill="#8a8d91"/></svg>'
  );

const nodeMarkerIconSmallUrl =
  'data:image/svg+xml;charset=utf-8,' +
  encodeURIComponent(
    '<svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="6" height="6" fill="#0073bf"/></svg>'
  );

const nodeMarkerIconSmallCustomUrl =
  'data:image/svg+xml;charset=utf-8,' +
  encodeURIComponent(
    '<svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="6" height="6" fill="#1D9781"/></svg>'
  );

const nodeMarkerIconSmallPaidUrl =
  'data:image/svg+xml;charset=utf-8,' +
  encodeURIComponent(
    '<svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="6" height="6" fill="#C45200"/></svg>'
  );

const nodeNotAvailableMarkerIconSmallUrl =
  'data:image/svg+xml;charset=utf-8,' +
  encodeURIComponent(
    '<svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="6" height="6" fill="#8a8d91"/></svg>'
  );

const warningMarkerIconUrl =
  'data:image/svg+xml;charset=utf-8,' +
  encodeURIComponent(
    '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d)"><rect x="2" y="1" width="28" height="28" rx="14" fill="white"/></g><path d="M23.1384 20.5H8.86159L16 8.00778L23.1384 20.5Z" fill="#C45200" stroke="#C45200"/><rect x="15.5" y="13" width="1" height="3" fill="white"/><rect x="15" y="17" width="2" height="2" rx="1" fill="white"/><defs><filter id="filter0_d" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="1"/><feGaussianBlur stdDeviation="1"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/></filter> </defs></svg>'
  );

const lostMarkerIconUrl =
  'data:image/svg+xml;charset=utf-8,' +
  encodeURIComponent(
    '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d)"><rect x="2" y="1" width="28" height="28" rx="14" fill="white"/></g><rect x="8.5" y="7.5" width="15" height="15" rx="7.5" stroke="#5F6167"/><rect x="13.5254" y="12" width="8" height="1" transform="rotate(45 13.5254 12)" fill="#5F6167"/><rect width="8" height="1" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 13.5254 18.3633)" fill="#5F6167"/><defs><filter id="filter0_d" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="1"/><feGaussianBlur stdDeviation="1"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/></filter></defs></svg>'
  );

export declare type MapMode = 'static' | 'dynamic';

declare global {
  interface Window {
    initMap: () => void;
  }
}

@Component({
  selector: 'shai-google-map',
  template: `<div class="map-card" (dblclick)="onLeftClick($event)" (mousedown)="onRightClick($event)">
    <div class="map-container">
      <div #map class="leaflet-wrapper"
      [style.height]="mapHeigth + 'px'"></div>
      <div class="map-legend" *ngIf="(mode == 'dynamic' || isLandingOrg) && allNodes > 0">
        <h4>Node monitor</h4>
        <ul>
          <li *ngIf="sherpaNodes > 0 && !this.isMapNodesTypeOrg()">
            <shai-icon-node-color-16></shai-icon-node-color-16>
            <label
              >{{ sherpaNodes }} {{legendtTitle}}{{
                sherpaNodes > 1 ? 's' : ''
              }}</label
            >
          </li>
          <li *ngIf="notSherpaNodes > 0 && !this.isMapNodesTypeOrg()">
            <shai-icon-node-telefonica-12></shai-icon-node-telefonica-12>
            <label
              >{{ notSherpaNodes }} {{legendtTitle}}{{
                notSherpaNodes > 1 ? 's' : ''
              }}</label
            >
          </li>
          <li *ngIf="availableNodes > 0 && this.isMapNodesTypeOrg()">
            <shai-icon-node-color-16></shai-icon-node-color-16>
            <label
              >{{ availableNodes }} {{legendtTitle}}{{
                availableNodes > 1 ? 's' : ''
              }}</label
            >
          </li>
          <li *ngIf="customAvailableNodes > 0 && this.isMapNodesTypeOrg()">
            <shai-icon-node-telefonica-12></shai-icon-node-telefonica-12>
            <label
              >{{ customAvailableNodes }} {{ customOrgLegend }}{{
                customAvailableNodes > 1 ? 's' : ''
              }}</label
            >
          </li>
          <li *ngIf="paidAvailableNodes > 0 && this.isMapNodesTypeOrg()">
            <shai-icon-node-paid-12></shai-icon-node-paid-12>
            <label
              >{{ paidAvailableNodes }} {{ 'Available Pay-per-use node' }}{{
                paidAvailableNodes > 1 ? 's' : ''
              }}</label
            >
          </li>
          <li *ngIf="notAvailableNodes > 0 && this.isMapNodesTypeOrg()">
            <shai-icon-node-not-available-16></shai-icon-node-not-available-16>
            <label
              >{{ notAvailableNodes }} Not available node{{
                notAvailableNodes > 1 ? 's' : ''
            }}</label
            >
          </li>
          <li class="warning" *ngIf="warningNodes > 0 && this.isMapNodesTypeOrg()">
            <shai-icon-warning></shai-icon-warning>
            <label
              >{{ warningNodes }} Warning{{
                warningNodes > 1 ? 's' : ''
              }}</label
            >
          </li>
        </ul>
      </div>
      <div *ngIf="mode == 'dynamic' || isLandingOrg" class="map-zoom-controls">
        <shai-icon-zoom-increase (click)="zoomIn()"></shai-icon-zoom-increase>
        <shai-icon-zoom-decrease (click)="zoomOut()"></shai-icon-zoom-decrease>
      </div>
    </div>
  </div>`,
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements AfterViewInit, OnDestroy {
  @Input() set model(newValue: MapStateProps | undefined) {
    if (newValue) {
      this.mapState = newValue;
      this.updateMapContent();
    }
  }
  @Input() mode: MapMode = 'dynamic';
  @Input() isLandingOrg: boolean = false;
  @Input() mapHeigth = 546;
  @Input() applyCardStyle: boolean = true;

  @ViewChild('map', { static: false }) mapElementRef?: ElementRef;

  rightClicksAmount = 0;
  private mapZoom = 2;
  private maxZoom = 15;

  private googleMapObj: any;
  private googleMapsModuleObj: any;
  private markers: any[] = [];

  mapState?: MapStateProps;

  availableNodes: number = 0;
  notAvailableNodes: number = 0;
  disabledNodes: number = 0;
  warningNodes: number = 0;
  allNodes: number = 0;

  sherpaNodes: number = 0;
  notSherpaNodes: number = 0;

  customAvailableNodes: number = 0;
  paidAvailableNodes: number = 0;

  availableNodesList: Node[] = [];

  nodes: Node[] = [];
  firstProjectTag: string = '';
  trainingModel: string = '';

  customOrgLegend: string = 'Available node';
  organization$: Observable<Organization>;

  @HostBinding('class.map-card-styles') get outlineClass() {
    return this.applyCardStyle;
  }

  constructor(
    private _nodeService: NodeService,
    private store: Store,
  ) {
    this.organization$ = this.store.pipe(select(fromProject.getOrganization)) as Observable<Organization>;

    this.organization$.subscribe((org) => {
      if (org.config?.name) {
        this.customOrgLegend = 'Available ' + org.config?.name + ' node';
      }
    });
  }

  ngAfterViewInit(): void {
    this.ngAfterView();
  }

  ngOnInit() {
    window.initMap = this.initMap.bind(this);
  }

  initMap() {
    return
  }

  ngOnDestroy() {
    this.clearListeners();
  }

  ngAfterView() {
    this.getGoogleMaps()
      .then((googleMapsModule) => {
        const mapEl = this.mapElementRef?.nativeElement;
        const map = new googleMapsModule.Map(mapEl, {
          center: { lat: 34, lng: 12 },
          zoom: this.mapZoom,
          scrollwheel: false,
          disableDefaultUI: true,
          gestureHandling: 'cooperative',
          zoomControl: false,
          styles: mapStyles,
          disableDoubleClickZoom: this.isMapStatic() ? true : false,
          draggable: true,
        });

        //save refereces
        this.googleMapObj = map;
        this.googleMapsModuleObj = googleMapsModule;
        if (googleMapsModule && this.nodes && this.nodes.length > 0) {
          this.addMarkers(googleMapsModule);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  get legendtTitle () {
    switch(this.mapState?.type) {
      case MapNodesType.ORG:
      return "Public Data Node";
      case MapNodesType.LINKED:
      return "Participating node";
      case MapNodesType.HYSTORY:
        return "Node";
    }
    return "";
  }

  private updateMapContent(): void {
    const newNodes = this.mapState?.nodes ? this.mapState?.nodes : [];
    this.nodes = newNodes;
    this.firstProjectTag = this.mapState?.firstProjectTag ? this.mapState.firstProjectTag : '';
    this.trainingModel = this.mapState?.trainingModel ? this.mapState.trainingModel : '';

    this.availableNodesList = this._nodeService.getAvailableNodesList(newNodes, this.firstProjectTag, this.trainingModel);
    this.notAvailableNodes = this._nodeService.getNotAvailableNodes(newNodes, this.firstProjectTag, this.trainingModel);

    this.availableNodes = 0;
    this.availableNodesList.forEach((node) => {
      if (isSherpaNode(node) && !this._isPaidNode(node)){
        this.availableNodes++;
      }
    });

    this.customAvailableNodes = 0;
    this.availableNodesList.forEach((node) => {
      if (!isSherpaNode(node)){
        this.customAvailableNodes++;
      }
    });

    this.paidAvailableNodes = 0;
    this.availableNodesList.forEach((node) => {
      if (this._isPaidNode(node)){
        this.paidAvailableNodes++;
      }
    });

//     this.customAvailableNodes = this.customAvailableNodes - this.paidAvailableNodes;

    this.allNodes = this.availableNodes + this.notAvailableNodes + this.disabledNodes + this.customAvailableNodes + this.paidAvailableNodes;

    if (!this.isMapNodesTypeOrg()) {
      this.sherpaNodes = 0;
      this.nodes.forEach((node) => {
        if (isSherpaNode(node)){
          this.sherpaNodes++;
        }
      });
      this.notSherpaNodes = 0;
      this.nodes.forEach((node) => {
        if (!isSherpaNode(node)){
          this.notSherpaNodes++;
        }
      });
    }

    this.refreshMarkers();
  }

  private clearAllMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
    this.markers = [];
  }

  private refreshMarkers() {
    this.clearAllMarkers();
    this.addMarkers(this.googleMapsModuleObj);
  }

  private isMapStatic() {
    return this.mode === 'static';
  }

  private addToolTip(googleMapsModule: any, marker: any, node: Node) {

    let infoContentHtml = `<div class='map-tooltip-container'>`;

    if (node?.config?.name) {
      infoContentHtml = infoContentHtml + `<span class='caption-bold'>${node?.config?.name}</span>`;
    }

    if (node?.config?.tags && node?.config?.tags?.length > 0) {
      infoContentHtml = infoContentHtml + `<span class='caption'>${node?.config?.tags?.join(', ')}</span>`;
    }

    infoContentHtml = infoContentHtml + `<span class='caption'>${node?.location?.coordinates}</span>`;
    infoContentHtml = infoContentHtml + `</div>`;

    let infowindow = new googleMapsModule.InfoWindow({
      content: infoContentHtml,
    });

    marker.addListener('mouseover', () => {
      infowindow.open(this.googleMapObj, marker);
    });

    marker.addListener('mouseout', () => {
      infowindow.close();
    });
  }

  private listenToBoundsChange(googleMapsModule: any) {
    googleMapsModule.event.addListener(this.googleMapObj, 'zoom_changed', () => {
        const zoomChangeBoundsListener = googleMapsModule.event.addListener( this.googleMapObj, 'bounds_changed', () => {
            if (this.googleMapObj.getZoom() > this.maxZoom && this.googleMapObj.initialZoom == true) {
              this.googleMapObj.setZoom(this.mapZoom);
              this.googleMapObj.initialZoom = false;
            }
            googleMapsModule.event.removeListener(zoomChangeBoundsListener);
          }
        );
      }
    );
  }

  public isMapNodesTypeOrg() {
    return this.mapState?.type == MapNodesType.ORG
  }

  public isAvailableNode(node: Node) {
    const nodetags = node?.config?.tags ? node.config.tags : []
    return !this.isMapNodesTypeOrg() || (isAvailable(node.isSherpa, nodetags, this.firstProjectTag, this.trainingModel) && isEnabled(node?.entityStatus) && !isLinked(node?.entityStatus))
  }

  public getUrl(node: Node) {
    if(this.isMapStatic()) {

      if (this._isPaidNode(node)) {
        return nodeMarkerIconSmallPaidUrl;
      }

      if (!isSherpaNode(node)) {
        return nodeMarkerIconSmallCustomUrl;
      }

      if(this.isAvailableNode(node)) {
        return nodeMarkerIconSmallUrl
      } else {
        return nodeNotAvailableMarkerIconSmallUrl
      }
    } else {

      if (this._isPaidNode(node)) {
        return nodeMarkerIconPaidUrl;
      }

      if (!isSherpaNode(node)) {
        return nodeMarkerIconCustomUrl;
      }

      if(this.isAvailableNode(node)) {
        return nodeMarkerIconUrl
      } else {
        return nodeNotAvailableMarkerIconUrl
      }
    }
  }

  private _isPaidNode(node: Node): boolean {
    let response = false;

    if (node.type === 'free') {
      response = false;
    }

    if (node.type === 'premium') {
      response = true;
    }

    return response;
  }

  private async addMarkers(googleMapsModule: any) {
    if (googleMapsModule) {
      let bounds = new googleMapsModule.LatLngBounds();
      if (this.nodes && this.nodes.length > 0) {
        for (let node of this.nodes) {
          if (node?.location && node.location.coordinates) {
            const coordinates = getCoordinatesFromString(node?.location?.coordinates);
            const marker = new googleMapsModule.Marker({
              position: {
                lat: coordinates.latitude,
                lng: coordinates.longitude,
              },
              map: this.googleMapObj,
              icon: {
                url: this.getUrl(node)
              },
            });
            this.addToolTip(googleMapsModule, marker, node);
            this.markers.push(marker);
            bounds.extend(marker.getPosition());
          }
        }
        if (!this.isMapStatic()) {
          this.listenToBoundsChange(googleMapsModule);
          this.googleMapObj.initialZoom = true;
          this.googleMapObj.fitBounds(bounds, 150);
        } else {
          if (this.nodes.length === 1) {
            this.googleMapObj.setZoom(8);
            const markerPosition = this.markers[0].getPosition();
            this.googleMapObj.setCenter(markerPosition);
          }
          else {
            this.googleMapObj.fitBounds(bounds);
          }
        }
      }
    }
  }

  private getGoogleMaps(): Promise<any> {
    const win = window as any;
    const googleModule = win.google;
    if (googleModule && googleModule.maps) {
      return Promise.resolve(googleModule.maps);
    }

    const scriptExists = Array.from(document.getElementsByTagName('script'))
      .some(script => script.src.includes('key=' + apikeys.googleMaps));

    if (scriptExists) {
      return new Promise((resolve, reject) => {
        const checkMapsLoaded = () => {
          if (win.google && win.google.maps) {
            resolve(win.google.maps);
          } else {
            setTimeout(checkMapsLoaded, 100);
          }
        };
        checkMapsLoaded();
      });
    }

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = `${environment.googleMapsApi}?key=${apikeys.googleMaps}&language=en&region=GB&callback=initMap`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      script.onload = () => {
        if (win.google && win.google.maps) {
          resolve(win.google.maps);
        } else {
          reject('Google maps SDK not available.');
        }
      };
    });
  }

  private clearListeners() {
    this.markers.forEach((marker) => {
      this.googleMapsModuleObj.event.clearInstanceListeners(marker);
    });
  }

  //CLICK HANDLERS
  zoomIn() {
    this.googleMapObj.setZoom(this.googleMapObj.getZoom() + 1);
  }

  zoomOut() {
    this.googleMapObj.setZoom(this.googleMapObj.getZoom() - 1);
  }

  onLeftClick(event: any) {
    this.zoomIn();
  }

  onRightClick(event: any) {
    // check for double right click
    if(event.button == 2) {
      // if right mouse button clicked increase amount by 1
      this.rightClicksAmount++;
    }
    // if we had more than 1 right click during the 300 ms - we caught right double click event
    if (this.rightClicksAmount > 1) {
      this.zoomOut();
    }
    // after 300ms set right clicks amount to 0
    setTimeout(()=> {
      this.rightClicksAmount = 0;
    }, 300);
  }
}
