import { Directive, Input, Type, ViewContainerRef } from '@angular/core';
import { ExtraCellElement } from '../components/table/models/extra-cell-element';

@Directive({
  selector: '[adHost]',
})
export class AdDirective {
  @Input() component!: Type<any>;
  @Input() data: any;
  @Input() extraCellElements?: ExtraCellElement[] = [];

  constructor(public viewContainerRef: ViewContainerRef) { }
}
