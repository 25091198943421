import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthModule } from '../auth/auth.module';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './components/header.component';
import { LayoutComponent } from './containers/layout.component';
import { AppComponent } from './containers/app.component';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { ChangePasswordOverlayComponent } from './overlays/change-password-overlay/change-password-overlay.component';
import { UserRoleOverlayComponent } from './overlays/user-role-overlay/user-role-overlay.component';
import { ChangeOrgSecretOverlayComponent } from './overlays/change-org-secret-overlay/change-org-secret-overlay.componen';
import { LottieModule } from 'ngx-lottie';

export const COMPONENTS = [
  AppComponent,
  LayoutComponent,
  HeaderComponent,
  NavigationBarComponent,
  ChangePasswordOverlayComponent,
  UserRoleOverlayComponent,
  ChangeOrgSecretOverlayComponent
]

@NgModule({
  declarations: COMPONENTS,
  imports: [
    AuthModule,
    CommonModule,
    RouterModule,
    SharedModule,
    LottieModule,
  ],
  providers: [],
  exports: COMPONENTS
})
export class CoreModule { }
