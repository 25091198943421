import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import * as fromAuth from '../state/selectors/auth.selectors';
import { AuthActions } from '../state/actions';
import { AuthResponse } from '../models/auth.models';
@Component({
  selector: 'shai-verify-tfa',
  template: `
    <div id="verify-tfa-wrapper">
      <mat-grid-list cols="12" rowHeight="220px">
        <mat-grid-tile colspan="2"></mat-grid-tile>
        <mat-grid-tile colspan="8">
          <shai-title-bar title="Verify Authenticator" topDescription="STEP 2 OF 3"
            bottomDescription="Enter the code from your Authenticator App to make sure everything works.">
          </shai-title-bar>
          <div class="center-wrapper">
            <div class="center-container">
              <shai-otp-input #otpInputRef [otpLength]="6" (onOtpCompleted)="onOtpCompleted($event)"></shai-otp-input>
            </div>
          </div>
          <div *ngIf="errorMsg.length > 0" class="center-wrapper">
            <div class="center-container">
              <span class="paragraph-small error-msg">{{ errorMsg }}</span>
            </div>
          </div>
          <div class="center-wrapper">
            <div class="center-container">
              <div class="icon-plus-text">
                <shai-icon-help-16></shai-icon-help-16>
                <span class="paragraph-small help-auth cursor-pointer" (click)="getVerificationCodeForResetMFA()">Problems with your Authentication app?</span>
              </div>
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile colspan="2"></mat-grid-tile>
      </mat-grid-list>
      <shai-action-bar>
        <ng-container left-cell>
          <shai-button
            [type]="'button'"
            [stretch]="true"
            [route]="['/auth/enable-tfa']"
            buttonStyle="secondary"
          >Previous</shai-button>
        </ng-container>
      </shai-action-bar>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./verify-tfa.component.scss']
})
export class VerifyTfaComponent {
  @ViewChild('otpInputRef', { static: false }) otpInputRef: any;
  errorMsg: string = '';
  private errorSubscription!: Subscription;
  private subscription?: Subscription;
  errorMFA$: Observable<boolean | undefined> = this.store.pipe(
    select(fromAuth.isLoginError)
  );

  constructor(
    private store: Store
  ) {}

  onOtpCompleted(otpValue: string) {
    console.log('[Login] código app', otpValue);
    if (otpValue) {
      this.errorMsg = '';
      this.verifyMFA(otpValue);
      console.log(otpValue);
      this.errorSubscription = this.errorMFA$.subscribe((error) => {
        if (error) {
          this.errorMsg = 'The code doesn’t match.';
          setTimeout(()=> {
            this.otpInputRef.otpInputRef.otpForm.enable();
            this.otpInputRef.otpInputRef.otpForm.reset();
            this.errorSubscription.unsubscribe();
          }, 1000);
        }
      })
    }
  }

  verifyMFA(code: string) {
    let hasResp = false;
    this.subscription = combineLatest([
      this.store.pipe(select(fromAuth.getCredentials)),
      this.store.pipe(select(fromAuth.getLoginResponse))
    ]).subscribe(([credentials, loginResponse]) => {
      if (credentials && loginResponse && !hasResp) {
        const data = {
          authFlow: "ENABLE_SOFTWARE_TOKEN_MFA",
          fakeSession: loginResponse?.fakeSession,
          userCode: code,
          organizationSuid: credentials?.organizationSuid,
          username: credentials?.username,
        }
        hasResp = true;
        this.store.dispatch(AuthActions.verifyMFA({ verifyMFA: data }));
        this.subscription?.unsubscribe();
      }
    })
  }

  getVerificationCodeForResetMFA() {
    let hasResp = false;
    this.subscription = combineLatest([
      this.store.pipe(select(fromAuth.getCredentials)),
      this.store.pipe(select(fromAuth.getLoginResponse)),
      this.store.pipe(select(fromAuth.getResetMFAResponse))
    ]).subscribe(([credentials, loginResponse, resetResponse]) => {
      if (credentials && loginResponse && resetResponse && !hasResp) {
        const data = {
          authFlow: "RESET_MFA_REQUIRED",
          session: this.getSession(loginResponse, resetResponse),
          organizationSuid: credentials?.organizationSuid,
          username: credentials?.username,
        }
        hasResp = true;
        this.store.dispatch(AuthActions.resetMFA({ resetMFA: data }));
        this.subscription?.unsubscribe();
      }
    })
  }

  getSession(loginResponse: AuthResponse, resetResponse: AuthResponse) {
    let session = '';
    if (loginResponse && loginResponse?.session !== undefined) {
      session = loginResponse?.session;
    }
    if (resetResponse && resetResponse?.session !== undefined) {
      session = resetResponse?.session;
    }
    return session;
  }
}
