import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
export interface IRadio {
  checked: boolean;
  disabled: boolean;
  group: string;
  value: string;
}
@Component({
    selector: 'shai-radio',
    template: `
        <mat-radio-button *ngIf="data"
                          [checked]="data.checked ? true : false"
                          [value]="data.value ? data.value : ''"
                          [ngClass]="data.disabled ? 'disabled' : ''"
                          class="outer-square"
                          [disabled]="data.disabled ? true : false"
                          (click)="radioClickHandler(data.value)">
            {{ data.label }}
            <ng-content></ng-content>
        </mat-radio-button>
    `,
    styleUrls: [`./radio.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioComponent implements AfterContentChecked {
    @Input() data!: {
        checked: boolean;
        disabled: boolean;
        group: string;
        label?: string;
        value: string;
    };
    @Output() onRadioClick = new EventEmitter();

    radioClickHandler(value: string) {
        if (!this.data.disabled) {
            this.data.checked = !this.data.checked;
            this.onRadioClick.emit(value);
        }
    }
    ngAfterContentChecked() {
        this.ref.detectChanges();
    }
    constructor(private ref: ChangeDetectorRef){}
}
