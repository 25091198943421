import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromPlatform from 'src/app/platform/reducers';
import { DifferentialPrivacyData, CalculationBasedOn } from 'src/app/platform';
import { ListItem } from 'src/app/shared/components/list-items/list-item.model';
import { AccessControlService } from 'src/app/shared/services/access-control.service';

@Component({
  selector: 'shai-differential-privacy-content',
  template: `
    <div id="differential-privacy-content-wrapper">
      <mat-radio-group>
        <div class="table-container" [ngStyle]="{'margin-top': isOverlay ? '0px' : '32px'}">
          <shai-table-header [headerTabs]="['Mechanism']">
            <div *ngIf="!isOverlay" class="table-header-right-contextual" right>
              <shai-tip tip type="oneLine" text="This configuration will be used as default unless overridden by the node."></shai-tip>
            </div>
          </shai-table-header>
          <div class="mat-table">
            <div class="mat-row" (click)="selectMechanism('laplace')">
              <div class="left-column">
                <shai-radio [data]="dataLaplace" (onRadioClick)="onMechanismChange($event)"></shai-radio>
              </div>
              <div class="right-column">
                <div class="card-head">
                  <div class="card-head-center">
                    <div id="card-title">
                      <div class="paragraph">Laplace Mechanism</div>
                    </div>
                    <div id="card-subtitle">
                      <div class="paragraph-small">The Laplace Mechanism accomplishes a higher privacy level by adding noise to the output of f, where the noise is computed under some given parameter ε.</div>
                    </div>
                  </div>
                  <div class="card-head-right">
                    <div><shai-button type="button" iconName="icon-document-16" buttonStyle="contextual" (click)="$event.stopPropagation(); $event.preventDefault(); onViewDocumentation('laplace')">View Documentation</shai-button></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mat-row">
              <div class="mat-cell-left">
                <div class="paragraph">Epsilon</div>
                <div class="paragraph-small">Default: 0.01  |  Range: 10<sup>-11</sup> to 10<sup>11</sup></div>
              </div>
              <div class="mat-cell-right">
                <shai-counter
                  [decimals]="2"
                  [floor]="minValue"
                  [ceil]="maxValue"
                  [counter]="epsilonLaplaceCounter"
                  (onCounterChange)="onCounterMechanismChange($event, 'epsilon', 'laplace')"
                ></shai-counter>
              </div>
            </div>
            <!--
            <div class="mat-row">
              <div class="mat-cell-left">
                <div class="paragraph">Delta</div>
                <div class="paragraph-small">Default: 0.01  |  Range: 0 to 1 excluded</div>
              </div>
              <div class="mat-cell-right">
                <shai-counter
                  [decimals]="2"
                  [floor]="0.01"
                  [ceil]="0.99"
                  [counter]="deltaLaplaceCounter"
                  (onCounterChange)="onCounterMechanismChange($event, 'delta', 'laplace')"
                ></shai-counter>
              </div>
            </div>
            -->
          </div>
      </div>
      <div class="table-container">
          <div class="mat-table">
            <div class="mat-row" (click)="selectMechanism('gaussian')">
              <div class="left-column">
                <shai-radio [data]="dataGaussian" (onRadioClick)="onMechanismChange($event)"></shai-radio>
              </div>
              <div class="right-column">
                <div class="card-head">
                  <div class="card-head-center">
                    <div id="card-title">
                      <div class="paragraph">Gaussian Mechanism</div>
                    </div>
                    <div id="card-subtitle">
                      <div class="paragraph-small">The Gaussian mechanism is an alternative to the Laplace mechanism, which adds Gaussian noise instead of Laplacian noise. The Gaussian mechanism does not satisfy pure ε -differential privacy, but does satisfy (ε, δ)-differential privacy.</div>
                    </div>
                  </div>
                  <div class="card-head-right">
                    <div><shai-button type="button" iconName="icon-document-16" buttonStyle="contextual" (click)="$event.stopPropagation(); $event.preventDefault(); onViewDocumentation('gaussian')">View Documentation</shai-button></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mat-row">
              <div class="mat-cell-left">
                <div class="paragraph">Epsilon</div>
                <div class="paragraph-small">Default: 0.01  |  Range: 10<sup>-11</sup> to 10<sup>11</sup></div>
              </div>
              <div class="mat-cell-right">
                <shai-counter
                  [decimals]="2"
                  [floor]="minValue"
                  [ceil]="maxValue"
                  [counter]="epsilonGaussianCounter"
                  (onCounterChange)="onCounterMechanismChange($event, 'epsilon', 'gaussian')"
                ></shai-counter>
              </div>
            </div>
            <div class="mat-row">
              <div class="mat-cell-left">
                <div class="paragraph">Delta</div>
                <div class="paragraph-small">Default: 0.01  |  Range: 0 to 1 excluded</div>
              </div>
              <div class="mat-cell-right">
                <shai-counter
                  [decimals]="2"
                  [floor]="0.01"
                  [ceil]="0.99"
                  [counter]="deltaGaussianCounter"
                  (onCounterChange)="onCounterMechanismChange($event, 'delta', 'gaussian')"
                ></shai-counter>
              </div>
            </div>
          </div>
      </div>
      <div class="table-container">
          <div class="mat-table" [ngClass]="{'disabled': exponentialMechanismAccessControl}" >
            <div class="mat-row" (click)="selectMechanism('exponential')">
              <div class="left-column">
                <shai-radio [data]="dataExponential" (onRadioClick)="onMechanismChange($event)"></shai-radio>
              </div>
              <div class="right-column">
                <div class="card-head">
                  <div class="card-head-center">
                    <div id="card-title">
                      <div class="paragraph">Exponential Mechanism</div>
                    </div>
                    <div id="card-subtitle">
                      <div class="paragraph-small">It is the most general mechanism for differential privacy. The exponential mechanism is defined with respect to the utility function which maps database/outputs pairs to utility scores.</div>
                    </div>
                  </div>
                  <div class="card-head-right">
                    <div><shai-button type="button" [disabled]="exponentialMechanismAccessControl" iconName="icon-document-16" buttonStyle="contextual" (click)="$event.stopPropagation(); $event.preventDefault(); onViewDocumentation('exponential')">View Documentation</shai-button></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mat-row disabled">
              <div class="left-column">
                <shai-icon-warning-solid icon></shai-icon-warning-solid>
              </div>
              <div class="right-column">
                <div class="card-head">
                  <div class="card-head-center">
                    <div id="card-title">
                      <div class="paragraph"><span class="color-danger">Notice: </span>Utility Function</div>
                    </div>
                    <div id="card-subtitle">
                      <div class="paragraph-small">The node must be provided with the appropriate Utility Function locally.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mat-row disabled">
              <div class="mat-cell-left">
                <div class="paragraph">Epsilon</div>
                <div class="paragraph-small">Default: 0.01  |  Range: 10<sup>-11</sup> to 10<sup>11</sup></div>
              </div>
              <div class="mat-cell-right">
                <shai-counter
                  [disable]="exponentialMechanismAccessControl"
                  [decimals]="2"
                  [floor]="minValue"
                  [ceil]="maxValue"
                  [counter]="epsilonExponentialCounter"
                  (onCounterChange)="onCounterMechanismChange($event, 'epsilon', 'exponential')"
                ></shai-counter>
              </div>
            </div>
          </div>
        </div>
      </mat-radio-group>
      <div class="table-container">
        <shai-table-header [headerTabs]="['Sensitivity']">
          <div *ngIf="!isOverlay" class="table-header-right-contextual" right>
            <shai-tip tip type="oneLine" text="This configuration will be used as default unless overridden by the node."></shai-tip>
          </div>
        </shai-table-header>
          <div class="mat-table">
            <div class="mat-row">
              <div class="mat-cell-left">
                <div class="paragraph">Calculation method</div>
                <div class="paragraph-small truncate-1-line separation">
                  <shai-info-hover content="The sensitivity reflects the amount the function's output will change when its input changes. The amount of noise necessary to ensure differential privacy depends on the sensitivity. If the maximum method is selected, the amount of privacy is much higher but the utility is much lower. Otherwise, if the mean method is selected, the trade-off between privacy and utility is balanced."> </shai-info-hover>
                  The sensitivity reflects the amount the function's output will change when its input changes. The amount of noise necessary to ensure differential privacy depends on the sensitivity. If the maximum method is selected, the amount of privacy is much higher but the utility is much lower. Otherwise, if the mean method is selected, the trade-off between privacy and utility is balanced.
                </div>
              </div>
              <div class="mat-cell-right">
                <shai-dropdown
                  (onListItemChange)="dropdownSelected($event, 'calculationMethod')"
                  [data]="calculationMethodDropdown"
                ></shai-dropdown>
              </div>
            </div>
            <div class="mat-row">
              <div class="mat-cell-left">
                <div class="paragraph">Maximum data size</div>
                <div class="paragraph-small truncate-1-line separation">
                  <shai-info-hover content="To estimate the sensitivity, an amount of data is mandatory. The more data size you select, the more accurate the estimation of the sensitivity will be; but also the higher the time complexity will be."> </shai-info-hover>
                  To estimate the sensitivity, an amount of data is mandatory. The more data size you select, the more accurate the estimation of the sensitivity will be; but also the higher the time complexity will be.
                </div>
              </div>
              <div class="mat-cell-right">
                <shai-counter
                  [floor]="minDataSizeCounter"
                  [ceil]="9999999"
                  [counter]="maxDataSizeCounter"
                  (onCounterChange)="onCounterSensitivityChange($event, 'maxDataSize')"
                ></shai-counter>
              </div>
            </div>
            <div class="mat-row">
              <div class="mat-cell-left">
                <div class="paragraph">Calculate based on</div>
              </div>
              <div class="mat-cell-right">
                <shai-dropdown
                  (onListItemChange)="dropdownSelected($event, 'calculateBasedOn')"
                  [data]="calculatedBasedOnDropdown"
                ></shai-dropdown>
              </div>
            </div>
            <div *ngIf="calculateBasedOnSelected === 'iterations'" class="mat-row">
              <div class="mat-cell-left">
                <div class="paragraph">Iterations</div>
                <div class="paragraph-small truncate-1-line">Number of compared neighboring datasets.</div>
                <div class="paragraph-small">Default: 20  |  Range: 1 to 99</div>
              </div>
              <div class="mat-cell-right">
                <shai-counter
                  [floor]="1"
                  [ceil]="99"
                  [counter]="iterationsCounter"
                  (onCounterChange)="onCounterSensitivityChange($event, 'iterations')"
                ></shai-counter>
              </div>
            </div>
            <div *ngIf="calculateBasedOnSelected === 'gamma'" class="mat-row">
              <div class="mat-cell-left">
                <div class="paragraph">Gamma</div>
                <div class="paragraph-small truncate-1-line">Desired privacy confidence level.</div>
                <div class="paragraph-small">Default: 0.1  |  Range: 0.1 to 0.99</div>
              </div>
              <div class="mat-cell-right">
                <shai-slider
                  (onSliderChange)="onSliderChange($event)"
                  [stretch]="true"
                  [decimals]="2"
                  [low]="lowValue"
                  [floor]="0.1"
                  [ceil]="0.99"
                  [value]="gammaCounter"
                ></shai-slider>
              </div>
            </div>
          </div>
      </div>
    </div>
  `,
  styleUrls: ['./differential-privacy-content.component.scss']
})
export class DifferentialPrivacyContentComponent implements OnInit, OnDestroy {
  @Input() isOverlay!: boolean;
  @Input() set hasSubmitAttemps(value: boolean) {
    if (value) {
      this.emitData()
    }
  }
  @Input() isEditMode: boolean = false;
  @Input() differentialPrivacyTraining!: DifferentialPrivacyData;

  calculationMethodDropdown: any = {};
  calculatedBasedOnDropdown: any = {};

  calculationMethodList: ListItem[] = [];
  calculateBasedOnList: ListItem[] = [];
  mechanismSelected: 'none' | 'laplace' | 'gaussian' | 'exponential' = 'none';
  calculationMethodSelected: 'maximum' | 'mean' = 'mean';
  calculateBasedOnSelected: 'iterations' | 'gamma' = 'iterations';
  epsilonLaplaceCounter: string = '0.01';
  deltaLaplaceCounter: string = '0.01';
  epsilonGaussianCounter: string = '0.01';
  deltaGaussianCounter: string = '0.01';
  epsilonExponentialCounter: string = '0.01';
  minDataSizeCounter = 1;
  maxDataSizeCounter: string = '256';
  iterationsCounter: string = '20';
  gammaCounter: string = '0.1';
  lowValue: number = 0.1;
  private modelSubscription!: Subscription;
  dataLaplace = {
    'checked': false,
    'disabled': false,
    'group': 'addDifferentialPrivacy',
    'value': 'laplace'
  };
  dataGaussian = {
    'checked': false,
    'disabled': false,
    'group': 'addDifferentialPrivacy',
    'value': 'gaussian'
  };
  dataExponential = {
    'checked': false,
    'disabled': false,
    'group': 'addDifferentialPrivacy',
    'value': 'exponential'
  };
  minValue = Math.pow(10,-11);
  maxValue = Math.pow(10,11);
  exponentialMechanismAccessControl: boolean = true;

  private subscription?: Subscription;
  newTrainingData$ : Observable<any> = new Observable();
  modelData$ : Observable<any> = new Observable();
  differentialPrivacyData$ : Observable<any> = new Observable();
  @Output() differentialPrivacyContent = new EventEmitter();

  constructor(
    private store: Store,
    private _accessControlService: AccessControlService
  ) {
    this.mechanismSelected = 'laplace';
    this.newTrainingData$ = this.store.pipe(select(fromPlatform.getNewTrainingData)) as Observable<any>;
    this.modelData$ = this.store.pipe(select(fromPlatform.getModelData)) as Observable<any>;
    this.differentialPrivacyData$ = this.store.pipe(select(fromPlatform.getDifferentialPrivacyData)) as Observable<any>;
    this.getMinDataSizeCounter();
  }

  ngOnInit() {

    this._getListItems();
    this.dataLaplace.checked = true;
    this.exponentialMechanismAccessControl = this._accessControlService.newCheckAccessControl('exponential-mechanism') || false;

    this.calculationMethodDropdown = {
      disabled: false,
      title: 'Mean',
      listItems: this.calculationMethodList
    }

    this.calculatedBasedOnDropdown = {
      disabled: false,
      title: 'Iterations',
      listItems: this.calculateBasedOnList
    }

    if (this.isEditMode && this.differentialPrivacyTraining) {
      if (this.differentialPrivacyTraining.mechanism?.type) {
        this.selectMechanism(this.differentialPrivacyTraining.mechanism?.type);
        this.onCounterMechanismChange(this.differentialPrivacyTraining.mechanism.delta, 'delta', this.differentialPrivacyTraining.mechanism?.type);
        this.onCounterMechanismChange(this.differentialPrivacyTraining.mechanism.epsilon, 'epsilon', this.differentialPrivacyTraining.mechanism?.type);
      }
      if (this.differentialPrivacyTraining.sensitivity?.calculationMethod) {
        const event = { value: this.differentialPrivacyTraining.sensitivity?.calculationMethod };
        this.dropdownSelected(event, 'calculationMethod');
        this.calculationMethodDropdown.title = this.getTitleByValueFromListItems(this.calculationMethodList, this.differentialPrivacyTraining.sensitivity?.calculationMethod);
      }
      if (this.differentialPrivacyTraining.sensitivity?.maxDataSize) {
        this.onCounterSensitivityChange(this.differentialPrivacyTraining.sensitivity?.maxDataSize, 'maxDataSize')
      }
      if (this.differentialPrivacyTraining.sensitivity?.calculationBasedOn.gamma) {
        const event = { value: 'gamma' };
        this.dropdownSelected(event, 'calculateBasedOn');
        this.calculatedBasedOnDropdown.title = this.getTitleByValueFromListItems(this.calculateBasedOnList, 'gamma');
        this.lowValue = this.differentialPrivacyTraining.sensitivity?.calculationBasedOn.gamma;
        this.onSliderChange(this.differentialPrivacyTraining.sensitivity?.calculationBasedOn.gamma);
      }
      if (this.differentialPrivacyTraining.sensitivity?.calculationBasedOn.sensitivityIterations) {
        const event = { value: 'iterations' };
        this.dropdownSelected(event, 'calculateBasedOn');
        this.calculatedBasedOnDropdown.title = this.getTitleByValueFromListItems(this.calculateBasedOnList, 'iterations');
        this.onCounterSensitivityChange(this.differentialPrivacyTraining.sensitivity?.calculationBasedOn.sensitivityIterations, 'iterations');
      }
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.modelSubscription?.unsubscribe();
  }

  getMinDataSizeCounter() {
    this.modelSubscription = this.modelData$.subscribe((data) => {
      if (data && data.hyperparametersData && data.hyperparametersData.length > 0 && data.modelId && data.modelId === 'k-means') {
        data.hyperparametersData.forEach((hyperparameter: any) => {
          if (hyperparameter.name === 'n_clusters') {
            this.minDataSizeCounter = Number(hyperparameter.value) + 1;
            if (Number(this.maxDataSizeCounter) < this.minDataSizeCounter) {
              this.maxDataSizeCounter = this.minDataSizeCounter.toString();
            }
          }
        });
      }
    });
  }

  getTitleByValueFromListItems(list: ListItem[], value: string): string {
    let title: string = '';
    if (list.length > 0) {
      list.forEach((item) => {
        if (item.value === value) {
          title = item.title;
        }
      });
    }
    return title;
  }

  emitData() {
    const data: DifferentialPrivacyData = this._getDifferentialPrivacyData();
    this.differentialPrivacyContent.emit(data);
  }

  private _getListItems() {
    this.calculationMethodList = [
      {type: 'body', title: 'Mean', subtitle: '', value: 'mean'},
      {type: 'body', title: 'Maximum', subtitle: '', value: 'maximum'}
    ];
    this.calculateBasedOnList = [
      {type: 'body', title: 'Iterations', subtitle: '', value: 'iterations'},
      {type: 'body', title: 'Gamma', subtitle: '', value: 'gamma'}
    ];
  }

  private _getDifferentialPrivacyData(): DifferentialPrivacyData {
    let data!: DifferentialPrivacyData;
    data = {
      mechanism: this._getMechanismData(),
      sensitivity: {
        calculationMethod: this.calculationMethodSelected,
        calculationBasedOn: this._getCalculationBasedOnObj(),
        maxDataSize: Number(this.maxDataSizeCounter)
      }
    };
    return data;
  }

  private _getMechanismData(): any {
    switch (this.mechanismSelected) {
      case 'laplace':
        return {
          type: this.mechanismSelected,
          epsilon: Number(this.epsilonLaplaceCounter),
        };
      case 'gaussian':
        return {
          type: this.mechanismSelected,
          epsilon: Number(this.epsilonGaussianCounter),
          delta: Number(this.deltaGaussianCounter),
        };
      case 'exponential':
        return {
          type: this.mechanismSelected,
          epsilon: Number(this.epsilonExponentialCounter),
        };
      default:
        return 0;
    }
  }

  private _getCalculationBasedOnObj(): CalculationBasedOn {
    switch (this.calculateBasedOnSelected) {
      case 'iterations':
        return { sensitivityIterations: Number(this.iterationsCounter) };
      case 'gamma':
        return { gamma: Number(this.gammaCounter) };
      default:
        return {};
    }
  }

  onViewDocumentation(documentType: string) {
    let route = '';
    switch (documentType) {
      case 'laplace':
        route = this._accessControlService.getBaseUrlByUserRole() + '/dp-laplace.html'
        break;
      case 'gaussian':
        route = this._accessControlService.getBaseUrlByUserRole() + '/dp-gaussian.html'
        break;
      case 'exponential':
        // route = this._accessControlService.getBaseUrlByUserRole() + '/dp-exponential.html'
        break;
    }
    const link = document.createElement('a');
    link.target = "_blank";
    link.href = route;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  selectMechanism(value: 'none' | 'laplace' | 'gaussian' | 'exponential') {
    this.onMechanismChange(value);
    switch (value) {
      case 'laplace':
        this.dataLaplace.checked = true;
        this.dataGaussian.checked = false;
        this.dataExponential.checked = false;
      break;
      case 'gaussian':
        this.dataLaplace.checked = false;
        this.dataGaussian.checked = true;
        this.dataExponential.checked = false;
      break;
      case 'exponential':
        this.dataLaplace.checked = false;
        this.dataGaussian.checked = false;
        this.dataExponential.checked = true;
      break;
      default:
        break;
    }
  }

  onMechanismChange(value: 'none' | 'laplace' | 'gaussian' | 'exponential') {
    this.mechanismSelected = value;
    this.emitData()
  }

  onCounterMechanismChange(value: any, counter: string, mechanism: string) {
    if (mechanism === 'laplace') {
      if (counter === 'epsilon') {
        this.epsilonLaplaceCounter = value;
        this.emitData()
        return;
      } else if (counter === 'delta') {
        this.deltaLaplaceCounter = value;
        this.emitData()
        return;
      }
    }

    if (mechanism === 'gaussian') {
      if (counter === 'epsilon') {
        this.epsilonGaussianCounter = value;
        this.emitData()
        return;
      } else if (counter === 'delta') {
        this.deltaGaussianCounter = value;
        this.emitData()
        return;
      }
    }

    if (mechanism === 'exponential') {
      if (counter === 'epsilon') {
        this.epsilonExponentialCounter = value;
        this.emitData()
        return;
      }
    }
    this.emitData()
  }

  onCounterSensitivityChange(value: any, sensitivity: string) {
    if (sensitivity === 'maxDataSize') {
      this.maxDataSizeCounter = value;
      this.emitData()
      return;
    } else if (sensitivity === 'iterations') {
      this.iterationsCounter = value;
      this.emitData()
      return;
    }
  }

  dropdownSelected(event: any, type: string) {
    if (event.value) {
      if (type === 'calculationMethod') {
        this.calculationMethodSelected = event.value;
        this.emitData()
        return;
      } else if (type === 'calculateBasedOn') {
        this.calculateBasedOnSelected = event.value;
        this.emitData()
        return;
      }
    }
  }

  onSliderChange(value: any) {
    this.gammaCounter = value.toString();
    this.emitData();
  }
}
