import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'trunc'})
export class TruncatePipe implements PipeTransform {
  transform(value: string | undefined, chars: number = 240): string | undefined {
    if (value === undefined) {
      return value;
    }

    if (value.length <= chars) {
      return value;
    }

    // Encuentra la última posición de espacio antes del límite de caracteres.
    const lastSpaceIndex = value.lastIndexOf(' ', chars);

    // Trunca la cadena en la última palabra completa antes del límite y agrega '...'.
    return `${value.slice(0, lastSpaceIndex)}...`;
  }
}
