import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DockerImage, Node, SpotNodeResponse } from '../models';
import { catchError, concatMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { combineLatest, from, Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { sortObjectArrayByConfigName } from 'src/app/utils/dom-utils';
import { isEnabled, isAvailable, isLinked } from 'src/app/utils/node-utils';
import { Plan, ValidDockerImages } from '../models/plan';

const headers = new HttpHeaders({'Content-Type':'application/json'});
@Injectable({
  providedIn: 'root',
})
export class NodeService {
  baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) {}

  // Gest list of nodes
  getNodeList(org: string | undefined): Observable<any> {
    const params = new HttpParams()
      .set('page', '0')
      .set('pageSize', '100')
      .set('pagination', false);

    return this.http.get<{ pageSize: number; page: number; items: Node[] }>(
        `${this.baseUrl}orgs/${org}/nodes/`,
        { headers, params }
      )
      .pipe(
        switchMap((nodes) => {
          nodes.items = sortObjectArrayByConfigName(nodes.items);
          return of(nodes);
        }),
        catchError((res) => {
          return throwError(res?.error?.message);
        })
      );
  }

  getNode(suid: string | null | undefined, org: string | undefined): Observable<Node> {
    return this.http.get<Node>(
      `${this.baseUrl}orgs/${org}/nodes/${suid}`,
      { headers }
    ).pipe(
      catchError(error => {
        return throwError(error)
      })
    )
  }

  getNodeStructure(suid: string | null | undefined, org: string | undefined): Observable<any> {
    return this.http.get<Node>(
      `${this.baseUrl}orgs/${org}/nodes/${suid}/analytics`,
      { headers }
    ).pipe(
      catchError(error => {
        return throwError(error)
      })
    )
  }

  spotNode(
    org: any,
    name: string,
    coordinates: string,
    metaData: any
  ): Observable<SpotNodeResponse> {
    const params = {
      metaData,
      config: {
          name,
          description: name.split('-')[0],
          tags: [name.split('-')[0]]
      },
      location: {
          geoIpLocation: true,
          autoGeoIpLocation: coordinates ? false : true,
          coordinates: coordinates
      }
    }

    return this.http
      .post<SpotNodeResponse>(`${this.baseUrl}admin/orgs/${org}/spotNodes`, params)
      .pipe(
        tap((res) => res),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  linkNodes(
    org: any,
    project: any,
    session: any,
    nodes: Node[]
  ) {
    const linkNodeCalls = nodes.map((node) => {
      return this.linkNode(org, project, session, node.nodeSuid);
    });
    return combineLatest(linkNodeCalls);
  }

  linkNode(
    org: any,
    project: any,
    session: any,
    nodeId: string
  ) {
    const params = {
      projectSuid: project,
      trainingSessionTimestamp: session,
    };

    return this.http
      .post(`${this.baseUrl}orgs/${org}/nodes/${nodeId}/link`, params)
      .pipe(
        tap((res) => res),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  loadDockerImageUrl(imageSuid: string, orgSuid: string): Observable<DockerImage> {
    return this.http.get<DockerImage>(
      `${this.baseUrl}orgs/${orgSuid}/nodes/images/${imageSuid}`,
      { headers }
    )
    .pipe(
      map((res) => res as DockerImage),
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }

  getEnabledNodes(nodes: Node[]) {
    const enabledNodes = nodes?.filter(
      (node) => node?.location && isEnabled(node?.entityStatus)
    ).length || 0;
    return enabledNodes;
  }

  getAvailableNodes(nodes: Node[], firstProjectTag: string = '', trainingModel: string = '') {
    const availableNodes = nodes?.filter(
      (node) => {
        const nodetags = node?.config?.tags ? node.config.tags : []
        return node?.location &&
        isEnabled(node?.entityStatus) &&
        isAvailable(node.isSherpa, nodetags, firstProjectTag, trainingModel) &&
        !isLinked(node?.entityStatus)
      }).length || 0;
    return availableNodes;
  }

  getAvailableNodesList(nodes: Node[], firstProjectTag: string = '', trainingModel: string = ''): Node[] {
    const availableNodes = nodes?.filter(
      (node) => {
        const nodetags = node?.config?.tags ? node.config.tags : []
        return node?.location &&
        isEnabled(node?.entityStatus) &&
        isAvailable(node.isSherpa, nodetags, firstProjectTag, trainingModel) &&
        !isLinked(node?.entityStatus)
      });
    return availableNodes;
  }

  getNotAvailableNodes(nodes: Node[], firstProjectTag: string = '', trainingModel: string = '') {
    const notAvailableNodes = nodes?.filter(
      (node) => {
        const nodetags = node?.config?.tags ? node.config.tags : []
        return !node?.location ||
        !isEnabled(node?.entityStatus) ||
        !isAvailable(node.isSherpa, nodetags, firstProjectTag, trainingModel) ||
        isLinked(node?.entityStatus)
      }).length || 0;
    return notAvailableNodes;
  }

  getDisabledNodes(nodes: Node[]) {
    const disabledNodes = nodes?.filter(
      (node) => node?.location && !isEnabled(node?.entityStatus)
    ).length || 0;
    return disabledNodes;
  }

  pingNode(orgSuid?: string, nodeSuid?: string) : Observable<any> {
    const params = new HttpParams()
      .set('page', '0')
      .set('pageSize', '100')
      .set('pagination', false);

    return this.http.post<{ pageSize: number; page: number; items: Node[] }>(
        `${this.baseUrl}orgs/${orgSuid}/nodes/${nodeSuid}/ping`,
        {},
        { headers, params }
      )
      .pipe(
        tap((res) => res),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  deleteNode(orgSuid?: string, nodeSuid?: string) : Observable<any> {
    const params = new HttpParams().set('deleteConfig', 'countdown');
    return this.http.delete<any>(
      `${this.baseUrl}orgs/${orgSuid}/nodes/${nodeSuid}`,
      {headers, params}
    )
    .pipe(
      catchError(res => {
        return throwError(res?.error);
      })
    )
  }

  getPlanByOrg(orgSuid: string): Observable<Plan> {
    return this.http.get<Plan>(
      `${this.baseUrl}orgs/${orgSuid}/plan?description=true`,
      { headers }
    )
    .pipe(
      map((res) => res),
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }

  getDockerImageInfo(orgSuid: string, imageSuid: string): Observable<DockerImage> {
    return this.http.get<DockerImage>(
      `${this.baseUrl}orgs/${orgSuid}/nodes/images/${imageSuid}/info`,
      { headers }
    )
    .pipe(
      map((res) => res),
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }

}
