import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-location-16',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10 6.5C10 7.60457 9.10457 8.5 8 8.5C6.89543 8.5 6 7.60457 6 6.5C6 5.39543 6.89543 4.5 8 4.5C9.10457 4.5 10 5.39543 10 6.5ZM9 6.5C9 7.05228 8.55228 7.5 8 7.5C7.44772 7.5 7 7.05228 7 6.5C7 5.94772 7.44772 5.5 8 5.5C8.55228 5.5 9 5.94772 9 6.5Z" fill="#1F1F1F"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1677 12.0543L8 16L3.83234 12.0543C2.34345 10.6447 1.5 8.68466 1.5 6.63435V6.5C1.5 2.91015 4.41015 0 8 0C11.5899 0 14.5 2.91015 14.5 6.5V6.63435C14.5 8.68466 13.6565 10.6447 12.1677 12.0543ZM13.5 6.5V6.63435C13.5 8.40996 12.7696 10.1074 11.4802 11.3281L8 14.6229L4.51985 11.3281C3.23044 10.1074 2.5 8.40996 2.5 6.63435V6.5C2.5 3.46243 4.96243 1 8 1C11.0376 1 13.5 3.46243 13.5 6.5Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class IconLocation16 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
