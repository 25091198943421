import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from 'src/app/auth/models/auth.models';
import { ProjectActions } from 'src/app/platform';
import { ListItem } from 'src/app/shared/components/list-items/list-item.model';

@Component({
  selector: 'shai-header',
  template: `
    <header>
      <w-wrapper-fluid>
        <a (click)="handleBrandClick()" class="brand-wrapper">
          <img src="assets/images/logo.png" alt="Logo"/>
          <shai-beta-svg></shai-beta-svg>
        </a>
        <div *ngIf="this.creationProjectName && this.router.url.indexOf('/project/new') !== 1" class="creation-project-name">{{creationProjectName}}</div>
        <nav>
          <ul>
            <li
              (click)="toggleFloatingMenu()"
              cdkOverlayOrigin
              #trigger="cdkOverlayOrigin"
            >
              <a>{{ userName }}</a>
            </li>
            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="trigger"
              [cdkConnectedOverlayOpen]="isFloatingActive"
              [cdkConnectedOverlayPositions]="positionPairs"
              [cdkConnectedOverlayHasBackdrop]="true"
              [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
              (backdropClick)="isFloatingActive = false"
            >
              <shai-floating-menu
                #floatingElement
                (handleItemClick)="onListItemClick($event)"
                [listItems]="listItems"
                width="120"
                (onChange)="this.isFloatingActive = $event"
                [active]="isFloatingActive"
              >
              </shai-floating-menu>
            </ng-template>
          </ul>
        </nav>
      </w-wrapper-fluid>
    </header>
  `,
  styleUrls: [`./header.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  listItems: Array<ListItem> = [];

  @Input() creationProjectName : string | null = null;
  @Input() userName!: string | undefined;

  @Output() onLogout = new EventEmitter();
  isFloatingActive: boolean = false;
  positionPairs: ConnectionPositionPair[] = [
    {
      offsetX: 5,
      offsetY: 30,
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'top',
      // panelClass: 'user-menu',
    },
  ];
  constructor(
    public router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.listItems = [
      {
        'type': 'body',
        'title': 'Logout',
        'value': 'logout',
      },
    ] as ListItem[];
  }

  handleBrandClick() {
    this.store.dispatch(ProjectActions.clearCurrent({ route: '/' }))
  }

  onListItemClick(event: any) {
    if (event.value === 'logout') {
      this.onLogout.emit();
    }
    this.isFloatingActive = false;
  }

  toggleFloatingMenu() {
    this.isFloatingActive = !this.isFloatingActive;
  }
}
