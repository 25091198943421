import { createAction, props, union } from "@ngrx/store";
import { DockerImage, NodeList } from "../models";

export const loadNodes = createAction('[Nodes] Get nodes')
export const loadNodesSuccess = createAction('[Nodes] Get nodes success', props<{ list: NodeList }>());
export const loadNodesFailure = createAction('[Nodes] Get nodes failure', props<{ error: any }>());

export const loadDockerImageUrl = createAction('[Nodes] Load docker image url', props<{ imageSuid: string }>());
export const loadDockerImageUrlSuccess = createAction('[Nodes] Load docker image url success', props<{ image: DockerImage}>());
export const loadDockerImageUrlFailure = createAction('[Nodes] Load docker image url failure', props<{ error: any }>());
export const clearLoadDockerImageUrl = createAction('[Nodes] Clear load docker image url');

export const pingNode = createAction('[Nodes] Ping node', props<{ suid: string | undefined }>())
export const pingNodeSuccess = createAction('[Nodes] Ping node success')
export const pingNodeFailure = createAction('[Nodes] Ping node failure', props<{ error: any }>())

export const deleteNode = createAction('[Nodes] Delete node', props<{ nodeSuid: string }>())
export const deleteNodeSuccess = createAction('[Nodes] Delete node success', props<{ nodeSuid: string }>())
export const deleteNodeFailure = createAction('[Nodes] Delete node failure', props<{ error: any }>())

const actionsUnion = union({
  loadNodes,
  loadNodesSuccess,
  loadNodesFailure,
  loadDockerImageUrl,
  loadDockerImageUrlSuccess,
  loadDockerImageUrlFailure,
  clearLoadDockerImageUrl,
  pingNode,
  pingNodeSuccess,
  pingNodeFailure,
  deleteNode,
  deleteNodeSuccess,
  deleteNodeFailure
})

export type NodeActionsUnion = typeof actionsUnion