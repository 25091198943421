interface Coordinates {
  latitude:	number;
  longitude: number;
}

export const getCoordinatesFromString = (value: string) => {
  const coordinatesValues = value.split(',');

  let coordinates: Coordinates = {
    latitude: parseFloat(coordinatesValues[0]) ? parseFloat(coordinatesValues[0]) : 0,
    longitude: parseFloat(coordinatesValues[1]) ? parseFloat(coordinatesValues[1]) : 0,
  }

  return coordinates;
}
