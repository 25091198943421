import { createAction, props, union } from '@ngrx/store';
import { InferenceFiles, InferencesList } from '../models';

export const saveFilesForInference = createAction('[Inference] Save files for inference', props<{ node: string; round: number, paradigmId: string }>());
export const saveFilesForInferenceSuccess = createAction('[Inference] Save files for inference success');
export const saveFilesForInferenceFailure = createAction('[Inference] Save files for inference failure', props<{ error?: any }>());
export const clearSaveFilesForInference = createAction('[Inference] Clear data for save files for inference status');

export const getSavedModelsForInference = createAction('[Inference] Get saved models for inferences', props<{ paradigmId: string }>());
export const getSavedModelsForInferenceSuccess = createAction('[Inference] Get saved models for inferences success', props<{ savedModelsList: InferenceFiles }>());
export const getSavedModelsForInferenceFailure = createAction('[Inference] Get saved models for inferences failure', props<{ error?: any }>());

export const getAllInferences = createAction('[Inference] Get all inferences');
export const getAllInferencesSuccess = createAction('[Inference] Get all inferences success', props<{ list: InferencesList }>());
export const getAllInferencesFailure = createAction('[Inference] Get all inferences failure', props<{ error: any }>() );
export const clearInferences = createAction('[Inference] Clear inferences list');

export const deleteInferences = createAction('[Inference] Delete inferences', props<{ inferences: any[]}>());
export const deleteInferencesSuccess = createAction('[Inference] Delete inferences success');
export const deleteInferencesFailure = createAction('[Inference] Delete inferences failure', props<{error: any;}>());
export const clearDeleteInferences = createAction('[Inference] Clear delete inferences');

const actionsUnion = union({
  saveFilesForInference,
  saveFilesForInferenceSuccess,
  saveFilesForInferenceFailure,
  clearSaveFilesForInference,
  getAllInferences,
  getAllInferencesSuccess,
  getAllInferencesFailure,
  clearInferences,
  getSavedModelsForInference,
  getSavedModelsForInferenceSuccess,
  getSavedModelsForInferenceFailure,
  deleteInferences,
  deleteInferencesSuccess,
  deleteInferencesFailure,
  clearDeleteInferences
});

export type InferenceActionsUnion = typeof actionsUnion;
