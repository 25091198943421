import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ExtraCellElement } from '../../models/extra-cell-element';
import { AdComponent } from '../../models/table.model';

@Component({
    selector: 'shai-table-cell-single',
    template: `
        <div class="table-cell-single-container">
            <div class="table-cell-single">
                <ng-content select="[left]" class="left"></ng-content>
                <ng-content select="[title]" class="paragraph-bold"></ng-content>
                <p *ngIf="!data" class="paragraph"><ng-content></ng-content></p>
                <p *ngIf="data" [ngStyle]="{'color': data.color}" class="paragraph {{ data.extraClass ? data.extraClass : ''}}" title="{{ data.tagsColumn ? data.text : '' }}">{{ data.text }}</p>
                <ng-content select="[right]" class="right paragraph-small"></ng-content>
            </div>
        </div>
    `,
    styleUrls: [`./table-cell-single.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCellSingleComponent implements AdComponent {
  @Input() data: any = null;
  @Input() color: string = 'var(--color-primary);';
  @Input() extraCellElements: ExtraCellElement[] = [];

  constructor() { }
}
