import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-document-32',
  template: `
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.5 0.5H20.9129L27.5 8.18496V31.5H4.5V0.5Z" stroke="#5F6167" />
      <path d="M21 1V9H27" stroke="#5F6167" />
      <line x1="10" y1="17.5" x2="18" y2="17.5" stroke="#5F6167" />
      <line x1="10" y1="23.5" x2="22" y2="23.5" stroke="#5F6167" />
    </svg>
  `,
})
export class IconDocument32 {
  @HostBinding('class.shai-icon') get iconClass() {
    return true;
  }
}
