import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-llm-32',
  template: `
    <svg
      width="25"
      height="28"
      viewBox="0 0 25 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="12.0167"
        y1="3.33334"
        x2="12.0167"
        y2="25"
        stroke="#0073BF"
        stroke-width="0.7"
      />
      <line
        x1="2.68791"
        y1="8.03806"
        x2="21.0212"
        y2="19.7047"
        stroke="#0073BF"
        stroke-width="0.7"
      />
      <line
        x1="2.32778"
        y1="19.6953"
        x2="21.4944"
        y2="8.86197"
        stroke="#0073BF"
        stroke-width="0.7"
      />
      <circle
        cx="12.0834"
        cy="13.75"
        r="5.06667"
        fill="#84D1DF"
        stroke="#0073BF"
        stroke-width="0.7"
      />
      <path
        d="M2.69053 8.32707L12.0833 2.90415L21.4761 8.32707V19.1729L12.0833 24.5959L2.69053 19.1729V8.32707Z"
        stroke="#0073BF"
        stroke-width="0.7"
      />
      <circle
        cx="2.91667"
        cy="7.91667"
        r="2.56667"
        fill="white"
        stroke="#0073BF"
        stroke-width="0.7"
      />
      <circle
        cx="12.0834"
        cy="2.91667"
        r="2.56667"
        fill="#84D1DF"
        stroke="#0073BF"
        stroke-width="0.7"
      />
      <circle
        cx="12.0834"
        cy="24.5833"
        r="2.56667"
        fill="white"
        stroke="#0073BF"
        stroke-width="0.7"
      />
      <circle
        cx="22.0834"
        cy="7.91667"
        r="2.56667"
        fill="white"
        stroke="#0073BF"
        stroke-width="0.7"
      />
      <circle
        cx="22.0834"
        cy="19.5833"
        r="2.56667"
        fill="#84D1DF"
        stroke="#0073BF"
        stroke-width="0.7"
      />
      <circle
        cx="2.91667"
        cy="19.5833"
        r="2.56667"
        fill="#84D1DF"
        stroke="#0073BF"
        stroke-width="0.7"
      />
    </svg>
  `,
})
export class IconLLM32 {
  @HostBinding('class.shai-icon') get iconClass() {
    return true;
  }
}
