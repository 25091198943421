import { createAction, props, union } from "@ngrx/store";
import { GlobalConfig } from "..";

export const loadConfig = createAction('[Config] Load config')
export const loadConfigSuccess = createAction('[Config] Load config success', props<{config: GlobalConfig}>())
export const loadConfigFailure = createAction('[Config] Load config failure', props<{error: any}>())

const actionsUnion = union({
  loadConfig,
  loadConfigSuccess,
  loadConfigFailure
})

export type ConfigActionsUnion = typeof actionsUnion