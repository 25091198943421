import { Injectable } from '@angular/core';
import { AuthService, UserRole } from 'src/app/auth/services/auth.service';
import { AccessControl } from '../models/access-control';
import { expUserAccessControlsValues } from '../models/exp-role-access.data';
import { expProUserAccessControlsValues } from '../models/exp-pro-role-access.data';
import { orgAdminUserAccessControlsValues } from '../models/org-admin-role-access.data';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromPlatform from 'src/app/platform/reducers';

@Injectable({ providedIn: 'root' })
export class AccessControlService {
  capabilities: string[] = [];
  private plan$: Observable<any> = new Observable();
  constructor(
    private _authService: AuthService,
    private store: Store
  ) {
    this.plan$ = this.store.pipe(select(fromPlatform.getPlan)) as Observable<string>;
    this._setPlanSubscription();
  }

  private _setPlanSubscription() {
    this.plan$.subscribe((plan) => {
      if(plan && plan?.capabilities) {
        this.capabilities = plan.capabilities;
      }
    })
  }

  checkAccessControl(
    componentType: string,
    accessType: string
  ): boolean | null {
    const accessControls = this.getAccessControlValues();
    const component: any = accessControls.find(
      (access) => access.component === componentType
    );
    return component ? component[accessType] : null;
  }

  newCheckAccessControl(
    capability: string
  ): boolean {
    return !this.capabilities.includes(capability);
  }

  private getAccessControlValues(): AccessControl[] {
    //TODO: Get Access controls from Redux
    //Values should be requested to API
    const role = this._authService.getUserRoleFromIdToken();
    switch (role) {
      case UserRole.ExpUser:
        return expUserAccessControlsValues;
      case UserRole.ExpProUser:
        return expProUserAccessControlsValues;
      case UserRole.OrgAdmin:
        return orgAdminUserAccessControlsValues;
    }
  }

  getBaseUrlByUserRole(): string {
    const role = this._authService.getUserRoleFromIdToken();
    switch (role) {
      case UserRole.ExpUser:
        return 'https://docs.sherpa.ai';
      case UserRole.ExpProUser:
        return 'https://docs.sherpa.ai';
      case UserRole.OrgAdmin:
        return 'https://docs.sherpa.ai';
    }
  }
}
