import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'shai-button-icon',
  template: `
    <ng-template #content><ng-content></ng-content></ng-template>
    <button class="button-settings"
      *ngIf="!href"
      [type]="type"
      [disabled]="disabled"
      [routerLink]="route">
        <ng-content select="[icon-pre]"></ng-content>
        <ng-container *ngTemplateOutlet="content"></ng-container>
        <ng-content select="[icon-post]"></ng-content>
    </button>
    <a *ngIf="href"
       [href]="href"
       [target]="target ? target : '_self'">
        <ng-content select="[icon-pre]"></ng-content>
        <ng-container *ngTemplateOutlet="content"></ng-container>
        <ng-content select="[icon-post]"></ng-content>
    </a>
  `,
  styleUrls: [`./button-icon.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonIconComponent {
  @Input() href: string | undefined;
  @Input() route: Array<string> | undefined;
  @Input() target: string = '_self';
  @Input() type: string = 'button';
  @Input() outline: boolean = false;
  @Input() disabled = false;
  @Input() buttonStyle : 'primary' | 'secondary' | 'secondary-destructive' | 'tertiary' | 'link' | 'light' | 'light-outline' | 'danger' = 'primary';
  @HostBinding('class') get reducedClass() {
    return `button-${this.buttonStyle}`;
  }

  @HostBinding('class.button-outline') get outlineClass() {
    return this.outline;
  }
}
