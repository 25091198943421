import { ChangeDetectionStrategy, Component } from '@angular/core';
import { fadeIn } from 'src/app/shared/animations';
@Component({
  selector: 'shai-reset-mfa-error',
  animations: [fadeIn],
  template: `
    <div id="reset-mfa-error-wrapper" @fadeIn>
      <div class="error-icon-container">
        <shai-icon-big-warning> </shai-icon-big-warning>
      </div>
      <shai-title-bar
        [showExtraSpace]="false"
        title="Verification failed"
        bottomDescription="The code doesn’t match.<br>We must ask you to go through the whole sign in process again. Remember that the verification code has expired.">
      </shai-title-bar>
      <shai-action-bar>
        <ng-container left-cell>
          <shai-button
            [type]="'button'"
            [stretch]="true"
            [route]="['/welcome']"
            buttonStyle="primary"
          >Go to login</shai-button>
        </ng-container>
      </shai-action-bar>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./reset-mfa-error.component.scss']
})
export class ResetMfaErrorComponent {
}
