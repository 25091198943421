import { CdkStep } from '@angular/cdk/stepper';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { fadeIn } from 'src/app/shared/animations';
import { OverlayQuestionModel } from 'src/app/shared/components/overlay.component';
import { StepState } from 'src/app/shared/models/step-state';
import { AuthActions } from '../state/actions';

@Component({
  selector: 'shai-reset-password',
  animations: [fadeIn],
  template: `
    <shai-overlay
      [active]="isActive"
      [complete]="isComplete"
      [fullWidth]="true"
      [hasHeader]="true"
      heading=""
      [closeQuestion]="closeQuestion"
      (onChange)="onChangeOverlay($event)"
      (onLeave)="onLeaveOverlay()"
    >
      <div @fadeIn>
        <shai-stepper
          [defaultCloseQuestion]="defaultCloseQuestion"
          [closeQuestionList]="closeQuestionList"
          (closeQuestionOnChange)="closeQuestionOnChange($event)">
          <cdk-step #step1>
            <shai-reset-password-step-one
              [step]="step1"
              (onChange)="stepOnChange($event)"
            >
            </shai-reset-password-step-one>
          </cdk-step>
          <cdk-step #step2>
            <shai-reset-password-step-two
              [step]="step2"
              (onChange)="stepOnChange($event)"
              (resetPassComplete)="onResetPassComplete($event)"
            >
            </shai-reset-password-step-two>
          </cdk-step>
          <cdk-step #step3>
            <shai-reset-password-changed
              [step]="step3"
              (onChange)="stepOnChange($event)"
              [isResetPassComplete]="isResetPassComplete">
            </shai-reset-password-changed>
          </cdk-step>
        </shai-stepper>
      </div>
    </shai-overlay>
  `,
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  isResetPassComplete = false;
  @Output() onChange = new EventEmitter();
  @Input() step: CdkStep | undefined;
  isValidFileSelection = null;
  isActive: boolean = true;
  isComplete: boolean = false;
  closeQuestion: OverlayQuestionModel = {
    title: "Abort reset password?"
  }

  defaultCloseQuestion: OverlayQuestionModel | undefined = {
    title: 'Are you sure?',
  };
  currentCloseQuestion?: OverlayQuestionModel;
  closeQuestionList: OverlayQuestionModel[] = [
    {
      title: 'Quit creating a new project?',
    },
    {
      title: 'Files are being uploaded. Abort?',
      subtitle: "These files won't be saved.",
    },
  ];

  constructor(
    private cdRef: ChangeDetectorRef,
    private store: Store,
  ) { }

  ngOnInit(): void { }

  closeQuestionOnChange(question: OverlayQuestionModel) {
    this.currentCloseQuestion = question;
  }

  onResetPassComplete(value: boolean) {
    this.isResetPassComplete = value;
  }

  stepOnChange(event: any) {
    switch (event.state) {
      case StepState.cancel:
        this.isActive = false;
        break;
      case StepState.close:
        this.isActive = false;
        break;
      case StepState.next:
        break;
      case StepState.finish:
        this.isComplete = true;
        break;
    }
  }

  onCancelOverlay(event: boolean) {
    if (!event) {
      this.finishProcess();
      this.store.dispatch(AuthActions.clearReset());
    }
  }

  private finishProcess() {
    this.onChange.emit(false);
    this.cdRef.detectChanges();
  }

  onChangeOverlay(event: boolean) {
    //TODO show snackbar
    if (!event) {
      this.finishProcess();
    } else {
      this.isActive = true;
    }
  }

  onLeaveOverlay() { }
}
