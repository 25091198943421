import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-id3-32',
  template: `
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.38579 9.29251C4.23349 7.79822 4.55744 6.29403 5.31135 4.99491C6.06525 3.69578 7.21052 2.66822 8.58348 2.05906C9.95644 1.44991 11.4868 1.29034 12.9559 1.60317C14.425 1.91599 15.7576 2.6852 16.7633 3.80086C17.769 4.91652 18.3963 6.32152 18.5555 7.81509C18.7147 9.30865 18.3978 10.8143 17.6499 12.1169C16.9021 13.4196 15.7616 14.4524 14.3915 15.068C13.0213 15.6835 11.4917 15.8502 10.0212 15.5442"
        stroke="#84D1DF"
        stroke-linecap="square"
      />
      <path
        d="M13.2498 8.34312C11.7555 8.19083 10.2513 8.51478 8.95218 9.26869C7.65306 10.0226 6.62549 11.1679 6.01634 12.5408C5.40719 13.9138 5.24762 15.4442 5.56044 16.9133C5.87327 18.3823 6.64248 19.715 7.75813 20.7206C8.87379 21.7263 10.2788 22.3536 11.7724 22.5128C13.2659 22.6721 14.7716 22.3551 16.0742 21.6073C17.3768 20.8594 18.4097 19.7189 19.0252 18.3488C19.6408 16.9787 19.8074 15.4491 19.5014 13.9785"
        stroke="#0073BF"
        stroke-linecap="square"
      />
      <path
        d="M2.9021 8.61911L4.33861 10.0556L5.77513 8.61908"
        stroke="#84D1DF"
      />
      <path
        d="M12.4789 9.57675L13.9154 8.14024L12.4789 6.70373"
        stroke="#0073BF"
      />
    </svg>
  `,
})
export class IconID332 {
  @HostBinding('class.shai-icon') get iconClass() {
    return true;
  }
}
