import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum SnackbarType {
  simple = 'simple',
  oneButton = 'one-button',
  twoButtons = 'two-buttons',
  twoButtonsInverted = 'two-buttons-inverted',
  twoButtonsDestructive = 'two-buttons-destructive',
}

export interface Snackbar {
  title: string;
  type: SnackbarType;
  description?: string;
  autoDestroy?: boolean;
  primaryButtonText?: string; //right button
  cancelButtonText?: string; //left button
  hideCloseButton?:boolean;
  primaryButtonHandler?: () => void;
  cancelButtonHandler?: () => void;
  icon?: 'icon-federated-vertical-24' | 'icon-federated-horizontal-24';
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private snackbarSubject = new Subject<Snackbar | null>();
  public snackbarState = this.snackbarSubject.asObservable();

  constructor() {}

  show(
    title: string,
    type: SnackbarType,
    description?: string,
    autoDestroy?: boolean,
    primaryButtonText?: string,
    cancelButtonText?: string,
    primaryButtonHandler?: () => void,
    cancelButtonHandler?: () => void,
    hideCloseButton?: boolean,
    icon?: 'icon-federated-vertical-24' | 'icon-federated-horizontal-24'
  ) {
    const snackbarInfo: Snackbar = {
      title,
      description,
      type,
      autoDestroy,
      primaryButtonText: primaryButtonText || 'Button',
      cancelButtonText: cancelButtonText || 'Cancel',
      hideCloseButton: hideCloseButton || false,
      primaryButtonHandler,
      cancelButtonHandler,
      icon
    };
    this.snackbarSubject.next(snackbarInfo);
  }

  close() {
    this.snackbarSubject.next(null);
  }
}
