import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangePasswordComponent } from './containers/change-password.component';
import { EnableTfaComponent } from './containers/enable-tfa.component';
import { PasswordChangedComponent } from './containers/password-changed.component';
import { PasswordVerifiedComponent } from './containers/password-verified.component';
import { SigninPageComponent } from './containers/sign-in-page.component';
import { SignInTfaComponent } from './containers/sign-in-tfa.component';
import { VerifyTfaComponent } from './containers/verify-tfa.component';
import { ResetMfaComponent } from './containers/reset-mfa.component';
import { NotLoggedGuard } from './guards/notLogged.guard';
import { SignInMFAGuard } from './guards/signInMFA.guard';
import { ResetMfaSuccessComponent } from './containers/reset-mfa-success.component';
import { ResetMfaErrorComponent } from './containers/reset-mfa-error.component';
import { ResetMFAGuard } from './guards/resetMFA.guard';

const routes: Routes = [
  {
    path: 'welcome',
    component: SigninPageComponent
  },
  {
    path: 'auth/change-password',
    component: ChangePasswordComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'auth/password-changed',
    component: PasswordChangedComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'auth/password-verified',
    component: PasswordVerifiedComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'auth/enable-tfa',
    component: EnableTfaComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'auth/verify-tfa',
    component: VerifyTfaComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'auth/sign-in-tfa',
    component: SignInTfaComponent,
    canActivate: [SignInMFAGuard]
  },
  {
    path: 'auth/reset-mfa',
    component: ResetMfaComponent,
    canActivate: [ResetMFAGuard]
  },
  {
    path: 'auth/reset-mfa-success',
    component: ResetMfaSuccessComponent,
    canActivate: [ResetMFAGuard]
  },
  {
    path: 'auth/reset-mfa-error',
    component: ResetMfaErrorComponent,
    canActivate: [ResetMFAGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
