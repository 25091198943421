import { createReducer, on } from '@ngrx/store';
import { InferenceFiles, InferencesList, Status } from '../models';
import * as InferenceActions from '../actions/inference.actions';

export const inferenceFeatureKey = 'inference';

export interface State {
  list?: InferencesList,
  listStatus: Status,
  saveFilesForInferenceStatus: Status;
  savedModelsList: InferenceFiles;
  savedModelsListStatus: Status;
  deleteStatus: Status;
}

const initialState: State = {
  list: { page: 0, pageSize: 0, totalItemCount: 0, items: [] },
  listStatus: { pending: false },
  saveFilesForInferenceStatus: { pending: false },
  savedModelsList: { files: [] },
  savedModelsListStatus: { pending: false },
  deleteStatus: { pending: false }
};

export const reducer = createReducer(
  initialState,

  on(InferenceActions.saveFilesForInference, (state) => ({ ...state, saveFilesForInferenceStatus: { pending: true, error: null }})),
  on(InferenceActions.saveFilesForInferenceSuccess, (state) => ({ ...state, saveFilesForInferenceStatus: { pending: false, error: null }})),
  on(InferenceActions.saveFilesForInferenceFailure, (state, { error }) => ({ ...state, saveFilesForInferenceStatus: { pending: false, error }})),
  on(InferenceActions.clearSaveFilesForInference, (state) => ({ ...state, saveFilesForInferenceStatus: initialState.saveFilesForInferenceStatus })),

  on(InferenceActions.getAllInferences, (state) => ({ ...state, listStatus: {...state.listStatus, pending: true}})),
  on(InferenceActions.getAllInferencesSuccess, (state, { list }) => ({...state, list ,listStatus: {...state.listStatus, pending: false}})),
  on(InferenceActions.getAllInferencesFailure, (state, error) => ({ ...state, listStatus: {...state.listStatus, pending: false, error}})),
  on(InferenceActions.clearInferences, state => ({ ...state, list: initialState.list })),

  on(InferenceActions.getSavedModelsForInference, (state) => ({ ...state, savedModelsListStatus: {...state.savedModelsListStatus, pending: true}})),
  on(InferenceActions.getSavedModelsForInferenceSuccess, (state, { savedModelsList }) => ({...state, savedModelsList ,savedModelsListStatus: {...state.savedModelsListStatus, pending: false}})),
  on(InferenceActions.getSavedModelsForInferenceFailure, (state, error) => ({ ...state, savedModelsListStatus: {...state.savedModelsListStatus, pending: false, error}})),

  on(InferenceActions.deleteInferences, state => ({ ...state, deleteStatus: { pending: true, error: null } })),
  on(InferenceActions.deleteInferencesSuccess, state => ({ ...state, deleteStatus: { pending: false, error: null } })),
  on(InferenceActions.deleteInferencesFailure, (state, {error}) => ({ ...state, deleteStatus: { pending: false, error }})),
  on(InferenceActions.clearDeleteInferences, state => ({ ...state, deleteStatus: initialState.deleteStatus })),

  );

export const saveFilesForInferenceStatus = (state: State) => state?.saveFilesForInferenceStatus;
export const getAllInferences = (state: State) => state?.list;
export const getSavedModelsForInference = (state: State) => state?.savedModelsList;
export const savedModelsListStatus = (state: State) => state?.savedModelsListStatus;
export const getDeleteInferenceStatus = (state: State) => state?.deleteStatus;
