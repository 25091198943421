import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'qrFormatter'
})
export class QrFormatterPipe implements PipeTransform {

  transform(arrayQrCode: string[]): string {
    let result = '';
    const parts = [];
    for(let i = 0; i < arrayQrCode.length; i += 4){
      parts.push(arrayQrCode.slice(i, i + 4).join(''));
    }
    result = parts.join(' ');
    return result;
  }

}