import { EventEmitter, Injectable, Output } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FileName, Project, ProjectResponse } from "../models";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { combineLatest, Observable, of, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { FileList } from "../models";
import { sortObjectArrayByConfigName } from "src/app/utils/dom-utils";

const headers = new HttpHeaders({'Content-Type':'application/json'});
@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  baseUrl: string = environment.baseUrl;

  constructor(
    private http: HttpClient
  ) {
  }

  createProject(name: string, description: string, org: string | undefined): Observable<ProjectResponse> {
    return this.http.post<Project>(
      `${this.baseUrl}orgs/${org}/projects`,
      { name, description },
      { headers }
    )
    .pipe(
      catchError(res => {
        return throwError(res?.error)
      })
    )
  }

  updateProject(suid: string, name: string, description: string, org: string | undefined): Observable<ProjectResponse> {
    return this.http.put<Project>(
      `${this.baseUrl}orgs/${org}/projects/${suid}`,
      { name, description },
      { headers }
    ).pipe(
      catchError(res => {
        return throwError(res?.error)
      })
    )
  }

  deleteProject(orgSuid?: string, projectSuid?: string) : Observable<any> {
    return this.http.delete<any>(
      `${this.baseUrl}orgs/${orgSuid}/projects/${projectSuid}`,
      { headers }
    ).pipe(
      catchError(res => {
        return throwError(res?.error);
      })
    )
  }

  loadProject(suid: string | null | undefined, org: string | undefined): Observable<Project> {
    return this.http.get<Project>(
      `${this.baseUrl}orgs/${org}/projects/${suid}`,
      { headers }
    ).pipe(
      catchError(error => {
        return throwError(error)
      })
    )
  }

  loadProjectList(orgSuid?: string): Observable<any> {
    const params = new HttpParams()
    .set('page', '0')
    .set('pageSize', '100')
    .set('pagination', false);

    return this.http.get<{ pageSize: number; page: number; items: Project[] }>(
      `${this.baseUrl}orgs/${orgSuid}/projects/`,
      { headers, params }
    ).pipe(
      switchMap(projects => {
        projects.items = sortObjectArrayByConfigName(projects.items);
        return of(projects);
      }),
      catchError(res => {
        return throwError(res?.error)
      })
    )
  }

  countModelsByProject(org: string | undefined, id: string) {
    return this.http.get(
      `${this.baseUrl}orgs/${org}/projects/${id}/models`,
      { headers }
    ).pipe(
      map((models: any ) => {
        return models.items.length
      }),
      catchError(res => {
        return of(null)
      })
    )
  }

  getAllFiles(orgId: any, projectId: any) {
    const params = new HttpParams()
    .set('page', '0')
    .set('pageSize', '100')
    .set('pagination', false);

    return this.http.get<FileList>(
      `${this.baseUrl}orgs/${orgId}/projects/${projectId}/files/`,
      { headers, params }
    )
    .pipe(
      map((filesList: FileList) => {
        const items = filesList.items;
        if (items) {
          filesList.items = items.sort((a: any, b: any) => {
            const nameA = a.filename ? a.filename : '';
            const nameB = b.filename ? b.filename : '';
            return nameA.localeCompare(nameB);
          })
        }
        return filesList;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  deleteFile(orgSuid?: string, projectSuid?: string, fileUuid?: string) : Observable<any> {
    return this.http.delete<any>(
      `${this.baseUrl}orgs/${orgSuid}/projects/${projectSuid}/files/${fileUuid}`,
      {headers}
    ).pipe(
      catchError(res => {
        return throwError(res?.error);
      })
    )
  }

  deleteFiles(orgSuid: string | undefined, projectSuid: string | undefined, filenames: string[]) : Observable<any> {
    const data = {
      filterBy: 'filename',
      filenames: filenames.map((name) => ({ "filename": name }))
    }

    return this.http.post<Project>(
      `${this.baseUrl}orgs/${orgSuid}/projects/${projectSuid}/files/delete`,
      data,
      { headers }
    )
    .pipe(
      catchError(res => {
        return throwError(res?.error)
      })
    )
  }

  getAllSessions(orgId: any, projectId: any) {
    const params = new HttpParams()
    .set('page', '0')
    .set('pageSize', '100')
    .set('pagination', false);

    return this.http.get<FileList>(
      `${this.baseUrl}orgs/${orgId}/projects/${projectId}/sessions/`,
      { headers, params }
    )
    .pipe(
      map((filesList: FileList) => {
        const items = filesList.items;
        if (items) {
          filesList.items = items.sort((a: any, b: any) => {
            const nameA = a.filename ? a.filename : '';
            const nameB = b.filename ? b.filename : '';
            return nameA.localeCompare(nameB);
          })
        }
        return filesList;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
