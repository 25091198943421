import { createReducer, on } from '@ngrx/store';
import * as TrainingSessionActions from '../actions/training-session.actions';
import {
  TrainingParadigm,
  TrainingModel,
  TrainingSession,
  StopCondition,
  PrivateAttrs,
  Infrastructure,
  TrainingSessionRound,
  NodeMetrics,
  UrlModelParameters,
  Status,
  MetricsConfig,
} from '../models/';
import { Node } from 'src/app/platform';
import { createRehydrateReducer } from './rehydrate.reducer';
import { RocMetrics } from 'src/app/training/components/charts/models/chart.model';

export const trainingSessionFeatureKey = 'trainingSession';
const trainingSessionStorageKey = 'flp_storage_training_session_state';

export interface State {
  currentTrainingSession: TrainingSession | null;
  currentTrainingSessionRounds: TrainingSessionRound[] | null;
  trainingSession: TrainingSession | null;
  list: TrainingSession[] | null;
  privateAttrs: PrivateAttrs | null | undefined;
  model?: TrainingModel;
  paradigm?: TrainingParadigm;
  error?: { message: string };
  stopConditions?: StopCondition[];
  metricsConfig?: MetricsConfig;
  linkedNodes?: Node[] | undefined | null;
  nodeToLink?: string | undefined | null;
  nodeToLinkStatus?: Status;
  nodeToUnLink?: string | undefined | null;
  nodeToUnLinkStatus?: Status;
  historyNodes?: Node[] | undefined | null;
  mappingCompleted: boolean;
  stopConditionCompleted: boolean;
  infrastructure?: Infrastructure;
  mechanicsDataUpdated: boolean;
  datasetLengthUpdated: boolean;
  metrics?: NodeMetrics[][] | null;
  aggregatorMetrics?: NodeMetrics[] | null;
  rocMetrics?: RocMetrics | null;
  urlModelParameters: UrlModelParameters | null;
  urlModelParametersStatus: Status;
  startTrainingStatus: Status;
  deployInfraStatus: Status;
  stopTrainingStatus: Status;
}

const initialState: State = {
  currentTrainingSession: null,
  currentTrainingSessionRounds: null,
  trainingSession: null,
  list: null,
  nodeToLink: null,
  nodeToLinkStatus: { pending: false },
  nodeToUnLink: null,
  nodeToUnLinkStatus: { pending: false },
  privateAttrs: undefined,
  mechanicsDataUpdated: false,
  mappingCompleted: false,
  stopConditionCompleted: false,
  datasetLengthUpdated: false,
  metrics: null,
  aggregatorMetrics: null,
  rocMetrics: null,
  urlModelParameters: null,
  urlModelParametersStatus: { pending: false },
  startTrainingStatus: { pending: false },
  deployInfraStatus: { pending: false },
  stopTrainingStatus: { pending: false }
};

export const reducer = createReducer(
  initialState,
  //create training session
  on(TrainingSessionActions.createTrainingSession, (state) => ({
    ...state,
    error: undefined,
  })),
  on(
    TrainingSessionActions.createTrainingSessionSuccess,
    (state) => ({
      ...state
    })
  ),

  //Get training session
  on(TrainingSessionActions.getTrainingSession, (state) => ({
    ...state,
    error: undefined,
  })),
  on(
    TrainingSessionActions.getTrainingSessionSuccess,
    (state, { session }) => ({
      ...state,
      currentTrainingSession: session,
    })
  ),
  on(TrainingSessionActions.getTrainingSessionFailure, (state, { error }) => ({
    ...state,
    currentTrainingSession: null,
    error,
  })),

  //Get training session rounds
  on(TrainingSessionActions.getTrainingSessionRounds, (state) => ({
    ...state,
    error: undefined,
  })),
  on(
    TrainingSessionActions.getTrainingSessionRoundsSuccess,
    (state, { rounds }) => {
      if (state.currentTrainingSession) {
        return {
          ...state,
          currentTrainingSession: {
            ...state.currentTrainingSession,
            rounds: rounds,
          },
        };
      }
      return state;
    }
  ),

  //Get training session status
  on(TrainingSessionActions.getTrainingSessionStatus, (state) => {
    if (state.currentTrainingSession) {
      return {
        ...state,
        currentTrainingSession: {
          ...state.currentTrainingSession,
          statusPending: true,
        },
        loaderInfo: 'Getting training session status',
      };
    }
    return state;
  }),
  on(
    TrainingSessionActions.getTrainingSessionStatusSuccess,
    (state, { status }) => {
      if (state.currentTrainingSession) {
        return {
          ...state,
          currentTrainingSession: {
            ...state.currentTrainingSession,
            statusPending: false,
            status,
          },
          loaderInfo: '',
        };
      }
      return state;
    }
  ),
  on(TrainingSessionActions.getTrainingSessionStatusFailure, (state) => {
    if (state.currentTrainingSession) {
      return {
        ...state,
        currentTrainingSession: {
          ...state.currentTrainingSession,
          statusPending: false,
        },
      };
    }
    return state;
  }),
  on(
    TrainingSessionActions.getTrainingSessionStatusRetry,
    (state, { attemp }) => ({
      ...state,
      loaderInfo: `Retrying to get the training session status (${
        attemp - 1
      }/2)`,
    })
  ),
  //get model
  on(TrainingSessionActions.getTrainingModel, (state) => ({
    ...state,
    error: undefined,
  })),
  on(TrainingSessionActions.getTrainingModelSuccess, (state, { model }) => ({
    ...state,
    model,
  })),
  on(TrainingSessionActions.getTrainingModelFailure, (state, { error }) => ({
    ...state,
    error,
    model: undefined,
  })),
  //get paradigm
  on(TrainingSessionActions.getTrainingParadigm, (state) => ({
    ...state,
    error: undefined,
  })),
  on(
    TrainingSessionActions.getTrainingParadigmSuccess,
    (state, { paradigm }) => ({
      ...state,
      paradigm,
    })
  ),
  on(TrainingSessionActions.getTrainingParadigmFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  // update training mechanics -> model + paradigm
  on(TrainingSessionActions.updateTrainingMechanics, (state) => ({
    ...state,
    mechanicsDataUpdated: false,
    error: undefined,
  })),
  on(
    TrainingSessionActions.updateTrainingMechanicsFailure,
    (state, { error }) => ({
      ...state,
      mechanicsDataUpdated: false,
      error,
    })
  ),
  on(
    TrainingSessionActions.updateTrainingMechanicsSuccess,
    (state, { model, paradigm }) => ({
      ...state,
      mechanicsDataUpdated: true,
      model,
      paradigm,
    })
  ),
  // Link node
  on(TrainingSessionActions.linkNode, (state) => ({
    ...state,
    nodeToLinkStatus: {
      ...state.nodeToLinkStatus,
      pending: true,
    },
    nodeToLink: undefined,
    error: undefined,
  })),
  on(TrainingSessionActions.linkNodeSuccess, (state, { nodeSuid }) => ({
    ...state,
    nodeToLinkStatus: {
      ...state.nodeToLinkStatus,
      pending: false,
      error: undefined,
      nodeSuid
    },
    nodeToLink: nodeSuid,
  })),
  on(TrainingSessionActions.linkNodeFailure, (state, { nodeSuid, error }) => ({
    ...state,
    nodeToLinkStatus: {
      ...state.nodeToLinkStatus,
      pending: false,
      error,
      nodeSuid
    },
    nodeToLink: undefined,
    error
  })),
  // UN-Link node
  on(TrainingSessionActions.unlinkNode, (state) => ({
    ...state,
    nodeToUnLinkStatus: {
      ...state.nodeToUnLinkStatus,
      pending: true,
    },
    nodeToUnLink: undefined,
    error: undefined,
  })),
  on(TrainingSessionActions.unlinkNodeSuccess, (state, { nodeSuid }) => ({
    ...state,
    nodeToUnLinkStatus: {
      ...state.nodeToUnLinkStatus,
      pending: false,
      error: undefined,
      nodeSuid
    },
    nodeToUnLink: nodeSuid,
  })),
  on(TrainingSessionActions.unlinkNodeFailure, (state, { nodeSuid, error }) => ({
    ...state,
    nodeToUnLinkStatus: {
      ...state.nodeToUnLinkStatus,
      pending: false,
      error,
      nodeSuid
    },
    nodeToUnLink: undefined,
    error,
  })),
  //Linked nodes
  on(TrainingSessionActions.getLinkedNodes, (state) => ({
    ...state,
    linkedNodes: [],
    error: undefined,
  })),
  on(TrainingSessionActions.getLinkedNodesSuccess, (state, { nodes }) => ({
    ...state,
    linkedNodes: nodes,
  })),
  on(TrainingSessionActions.getLinkedNodesFailure, (state, { error }) => ({
    ...state,
    linkedNodes: undefined,
    error,
  })),
  //History nodes
  on(TrainingSessionActions.getHistoryNodes, (state) => ({
    ...state,
    historyNodes: [],
    error: undefined,
  })),
  on(TrainingSessionActions.getHistoryNodesSuccess, (state, { nodes }) => ({
    ...state,
    historyNodes: nodes,
  })),
  on(TrainingSessionActions.getHistoryNodesFailure, (state, { error }) => ({
    ...state,
    historyNodes: undefined,
    error,
  })),
  // Stop condition
  on(TrainingSessionActions.stopCondition, (state) => ({
    ...state,
    stopConditionCompleted: false,
    error: undefined,
  })),
  on(TrainingSessionActions.stopConditionSuccess, (state) => ({
    ...state,
    stopConditionCompleted: true,
  })),
  on(TrainingSessionActions.stopConditionFailure, (state, { error }) => ({
    ...state,
    stopConditionCompleted: false,
    error,
  })),
  // Get Stop conditions
  on(TrainingSessionActions.getStopConditions, (state) => ({
    ...state,
    stopConditions: undefined,
    error: undefined,
  })),
  on(
    TrainingSessionActions.getStopConditionsSuccess,
    (state, { stopConditions }) => ({
      ...state,
      stopConditions,
    })
  ),
  on(TrainingSessionActions.getStopConditionsFailure, (state, { error }) => ({
    ...state,
    stopConditions: undefined,
    error,
  })),
  // Get Metrics config
  on(TrainingSessionActions.getMetricsConfig, (state) => ({
    ...state,
    metricsConfig: undefined,
    error: undefined,
  })),
  on(
    TrainingSessionActions.getMetricsConfigSuccess,
    (state, { metricsConfig }) => ({
      ...state,
      metricsConfig,
    })
  ),
  on(TrainingSessionActions.getMetricsConfigFailure, (state, { error }) => ({
    ...state,
    metricsConfig: undefined,
    error,
  })),
  // Create Private Attr
  on(TrainingSessionActions.createPrivateAttrs, (state) => ({
    ...state,
    error: undefined,
    datasetLengthUpdated: false,
  })),
  on(
    TrainingSessionActions.createPrivateAttrsSuccess,
    (state, { privateAttrs }) => ({
      ...state,
      privateAttrs,
      datasetLengthUpdated: true,
    })
  ),
  on(TrainingSessionActions.createPrivateAttrsFailure, (state, { error }) => ({
    ...state,
    privateAttrs: null,
    datasetLengthUpdated: false,
    error,
  })),
  // Get Private Attr
  on(TrainingSessionActions.getPrivateAttrs, (state) => ({
    ...state,
    error: undefined,
  })),
  on(
    TrainingSessionActions.getPrivateAttrsSuccess,
    (state, { privateAttrs }) => ({
      ...state,
      privateAttrs,
    })
  ),
  on(TrainingSessionActions.getPrivateAttrsFailure, (state, { error }) => ({
    ...state,
    privateAttrs: null,
    error,
  })),
  // Cancel/delete training session
  on(TrainingSessionActions.cancelTrainingSession, (state) => ({
    ...state,
    error: undefined,
  })),
  // Finish training session configuration
  on(TrainingSessionActions.finishTrainingSession, (state) => ({
    ...state,
    stopConditionCompleted: false,
    mechanicsDataUpdated: false,
    mappingCompleted: false,
    datasetLengthUpdated: false,
    error: undefined,
  })),
  // Get node metric
  on(TrainingSessionActions.getNodeMetric, (state) => ({
    ...state,
    error: undefined,
  })),
  on(
    TrainingSessionActions.getNodeMetricSuccess,
    (state, { aggregatorMetrics }) => ({
      ...state,
      aggregatorMetrics,
    })
  ),
  on(TrainingSessionActions.getNodeMetricFailure, (state, { error }) => ({
    ...state,
    aggregatorMetrics: [],
    error,
  })),
  // Get node metrics
  on(TrainingSessionActions.getNodeMetrics, (state) => ({
    ...state,
    error: undefined,
  })),
  on(TrainingSessionActions.getNodeMetricsSuccess, (state, { metrics }) => ({
    ...state,
    metrics,
  })),
  on(TrainingSessionActions.getNodeMetricsFailure, (state, { error }) => ({
    ...state,
    metrics: [],
    error,
  })),
  // Get roc metrics
  on(TrainingSessionActions.getRocMetrics, (state) => ({
    ...state,
    error: undefined,
  })),
  on(TrainingSessionActions.getRocMetricsSuccess, (state, { rocMetrics }) => ({
    ...state,
    rocMetrics,
  })),
  on(TrainingSessionActions.getRocMetricsFailure, (state, { error }) => ({
    ...state,
    rocMetrics: null,
    error,
  })),
  on(TrainingSessionActions.cancelTrainingSessionSuccess, (_) => initialState),
  on(TrainingSessionActions.cancelTrainingSessionFailure, (_) => initialState),
  // Infra
  on(TrainingSessionActions.loadInfra, (state) => ({
    ...state,
    loadInfraStatus: { pending: true },
  })),
  on(TrainingSessionActions.loadInfraRetry, (state, { infrastructure }) => {
    return { ...state, infrastructure, loadInfraStatus: { pending: false } };
  }),
  on(TrainingSessionActions.loadInfraSuccess, (state, { infrastructure }) => {
    return { ...state, infrastructure, loadInfraStatus: { pending: false } };
  }),
  on(TrainingSessionActions.loadInfraFailure, (state, { infrastructure }) => {
    return { ...state, infrastructure, loadInfraStatus: { pending: false } };
  }),
  on(TrainingSessionActions.clearInfra, (state) => ({
    ...state,
    infrastructure: undefined,
  })),
  on(TrainingSessionActions.clearTrainingSession, (_) => initialState),

  on(TrainingSessionActions.downloadModelParameters, (state) => ({
    ...state,
    urlModelParametersStatus: {
      ...state.urlModelParametersStatus,
      pending: true,
    },
  })),
  on(
    TrainingSessionActions.downloadModelParametersSuccess,
    (state, { url }) => {
      return {
        ...state,
        urlModelParametersStatus: {
          ...state.urlModelParametersStatus,
          pending: false,
        },
        urlModelParameters: url,
      };
    }
  ),
  on(
    TrainingSessionActions.downloadModelParametersFailure,
    (state, { error }) => {
      return {
        ...state,
        urlModelParametersStatus: {
          ...state.urlModelParametersStatus,
          pending: false,
          error,
        },
      };
    }
  ),
  on(TrainingSessionActions.clearDownloadModelParameters, (state) => ({
    ...state,
    urlModelParameters: initialState.urlModelParameters,
  })),
  on(TrainingSessionActions.startTraining, state => ({ ...state, startTrainingStatus: { pending: true, error: null } })),
  on(TrainingSessionActions.startTrainingSuccess, state => ({ ...state, startTrainingStatus: { pending: false, error: null } })),
  on(TrainingSessionActions.startTrainingFailure, (state, {error}) => ({ ...state, startTrainingStatus: { pending: false, error }})),
  on(TrainingSessionActions.deployInfra, state => ({ ...state, deployInfraStatus: { pending: true, error: null } })),
  on(TrainingSessionActions.deployInfraSuccess, state => ({ ...state, deployInfraStatus: { pending: false, error: null } })),
  on(TrainingSessionActions.deployInfraFailure, (state, {error}) => ({ ...state, deployInfraStatus: { pending: false, error }})),
  on(TrainingSessionActions.stopTraining, state => ({ ...state, stopTrainingStatus: { pending: true, error: null } })),
  on(TrainingSessionActions.stopTrainingSuccess, state => ({ ...state, stopTrainingStatus: { pending: false, error: null } })),
  on(TrainingSessionActions.stopTrainingFailure, (state, {error}) => ({ ...state, stopTrainingStatus: { pending: false, error }})),
  on(TrainingSessionActions.stopTrainingByParams, state => ({ ...state, stopTrainingStatus: { pending: true, error: null } })),
  on(TrainingSessionActions.stopTrainingByParamsSuccess, state => ({ ...state, stopTrainingStatus: { pending: false, error: null } })),
  on(TrainingSessionActions.stopTrainingByParamsFailure, (state, {error}) => ({ ...state, stopTrainingStatus: { pending: false, error }}))
);
export const getAllTrainingSessions = (state: State) => state?.list;
export const getCurrentTrainingSession = (state: State) =>
  state?.currentTrainingSession;
export const getCurrentTrainingSessionRounds = (state: State) =>
  state?.currentTrainingSession?.rounds;
export const getCurrentTrainingSessionTimestamp = (state: State) =>
  state?.currentTrainingSession?.trainingSessionTimestamp;
export const getTrainingSessionModel = (state: State) => state?.model;
export const getTrainingSessionParadigm = (state: State) => state?.paradigm;
export const getIsMechanicsDataUpdated = (state: State) =>
  state?.mechanicsDataUpdated;
export const getLinkedNodes = (state: State) => state?.linkedNodes;
export const getHistoryNodes = (state: State) => state?.historyNodes;
export const getIsStopConditionCompleted = (state: State) =>
  state?.stopConditionCompleted;
export const getIsMappingNodesCompleted = (state: State) =>
  state?.mappingCompleted;
export const getStopConditions = (state: State) => state?.stopConditions;
export const getTrainingSessionError = (state: State) => state?.error;
export const getInfra = (state: State) => state?.infrastructure;
export const getIsDatasetLengthCompleted = (state: State) =>
  state?.datasetLengthUpdated;
export const getPrivateAttrs = (state: State) => state?.privateAttrs;
export const getMetrics = (state: State) => state?.metrics;
export const getAggregatorMetrics = (state: State) => state?.aggregatorMetrics;
export const getUrlModelParameters = (state: State) =>
  state?.urlModelParameters;
export const getUrlModelParametersError = (state: State): boolean =>
  state.urlModelParametersStatus?.error;
export const getCurrentTrainingSessionLastRound = (state: State) =>
  state?.currentTrainingSession?.summaryTrainingSessionExecution.lastRound;
export const getRocMetrics = (state: State) => state?.rocMetrics;

export const nodeToLink = (state: State) => state?.nodeToLink;
export const isNodeToLinkPending = (state: State) => state?.nodeToLinkStatus;
export const isNodeToLinkError = (state: State) => state?.nodeToLinkStatus;

export const nodeToUnLink = (state: State) => state?.nodeToUnLink;
export const isNodeToUnLinkPending = (state: State) => state?.nodeToUnLinkStatus;
export const isNodeToUnLinkError = (state: State) => state?.nodeToUnLinkStatus;

export const getStartTrainingStatus = (state: State) => state?.startTrainingStatus;

export const getDeployInfraStatus = (state: State) => state?.deployInfraStatus;

export const getStopTrainingByParamsStatus = (state: State) => state?.stopTrainingStatus;

