import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import {MatTabsModule} from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatGridListModule } from '@angular/material/grid-list';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { CdkStepperModule } from '@angular/cdk/stepper';
import {OverlayModule} from '@angular/cdk/overlay';
import { MatTooltipModule } from '@angular/material/tooltip';

export const MATERIAL = [
  MatFormFieldModule,
  MatInputModule,
  TextFieldModule,
  MatSelectModule,
  MatFileUploadModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatDialogModule,
  MatMenuModule,
  MatDatepickerModule,
  MatNativeDateModule,
  NgxSliderModule,
  MatTabsModule,
  MatPaginatorModule,
  MatSortModule,
  MatTableModule,
  MatGridListModule,
  MatTooltipModule,
  ClipboardModule,
  CdkStepperModule,
  OverlayModule
];
