import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-tip',
  template: `<svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.44123 3.50279L8 2.19898L8.55877 3.50279C9.31739 5.2729 10.7279 6.68336 12.498 7.44198L13.8018 8.00076L12.498 8.55953C10.7279 9.31815 9.31739 10.7286 8.55877 12.4987L8 13.8025L7.44123 12.4987C6.68261 10.7286 5.27214 9.31815 3.50203 8.55953L2.19823 8.00076L3.50203 7.44198C5.27214 6.68336 6.68261 5.2729 7.44123 3.50279Z"
    />
  </svg> `,
})
export class IconTip {
  @HostBinding('class.shai-icon') get iconClass() {
    return true;
  }
}
