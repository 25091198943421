import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'shai-card-tip',
  template: `
    <div class="card-group-title">&nbsp;{{ group }}</div>
    <div class="card-container">
      <div class="card-inner">
        <ng-content select="[icon]"></ng-content>
        <div class="card-content">
          <h4>{{ title }}</h4>
        </div>
      </div>
    </div>
  `,
  styleUrls: [`./card-tip.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardTipComponent {
  @Input() title: string = '';
  @Input() group?: string = '';
  constructor() {}
}
