import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  QueryList,
  ViewChildren,
  AfterViewInit,
  ComponentFactoryResolver,
  ContentChildren,
} from '@angular/core';
import {
  CdkStep,
  CdkStepper,
  StepContentPositionState,
} from '@angular/cdk/stepper';
import { AnimationEvent } from '@angular/animations';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { stepperFadeInTransition } from '../../animations';
import { Subscription } from 'rxjs';
import { OverlayQuestionModel } from '../overlay.component';
import { BaseStepComponent } from './base-step.component';

@Component({
  selector: 'shai-stepper',
  template: `
    <div class="container">
      <div *ngIf="customSteps">
        <div
          class="step-content-div"
          *ngFor="let step of customSteps; let i = index"
          [@stepTransition]="_getAnimationDirection(i)"
          [attr.tabindex]="selectedIndex === i ? 0 : null"
          [id]="_getStepContentId(i)"
          (@stepTransition.done)="_animationDone.next($event)"
          [attr.aria-labelledby]="_getStepLabelId(i)"
          [attr.aria-expanded]="selectedIndex === i"
          [attr.step-info]="stepInfo"
        >
          <ng-container shaiStep [ngTemplateOutlet]="step.content">
          </ng-container>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./stepper.component.scss'],
  // This custom stepper provides itself as CdkStepper so that it can be recognized
  // by other components.
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
  animations: [stepperFadeInTransition],
})
export class StepperComponent
  extends CdkStepper
  implements OnInit, OnDestroy
{
  @Output() readonly animationDone: EventEmitter<void> =
    new EventEmitter<void>();

  _animationDone = new Subject<AnimationEvent>();
  stepInfo = '';
  customSteps?: QueryList<CdkStep> = undefined;
  private subscription?: Subscription;

  private stepsCount = 0;
  private stepSelected = 1;

  // Create a defaultCloseQuestion if all steps must show the same close question
  @Input() defaultCloseQuestion: OverlayQuestionModel | undefined;
  // Emit current question
  @Output() closeQuestionOnChange = new EventEmitter<OverlayQuestionModel>();
  // Create a closeQuestionList in order to pick the question related to the step
  // when setCloseQuestion is executed
  @Input() closeQuestionList: OverlayQuestionModel[] = [];

  @ContentChildren(BaseStepComponent, {descendants: true}) baseStepComponents?: QueryList<BaseStepComponent>;

  ngOnInit(): void {
    setTimeout(() => {
      this.customSteps = this._steps;
      this.animations();
      this.stepsCount = this.steps.length;
      this.stepInfo = `Step ${this.stepSelected} of ${this.stepsCount}`;
      this.setCloseQuestion();

      const initialStep = this.baseStepComponents?.get(0)
      if (initialStep) {
        initialStep.onEnter()
      }

      this.selectionChange.subscribe((value) => {
        this.stepSelected = value.selectedIndex + 1;
        this.stepInfo = `Step ${this.stepSelected} of ${this.stepsCount}`;
        this.setCloseQuestion();
      });
    }, 1);
  }

  ngOnDestroy(): void {
    this.selectionChange.unsubscribe();
    this.subscription?.unsubscribe();
  }

  next(): void {
    const actualElement = this.baseStepComponents?.get(this.selectedIndex);
    const nextElement = this.baseStepComponents?.get(this.selectedIndex + 1);
    if (actualElement) {
      actualElement.onLeave();
    }

    if (nextElement) {
      nextElement.onEnter();
    }

    super.next()
  }

  previous(): void {
    const actualElement = this.baseStepComponents?.get(this.selectedIndex);
    const previousElement = this.baseStepComponents?.get(this.selectedIndex - 1);
    if (actualElement) {
      actualElement.onLeave();
    }

    if (previousElement) {
      previousElement.onEnter();
    }
    super.previous()
  }

  private setCloseQuestion() {
    if (this.stepSelected <= this.closeQuestionList.length) {
      this.closeQuestionOnChange.emit(
        this.closeQuestionList[this.stepSelected - 1]
      );
    } else {
      this.closeQuestionOnChange.emit(this.defaultCloseQuestion);
    }
  }

  private animations() {
    this.subscription = this._steps.changes
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => {
        this._stateChanged();
      });

    this._animationDone
      .pipe(
        // This needs a `distinctUntilChanged` in order to avoid emitting the same event twice due
        // to a bug in animations where the `.done` callback gets invoked twice on some browsers.
        // See https://github.com/angular/angular/issues/24084
        distinctUntilChanged((x, y) => {
          return x.fromState === y.fromState && x.toState === y.toState;
        }),
        takeUntil(this._destroyed)
      )
      .subscribe((event) => {
        // console.log(event);
        if ((event.toState as StepContentPositionState) === 'current') {
          // console.log('event.toState',event.toState);
          this.animationDone.emit();
        }
      });
  }
}
