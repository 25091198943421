import { RequestAccessMode } from '../request-access/request-access.model';

export function getInfo(mode: RequestAccessMode) {
  switch (mode) {
    case RequestAccessMode.Experimental:
      return {
        title: 'Join the waitlist to get early access to the platform',
        description:
          'Enter your email and we will provide access to the platform as soon as possible',
        submitButtonText: "JOIN THE WAITLIST",
        goToSignInButtonText: "GO TO SIGN IN"
      };
    case RequestAccessMode.Enterprise:
    case RequestAccessMode.Hub:
      return {
        title: 'Request access to Sherpa.ai platform',
        description:
          'Maximize the value of data and AI with Sherpa.ai Privacy-Preserving Platform',
          submitButtonText: "Submit",
          goToSignInButtonText: "GO TO SIGN IN"
      };
  }
}
