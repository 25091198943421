import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  TrainingSessionActions,
  NewTrainingSessionActions,
} from '../actions';
import {
  catchError,
  concatMap,
  delay,
  map,
  switchMap,
  withLatestFrom,
  throttleTime,
  mergeMap,
  toArray,
  tap,
  take,
} from 'rxjs/operators';
import { fromAuth } from 'src/app/auth/state';
import * as fromPlatform from '../../platform/reducers';
import { TrainingSessionService } from '../services/training-session.service';
import { of, from, forkJoin } from 'rxjs';
import {
  Infrastructure,
  TrainingSession,
} from '../models';
import { PrivateAttrs } from '../models';
import { MetricsService } from '../services/metrics.service';
import { averageMetrics } from 'src/app/training/components/charts/models/training-session-utils';

@Injectable()
export class TrainingSessionEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private _trainingSessionService: TrainingSessionService,
    private _metricsService: MetricsService
  ) {}

  createTrainingSession$ = createEffect(() =>
  this.actions$.pipe(
    ofType(TrainingSessionActions.createTrainingSession),
    map(({ timestamp }) => {
      return TrainingSessionActions.createTrainingSessionSuccess({
        session: timestamp,
      });
    })
  ));

  createTrainingSessionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.createTrainingSessionSuccess),
      withLatestFrom(
        this.store.pipe(select(fromPlatform.getStopCondition))
      ),
      switchMap(([{ session }, stopConditions]) => {
        return of(NewTrainingSessionActions.chooseStopConditions({
          timestamp: session!,
          stopConditions,
        }));
      })
    )
  );

  chooseStopConditions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewTrainingSessionActions.chooseStopConditions),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId))
      ),
      switchMap(
        ([{ timestamp, stopConditions }, orgId, projectId]) => {
          const combinedStopConditions = [stopConditions];

          return this._trainingSessionService.setStopConditions(
            orgId,
            projectId,
            timestamp,
            combinedStopConditions
          ).pipe(
            map((result) =>
              NewTrainingSessionActions.chooseStopConditionsSuccess({
                stopConditions: result
              })
            ),
            catchError((error) => [
              NewTrainingSessionActions.chooseStopConditionsFailure({
                error,
              }),
            ])
          );
        }
      )
    )
  );

  chooseStopConditionsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewTrainingSessionActions.chooseStopConditionsSuccess),
      withLatestFrom(
        this.store.select(fromPlatform.getParticipatingNodes),
        this.store.pipe(select(fromPlatform.getNewTrainingTimestamp))
      ),
      switchMap(([{}, participatingNodes, session]) => {
        return of(NewTrainingSessionActions.chooseParticipatingNodes({
          timestamp: session!,
          participatingNodes
        }));
      })
    )
  );

  chooseMappings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewTrainingSessionActions.chooseMappings),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId))
      ),
      switchMap(([{ timestamp, mappings }, orgId, projectId]) => {
        return this._trainingSessionService
          .mappingNodes(orgId, projectId, timestamp!, mappings)
          .pipe(
            map(() =>
              NewTrainingSessionActions.chooseMappingsSuccess({
                timestamp: timestamp!
          })),
          catchError((error) => {
            return of(NewTrainingSessionActions.chooseMappingsFailure({ error }));
          })
        );
      })
    )
  );

  chooseMappingsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewTrainingSessionActions.chooseMappingsSuccess),
      map((action) =>
        TrainingSessionActions.getTrainingSession({
          timestamp: action.timestamp!,
        })
      )
    )
  );

  chooseParticipatingNodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewTrainingSessionActions.chooseParticipatingNodes),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId))
      ),
      switchMap(
        ([{ timestamp, participatingNodes }, orgId, projectId]) =>
          from(participatingNodes).pipe(
            mergeMap((node: string) =>
              this._trainingSessionService
                .linkNode(orgId, projectId, timestamp, node)
                .pipe(map(() => ''))
            ),
            toArray(),
            map((participatingNodes: string[]) =>
              NewTrainingSessionActions.chooseParticipatingNodesSuccess({
                timestamp,
                participatingNodes,
              })
            ),
            catchError((error: any) =>
              of(
                NewTrainingSessionActions.chooseParticipatingNodesFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  chooseParticipatingNodesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewTrainingSessionActions.chooseParticipatingNodesSuccess),
      switchMap((action) => {
        let { timestamp } = action;
        return this.store.pipe(
          select(fromPlatform.getMappings),
          map((mappings) =>
            NewTrainingSessionActions.chooseMappings({ timestamp, mappings, origin: 'TrainingSessionEffects' })
          ),
          take(1)
        );
      })
    )
  );

  getTrainingModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.getTrainingModel),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingId))
      ),
      switchMap(([, org, projectId, trainingId]) => {
        return this._trainingSessionService
          .getModel(org, projectId, trainingId)
          .pipe(
            map((response) => {
              return TrainingSessionActions.getTrainingModelSuccess({
                model: response,
              });
            }),
            catchError((error) => [
              TrainingSessionActions.getTrainingModelFailure({ error }),
            ])
          );
      })
    )
  );

  getTrainingParadigm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.getTrainingParadigm),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingId))
      ),
      switchMap(([, org, projectId, trainingId]) => {
        return this._trainingSessionService
          .getParadigm(org, projectId, trainingId)
          .pipe(
            map((response) => {
              return TrainingSessionActions.getTrainingParadigmSuccess({
                paradigm: response,
              });
            }),
            catchError((error) => [
              TrainingSessionActions.getTrainingParadigmFailure({ error }),
            ])
          );
      })
    )
  );

  updateTrainingMechanics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.updateTrainingMechanics),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingId))
      ),
      switchMap(([data, org, projectId, trainingId]) => {
        return this._trainingSessionService
          .updateMechanics(org, projectId, trainingId, data)
          .pipe(
            map((response) => {
              return TrainingSessionActions.updateTrainingMechanicsSuccess({
                model: response[0],
                paradigm: response[1],
              });
            }),
            catchError((error) => [
              TrainingSessionActions.updateTrainingMechanicsFailure({ error }),
            ])
          );
      })
    )
  );

  linkNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.linkNode),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(([{ nodeSuid }, org, projectId, trainingId, session]) => {
        return this._trainingSessionService
          .linkNode(
            org,
            projectId,
            session?.trainingSessionTimestamp,
            nodeSuid
          )
          .pipe(
            map((nodeSuidApi: any) => {
              return TrainingSessionActions.linkNodeSuccess({
                nodeSuid: nodeSuidApi,
              });
            }),
            catchError((error) => [
              TrainingSessionActions.linkNodeFailure({ nodeSuid, error }),
            ])
          );
      })
    )
  );

  unlinkNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.unlinkNode),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(([{ nodeSuid }, org]) => {
        return this._trainingSessionService.unlinkNode(org, nodeSuid).pipe(
          map((nodeSuidApi: any) => {
            return TrainingSessionActions.unlinkNodeSuccess({
              nodeSuid: nodeSuidApi,
            });
          }),
          catchError((error) => [
            TrainingSessionActions.unlinkNodeFailure({ nodeSuid, error }),
          ])
        );
      })
    )
  );

  stopCondition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.stopCondition),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(([data, org, projectId, trainingId, session]) => {
        return this._trainingSessionService
          .setTrainingSessionStepFiveConfiguration(
            org,
            projectId,
            trainingId,
            session?.trainingSessionTimestamp,
            data
          )
          .pipe(
            map((response) => {
              return TrainingSessionActions.stopConditionSuccess();
            }),
            catchError((error) => [
              TrainingSessionActions.stopConditionFailure({ error }),
            ])
          );
      })
    )
  );

  cancelTrainingSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.cancelTrainingSession),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(([, org, projectId, trainingId, session]) => {
        return this._trainingSessionService
          .deleteTrainingSessionProcess(
            org,
            projectId,
            trainingId,
            session?.trainingSessionTimestamp
          )
          .pipe(
            map((response) => {
              return TrainingSessionActions.cancelTrainingSessionSuccess();
            }),
            catchError((error) => [
              TrainingSessionActions.cancelTrainingSessionFailure({ error }),
            ])
          );
      })
    )
  );

  getTrainingSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.getTrainingSession),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingId))
      ),
      switchMap(([data, org, projectId, trainingId]) => {
        return this._trainingSessionService
          .getTrainingSession(org, projectId, data.timestamp)
          .pipe(
            map((response) => {
              return TrainingSessionActions.getTrainingSessionSuccess({
                session: response,
              });
            })
          );
      })
    )
  );

  getTrainingSessionRounds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.getTrainingSessionRounds),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(([data, org, projectId, trainingId, session]) => {
        return this._trainingSessionService
          .getTrainingSessionRounds(org, projectId, session)
          .pipe(
            map((response) => {
              return TrainingSessionActions.getTrainingSessionRoundsSuccess({
                rounds: response,
              });
            }),
            catchError((error) => [
              TrainingSessionActions.getTrainingSessionRoundsFailure({
                error: {
                  message:
                    'An error occurred getting the training session rounds',
                },
              }),
            ])
          );
      })
    )
  );

  getTrainingSessionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.getTrainingSessionStatus),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSessionTimestamp))
      ),
      switchMap(
        ([_, orgId, projectId, trainingId, trainingStatusTimestamp]) => {
          return this._trainingSessionService
            .getTrainingSessionStatus(
              orgId,
              projectId,
              trainingId,
              trainingStatusTimestamp
            )
            .pipe(
              switchMap((trainingSessionStatus) => {
                return [
                  TrainingSessionActions.getTrainingSessionStatusSuccess({
                    status: trainingSessionStatus,
                  }),
                ];
              })
            );
        }
      )
    )
  );

  getTrainingSessionStatusSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.getTrainingSessionStatusSuccess),
      map(() => {
        return TrainingSessionActions.getTrainingSessionStatusFailure({
          error: null,
        });
      })
    )
  );

  getHistoryNodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.getHistoryNodes),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(([, orgId, projectId, session]) => {
        return this._trainingSessionService
          .getNodeHistoryList(
            orgId,
            projectId,
            session?.trainingSessionTimestamp
          )
          .pipe(
            map((response) => {
              return TrainingSessionActions.getHistoryNodesSuccess({
                nodes: response,
              });
            }),
            catchError((error) => [
              TrainingSessionActions.getHistoryNodesFailure({
                error: {
                  message: 'An error occurred getting history nodes',
                },
              }),
            ])
          );
      })
    )
  );

  getLinkedNodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.getLinkedNodes),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(([, org, projectId, trainingId, session]) => {
        return this._trainingSessionService
          .getLinkedNodes(
            org,
            projectId,
            trainingId,
            session?.trainingSessionTimestamp
          )
          .pipe(
            map((response) => {
              return TrainingSessionActions.getLinkedNodesSuccess({
                nodes: response.length >= 0 ? response : null,
              });
            }),
            catchError((error) => [
              TrainingSessionActions.getLinkedNodesFailure({ error }),
            ])
          );
      })
    )
  );

  deployInfra$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.deployInfra),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(([, org, projectId, trainingId, session]) => {
        return this._trainingSessionService
          .deployInfra(
            org,
            projectId,
            trainingId,
            session?.trainingSessionTimestamp
          )
          .pipe(
            map((res) => {
              return TrainingSessionActions.deployInfraSuccess();
            }),
            catchError((error) => [
              TrainingSessionActions.deployInfraFailure({ error }),
            ])
          );
      })
    )
  );

  cancelDeployInfra$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.cancelDeployInfra),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(([, org, projectId, trainingId, session]) => {
        return this._trainingSessionService
          .cancelDeployInfra(
            org,
            projectId,
            trainingId,
            session?.trainingSessionTimestamp
          )
          .pipe(
            map((res) => {
              return TrainingSessionActions.cancelDeployInfraSuccess();
            }),
            catchError((error) => [
              TrainingSessionActions.cancelDeployInfraFailure({ error }),
            ])
          );
      })
    )
  );

  loadInfra$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.loadInfra),
      throttleTime(2000),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(([_, org, projectId, trainingId, session]) => {
        return this._trainingSessionService
          .loadInfra(
            org,
            projectId,
            trainingId,
            session?.trainingSessionTimestamp
          )
          .pipe(
            switchMap((infrastructure: Infrastructure) => {
              if (infrastructure.status !== 'READY') {
                return [
                  TrainingSessionActions.loadInfraRetry({ infrastructure }),
                ];
              }
              return [
                TrainingSessionActions.loadInfraSuccess({ infrastructure }),
              ];
            }),
            catchError((error) => {
              return [
                TrainingSessionActions.loadInfraFailure({
                  infrastructure: { status: 'PENDING' },
                }),
              ];
            })
          );
      })
    )
  );

  loadInfraRetry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.loadInfraRetry),
      throttleTime(2000),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(([_, org, projectId, trainingId, session]) => {
        return this._trainingSessionService
          .loadInfra(
            org,
            projectId,
            trainingId,
            session?.trainingSessionTimestamp
          )
          .pipe(
            delay(10000),
            map((infrastructure: Infrastructure) => {
              if (infrastructure.status !== 'READY' && infrastructure.status !== 'DONE') {
                return TrainingSessionActions.loadInfraRetry({
                  infrastructure,
                });
              }
              return TrainingSessionActions.loadInfraSuccess({
                infrastructure,
              });
            }),
            catchError((error) => {
              return [
                TrainingSessionActions.loadInfraFailure({
                  infrastructure: { status: 'PENDING' },
                }),
              ];
            })
          );
      })
    )
  );

  startTraining$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.startTraining),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(([_, orgId, projectId, session]) => {
        return this._trainingSessionService
          .startTraining(
            orgId,
            projectId,
            session?.trainingSessionTimestamp
          )
          .pipe(
            switchMap(() => {
              return [
                TrainingSessionActions.startTrainingSuccess()
              ];
            }),
            catchError((error) => {
              return [TrainingSessionActions.startTrainingFailure({ error })];
            })
          );
      })
    )
  );
  stopTraining$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.stopTraining),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(([_, orgId, projectId, session]) => {
        return this._trainingSessionService
          .stopTraining(
            orgId,
            projectId,
            session?.trainingSessionTimestamp
          )
          .pipe(
            map((training) => {
              return TrainingSessionActions.stopTrainingSuccess();
            }),
            catchError((error) => [
              TrainingSessionActions.stopTrainingFailure({ error }),
            ])
          );
      })
    )
  );

  stopTrainingByParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.stopTrainingByParams),
      withLatestFrom(this.store.pipe(select(fromAuth.getOrg))),
      switchMap(([{ projectId, trainingId, trainingSessionId }, orgId]) => {
        return this._trainingSessionService
          .stopTraining(orgId, projectId, trainingSessionId)
          .pipe(
            map((training) => {
              return TrainingSessionActions.stopTrainingByParamsSuccess();
            }),
            catchError((error) => [
              TrainingSessionActions.stopTrainingByParamsFailure({ error }),
            ])
          );
      })
    )
  );

  getStopConditions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.getStopConditions),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(([, org, projectId, trainingId, session]) => {
        return this._trainingSessionService
          .getStopConditions(
            org,
            projectId,
            trainingId,
            session?.trainingSessionTimestamp
          )
          .pipe(
            map((response) => {
              return TrainingSessionActions.getStopConditionsSuccess({
                stopConditions: response,
              });
            }),
            catchError((error) => [
              TrainingSessionActions.getStopConditionsFailure({ error }),
            ])
          );
      })
    )
  );

  getMetricsConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.getMetricsConfig),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(([, org, projectId, trainingId, session]) => {
        return this._trainingSessionService
          .getMetricsConfig(
            org,
            projectId,
            trainingId,
            session?.trainingSessionTimestamp
          )
          .pipe(
            map((response) => {
              return TrainingSessionActions.getMetricsConfigSuccess({
                metricsConfig: response,
              });
            }),
            catchError((error) => [
              TrainingSessionActions.getMetricsConfigFailure({ error }),
            ])
          );
      })
    )
  );

  getPrivateAttrs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.getPrivateAttrs),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(([{ round }, org, projectId, trainingId, session]) => {
        return this._trainingSessionService
          .getPrivateAttrs(
            org,
            projectId,
            trainingId,
            session?.trainingSessionTimestamp,
            round
          )
          .pipe(
            map((response) => {
              return TrainingSessionActions.getPrivateAttrsSuccess({
                privateAttrs: response,
              });
            }),
            catchError((error) => {
              if (error.error.code === '404') {
                return [
                  TrainingSessionActions.getPrivateAttrsSuccess({
                    privateAttrs: null,
                  }),
                ];
              }
              return [
                TrainingSessionActions.getPrivateAttrsFailure({
                  error,
                }),
              ];
            })
          );
      })
    )
  );

  createPrivateAttrs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.createPrivateAttrs),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(
        ([{ round, datasetLength }, org, projectId, trainingId, session]) => {
          return this._trainingSessionService
            .createPrivateAttrs(
              org,
              projectId,
              trainingId,
              session?.trainingSessionTimestamp,
              round,
              datasetLength
            )
            .pipe(
              map((_) => {
                const attr: PrivateAttrs = {
                  trainingDatasetLength: datasetLength,
                };
                return TrainingSessionActions.createPrivateAttrsSuccess({
                  privateAttrs: attr,
                });
              }),
              catchError((error) => {
                if (error.error.code === '409') {
                  return [
                    TrainingSessionActions.createPrivateAttrsSuccess({
                      privateAttrs: null,
                    }),
                  ];
                }
                return [
                  TrainingSessionActions.createPrivateAttrsFailure({
                    error,
                  }),
                ];
              })
            );
        }
      )
    )
  );

  getNodeMetrics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.getNodeMetrics),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(([{ nodes }, org, projectId, session]) => {
        return this._metricsService
          .getAllNodesMetrics(org, projectId, session?.trainingSessionTimestamp, nodes)
          .pipe(
            map((response) => {
              // if (response.length > 1 && response[0]) {
              //   response[0].forEach((nodeMetrics, index) => {
              //     if (nodeMetrics.metricsData && nodeMetrics.metricsData.metrics) {
              //       const correspondingMetrics = response
              //         .slice(1)
              //         .map(res => res[index]?.metricsData?.metrics)
              //         .filter((metric): metric is { [key: string]: number | number[] } => metric !== undefined);

              //       if (correspondingMetrics.length > 0) {
              //         const averagedMetrics = averageMetrics(...correspondingMetrics);
              //         nodeMetrics.metricsData.metrics = averagedMetrics;
              //       }
              //     }
              //   });
              // }

              return TrainingSessionActions.getNodeMetricsSuccess({
                metrics: response,
              });
            }),
            catchError((error) => {
              if (error.error.code === '404') {
                return [
                  TrainingSessionActions.getNodeMetricsSuccess({
                    metrics: [],
                  }),
                ];
              }
              return [
                TrainingSessionActions.getNodeMetricsFailure({
                  error,
                }),
              ];
            })
          );
      })
    )
  );

  downloadModelParameters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.downloadModelParameters),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(([{ node, round }, org, projectId, session]) => {
        return this._trainingSessionService
          .downloadModelParameters(
            org,
            projectId,
            session?.trainingSessionTimestamp,
            node
          )
          .pipe(
            map((url) =>
              TrainingSessionActions.downloadModelParametersSuccess({ url })
            ),
            catchError((error) => {
              return of(
                TrainingSessionActions.downloadModelParametersFailure({ error })
              );
            })
          );
      })
    )
  );

  getRocMetrics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrainingSessionActions.getRocMetrics),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(([{ node, round }, org, projectId, trainingId, session]) => {
        return this._metricsService
          .getRocMetric(
            org,
            projectId,
            session?.trainingSessionTimestamp,
            node,
            round
          )
          .pipe(
            map((rocMetrics) =>
              TrainingSessionActions.getRocMetricsSuccess({ rocMetrics })
            ),
            catchError((error) => {
              if (error.error.code === '404') {
                return [
                  TrainingSessionActions.getRocMetricsSuccess({
                    rocMetrics: { rocData: [] },
                  }),
                ];
              }
              return [TrainingSessionActions.getRocMetricsFailure({ error })];
            })
          );
      })
    )
  );
}
