import { Component, OnInit } from '@angular/core';
import { BreackpointService } from 'src/app/platform/services/breackpoint.service';

@Component({
  selector: 'shai-action-bar',
  template: `
    <div class="bottom-button">
        <mat-grid-list cols="12">
            <mat-grid-tile colspan="2"
                *ngIf="(_bkp.obsScreen$ | async) === 'desktop'">
            </mat-grid-tile>
            <mat-grid-tile [colspan]="(_bkp.obsScreen$ | async) === 'desktop' ? 8 : 12">
                <div [ngClass]="{'padding-container': (_bkp.obsScreen$ | async) !== 'desktop'}"
                    [ngStyle]="{'justify-content': (!leftRef.children.length || !rightRef.children.length) ? 'center' : 'space-between'}"
                    class="elements-row">
                    <div #leftRef class="two-columns"
                        [ngClass]="{'one-column': !rightRef.children.length, 'd-none': !leftRef.children.length}">
                        <ng-content select="[left-cell]"></ng-content>
                    </div>
                    <div #rightRef class="two-columns"
                        [ngClass]="{'one-column': !leftRef.children.length, 'd-none': !rightRef.children.length, 'c-padding-12': leftRef.children.length}">
                        <ng-content select="[right-cell]"></ng-content>
                    </div>
                </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="2"
                *ngIf="(_bkp.obsScreen$ | async) === 'desktop'">
            </mat-grid-tile>
        </mat-grid-list>
    </div>
  `,
  styleUrls: ['./action-bar.component.scss'],
})
export class ActionBarComponent {

  constructor(
    public _bkp: BreackpointService,
  ) {}

}
