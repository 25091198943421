import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HelperService {

  public subjectOverlayState = new BehaviorSubject<{isOpen: boolean, isSecondaryOverlay: boolean}>({isOpen: false, isSecondaryOverlay: false});
  public obsOverlayState$ = this.subjectOverlayState.asObservable();

  public subjectFooter = new BehaviorSubject<number>(0);
  public obsFooter$ = this.subjectFooter.asObservable();

  public subjectHeader = new BehaviorSubject<number>(0);
  public obsHeader$ = this.subjectHeader.asObservable();

  public subjectConfigurationState = new BehaviorSubject<{isOpen: boolean, trainingSuid: string, trainingStatus: string}>({isOpen: false, trainingSuid: '', trainingStatus: ''});
  public obsConfiguration$ = this.subjectConfigurationState.asObservable();

  public subjectOpenMainMenuOverlay = new BehaviorSubject<boolean>(true);
  public obsOpenMainMenu$ = this.subjectOpenMainMenuOverlay.asObservable();

  constructor() {}

  setOverlayState(status: any){
    this.subjectOverlayState.next(status);
  }

  setFooterHeight(height: number){
    this.subjectFooter.next(height);
  }

  setHeaderHeight(height: number){
    this.subjectHeader.next(height);
  }

  setConfigurationState(status: any){
    this.subjectConfigurationState.next(status);
  }

  setOpenMainMenuState(open: boolean){
    this.subjectOpenMainMenuOverlay.next(open);
  }
}
