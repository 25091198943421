import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'shai-table-header',
    template: `
        <div class="table-header-container">
            <div class="heading" left><shai-table-header-tabs [classForTrainingsLanding]="classForTrainingsLanding" [showEllipsis]="showEllipsis" [headerTabs] = "headerTabs" (onTabSelected)="onTabSelected($event)"></shai-table-header-tabs></div>
            <ng-content select="[right]"></ng-content>
        </div>
    `,
    styleUrls: [`./table-header.component.scss`],
})
export class TableHeaderComponent {
    @Input() headerTabs = ["Training Sessions Tab1", "Training Sessions Tab2"];
    @Input() showEllipsis: boolean = false;
    @Input() classForTrainingsLanding: boolean = false;
    @Output() onChangeTableTab = new EventEmitter();
    
    onTabSelected(tabNumber: number) {
        this.onChangeTableTab.emit(tabNumber);
    }
}