import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { NodeActions } from '../actions';
import { fromAuth } from "src/app/auth/state";
import { NodeList } from "../models/node";
import { NodeService } from "../services/node.service";
import { of } from "rxjs";


@Injectable()
export class NodeEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private _nodeService: NodeService
  ) { }

  getNodes$ = createEffect(() => this.actions$.pipe(
    ofType(NodeActions.loadNodes),
    withLatestFrom(this.store.pipe(select(fromAuth.getOrg))),
    switchMap(([,org]) => {
      return this._nodeService.getNodeList(org).pipe(
        switchMap((list: NodeList) => [NodeActions.loadNodesSuccess({list})]),
        catchError(error => [NodeActions.loadNodesFailure({error})])
      )
    })
  ))

  loadDockerImageUrl$ = createEffect(() => this.actions$.pipe(
    ofType(NodeActions.loadDockerImageUrl),
    withLatestFrom(this.store.pipe(select(fromAuth.getOrg))),
    switchMap(([{imageSuid}, orgSuid]) => this._nodeService.loadDockerImageUrl(imageSuid!, orgSuid!).pipe(
      map((image) => NodeActions.loadDockerImageUrlSuccess({ image })),
      catchError((error) => {
        return of(NodeActions.loadDockerImageUrlFailure({ error }))
      })
    ))
  ));

  pingNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeActions.pingNode),
      withLatestFrom(this.store.pipe(select(fromAuth.getOrg))),
      switchMap(([{ suid }, orgId]) =>
        this._nodeService.pingNode(orgId, suid).pipe(
          map(() => NodeActions.pingNodeSuccess()),
          catchError((error) => [
            NodeActions.pingNodeFailure({ error }),
          ])
        )
      )
    )
  );

  pingNodeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeActions.pingNodeSuccess),
      map(() => NodeActions.loadNodes())
    )
  );

  deleteNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeActions.deleteNode),
      withLatestFrom(this.store.pipe(select(fromAuth.getOrg))),
      switchMap(([{ nodeSuid }, orgId]) =>
        this._nodeService.deleteNode(orgId, nodeSuid).pipe(
          map(() => NodeActions.deleteNodeSuccess({nodeSuid})),
          catchError((error) => [
            NodeActions.deleteNodeFailure({ error }),
          ])
        )
      )
    )
  );

  deleteNodeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeActions.deleteNodeSuccess),
      map(() => NodeActions.loadNodes())
    )
  );
}