import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { configMock } from 'src/app/platform/mocks/global-config';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromPlatform from 'src/app/platform/reducers';

@Component({
  selector: 'shai-upload-files-tip',
  template: `
    <div class="tip-container">
      <div class="tip-heading-container">
        <div class="tip-heading">
          <span class="tip-title">Supported libraries</span>
        </div>
      </div>

      <div class="logos-container">
        <div class="logo-container">
          <img src="assets/images/pytorch.png">
        </div>
        <div class="logo-container">
          <img src="assets/images/lightgbm.png">
        </div>
        <div class="logo-container">
          <img src="assets/images/huggingface.png">
        </div>
        <div class="logo-container">
          <img src="assets/images/tensorflow.png">
        </div>
        <div class="logo-container">
          <img src="assets/images/keras.png">
        </div>
        <div class="logo-container">
          <img src="assets/images/dmlcxgboost.png">
        </div>
        <div class="logo-container">
          <img src="assets/images/scikitlearn.png">
        </div>
        <div class="logo-container">
          <div class="own">
            <h4>Your own model</h4>
          </div>
        </div>
        <div class="logo-container spacer"></div>
      </div>
    </div>

    <div class="tip-container">
      <div class="tip-heading-container">
        <div class="tip-heading">
          <span class="tip-title">Supported models</span>
          <shai-icon-tip></shai-icon-tip>
        </div>
        <div *ngIf="showUpgradePlan" class="upgrade-plan-container">
          <a class="upgrade-plan" routerLink="/plans">Upgrade your plan to get all these model types</a>
        </div>
      </div>

      <div class="cards-container">
        <shai-card-tip
          title="Neural Networks for Classification"
          group="Neural Networks"
          >
          <shai-icon-neural-network-32
            class="icon-tip"
            icon
          ></shai-icon-neural-network-32>
        </shai-card-tip>
        <shai-card-tip
          title="Neural Networks for Regression"
        >
          <shai-icon-neural-network-32
            class="icon-tip"
            icon
          ></shai-icon-neural-network-32>
        </shai-card-tip>

        <shai-card-tip
          title="Linear Regression"
          group="Regression"
          >
          <shai-icon-linear-regression-32
            class="icon-tip"
            icon
          ></shai-icon-linear-regression-32>
        </shai-card-tip>
        <shai-card-tip
          title="Logistic Regression"
        >
          <shai-icon-logistic-regression-32
            class="icon-tip"
            icon
          ></shai-icon-logistic-regression-32>
        </shai-card-tip>
        <shai-card-tip
          title="Support Vector Machines"
        >
          <shai-icon-svm-32
            class="icon-tip"
            icon
          ></shai-icon-svm-32>
        </shai-card-tip>

        <shai-card-tip
          title="K-Means"
          group="Clustering"
          >
          <shai-icon-kmeans-32
            class="icon-tip"
            icon
          ></shai-icon-kmeans-32>
        </shai-card-tip>

        <shai-card-tip
          title="Random Forest"
          group="Ensemble"
          >
          <shai-icon-random-forest-32
            class="icon-tip"
            icon
          ></shai-icon-random-forest-32>
        </shai-card-tip>
        <shai-card-tip
          title="Gradient Boosting"
        >
          <shai-icon-gradient-boosting-32
            class="icon-tip"
            icon
          ></shai-icon-gradient-boosting-32>
        </shai-card-tip>

        <shai-card-tip
          title="Naive Bayes"
          group="Bayesian"
          >
          <shai-icon-naive-bayes-32
            class="icon-tip"
            icon
          ></shai-icon-naive-bayes-32>
        </shai-card-tip>
        <shai-card-tip
          title="Tree Augmented Naive"
        >
          <shai-icon-naive-bayes-32
            class="icon-tip"
            icon
          ></shai-icon-naive-bayes-32>
        </shai-card-tip>

        <shai-card-tip
          title="Iterative Dochotomizer 3 (ID3)"
          group="Decision Tree"
          >
          <shai-icon-id3-32
            class="icon-tip"
            icon
          ></shai-icon-id3-32>
        </shai-card-tip>

        <shai-card-tip
          title="Large Language Models (LLMs)"
          group="Transformer"
          >
          <shai-icon-llm-32
            class="icon-tip"
            icon
          ></shai-icon-llm-32>
        </shai-card-tip>
      </div>

    </div>
  `,
  styleUrls: [`./upload-files-tip.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadFilesTipComponent implements OnInit, OnDestroy {
  private plan?: any;
  private plan$: Observable<any> = new Observable();
  private planSubscription?: Subscription;
  showUpgradePlan: boolean = false;

  constructor(
    private store: Store
  ) {
    this.plan$ = this.store.pipe(select(fromPlatform.getPlan)) as Observable<string>;
  }

  ngOnInit(): void {
    this._setPlanSubscription();
  }

  ngOnDestroy() {
    this.planSubscription?.unsubscribe();
  }

  private _setPlanSubscription() {
    this.planSubscription = this.plan$.subscribe((plan) => {
      if(plan) {
        this.plan = plan;
        this.checkUpgradePlan();
      }
    })
  }

  checkUpgradePlan() {
    if(this.plan.availableModels) {
      const allAlgorithmIds = configMock.algorithms.map(algorithm => algorithm.id);
      this.showUpgradePlan = !allAlgorithmIds.every(id => this.plan.availableModels.includes(id));
    }
  }
}
