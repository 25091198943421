import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { getDocumentationRoute } from 'src/app/utils';
import { AccessControlService } from '../../services/access-control.service';

@Component({
  selector: 'shai-card-federated',
  template: `
    <div class="card-container" [ngClass]="disabled?'disabled':''">
      <div class="card-inner">
        <ng-content select="[icon]"></ng-content>
        <shai-button
          *ngIf="isParadigm"
          class="documentation-btn"
          [type]="'button'"
          iconName="icon-document-16"
          [buttonStyle]="'contextual'"
          target="_blank"
          tooltipText="View document"
          [disabled]="disabled"
          (click)="viewDocumentation()"
        ></shai-button>
        <img *ngIf="value ==='bvfl' && !disabled" class="leaf" src="../../../../assets/images/leaf.png" alt="green-leaf">
        <img *ngIf="value ==='bvfl' && disabled" class="leaf" src="../../../../assets/images/disabled-leaf.png" alt="disabled-leaf">
        <div class="card-content">
          <h4 *ngIf="type === 'service'">{{title}}</h4>
          <h5 *ngIf="type === 'aggregator' || type ==='privacy'">{{title}}</h5>
          <ng-content select="[description]"></ng-content>
        </div>
        <div class="tags-bottom" [ngClass]="{ 'absolute-tags': absoluteTags }">
          <div class="tag-content">
            <div class="tag" *ngFor="let tag of tags">{{ tag }}</div>
          </div>
        </div>
        <ng-content select="[tip]"></ng-content>
      </div>
    </div>
  `,
  styleUrls: [`./card-federated.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardFederatedComponent {
  @Output() onSelect = new EventEmitter();
  @Input() type: 'service' | 'aggregator' | 'privacy' = 'service';
  @Input() title: string = '';
  @Input() selected: boolean = false;
  @Input() value: string | undefined;
  @Input() disabled: boolean = false;
  @Input() tags: string[] = [];
  @Input() isParadigm: boolean = false;
  @Input() absoluteTags: boolean = false;

  @HostBinding('class') get typeClass() {
    return `type-${this.type}`
  }
  @HostBinding('class.active') get activeClass() {
    return this.selected;
  }

  @HostListener('click', []) public click() {
    if (!this.disabled) {
      this.onSelect.emit(this.value);
    }
  }

  constructor( private _accessControlService: AccessControlService ) {}

  viewDocumentation(): void {
    let route;
    switch (this.value) {
      case 'hfl':
        route = this._accessControlService.getBaseUrlByUserRole() + getDocumentationRoute('hfl');
        break;
      case 'vfl':
        route = this._accessControlService.getBaseUrlByUserRole() + getDocumentationRoute('vfl');
        break;
      case 'bvfl':
        route = this._accessControlService.getBaseUrlByUserRole() + getDocumentationRoute('bvfl');
        break;        
      default:
        break;
    }
    window.open(route, '_blank');
  }
}
