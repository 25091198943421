import { createReducer, on } from "@ngrx/store";
import { InferenceFiles, NewInferenceResponse } from "../models";
import * as NewInferenceActions from '../actions/new-inference.actions';

export const newInferenceFeatureKey = 'newInference';

export interface State {
  newInference?: NewInferenceResponse | null,
  participatingNodes: string[];
  mappings: any[];
  savedFiles: InferenceFiles;
  inferenceCreationCompleted: boolean;
}

const initialState: State = {
  newInference: null,
  participatingNodes: [],
  mappings: [],
  savedFiles: { files: [] },
  inferenceCreationCompleted: false
}

export const reducer = createReducer(
  initialState,

  on(NewInferenceActions.dataInferenceParticipatingNodes, (state, { participatingNodes }) => ({...state, participatingNodes})),
  on(NewInferenceActions.dataInferenceMappings, (state, { mappings }) => ({...state, mappings})),
  on(NewInferenceActions.dataSavedFiles, (state, { savedFiles }) => ({...state, savedFiles})),

  on(NewInferenceActions.createNewInference, (state) => ({ ...state, status: { pending: true }})),
  on(NewInferenceActions.createNewInferenceSuccess, (state, { inference }) => ({...state, newInference: inference, status: { pending: false }})),
  on(NewInferenceActions.createNewInferenceFailure, (state, { error }) => ({ ...state, status: { pending: false, error } })),

  on(NewInferenceActions.chooseInferenceParticipatingNodes, (state) => ({ ...state, participatingNodesStatus: { pending: true }})),
  on(NewInferenceActions.chooseInferenceParticipatingNodesSuccess, (state, { participatingNodes }) => ({...state, participatingNodes})),
  on(NewInferenceActions.chooseInferenceParticipatingNodesFailure, (state, { error }) => ({ ...state, status: { pending: false, error } })),

  on(NewInferenceActions.inferenceCreationCompleted, (state) => ({ ...state, inferenceCreationCompleted: true })),
  on(NewInferenceActions.resetNewInferenceState, (state) => initialState)
);

export const getNewInference = (state: State) => state?.newInference;
export const getParticipatingNodes = (state: State) => state?.participatingNodes;
export const getMappings = (state: State) => state?.mappings;
export const getSavedFiles = (state: State) => state?.savedFiles;
export const getInferenceCreationCompleted = (state: State) => state?.inferenceCreationCompleted;
