import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-arrow-up-1-16',
  template: `
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m14.6465 8.35351.7071-.70711-7.35356-7.353553-7.353556 7.353553.707106.70711 6.14649-6.14649v13.79298h1v-13.7929z" fill="#1f1f1f"/></svg>
  `
})
export class IconArrowUp116 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}

