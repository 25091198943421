import { Directive, HostBinding, HostListener, Output, EventEmitter, Input } from "@angular/core";

@Directive({
  selector: '[shaiDragDropFileUpload]'
})
export class DragDropFileUploadDirective {
  @Output() fileDropped = new EventEmitter();
  @Input() fileCount: number = 0;
  isMouseover = false;

  @HostBinding('class.mouseover') get mouseoverClass() {
    const noFilesAdded = this.fileCount === 0;
    return this.isMouseover && noFilesAdded;
  }

  // Mouseover Event
  @HostListener('mouseover', ['$event']) mouseOver(event:any) {
    event.preventDefault();
    event.stopPropagation();
    this.isMouseover = true;
  }

   // Mouseleave Event
  @HostListener('mouseleave', ['$event']) public mouseLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.isMouseover = false;
  }

  // Dragover Event
  @HostListener('dragover', ['$event']) dragOver(event:any) {
    event.preventDefault();
    event.stopPropagation();
  }

   // Dragleave Event
  @HostListener('dragleave', ['$event']) public dragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }

    // Drop Event
    @HostListener('drop', ['$event']) public drop(event: any) {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files)
    }
  }

  // Change Event
  @HostListener('change', ['$event']) public change(event: any) {
    event.preventDefault();
    event.stopPropagation();

    const files = (event.target as HTMLInputElement).files;

    if (files != null && files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
