import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-svm-32',
  template: `
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15.9561" cy="14.5651" r="1.08242" stroke="#89D5DD" />
      <circle cx="20.7031" cy="13.7738" r="1.08242" stroke="#89D5DD" />
      <circle cx="13.5824" cy="19.3123" r="1.08242" stroke="#89D5DD" />
      <circle cx="18.3296" cy="20.1035" r="1.08242" stroke="#89D5DD" />
      <line
        x1="18.4336"
        y1="1.9009"
        x2="4.69195"
        y2="23.1569"
        stroke="#1F1F1F"
      />
      <line
        x1="9.07135"
        y1="1.11472"
        x2="9.07135"
        y2="5.40043"
        stroke="#78C6E4"
      />
      <line
        x1="2.21429"
        y1="2.82903"
        x2="2.21429"
        y2="7.11474"
        stroke="#78C6E4"
      />
      <line
        x1="4.78571"
        y1="8.82903"
        x2="4.78571"
        y2="13.1147"
        stroke="#78C6E4"
      />
      <line
        x1="11.1429"
        y1="3.32903"
        x2="6.85723"
        y2="3.32903"
        stroke="#78C6E4"
      />
      <line
        x1="4.28571"
        y1="5.04335"
        x2="-8.58307e-06"
        y2="5.04335"
        stroke="#78C6E4"
      />
      <line
        x1="6.85706"
        y1="11.0434"
        x2="2.57134"
        y2="11.0434"
        stroke="#78C6E4"
      />
      <line
        x1="24.262"
        y1="4.11168"
        x2="1.97634"
        y2="17.826"
        stroke="#1F1F1F"
      />
      <line
        x1="22.6323"
        y1="1.4749"
        x2="0.346546"
        y2="22.9035"
        stroke="#1F1F1F"
      />
    </svg>
  `,
})
export class IconSVM32 {
  @HostBinding('class.shai-icon') get iconClass() {
    return true;
  }
}
