import { IRadio } from 'src/app/shared/components/radio.component';

export interface RequestAccessUIBlock {
  title: string;
  description: string;
  mode: RequestAccessMode;
  triggerText: string;
  data: IRadio;
  featured: boolean
}

export enum RequestAccessMode {
  Experimental = 'EXP',
  Enterprise = 'ENT',
  Hub = 'HUB',
}


export interface RequestAccessParams {
  type: string;
  name: string;
  attributes: RequestAccessParamsAttr;
}

export interface RequestAccessParamsAttr {
  email: string;
  termsUse: boolean;
  privacyPolicy: boolean;
  phone?: string;
  commercialCommunications?: boolean;
  company?: string;
  title?: string;
  description?: string;
}

const radioGroup = 'requestAccess';

export function getInitialBlocks(): RequestAccessUIBlock[] {
  return [
    {
      title: 'Experimentation',
      description:
        'Test our platform and run distributed Federated Learning experiments',
      mode: RequestAccessMode.Experimental,
      triggerText: 'JOIN THE WAITLIST',
      data: {
        checked: true,
        disabled: false,
        group: radioGroup,
        value: RequestAccessMode.Experimental.valueOf(),
      },
      featured: true
    },
    {
      title: 'Enterprise',
      description:
        "Maximize the value of your organization's data overcoming regulatory, privacy or security limitations.",
      mode: RequestAccessMode.Enterprise,
      triggerText: 'Talk to our sales team',
      data: {
        checked: false,
        disabled: false,
        group: radioGroup,
        value: RequestAccessMode.Enterprise.valueOf(),
      },
      featured: false
    },
    {
      title: 'Private collaborative data hub',
      description:
        'Build your private distributed data environment and allow others to take advantage of your data without sharing it.',
      mode: RequestAccessMode.Hub,
      triggerText: 'Talk to our sales team',
      data: {
        checked: false,
        disabled: false,
        group: radioGroup,
        value: RequestAccessMode.Hub.valueOf(),
      },
      featured: false
    },
  ];
}
