import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectActions, ProjectService } from 'src/app/platform';

@Component({
  selector: 'shai-card-project',
  template: `
    <div class="card-project" [ngClass]="{'simple-card': simpleCard, 'empty': empty, 'with-tag': withTag}">
      <div *ngIf="!simpleCard" class="card-project-c1">
        <div class="avatar">
          <ng-content select="[c1]"></ng-content>
        </div>
      </div>
      <div class="card-project-c2">
        <div class="card-title heading">
          <ng-content select="[title]"></ng-content>
        </div>
        <div class="card-subtitle paragraph">
          <ng-content select="[subtitle]"></ng-content>
        </div>
        <div>
          <ng-content select="[tags]"></ng-content>
        </div>
      </div>
      <div *ngIf="!simpleCard" class="card-project-c3 paragraph-small">
        <div class="card-project-c3-r1"><ng-content select="[c3-r1]"></ng-content></div>
        <div class="card-project-c3-r2"><ng-content select="[c3-r2]"></ng-content></div>
        <div class="card-project-c3-r3"><ng-content select="[c3-r3]"></ng-content></div>
      </div>
      <div *ngIf="!simpleCard" class="card-project-c4 paragraph-small">
        <div class="card-project-c4-r1"><ng-content select="[c4-r1]"></ng-content></div>
        <div class="card-project-c4-r2"><ng-content select="[c4-r2]"></ng-content></div>
        <div class="card-project-c4-r3"><ng-content select="[c4-r3]"></ng-content></div>
      </div>
      <div class="card-project-c5">
        <ng-content select="[c5]"></ng-content>
      </div>
      <div>
        <ng-content select="[top-right]"></ng-content>
      </div>
    </div>
  `,
  styleUrls: [`./card-project.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardProjectComponent {
  @Input() empty: boolean = false;
  @Input() simpleCard: boolean = false;
  @Input() withTag: boolean = false;
  @Input() projectSuid: string | undefined = '';

  deleteProject(suid: string | undefined) {
    if(suid) {
      this.store.dispatch(ProjectActions.deleteProject({ suid }));
    }
  }

  constructor(
    private store: Store
  ) {}
}
