import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-aggregator-sv-24',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20 1H4C2.34315 1 1 2.34315 1 4V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V4C23 2.34315 21.6569 1 20 1ZM4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0H4Z" fill="#DCDDE0"/>
    <path d="M8.835 15.081C10.293 15.081 11.193 14.397 11.193 13.272C11.193 12.309 10.572 11.706 9.402 11.463L8.547 11.283C7.881 11.139 7.476 10.86 7.476 10.347C7.476 9.636 8.016 9.339 8.826 9.339C9.798 9.339 10.248 9.807 10.284 10.491H11.085C11.076 9.375 10.194 8.619 8.844 8.619C7.449 8.619 6.657 9.285 6.657 10.347C6.657 11.31 7.242 11.814 8.331 12.048L9.249 12.246C9.933 12.39 10.374 12.66 10.374 13.272C10.374 14.01 9.834 14.37 8.835 14.37C7.845 14.37 7.296 13.893 7.251 13.11H6.45C6.486 14.298 7.305 15.081 8.835 15.081ZM13.6755 15H14.4585L16.2405 10.491V10.401H15.4575L14.0985 14.118H14.0625L12.6495 10.401H11.8665V10.491L13.6755 15Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class AggregatorSv24 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}