import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { fadeIn } from 'src/app/shared/animations';
import { CdkStep } from '@angular/cdk/stepper';
import { StepState } from 'src/app/shared/models/step-state';
import { Store } from '@ngrx/store';
import { AuthActions } from '../state/actions';
@Component({
  selector: 'shai-reset-password-changed',
  animations: [fadeIn],
  template: `
    <div id="reset-password-changed-wrapper" @fadeIn>
      <div id="stablish-min-height">
        <ng-lottie
          [options]="animationOptions"
          (animationCreated)="handleAnimation($event)"
        >
        </ng-lottie>
      </div>
      <shai-title-bar
        title="Password changed"
        bottomDescription="We must ask you to log in again using your new credentials."
        [showExtraSpace]="false">
      </shai-title-bar>
      <shai-action-bar>
        <ng-container left-cell>
          <shai-button
            [type]="'button'"
            [stretch]="true"
            (click)="goToHome()"
            buttonStyle="primary"
          >Go to Sign in</shai-button>
        </ng-container>
      </shai-action-bar>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./reset-password-changed.component.scss']
})
export class ResetPasswordChangedComponent implements OnChanges {
  @Input() step: CdkStep | undefined;
  @Input() isResetPassComplete!: boolean;
  @Output() onChange = new EventEmitter<{ state: StepState; data: any }>();
  showTrainingComplete = false;
  rowHeight = '';
  private anim: any;
  animationOptions: AnimationOptions = {
    path: '/assets/lotties/checkmark.json',
    loop: false,
    autoplay: false,
  };

  constructor(
    private store: Store,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isResetPassComplete?.currentValue === true) {
      if (this.anim) {
        this.anim.play();
      }
    }
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  goToHome() {
    this.onChange.emit({ state: StepState.finish, data: null });
    this.store.dispatch(AuthActions.clearReset());
  }
}
