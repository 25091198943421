import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'shai-not-found',
  template: `
    <shai-error-template
      title="Page not found"
      description="The page you’re looking does not exist or was removed."
      errorCode="404"
      imageName="404"
    >
    </shai-error-template>
  `,
  styleUrls: ['./global-error-page.scss'],
})
export class NotFoundComponent implements OnInit {
  ngOnInit(): void {}
}
