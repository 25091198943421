import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

const LAYOUT_MAX_WIDTH = 1300;
const DEFAULT_WIDTH = 110;
@Component({
  selector: 'shai-scroll-hint',
  template: `
    <div class="scroll-hint-container" *ngIf="showControls"
    [ngStyle]="{'width.px': scrollHintWidth}">
      <div
        class="scroll-controls-container"
        [ngStyle]="{ 'padding-bottom.px': bottomSpace }"
      >
        <div class="scroll-control top-control">
          <shai-button-icon
            [disabled]="!enabledScrollToTop"
            (click)="scrollTop()"
          >
            <shai-icon-arrow-up-1-16></shai-icon-arrow-up-1-16>
          </shai-button-icon>
        </div>
        <div class="scroll-control bottom-control">
          <shai-button-icon
            [disabled]="!enabledScrollToBottom"
            (click)="scrollBottom()"
          >
            <shai-icon-arrow-down-1-16></shai-icon-arrow-down-1-16>
          </shai-button-icon>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./scroll-hint.component.scss'],
})
export class ScrollHintComponent implements OnInit {
  @Input() set totalContentHeight(value: number | undefined) {
    if (value) {
      this._totalContentHeight = value;
      this.update()
    }
  }
  @Input() bottomSpace: number = 90;

  @Input() set scrollContentY(value: number | undefined) {
    if (value) {
      this._scrollContentY = value;
      this.update()
    }
  }
  @Input() refElement?: ElementRef<any>;

  private _totalContentHeight: number = 0;
  private _scrollContentY?: number
  private minTopOffset = 100;
  private offsetOverlay = 96;

  viewportHeight = 0;
  windowScrollY = 0;
  currentScrollValue = 0;

  showControls = false;
  enabledScrollToTop = false;
  enabledScrollToBottom = false;

  scrollHintWidth = DEFAULT_WIDTH;

  constructor() {
    this.viewportHeight = window.innerHeight;
  }

  @HostListener('window:scroll', ['$event']) onscroll() {
    this.windowScrollY = window.scrollY;
    this.update();
  }

  @HostListener('window:resize', ['$event']) onResize() {
    this.viewportHeight = window.innerHeight;
    this.update();
    this.recalculateScrollHintWidth();
  }

  update() {
    this.showControls = this._totalContentHeight > this.viewportHeight;
    this.currentScrollValue = this._scrollContentY
    ? this._scrollContentY
    : this.windowScrollY;

    this.enabledScrollToTop = this.currentScrollValue >= this.minTopOffset;
    const offsetOverlay = this._scrollContentY ? this.offsetOverlay : 0;
    const delta = this._totalContentHeight - this.currentScrollValue + offsetOverlay;
    this.enabledScrollToBottom = ((this._totalContentHeight > this.viewportHeight) &&
    (delta > this.viewportHeight));
  }

  ngOnInit(): void {
    this.recalculateScrollHintWidth();
  }

  private recalculateScrollHintWidth() {
    const innerWidth = window.innerWidth;
    if (innerWidth > LAYOUT_MAX_WIDTH) {
      const calculatedWidth = (innerWidth - LAYOUT_MAX_WIDTH) / 2
      this.scrollHintWidth = calculatedWidth > DEFAULT_WIDTH ? calculatedWidth : DEFAULT_WIDTH
    } else {
      this.scrollHintWidth = DEFAULT_WIDTH;
    }

    console.log('recalculateScrollHintWidth ', this.scrollHintWidth  )
  }

  scrollTop() {
    if (this.enabledScrollToTop) {
      const delta = this.currentScrollValue - this.viewportHeight;
      const top = delta <= this.viewportHeight ? 0 : delta;
      if (this.refElement) {
        this.refElement.nativeElement.scroll({
          top,
          left: 0,
          behavior: 'smooth',
        });
      } else {
        window.scroll({
          top,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }

  scrollBottom() {
    if (this.enabledScrollToBottom) {
      if (this.refElement) {
        this.refElement.nativeElement.scroll({
          top: this.currentScrollValue + this.viewportHeight,
          left: 0,
          behavior: 'smooth',
        });
      } else {
        window.scroll({
          top: this.currentScrollValue + this.viewportHeight,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }
}
