import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-aggregator-bi-24',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DCDDE0"/>
        <path d="M7.68047 15.8H11.1365C12.7805 15.8 13.8125 14.912 13.8125 13.376C13.8125 12.188 13.0925 11.612 12.4445 11.348V11.3C13.0685 11.024 13.5005 10.496 13.5005 9.58399C13.5005 8.20399 12.4685 7.39999 11.0525 7.39999H7.68047V15.8ZM8.71247 11V8.33599H10.9325C11.8925 8.33599 12.4325 8.79199 12.4325 9.58399C12.4325 10.568 11.7725 11 10.8005 11H8.71247ZM8.71247 14.852V11.912H11.0165C12.0845 11.912 12.7325 12.392 12.7325 13.376C12.7325 14.336 12.0125 14.852 11.0165 14.852H8.71247ZM16.0567 8.49199C16.5127 8.49199 16.7527 8.21599 16.7527 7.78399C16.7527 7.36399 16.5127 7.08799 16.0567 7.08799C15.5887 7.08799 15.3607 7.36399 15.3607 7.78399C15.3607 8.21599 15.5887 8.49199 16.0567 8.49199ZM15.5527 15.8H16.5607V9.66799H15.5527V15.8Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class AggregatorBi24 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
