import { NgxMatNativeDateAdapter } from '@angular-material-components/datetime-picker';
import { Injectable } from "@angular/core";
import * as moment from 'moment';

@Injectable()
export class CustomDateAdapter extends NgxMatNativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 1; // Monday
  }
  // Format the date, according to CUSTOM_DATE_FORMATS
  format(date: Date, displayFormat: Object): string {
    return moment(date).format(displayFormat.toString());       
  }  
}