import { ItemFile } from "./data-model";
import { EntityStatus, Stamp } from "./node";
import { Config } from "./organization";

export enum OwnProjectsOrderEnum {
  OWN_PROJECT_GROUP = 1000001,
  OWN_PROJECT_ITEM = 1000000
}

export interface ProjectStatus {
    pending: boolean;
    error?: any;
}

export interface ProjectList {
    page: number;
    pageSize: number;
    items: Project[];
}

export interface Project {
    config: Config;
    entityStatus: EntityStatus[];
    files: ItemFile[];
    projectSuid: string;
    stamp: Stamp;
}

export interface SortProjectList {
    project: Project,
    order: number,
    dataset?: any[],
    loadingDataTraining?: boolean;
}

export interface SortedGroupList {
    group: string;
    order?: number;
    projects: SortProjectList[];
}

export interface ProjectResponse {
    projectSuid: string;
    config?: Config;
}

export interface GroupsData {
    groupList: string [];
    sortedGroupsProjectsList: SortedGroupList[];
}

// WIP INFERENCE
export enum TrainingCategory {
    TRAINING = 'training',
    CHAT = 'chat',
    ANALYTICS = 'analytics',
    INFERENCE = 'inference',
}
