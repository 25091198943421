import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'shai-info-hover',
  template: `
    <div class="info-hover-container">
      <shai-icon-info-16
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
      ></shai-icon-info-16>
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="showContent"
      >
        <div
          class="content-container"
          [ngClass]="{ 'small-width': size === 'small' }"
        >
          <div class="content">
            <span class="paragraph">{{ content }}</span>
          </div>

          <div *ngIf="hint" class="hint">
            <ng-content
              id="left-icon"
              class="left-icon"
              select="[left-icon]"
            ></ng-content>
            <span class="paragraph-small text-hint">{{ hint }}</span>
          </div>
        </div>
      </ng-template>
    </div>
  `,
  styleUrls: ['./info-hover.component.scss'],
})
export class InfoHoverComponent {
  showContent = false;
  @Input() content: string = '';
  @Input() size: 'small' | 'normal' = 'normal';
  @Input() hint?: string;

  positionPairs: ConnectionPositionPair[] = [
    {
      offsetX: 0,
      offsetY: 25,
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
    },
  ];

  // Mouseover Event
  @HostListener('mouseover', ['$event']) mouseOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.showContent = true;
  }

  // Mouseleave Event
  @HostListener('mouseleave', ['$event']) public mouseLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.showContent = false;
  }
}
