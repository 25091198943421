import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ListItem } from 'src/app/shared/components/list-items/list-item.model';

@Component({
    selector: 'shai-list-item',
    template: `
            <shai-list-item-singletext *ngIf="!listItem?.subtitle" (handleItemClick)="onListItemClick(listItem.title)" [listItem]="listItem" [length]="length">
                <div *ngIf="listItem?.filter" class="checkbox-left">
                    <shai-list-item-filter [data]="listItem"></shai-list-item-filter>
                </div>
            </shai-list-item-singletext>
            <shai-list-item-multitext *ngIf="listItem?.subtitle" (handleItemClick)="onListItemClick(listItem.title)" [listItem]="listItem" [length]="length">
                <div *ngIf="listItem?.filter" class="checkbox-left">
                    <shai-list-item-filter [data]="listItem"></shai-list-item-filter>
                </div>
            </shai-list-item-multitext>
    `,
    styleUrls: [`./list-item.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListItemComponent implements OnChanges {
    @Input() listItem: any;
    @Input() length: number | undefined;
    @Output() handleItemClick = new EventEmitter();
    constructor() { }

    onListItemClick(itemContent: string) {
        this.handleItemClick.emit(itemContent)
    }

    onFilterClick(title: string) {
        console.log(title);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.length) {
            this.length = changes?.length?.currentValue;
        }
    }
}
