import { Injectable } from '@angular/core';
import { GroupsData, OwnProjectsOrderEnum, Project, SortedGroupList, SortProjectList } from 'src/app/platform';

@Injectable({providedIn: 'root'})
export class ProjectsGroupsService {

  constructor() { }

  createProjectGroups(projectsList: Project[]): GroupsData {

    let groupList: string [] = [];
    let sortedGroupList: SortedGroupList[] = [];

    for (let i = 0; i < projectsList.length; i++) {
      const project = projectsList[i];
      let projectList: SortProjectList[] = [];

      if (this.isNotUndefinedProject(project) && project.config && project.config.tags && !groupList.includes(project.config.tags[1])) {
        groupList.push(project.config.tags[1]);
        for (let index = 0; index < projectsList.length; index++) {
          const element = projectsList[index];
          if (this.isNotUndefinedProject(element) && element.config && element.config.tags && element.config.tags[1] === project.config.tags[1]) {
            if (this.hasOrderProjects(element)) {
              projectList.push({project: element, order: Number(element.config.tags[4]), dataset: [], loadingDataTraining: true });
            } else {
              projectList.push({project: element, order: OwnProjectsOrderEnum.OWN_PROJECT_ITEM, dataset: [], loadingDataTraining: true });
            }
          }
        }
        this.sortedProjectGroups(projectList);
        if (this.hasOrderGroups(project)) {
          sortedGroupList.push({group: project.config.tags[1], order: Number(project.config.tags[3]), projects: projectList});
        } else {
          sortedGroupList.push({group: project.config.tags[1], order: OwnProjectsOrderEnum.OWN_PROJECT_ITEM, projects: projectList});
        }
      }
      if (this.isUndefinedProject(project) && !groupList.includes('undefined')) {
        groupList.push('undefined');
        for (let index = 0; index < projectsList.length; index++) {
          const element = projectsList[index];
          if (this.isUndefinedProject(element)) {
            projectList.push({project: element, order: OwnProjectsOrderEnum.OWN_PROJECT_ITEM, dataset: [], loadingDataTraining: true });
          }
        }
        sortedGroupList.push({group: 'undefined', order: OwnProjectsOrderEnum.OWN_PROJECT_GROUP, projects: projectList});
      }
    }

    return { groupList: groupList, sortedGroupsProjectsList: sortedGroupList };
}

  hasOrderProjects(project: Project): boolean {
    return project.config && project.config.tags && project.config.tags[4] && project.config.tags[4].toLowerCase() !== 'undefined' && Number(project.config.tags[4]) > 0 && Number(project.config.tags[4]) < OwnProjectsOrderEnum.OWN_PROJECT_ITEM ? true : false;
  }

  hasOrderGroups(project: Project): boolean {
    return project.config && project.config.tags && project.config.tags[3] && project.config.tags[3].toLowerCase() !== 'undefined' && Number(project.config.tags[3]) > 0 && Number(project.config.tags[3]) < OwnProjectsOrderEnum.OWN_PROJECT_ITEM ? true : false;
  }

  isNotUndefinedProject(project: Project): boolean {
    return project.config && project.config.tags && project.config.tags.length > 0 && project.config.tags[1] && project.config.tags[1].toLowerCase() !== 'undefined' ? true : false;
  }

  isUndefinedProject(project: Project): boolean {
    return project.config && (!project.config.tags || (project.config.tags && project.config.tags.length === 0) || (project.config.tags && project.config.tags.length > 0 && !project.config.tags[1]) || (project.config.tags && project.config.tags.length > 0 && project.config.tags[1].toLowerCase() === 'undefined')) ? true : false;
  }

  sortedProjectGroups(array: any[]): void {
    array.sort((a, b) => Number(a['order']) - Number(b['order']));
  }

}
