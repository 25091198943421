import { EntityStatus } from '../platform/models/node';
import { Node } from 'src/app/platform';
import { TableCellSingleComponent } from '../shared/components/table/rows/cells/table-cell-single.component';
import { getTimestampFormatted } from './date';

const MODEL_POS = 5;

export const blueImg: string = "<img width='6' height='8' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAMCAYAAABbayygAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAOklEQVQYld2QMRIAIAzCQvXJ/tB3KS7OtbO5YwsDiDFNgahI34kdWMC+JWViu0mRbQGv0xUFCcDlMQc4+ApRTup5DwAAAABJRU5ErkJggg=='/> &nbsp;"
export const grayImg: string = "<img width='6' height='8' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAMCAYAAABbayygAAAACXBIWXMAAAsTAAALEwEAmpwYAAAALklEQVQYlWPs6pv8n4EIwESMolGFBAHj////GRkYGAgFOiMTEYoYGBgY/hNtNQCEuwi9KR378AAAAABJRU5ErkJggg=='/> &nbsp;"
export const greenImg: string = "<img width='6' height='8' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAMCAYAAABbayygAAAACXBIWXMAAAsTAAALEwEAmpwYAAAALklEQVQYlWNkWDrhPwMRgIkYRaMKCQLG////MzIwMBAKdEYmIhQxMDAw/CfaagCiSQhH5sXQBgAAAABJRU5ErkJggg=='/> &nbsp;"
export const orangeImg: string = "<img width='6' height='8' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAMCAYAAABbayygAAAACXBIWXMAAAsTAAALEwEAmpwYAAAALUlEQVQYlWN8GMjwn4EIwESMolGFBAHj////GRkYCAY6IxMRihgYGBj+E201AJ38CEQt/FLJAAAAAElFTkSuQmCC'/> &nbsp;"

const states = [
  { id: 3021, priority: 1 },
  { id: 3022, priority: 2 },
  { id: 3023, priority: 3 },
  { id: 3024, priority: 4 },
  { id: 3025, priority: 5 },
  { id: 3026, priority: 6 },

  { id: 3004, priority: 7 },
  { id: 3005, priority: 8 },
  { id: 3006, priority: 9 },
  { id: 3007, priority: 10 },
  { id: 0, priority: 11 },
];

const normaliceTags = (tags: string[]) => {
  return tags.map(tag => tag.toLowerCase().replace(/-|_/g, ''))
}

const normaliceTag = (tag: string) => {
  return tag.toLowerCase().replace(/-|_/g, '')
}

const isValidTag = (tag: any) => {
  return tag && tag !== 'undefined'
}

const isTagIncluded = (ts: string[], t: string) => {
  const tags = normaliceTags(ts)
  const tag = normaliceTag(t)
  return (tags.slice(MODEL_POS, tags.length)).includes(tag)
}

export const nodeColorPalette: string[] = ['#E53837', '#FF5898', '#E76405', '#F2A71B',
'#EBD84B', '#A7D241', '#30B073', '#148DEA', '#6634BB', '#9B36CA'];
export const aggregatorOrServerColor: string = '#0073BF';

export const isEnabled = (entityStatus: EntityStatus[]) => {
  // 1. existe el estado NodeEnabled (id: 4001)
  // 2. existe el estado NodeDisabled (id: 4002)
  // 3. no existe ninguno de los dos estados (nodos nuevos)
  // Cuando se crea y se registra un nodo nuevo, no va a crearse ninguno de los dos estados.
  // Cuando se llame a alguno de los endpoint para enable o disable del nodo, se creará o se sustituirá
  // por el estado correspondiente, y existirá a partir de entonces
  return entityStatus.some((status) => status.id === 4001);
};

export const isNodeAvailable = (node: Node): boolean => {
  return node?.location &&
  isEnabled(node?.entityStatus) &&
  isAvailable(node.isSherpa, []) &&
  !isLinked(node?.entityStatus)
}

export const isAvailable = (isSherpa: boolean, nodetags: string[], firstProjectTag: string = '', trainingModel: string = '') => {
  let response = true;
  if (nodetags.length > 0) {
    if (isSherpa) {
      response = isAvailableByProject(nodetags, firstProjectTag) && isAvailableByModel(nodetags, trainingModel);
    }
  }
  return response;
}


export const isAvailableByProject = (nodetags: string[], firstProjectTag: string = '') => {
  const tags = normaliceTags(nodetags)
  const projectTag = normaliceTag(firstProjectTag)
  return !(
    isValidTag(tags[1]) &&
    isValidTag(projectTag) &&
    tags[1] !== projectTag
  )
}

export const isAvailableByModel = (nodetags: string[], tModel: string = '') => {
  const tags = normaliceTags(nodetags)
  const trainingModel = normaliceTag(tModel)
  const result = !(
    tags.length > MODEL_POS &&
    isValidTag(tags[MODEL_POS]) &&
    isValidTag(trainingModel) &&
    !isTagIncluded(tags, trainingModel)
  )
  return result
}

export const isSherpaNode = (node: Node): boolean => {
  return node.isSherpa ? true : false;
}

export const isEnableAndNotLinked = (entityStatus: EntityStatus[]): boolean => {
  return isEnabled(entityStatus) && !isLinked(entityStatus);
}

export const isLinked = (entityStatus: EntityStatus[]) => {
  return entityStatus.some((status) => status.id === 4004);
};

const orderStatusByPriority = (entityStatus: EntityStatus[]) => {
  const sorted = entityStatus.sort((a, b) => {
    const aFeatured = states.find((x) => x.id === a.id);
    const bFeatured = states.find((x) => x.id === b.id);

    if (aFeatured && bFeatured) {
      return aFeatured.priority > bFeatured.priority ? 1 : -1;
    } else if (aFeatured) {
      return -1;
    }
    return 1;
  });

  return sorted;
};

export const getLastTrainingSessionState = (entityStatus: EntityStatus[]) => {
  const priorityStatus = orderStatusByPriority(entityStatus)[0];
  return priorityStatus;
}

export const getTrainedText = (entityStatus: EntityStatus[]) => {
  const status = getLastTrainingSessionState(entityStatus);

  // WIP add 5000 inference code states
  // WIP INFERENCE

  switch (true) {
    case status.id === 0:
      return 'Not trained';
    case status.id === 3004:
      return 'Running';
    case status.id === 3005:
      return 'Paused';
    case status.id === 3006:
      return 'Stopped';
    case status.id === 3007:
      return 'Configuring';
    case status.id === 3010:
      return 'Deployed';
    case status.id === 3021:
      return getTimestampFormatted(status.timestamp);
    case status.id >= 3023 && status.id <= 3025:
      return 'Aborted';
    case status.id === 5010:
      return 'Inference Deployed';
    case status.id === 5004:
      return 'Inference Running';
    case status.id === 5021:
      return getTimestampFormatted(status.timestamp);
    default:
      return getTimestampFormatted(status.timestamp);
  }
};
