import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import jwtDecode from "jwt-decode";
import { fromEvent, Observable, of, throwError, combineLatest, merge, Subscription } from "rxjs";
import { catchError, map, tap, switchMap, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthResponse, User } from '../models/auth.models';
import { AuthActions } from "../state/actions";
import * as fromAuth from 'src/app/auth/state/selectors/auth.selectors';
import * as fromPlatform from 'src/app/platform/reducers';
import { getDateDifferenceInSeconds } from "src/app/utils";
import { RequestAccessParams } from "../components/request-access/request-access.model";
import { FormGroup } from '@angular/forms';
import {
  SnackbarService,
  SnackbarType,
} from 'src/app/shared/components/snackbar/snackbar.service';

export enum UserRole {
  ExpUser = 'EXPERIMENTAL',
  ExpProUser = 'EXPERIMENTAL_PRO',
  OrgAdmin = 'HUMAN',
}

const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private subscriptionOrg?: Subscription;
  baseUrl: string = environment.baseUrl;
  bearerAuth: string = environment.bearerAuth;
  readonly AUTH_DATA = 'AUTH_DATA';
  timeOutInterval: any;
  refreshInterval: any;
  userSessionLimit = 3600; // 60min
  subscription = new Subscription();

  constructor(
    private router: Router,
    private http: HttpClient,
    private store: Store,
    private snackbarService: SnackbarService,
  ) {}

  checkMinChars(form: FormGroup): boolean {
    return this.passwordHasValue(form) &&
    this.atLeastTwelveCharacters(form)
  }

  checkLowerAndUpper(form: FormGroup): boolean {
    return this.passwordHasValue(form) &&
    this.hasLowerCaseCharacters(form) &&
    this.hasUpperCaseCharacters(form)
  }

  checkLettersAndNumbers(form: FormGroup): boolean {
    return this.passwordHasValue(form) &&
    this.hasLowerCaseCharacters(form) &&
    this.hasUpperCaseCharacters(form) &&
    this.hasNumberCharacters(form)
  }

  passwordHasValue(form: FormGroup): boolean {
    return form.value.password.length > 0;
  }

  atLeastTwelveCharacters(form: FormGroup): boolean {
    return form.value.password.length >= 12;
  }

  checkInvalidCharacters(form: FormGroup): boolean {
    const invalidCharacters = /[<>$]+/;
    return !invalidCharacters.test(form.value.password);
  }

  hasLowerCaseCharacters(form: FormGroup): boolean {
    const lowerCaseCharacters = /[a-z]+/g
    return lowerCaseCharacters.test(form.value.password);
  }

  hasUpperCaseCharacters(form: FormGroup): boolean {
    const upperCaseCharacters = /[A-Z]+/g
    return upperCaseCharacters.test(form.value.password);
  }

  hasNumberCharacters(form: FormGroup): boolean {
    const numberCharacters = /[0-9]+/g
    return numberCharacters.test(form.value.password);
  }

  hasSpecialCharacters(form: FormGroup): boolean {
    const specialCharacters = /[!@#%^&*()_+\-=\[\]{};':"\\|,.\/?]+/
    return specialCharacters.test(form.value.password);
  }

  async goToStripePortal() {
    try {
      const org = await this.store.pipe(select(fromPlatform.getOrganization),take(1)).toPromise();
      if (org?.organizationSuid) {
        const res = await this.getStripePortal(org.organizationSuid, org.aaa.customer).pipe(take(1)).toPromise();
        const stripeCustomerPortal = res.billingPortal.url;
        if (stripeCustomerPortal) {
          window.open(stripeCustomerPortal, '_self');
        } else {
          console.error("Stripe portal URL is not available.");
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async upgradePlan(title: string, subtitle: string) {
    const plan = await this.store.select(fromPlatform.getOrganizationPlan).pipe(take(1)).toPromise();

    if(plan === '0003' || plan === '0004') {
      this.snackbarService.show(
        title,
        SnackbarType.twoButtonsInverted,
        `Upgrade your plan ${subtitle}`,
        false,
        'Upgrade Plan',
        'Cancel',
        () => this.goToStripePortal(),
        undefined
      );
    } else {
      this.snackbarService.show(
        title,
        SnackbarType.twoButtonsInverted,
        `Contact us ${subtitle}`,
        false,
        'Contact us',
        'Cancel',
        () => window.open('mailto:sales@sherpa.ai', '_blank'),
        undefined
      );
    }
  }

  getEvents() {
    this.subscription = this.getEventActions().subscribe(() => {
      if (this.getAuthDataInSessionStorage()) {
        this.isProcessPending();
      }
    })
  }

  isLogged() {
    const token = sessionStorage.getItem(this.AUTH_DATA);
    if(token) return true;
    return false;
  }

  getUserFromIdToken(): User | null {
    const token = this.getAuthDataInSessionStorage() ? this.getAuthDataInSessionStorage().idToken : null;
    if(token) return jwtDecode(token);
    return null;
  }

  getUserRoleFromIdToken(): UserRole {
    const user = this.getUserFromIdToken();
    if (user) return user["custom:role"] as UserRole;
    return UserRole.ExpUser;
  }

  isAdminUser() {
    return this.getUserRoleFromIdToken() === UserRole.OrgAdmin
  }

  isExpUser(): boolean {
    return this.getUserRoleFromIdToken() === UserRole.ExpUser
  }

  isExpProUser(): boolean {
    return this.getUserRoleFromIdToken() === UserRole.ExpProUser
  }

  isAnyExpUser(): boolean {
    return (this.getUserRoleFromIdToken() === UserRole.ExpUser) || (this.getUserRoleFromIdToken() === UserRole.ExpProUser)
  }

  getSessionExpiresIn() {
    const user = this.getUserFromIdToken() ? this.getUserFromIdToken() : null;
    const time = Number(user?.exp);
    return getDateDifferenceInSeconds(time);
  }

  isProcessPending(): void {
    combineLatest([
      this.store.pipe(select(fromAuth.isLoginPending)),
      this.store.pipe(select(fromAuth.isLoginError)),
      this.store.pipe(select(fromPlatform.isCurrentProjectPending)),
      this.store.pipe(select(fromPlatform.getProjectError)),
      this.store.pipe(select(fromPlatform.isProjectListPending)),
      this.store.pipe(select(fromPlatform.getProjectListError)),
      this.store.pipe(select(fromPlatform.isConfigPending)),
      this.store.pipe(select(fromPlatform.isConfigError)),
      this.store.pipe(select(fromPlatform.isLogListPending)),
      this.store.pipe(select(fromPlatform.getLogListError)),
      this.store.pipe(select(fromPlatform.isNodeListPending)),
      this.store.pipe(select(fromPlatform.isNodeListError)),
      this.store.pipe(select(fromPlatform.isOrganizationPending)),
      this.store.pipe(select(fromPlatform.isOrganizationError)),

    ]).subscribe(([
      isLoginPending,
      isLoginError,
      isCurrentProjectPending,
      getProjectError,
      isProjectListPending,
      getProjectListError,
      isConfigPending,
      isConfigError,
      isLogListPending,
      getLogListError,
      isNodeListPending,
      isNodeListError,
      isOrganizationPending,
      isOrganizationError
    ]) => {
      if (this.getAuthDataInSessionStorage()) {
        if (!isLoginPending && !isLoginError
          && !isCurrentProjectPending && !getProjectError
          && !isProjectListPending && !getProjectListError
          && !isConfigPending && !isConfigError
          && !isLogListPending && !getLogListError
          && !isNodeListPending && !isNodeListError
          && !isOrganizationPending && !isOrganizationError
        ) {
          // console.log('LOGOUT no hay ningun proceso pendiente');
          clearInterval(this.timeOutInterval);
          this.getInactivity(this.userSessionLimit);
        } else {
          // console.log('LOGOUT hay al menos un proceso pendiente');
        }
      }
    });
  }

  getEventActions() {
    const events = [
      'scroll',
      'click',
      'wheel',
      'touch',
      'mousemove',
      'keydown',
      'resize',
      'orientationchange'
    ];
    const eventStreams = events.map((ev) => fromEvent(document, ev));
    return merge(...eventStreams);
  }

  getInactivity(limitTimeOut: number) {
    this.timeOutInterval = setInterval(() => {
      if (limitTimeOut > 0) {
        // console.log('time for LOGOUT', limitTimeOut);
        limitTimeOut = limitTimeOut - 1;
        if (limitTimeOut <= 0) {
          // console.log('LOGOUT inactividad por 60min. Cerrar sesion');
          this.store.dispatch(AuthActions.logout());
          this.subscription.unsubscribe();
        }
      }
    }, 1000);
  }

  initTimerForRefreshToken() {
    const expiresIn = this.getSessionExpiresIn();
    // const expiresIn = 12;
    // console.log('expiresIn', expiresIn);
    if (expiresIn > 0) {
      // !IMPORTANTE -> desde el back debe llegar el expiresIn en 86400(24h) y no en 3600(1h)
      let limitTimeOut = expiresIn - (expiresIn/4); // limitTimeOut sera expiresIn (24h) - 25% = 18h
      const initialTime = limitTimeOut;
      // console.log('limitTimeOut', limitTimeOut);

      this.refreshInterval = setInterval(() => {
        if (limitTimeOut > 0) {
          limitTimeOut = limitTimeOut - 1;
          // console.log('limitTimeOut', limitTimeOut);
          if (limitTimeOut <= 0) {
            // console.log('lanzar toker refresh');
            this.refreshToken();
            limitTimeOut = initialTime;
          }
        }
      }, 1000);
    } else {
      // Si la diferencia es negativa es porque la fecha de expiracion del Access Token es anterior a la fecha actual
      this.store.dispatch(AuthActions.logout());
      // this.logout();
    }
  }

  autologin(): Observable<User | null> {
    if (this.getUserFromIdToken()) {
      return of(this.getUserFromIdToken())
    } else {
      this.router.navigate(['/welcome']);
      return throwError({ message: 'Token not found' })
    }
  }

  logout() {
    const user = this.getUserFromIdToken() ? this.getUserFromIdToken() : null;
    const authData = this.getAuthDataInSessionStorage() ? this.getAuthDataInSessionStorage() : null;
    // console.log('user en logout', user);
    // console.log('authData en logout', authData);
    if (user && authData) {
      const data = {
        'organizationSuid': user['custom:org_suid'],
        'username': user?.preferred_username,
        'refreshToken': authData?.refreshToken
      }

      this.http.post<any>(`${this.baseUrl}auth/revoke`, data).subscribe();
    }
    sessionStorage.clear();
    this.router.navigate(['/welcome']);
    clearInterval(this.timeOutInterval);
    clearInterval(this.refreshInterval);
  }

  autologout() {
    sessionStorage.clear();
    this.router.navigate(['/welcome']);
  }

  getUserName(username: string) {
    return username.split('@')[0];
  }

  registerUser(userEmail: string): Observable<any> {
    const name = this.getUserName(userEmail);
    const email = userEmail;
    const data = {
      "username": name,
      "email": email
    }
    return this.http.post<any>(`${this.baseUrl}admin/orgs/sherpaorg4ui/users`, data);
  }

  auth(data: AuthResponse): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(`${this.baseUrl}auth`, data).pipe(
      tap((res) => res),
      catchError(error => throwError(error))
    );
  }

  refreshToken() {
    // console.log('entro en el refreshToken');
    const user = this.getUserFromIdToken() ? this.getUserFromIdToken() : null;
    const authData = this.getAuthDataInSessionStorage() ? this.getAuthDataInSessionStorage() : null;
    // console.log('user en refreshToken', user);
    // console.log('authData en refreshToken', authData);

    if (user && authData) {
      const data = {
        'organizationSuid': user ? user['custom:org_suid'] : '',
        'username': user?.preferred_username,
        'refreshToken': authData?.refreshToken
      }

      this.http.put<AuthResponse>(`${this.baseUrl}auth`, data).pipe(
        map((res) => {
          const currentAuth = this.getAuthDataInSessionStorage();
          currentAuth.accessToken = res?.accessToken;
          currentAuth.idToken = res?.idToken;
          this.setAuthDataInSessionStorage(currentAuth);
          // console.log('new AccessToken', res.accessToken);
        }),
        catchError(error => {
          // console.log('error en el refreshToken', error);
          this.logout();
          return throwError(error);
        })
      ).subscribe();
    }
  }

  setAuthDataInSessionStorage(data: AuthResponse) {
    sessionStorage.setItem(this.AUTH_DATA, JSON.stringify(data));
  }

  getAuthDataInSessionStorage() {
    const data = sessionStorage.getItem(this.AUTH_DATA);
    return data ? JSON.parse(data) : null;
  }

  deleteUsers(): Observable<any> {
    return this.http.delete<any>(`${environment.baseUrl}admin/orgs/sherpaorg4ui/users`);
  }

  deleteUser(userEmail: string): Observable<any> {
    const name = this.getUserName(userEmail);
    const data: any = [{
      "username": name
    }];
    return this.http.post<any>(`${environment.baseUrl}admin/orgs/sherpaorg4ui/users/delete`, data);
  }

  deleteUserByUsername(org: string, username: string): Observable<any> {
    const data: any = [{
      username
    }];
    return this.http.post<any>(`${environment.baseUrl}admin/orgs/${org}/users/delete`, data);
  }

  requestAcess(params: RequestAccessParams) {
    return this.http.post(`${environment.baseUrl}requestAccess`, params);
  }

  addUserWithNewOrg(params: RequestAccessParams) {
    const email = params.attributes.email;
    const username = this.getUserName(email);
    const body = {
      username,
      email,
      planSuid: "0002",
      organizationName: params.attributes.company,
      forceChangePassword: true,
      emailVerified: true,
      desiredDeliveryMediums: ["EMAIL"],
      userAttributes: [
        {"name": "locale", "value": "en-US"},
        {"name": "custom:role", "value": "HUMAN"}
      ]
    }
    return this.http.post(`${environment.baseUrl}orgs/users`, body);
  }

  getCustomerSession(org: string) {
    return this.http.post(`${environment.baseUrl}orgs/${org}/customerSession`, {});
  }

  getStripePortal(org: string, customerId: string): Observable<any> {
    const data = {
      customerId
    }
    return this.http.post<any>(`${this.baseUrl}orgs/${org}/stripePortal`, data);
  }


  loadUserList(orgSuid: string): Observable<any> {
    return this.http.get<{ pageSize: number; page: number; items: User[] }>(
      `${this.baseUrl}admin/orgs/${orgSuid}/users`,
      { headers }
    ).pipe(
      switchMap(users => {
        return of(users);
      }),
      catchError(res => {
        return throwError(res?.error)
      })
    )
  }

  addUserToOrg(orgSuid: string, email: string, role: string): Observable<any> {
    const username = this.getUserName(email);
    const data = {
      "username": username,
      "email": email,
      "forceChangePassword": true,
      "emailVerified": true,
      "desiredDeliveryMediums": ["EMAIL"],
      "userAttributes": [
          {"name": "custom:role", "value": role}
      ]
    }
    return this.http.post<any>(`${this.baseUrl}admin/orgs/${orgSuid}/users`, data);
  }
}
