import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-plus',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="8" y1="2.18557e-08" x2="8" y2="16" />
      <line y1="7.75" x2="16" y2="7.75" />
    </svg>
  `
})
export class IconPlus {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}