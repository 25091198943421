import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { BreackpointService } from 'src/app/platform/services/breackpoint.service';
import {
  SnackbarService,
  SnackbarType,
} from 'src/app/shared/components/snackbar/snackbar.service';
import { fromAuth } from '../../state';
import { AuthActions } from '../../state/actions';
import {
  createRequestAccessFormDatasource,
  IRequestAccessForm,
} from '../request-access-form/request-access-form.model';
import {
  RequestAccessMode,
  RequestAccessParams,
} from '../request-access/request-access.model';
import { getInfo } from './request-access-overlay.model';

@Component({
  selector: 'shai-request-access-overlay',
  template: `
    <shai-overlay
      [active]="isActive"
      [fullWidth]="false"
      [hasHeader]="true"
      heading=""
      (onLeave)="onLeaveOverlay()"
      (onChange)="onChangeOverlay($event)"
    >
      <div id="request-access-overlay-wrapper">
        <div id="request-access-overlay-wrapper-body">
          <mat-grid-list cols="12" rowHeight="fit">
            <mat-grid-tile
              colspan="1"
              *ngIf="(_bkp.obsScreen$ | async) === 'desktop'"
            >
            </mat-grid-tile>
            <mat-grid-tile
              [colspan]="(_bkp.obsScreen$ | async) === 'desktop' ? 10 : 12"
            >
              <shai-title-bar
                [title]="title"
                [bottomDescription]="description"
                [showExtraSpace]="false"
              >
              </shai-title-bar>
              <shai-request-access-form
                [form]="requestAccessForm"
                [placeholderKeys]="placeholderKeys"
                [error]="errorRequest$ | async"
              >
              </shai-request-access-form>
            </mat-grid-tile>
            <mat-grid-tile
              colspan="1"
              *ngIf="(_bkp.obsScreen$ | async) === 'desktop'"
            >
            </mat-grid-tile>
          </mat-grid-list>
        </div>
        <shai-action-bar>
          <ng-container left-cell>
            <shai-button
              [type]="'button'"
              (click)="onCancelHandler()"
              buttonStyle="secondary"
              >Cancel</shai-button
            >
          </ng-container>
          <ng-container right-cell>
            <shai-button
              [disabled]="disabledJoinBtn"
              [type]="'submit'"
              (click)="onSubmitHandler()"
              buttonStyle="primary"
              >{{ submitButtonText }}</shai-button
            >
          </ng-container>
        </shai-action-bar>
      </div>
    </shai-overlay>
  `,
  styleUrls: ['./request-access-overlay.component.scss'],
})
export class RequestAccessOverlayComponent implements OnInit, OnDestroy {
  isActive = true;
  title: string = '';
  description: string = '';
  submitButtonText: string = '';
  requestAccessForm = this.formBuilder.group({});
  placeholderKeys: { [key: string]: string } = {};
  errorMessages: { [key: string]: any } = {};
  disabledJoinBtn: boolean = false;

  @Input() requestAccessMode?: RequestAccessMode;
  @Output() toggleOnChange = new EventEmitter();

  private dataSource?: IRequestAccessForm;
  private isCompletedSubscription?: Subscription;
  private errorSubscription?: Subscription;
  private isCompletedRequest$: Observable<boolean> = new Observable();
  errorRequest$: Observable<string> = new Observable();
  private completedMsg = {
    title: 'We have sent you an email',
    description:
      'We have received your data correctly and sent you a validation email. Check your spam folder. If you have not received our email, please submit your information again.',
    buttonText: 'Close',
  };

  constructor(
    public _bkp: BreackpointService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    private store: Store
  ) {
    this.isCompletedRequest$ = this.store.pipe(
      select(fromAuth.isRequestAcessCompleted)
    ) as Observable<boolean>;
    this.errorRequest$ = this.store.pipe(
      select(fromAuth.getRequestAcessError)
    ) as Observable<string>;
  }

  ngOnInit(): void {
    this.setScreenInfo();
    this.prepareFormData();
    this.setRequestCompletedSubscription();
  }

  ngOnDestroy(): void {
    this.isCompletedSubscription?.unsubscribe();
    this.errorSubscription?.unsubscribe();
  }

  private getFormField(key: string): FormControl {
    return this.requestAccessForm?.get(key) as FormControl;
  }

  private setScreenInfo() {
    const info = getInfo(
      this.requestAccessMode || RequestAccessMode.Experimental
    );
    this.title = info.title;
    this.description = info.description;
    this.submitButtonText = info.submitButtonText;
  }

  onLeaveOverlay() {
    this.isActive = false;
  }

  onChangeOverlay(event: boolean) {
    if (!event) {
      this.finishProcess();
    } else {
      this.isActive = true;
    }
  }

  private finishProcess = () => {
    this.disabledJoinBtn = false;
    this.clearRequestAccessData();
    this.toggleOnChange.emit(false);
  };

  private clearRequestAccessData() {
    this.store.dispatch(AuthActions.clearRequestAccessData());
  }

  private prepareFormData() {
    this.dataSource = createRequestAccessFormDatasource(
      this.requestAccessMode || RequestAccessMode.Experimental
    );
    const formObject = this.dataSource.getFormObject();
    this.requestAccessForm = this.formBuilder.group(formObject);
    this.placeholderKeys = this.dataSource.getFormPlaceholders();
    this.errorMessages = this.dataSource.getErrorMessages();
  }

  private setRequestCompletedSubscription() {
    this.isCompletedSubscription = combineLatest([
      this.isCompletedRequest$,
    ]).subscribe(([isCompleted]) => {
      if (isCompleted) {
        this.snackbar.show(
          this.completedMsg.title,
          SnackbarType.oneButton,
          this.completedMsg.description,
          false,
          this.completedMsg.buttonText,
          undefined,
          this.finishProcess,
          undefined,
          true
        );
      }
    });
  }

  onCancelHandler() {
    this.finishProcess();
  }

  onSubmitHandler() {
    this.validateForm();
  }

  private requestAccess() {
    const data = this.getRequestData();
    this.disabledJoinBtn = true;
    this.store.dispatch(AuthActions.requestAccess({ params: data }));
  }

  private getRequestData(): RequestAccessParams {
    const name = this.getFormField('name')?.value;
    const email = this.getFormField('email')?.value;
    const phoneNumber = this.getFormField('phoneNumber')?.value;
    const termsAndprivacyAgree = this.getFormField('privacyAgree')?.value;
    const commercialAgree = this.getFormField('commercialAgree')?.value;
    const company = this.getFormField('company')?.value;
    const title = this.getFormField('title')?.value;
    const description = this.getFormField('observations')?.value;

    const type =
      this.requestAccessMode?.valueOf() ||
      RequestAccessMode.Experimental.valueOf();
    const params: RequestAccessParams = {
      type,
      name,
      attributes: {
        email,
        phone: phoneNumber || undefined,
        title,
        company,
        description,
        termsUse: termsAndprivacyAgree,
        privacyPolicy: termsAndprivacyAgree,
        commercialCommunications: commercialAgree,
      },
    };
    return params;
  }

  private validateForm() {
    if (this.requestAccessForm.valid) {
      const email = this.requestAccessForm?.get('email')?.value;
      const repeatEmail = this.requestAccessForm?.get('repeatEmail')?.value;
      if (email === repeatEmail) {
        this.requestAccess();
      } else {
        this.snackbar.show(
          'Email and confirmation email should be equals.',
          SnackbarType.oneButton,
          undefined,
          false,
          "Close"
        );
      }
    } else {
      this.processFormErrors();
    }
  }

  private processFormErrors() {
    const keys = Object.keys(this.requestAccessForm.controls);

    for(let i = 0; i < keys.length; i++) {
      let errorMsg = '';
      const errors = this.requestAccessForm?.get(keys[i])?.errors;
      if (errors) {
        for (let j = 0; j < this.errorMessages[keys[i]].length; j++) {
          const element = this.errorMessages[keys[i]][j];
          if (element.type === Object.keys(errors)[0]) {
            errorMsg = element.message;
          }
        }
        this.snackbar.show(errorMsg, SnackbarType.oneButton, undefined, false, "Close");
        break;
      }
    }
  }
}
