import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-aggregator-mk-24',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DCDDE0"/>
    <path d="M4.83281 15.8H5.82881V9.032H5.87681L8.85281 14.36H8.97281L11.9488 9.068H11.9968V15.8H12.9928V7.4H11.7808L8.94881 12.524H8.90081L6.06881 7.4H4.83281V15.8ZM15.2714 6.92V15.8H16.2794V6.92H15.2714ZM17.5754 12.356L20.0594 9.788V9.668H18.8714L16.2794 12.356L18.9674 15.8H20.1314V15.68L17.5754 12.356Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class AggregatorMk24 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
