import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-aggregator-p-24',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DCDDE0"/>
    <path d="M9.30352 15.8H10.3475V12.752H12.3995C14.0915 12.752 15.1715 11.66 15.1715 10.052C15.1715 8.492 14.1155 7.4 12.3995 7.4H9.30352V15.8ZM10.3475 11.792V8.348H12.2675C13.3955 8.348 14.0915 8.972 14.0915 10.052C14.0915 11.18 13.4315 11.792 12.2675 11.792H10.3475Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class AggregatorP24 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
