import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-aggregator-io-24',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DCDDE0"/>
    <path d="M7.03008 15.8H8.08608V7.4H7.03008V15.8ZM13.7271 15.908C16.3071 15.908 17.4471 14 17.4471 11.6C17.4471 9.188 16.3071 7.292 13.7271 7.292C11.1351 7.292 10.0071 9.188 10.0071 11.6C10.0071 14 11.1351 15.908 13.7271 15.908ZM13.7271 14.924C11.7351 14.924 11.0871 13.292 11.0871 11.6C11.0871 9.908 11.7231 8.276 13.7271 8.276C15.7191 8.276 16.3671 9.908 16.3671 11.6C16.3671 13.292 15.7191 14.924 13.7271 14.924Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class AggregatorIo24 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
