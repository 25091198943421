import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BreackpointService } from 'src/app/platform/services/breackpoint.service';

@Component({
  selector: 'shai-root',
  template: `
    <shai-layout *ngIf="(router.url !== '/welcome'); else withoutHelmet">
      <router-outlet></router-outlet>
      <shai-snackbar></shai-snackbar>
    </shai-layout>
    <shai-footer *ngIf="(router.url !== '/welcome' && router.url !== '/chat') && !router.url.startsWith('/auth')"></shai-footer>
    <ng-template #withoutHelmet>
      <router-outlet></router-outlet>
      <shai-snackbar></shai-snackbar>
    </ng-template>
    <a href="mailto:support@sherpa.ai" class="floating-button" (click)="openMailTo($event)">?</a>
  `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sherpa-privacy-ai-platform';

  constructor(
    public router: Router,
    private breakpointObserver: BreakpointObserver,
    public _bkp: BreackpointService
  ) {
    const breakpointMobile = '(max-width: 768px)';
    const breakpointTablet = '(min-width: 769px) and (max-width: 1024px)';
    const breakpointXl = '(min-width: 1025px)';
    this.breakpointObserver
      .observe([breakpointMobile, breakpointTablet, breakpointXl])
      .subscribe((state: BreakpointState) => {
        if (state.breakpoints[breakpointMobile]) {
          this.breackpointState('mobile');
        } else if (state.breakpoints[breakpointTablet]) {
          this.breackpointState('tablet');
        } else if (state.breakpoints[breakpointXl]) {
          this.breackpointState('desktop');
        } else {
          this.breackpointState('');
        }
      });
  }

  breackpointState(status: any) {
    this._bkp.setBreackpointState(status);
  }

  openMailTo(event: MouseEvent) {
    event.preventDefault();
    window.open('mailto:support@sherpa.ai', '_blank');
  }
}
