import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, OnDestroy, Renderer2, ViewChild, ElementRef, AfterViewInit, AfterViewChecked, ViewChildren, QueryList } from '@angular/core';
import {
  SnackbarService,
  SnackbarType,
} from 'src/app/shared/components/snackbar/snackbar.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { select, Store } from '@ngrx/store';
import { InferenceActions, Training, TrainingSession } from 'src/app/platform';
import * as fromPlatform from 'src/app/platform/reducers';
import { ListItem } from 'src/app/shared/components/list-items/list-item.model';
import { combineLatest, Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { MappingNode } from '../../../platform/models/training-session.model';
import { CheckboxData, CheckboxService } from 'src/app/shared/services/checkbox.service';
import * as fromAuth from 'src/app/auth/state/selectors/auth.selectors';
import { TrainingSessionService } from '../../../platform/services/training-session.service';
import { Node, ProjectResponse } from 'src/app/platform/models';
import { hasClass } from 'src/app/utils/dom-utils';
import { AnimationOptions } from 'ngx-lottie';
import { configMock } from 'src/app/platform/mocks/global-config';
import { AuthService } from 'src/app/auth/services/auth.service';
import { AccessControlService } from 'src/app/shared/services/access-control.service';

@Component({
  selector: 'shai-rounds-download-overlay',
  template: `
    <shai-overlay
      [active]="isActive"
      [fullWidth]="false"
      [hasHeader]="true"
      heading=""
      (onChange)="onChangeOverlay($event)"
      (onLeave)="onLeaveOverlay()"
    >
      <mat-grid-list cols="12" rowHeight="fit" id="rounds-download-overlay-wrapper">
        <mat-grid-tile colspan="12">
          <shai-title-bar title="Download trained model"> </shai-title-bar>
          <shai-table-header [headerTabs]="['Settings']"></shai-table-header>
          <div class="table-container">
            <div class="checkbox-table">
              <div class="mat-table">
                <div class="mat-row title-text">
                  <div class="card-head">
                    <div class="card-head-center">
                      <div id="card-title">
                        <div class="paragraph">Download model trained in:</div>
                      </div>
                    </div>
                  </div>
                </div>
                <mat-radio-group>
                  <div #nodeArray class="mat-row" *ngFor="let node of nodeList; let i = index">
                    <shai-radio [data]="node" (onRadioClick)="getSelectedNode($event)">
                      <div class="right-column-check">
                      <div class="card-head">
                        <div class="card-head-center">
                          <div id="card-title">
                            <div class="paragraph">{{ node.name }}</div>
                          </div>
                          <div id="card-subtitle">
                            <div class="paragraph-small">{{ node.value }}</div>
                          </div>
                        </div>
                        <!-- <div class="card-head-right"></div> -->
                      </div>
                    </div>
                  </shai-radio>
                  </div>
                  </mat-radio-group>
              </div>
            </div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <shai-action-bar>
        <ng-container left-cell>
          <shai-button
            (click)="downloadModelParameters()"
            [type]="'button'"
            iconName="icon-download-16"
            [buttonStyle]="'secondary'"
            [disabled]="isDownloadDisabled"
            tooltipText="Download"
          >Download</shai-button>
        </ng-container>
        <ng-container right-cell>
          <shai-button
            *ngIf="saveFilesForInferencePending"
            [type]="'button'"
            [buttonStyle]="'primary'"
            [disabled]="true">
            <div class="spinner-wrapper inside-button-spinner">
              <ng-lottie [options]="blackLoaderAnimation"></ng-lottie>
            </div>
          </shai-button>
          <shai-button
            *ngIf="!saveFilesForInferencePending"
            (click)="saveFilesForInference()"
            [type]="'button'"
            iconName="icon-link-16"
            [buttonStyle]="'primary'"
            [disabled]="!isNode"
            tooltipText="Save for Inference"
          >Save for Inference</shai-button>
        </ng-container>
      </shai-action-bar>
    </shai-overlay>
  `,
  styleUrls: ['./rounds-download-overlay.component.scss'],
})
export class RoundsDownloadOverlayComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  userOrg$: Observable<string | undefined> = this.store.pipe(select(fromAuth.getOrg))
  project$: Observable<ProjectResponse | null> = this.store.pipe(
    select(fromPlatform.getCurrentProject)
  );
  training$: Observable<Training | null> = this.store.pipe(
    select(fromPlatform.getCurrentTraining)
  );
  trainingSession$: Observable<TrainingSession | null> = this.store.pipe(
    select(fromPlatform.getCurrentTrainingSession)
  );
  historyNodes$: Observable<Node[] | null | undefined> = this.store.pipe(
    select(fromPlatform.getHistoryNodes)
  );
  isActive: boolean = true;
  subscription!: Subscription;
  private subscriptionUrlModelParameters?: Subscription;
  private subscriptionUrlModelParametersError?: Subscription;
  private orgSuid!: string;
  private orgSubscription?: Subscription;
  private projectSubscription!: Subscription;
  private project!: ProjectResponse;
  trainingSubscription!: Subscription;
  training!: Training;
  checkboxServiceSubscription!: Subscription;
  paradigm!: string | undefined;
  @Input() trainedInfo!: string | undefined;
  currentTrainedInfo!: string | undefined;
  @Input() zipName!: string | undefined;
  currentZipName!: string | undefined;
  mappingNodes: MappingNode[] = [];
  allHistoryNodes: Node[] = [];
  private trainingSessionSubscription!: Subscription;
  trainingSession!: TrainingSession;
  nodeList: any[] = [];
  aggregator?: string ;
  server!: MappingNode | undefined;
  @Output() onClose = new EventEmitter();
  private downloadLink?: string = '';
  counter: number = 0;
  @Input() lastRound!: number;
  @Input() roundLength!: number;
  listItems: ListItem[] = [];
  selectedRound: number | undefined = undefined;
  selectedNode: string | undefined = undefined;
  selectedNodes: string[] = [];
  @ViewChild('checkRef', { static: false }) checkRef?: ElementRef;
  @ViewChildren('nodeArray') nodeArray!: QueryList<any>;
  checkboxArray: any[] = [];
  saveFilesForInferencePending: boolean = false;
  blackLoaderAnimation: AnimationOptions = {
    path: '/assets/lotties/loader.json',
    loop: true
  }

  saveFilesForInferenceStatus$: Observable<any> = new Observable();
  private subscriptionSaveFilesForInferenceStatus?: Subscription;

  constructor(
    private store: Store,
    private _snackbarService: SnackbarService,
    private clipboard: Clipboard,
    private _checkboxService: CheckboxService,
    private render: Renderer2,
    private cdRef: ChangeDetectorRef,
    private trainingSessionService: TrainingSessionService,
    private authService: AuthService,
    public _accessControlService: AccessControlService,
  ) {
    this.saveFilesForInferenceStatus$ = this.store.pipe(select(fromPlatform.saveFilesForInferenceStatus)) as Observable<any>;
  }

  ngOnInit(): void {
    this.setOrgSubscription();
    this.setProjectSubscription();
    this.setTrainingSessionSubscription();
    this._getMappingNodes();
    this._setCheckboxSubscription();

    this.subscriptionSaveFilesForInferenceStatus = this.saveFilesForInferenceStatus$.subscribe(status => {

      const pending = status?.pending;
      const error = status?.error;

      if (pending === false && error === null) {
        this.saveFilesForInferencePending = false;
        this.cdRef.detectChanges();
        this._snackbarService.show(
          'Model saved successfully',
          SnackbarType.oneButton,
          'The model will be available in the inference process',
          false,
          'Ok',
          undefined,
          () => this.clearSaveFilesForInferenceStatus(),
          undefined,
          true
        );
      }
      else if (pending === false && error) {
        this.saveFilesForInferencePending = false;
        this.cdRef.detectChanges();
        this._snackbarService.show(
          'Sorry, something went wrong.',
          SnackbarType.oneButton,
          error.error.message,
          false,
          'Ok',
          undefined,
          () => this.clearSaveFilesForInferenceStatus(),
          undefined,
          true
        );
      }
    });
  }

  ngAfterViewInit(): void {
    this.checkboxArray = this.nodeArray.toArray();
    if(this.checkboxArray && this.checkboxArray.length > 0 && this.selectedNode) {
      const order = this.nodeList.filter(node => node.value === this.selectedNode)[0].order
        this.checkboxArray[0].nativeElement.childNodes[0].childNodes[0].childNodes[0].click()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.currentTrainedInfo = changes?.trainedInfo?.currentValue;
    this.currentZipName = changes?.zipName?.currentValue;
    this.getListItems();
  }

  ngOnDestroy(): void {
    this.orgSubscription?.unsubscribe();
    this.projectSubscription?.unsubscribe();
    this.trainingSubscription?.unsubscribe();
    this.trainingSessionSubscription?.unsubscribe();
    this.checkboxServiceSubscription?.unsubscribe();
    this.subscriptionUrlModelParameters?.unsubscribe();
    this.subscriptionUrlModelParametersError?.unsubscribe();
    this.subscriptionSaveFilesForInferenceStatus?.unsubscribe();
  }

  private setOrgSubscription() {
    this.orgSubscription = this.userOrg$.subscribe((orgSuid) => {
      if (orgSuid) {
        this.orgSuid = orgSuid;
      }
    });
  }

  private setProjectSubscription() {
    this.projectSubscription = this.project$.subscribe((project) => {
      if (project) {
        this.project = project;
      }
    });
  }

  private setTrainingSessionSubscription() {
    this.trainingSessionSubscription = this.trainingSession$.subscribe(
      (trainingSession) => {
        if (trainingSession) {
          this.trainingSession = trainingSession;
          this.paradigm = trainingSession?.training?.paradigm?.id;

          if(this.isRound(trainingSession)) {
            this.selectedRound = trainingSession.summaryTrainingSessionExecution?.lastRound;
          }
        }
      }
    );
  }

  clearSaveFilesForInferenceStatus(): void {
    this.store.dispatch(InferenceActions.clearSaveFilesForInference());
  }

  getSelectedNode(node: any) {
    this.selectedNode = node;
  }

  onFilterClick(nodeItem: CheckboxData) {
    this.selectedNode = nodeItem.value
    let checkedItem: any | undefined;
    this.nodeList.forEach(node => {
      if (node.value === nodeItem.value) {
        checkedItem = nodeItem;
      }
    });

    if (checkedItem && !checkedItem?.disabled) {
      checkedItem.checked = !checkedItem.checked;
      this._checkboxService.changeCheckbox({
          checked: checkedItem.checked,
          value: checkedItem.value,
          formGroup: checkedItem.formGroup
      } as CheckboxData);

      const index = checkedItem.order;
      const checkReference = this.checkboxArray[index].nativeElement.childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[0];
      if (!checkedItem.checked && hasClass(checkReference, 'checked')) {
          this.render.removeClass(checkReference, 'checked');
      } else if (checkedItem.checked && !hasClass(checkReference, 'checked')) {
          this.render.addClass(checkReference, 'checked');
      }
      this.cdRef.detectChanges();
    }
  }

  private _setCheckboxSubscription = () => {
    this.checkboxServiceSubscription =
      this._checkboxService.currentCheckbox.subscribe((currentChbx) => {
        this.nodeList.forEach(node => {
          if (currentChbx['value'] && currentChbx['value'] === node.value) {
            this.selectedNodes.push(currentChbx['value']);
          }
        });
      });
  };

  getListItems() {
    if (this.roundLength) {
      this.listItems = [];
      for (let i = this.roundLength; i > 0; i--) {
        this.listItems.push(
          {
            'type': 'body',
            'title': i === this.roundLength ? 'Last Round' : 'Round ' + i,
            'value': (i).toString(),
            checked: (i === this.roundLength) ? true : false,
            filter: false
          }
        );
      }
    }
  }

  dataCellDouble() {
    return {
      text: 'RandomForest_20221603.zip',
      subText: '18 mb',
    };
  }

  dataCellSingle(element: any) {
    return { text: element };
  }

  onChangeOverlay(event: boolean) {
    if (!event) {
      setTimeout(() => {
        this.onClose.emit(false);
      }, 700);
    }
  }

  async getDownloadModelParametersUrl() {
    if(this.selectedNode) {
      const res = await this.trainingSessionService
      .downloadModelParameters(
        this.orgSuid,
        this.project.projectSuid,
        this.trainingSession.trainingSessionTimestamp,
        this.selectedNode
      ).toPromise()
      return res.url
    } else {
      return ''
    }
  }

  async downloadModelParameters() {
    if(this._accessControlService.newCheckAccessControl('downloadable-models')) {
      this.authService.upgradePlan("Your current plan does not include saving trained models", "to download trained models");
      return
    } else {
      this.downloadLink = await this.getDownloadModelParametersUrl();
      if (this.downloadLink) {
          this.downloadLink = this.downloadLink;
          const link = document.createElement('a');
          link.href = this.downloadLink;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.subscriptionUrlModelParameters?.unsubscribe()
        }
    }
  }

  async saveFilesForInference(): Promise<void> {
    if(this._accessControlService.newCheckAccessControl('inference-card')) {
      this.authService.upgradePlan("Your current plan does not include saving trained models", "to save trained models");
      return
    } else if(this.selectedNode) {
      this.saveFilesForInferencePending = true;
      this.cdRef.detectChanges();
      const roundSelected = this.selectedRound ? this.selectedRound : 0;
      if (this.paradigm) {
        this.store.dispatch(InferenceActions.saveFilesForInference({ node: this.selectedNode, round: roundSelected, paradigmId: this.paradigm }));
      }
    }
  }

  primaryButtonHandler() {
    this.cancelButtonHandler()
    setTimeout(() => {
      this.downloadModelParameters();
    }, 700)
  }

  cancelButtonHandler() {
  }

  private _getMappingNodes() {
    this.subscription = combineLatest([this.trainingSession$, this.historyNodes$]).subscribe(([session, history]) => {
      this.nodeList = [];
      if (session && history) {
//         if (this._isHfl()) {
//           this.aggregator = session?.infrastructure?.aggregatorUuid;
//           this.nodeList.push({
//             'checked': false,
//             'disabled': false,
//             'name': 'Aggregator',
//             'value': this.aggregator,
//             'order': 0,
//           });
//         } else {
//           this.server = this.getNodeServer(session);
//           history.forEach(node => {
//             if (node.nodeSuid === this.server?.nodeSuid) {
//               this.nodeList.push({
//                 'checked': false,
//                 'disabled': false,
//                 'name': 'Server',
//                 'value': this.server?.nodeSuid,
//                 'order': 0,
//               });
//             }
//           });
//         }
        for (let i = 0; i < history.length; i++) {
          const nodeHistory = history[i];
          const foundInNodeList = this.nodeList.some(item => item.nodeSuid === nodeHistory.suid);
          if (!foundInNodeList) {
            this.nodeList.push({
              'checked': false,
              'disabled': false,
              'name': nodeHistory?.config?.name,
              'value': nodeHistory?.suid,
              'order': i + 1,
            });
          }
        }
        this.nodeList = this._sortNodeList(this.nodeList);
        this.selectedNode = this.nodeList.filter(node => this.isAggregatorOrServer(node.name))[0]?.value;
        this.subscription?.unsubscribe();
      }
    })
  }

  get isNode(): boolean {
    return Boolean(this.selectedNode);
  }

  get isDownloadDisabled() {
    return !(Boolean(this.selectedNode)) || this.paradigm === 'bvfl';
  }

  private isRound(trainingSession: TrainingSession): boolean {
    return trainingSession.summaryTrainingSessionExecution?.lastRound || trainingSession.summaryTrainingSessionExecution?.lastRound === 0;
  }

  private isAggregatorOrServer(node: string): boolean {
    node = node.toLowerCase();
    return node === 'aggregator' || node === 'server';
  }

  private getNodeServer(session: TrainingSession) {
    return session?.mappings?.find((node) => node.role === 'server');
  }

  private _sortNodeList(nodes: any[]) {
    return nodes.sort((a,b) => a.order - b.order);
  }

  private _isHfl(): boolean {
    return this.paradigm === 'hfl';
  }

  onLeaveOverlay() {
    this.isActive = false;
  }
}
