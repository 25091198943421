import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'shai-footer',
  template: `
    <footer>
      <w-wrapper-fluid>
        <div class="copyright">© 2023 Sherpa.ai</div>
        <nav class="nav-legal">
          <ul>
            <li><a target="_blank" [href]="privacyLink">Privacy</a></li>
            <li><a target="_blank" [href]="termsLink">Cookies</a></li>
            <li *ngIf="(router.url !== '/welcome')"><a target="_blank" [href]="helpLink">Help</a></li>
          </ul>
        </nav>
      </w-wrapper-fluid>
    </footer>
  `,
  styleUrls: [`./footer.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  constructor(public router: Router) {}
  privacyLink = "https://www.sherpa.ai/politica-de-privacidad/";
  termsLink = "https://www.sherpa.ai/politica-de-cookies/";
  helpLink = "https://docs.sherpa.ai";
  @Input() creationProjectName : string | null = null
}
