import { ChangeDetectionStrategy, Component, Input, OnInit, ViewContainerRef, ComponentFactoryResolver, ViewChild, AfterViewInit, ElementRef, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { IconArrowRight16 } from '../../icons/16/icon-arrow-right';
import { IconCheckmark16 } from '../../icons/16/icon-checkmark';
import { IconClose16 } from '../../icons/16/icon-close';
import { IconSoundOn16 } from '../../icons/16/icon-sound-on';
import { IconSoundOff16 } from '../../icons/16/icon-sound-off';
import { IconDocument16 } from '../../icons/16/icon-document';
import { IconInfo16 } from '../../icons/16/icon-info';
import { IconModel16 } from '../../icons/16/icon-model';
import { IconPrivacy16 } from '../../icons/16/icon-privacy';
import { IconTrash16 } from '../../icons/16/icon-trash';
import { IconUser16 } from '../../icons/16/icon-user';
import { IconUserNew16 } from '../../icons/16/icon-user-new';
import { IconWarning16 } from '../../icons/16/icon-warning';
import { ListItem } from './list-item.model';
import { IconLogout16 } from '../../icons/16/icon-logout';
import { IconHelp16 } from '../../icons/16/icon-help';
import { IconSettings16 } from '../../icons/16/icon-settings';
import { IconDuplicate16 } from '../../icons/16/icon-duplicate';
import { IconLocation16 } from '../../icons/16/icon-location';
// Add new icons at list-time.model.ts,  here ...

@Component({
    selector: 'shai-list-item-multitext',
    template: `
        <div class="list-item-container" [ngClass]="{'disabled': listItem?.disabled, 'filter': listItem?.filter, 'unclickable': listItem?.unclickable }" (click)="onListItemClick()">
            <div class="list-item">
                <div [ngClass]="!listItem?.iconLeft ? 'invisible' : 'icon-left'">
                    <ng-container #leftIcon></ng-container>
                </div>
                <ng-content></ng-content>
                <div class="multitext" *ngIf="!listItem?.filter" [ngStyle]="{'cursor': listItem?.unclickable ? 'default' : ''}">
                    <div class="paragraph" #text
                        [ngClass]="listItem?.title && (listItem.title.length > 32) ? 'truncate-1-line' : ''"
                        title="{{ listItem?.title && (listItem.title.length > 32) ? listItem.title : '' }}"
                    >{{listItem?.title}}</div>
                    <div class="caption" [ngClass]="(listItem?.subtitle?.length ?? 0) > 32 ? 'truncate-1-line' : ''">{{listItem?.subtitle}}</div>
                <div [ngClass]="!listItem?.iconRight ? 'invisible' : 'icon-right'">
                    <ng-container #rightIcon></ng-container>
                </div>
            </div>
        </div>
    `,
    styleUrls: [`./list-item-multitext.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListItemMultitextComponent implements AfterViewInit, OnChanges {
    @ViewChild('leftIcon', { read: ViewContainerRef}) leftIcon: ViewContainerRef | undefined;
    @ViewChild('rightIcon', { read: ViewContainerRef}) rightIcon: ViewContainerRef | undefined;
    @ViewChild('text') text: ElementRef<HTMLInputElement> | undefined;
    @Output() handleItemClick = new EventEmitter();
    @Input() listItem!: ListItem;
    @Input() length: number | undefined;

    modals = { // ... and here
        'icon-arrow-right-16': IconArrowRight16,
        'icon-checkmark-16': IconCheckmark16,
        'icon-close-16': IconClose16,
        'icon-sound-on-16': IconSoundOn16,
        'icon-sound-off-16': IconSoundOff16,
        'icon-document-16': IconDocument16,
        'icon-info-16': IconInfo16,
        'icon-privacy-16': IconPrivacy16,
        'icon-warning-16': IconWarning16,
        'icon-trash-16': IconTrash16,
        'icon-location-16': IconLocation16,
        'icon-settings-16': IconSettings16,
        'icon-help-16': IconHelp16,
        'icon-logout-16': IconLogout16,
        'icon-model-16': IconModel16,
        'icon-user-new-16': IconUserNew16,
        'icon-duplicate-16': IconDuplicate16
    };

    constructor(
        public viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) { }

    ngAfterViewInit() {
        if (this.listItem?.iconRight) {
            this.rightIcon?.clear();
            let component = this.modals[this.listItem.iconRight];
            const cFactory = this.componentFactoryResolver.resolveComponentFactory(component);
            const componentRef2 = this.rightIcon!.createComponent(cFactory);
            componentRef2.changeDetectorRef.detectChanges();
        }
        if (this.listItem?.iconLeft) {
            this.leftIcon?.clear();
            let component = this.modals[this.listItem.iconLeft];
            const cFactory = this.componentFactoryResolver.resolveComponentFactory(component);
            const componentRef = this.leftIcon!.createComponent(cFactory);
            componentRef.changeDetectorRef.detectChanges();
        }
    }
    onListItemClick() {
        const itemContent = this.text?.nativeElement.textContent;
        if (!this.listItem?.disabled && !this.listItem?.filter && !this.listItem?.unclickable) {
            this.handleItemClick.emit(itemContent);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            this.length = changes?.length?.currentValue;
            if (this.length !== undefined) {
                if (this.length > 0 && this.listItem.value === 'select_multiple') {
                    this.listItem.disabled = false;
                } else if (this.length === 0 && this.listItem.value === 'select_multiple') {
                    this.listItem.disabled = true;
                }
            }
        }
    }
}
