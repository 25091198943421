import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DropdownData, DropdownService } from '../services/dropdown.service';
import { HelperService } from 'src/app/platform/services/helper.service';
import { combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'shai-dropdown',
  template: `
    <div [class]="widthSize">
      <button
        #dropdownButton
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        [ngClass]="{
          'disabled': data && data.disabled ? data.disabled : false,
          'adapted-bottom': isFloatingActive && size === widthSize
        }"
        class="selector-preview button-floating"
        [class.active]="isFloatingActive"
        (click)="toggleFloating(data.listItems)"
      >
        <label class="truncate-1-line" [title]="data && data.title ? data.title : ''" *ngIf="data && data.title">{{ data.title }}</label>
        <shai-icon-arrow-down-16></shai-icon-arrow-down-16>
      </button>
      <ng-template *ngIf="data && data.disabled ? !data.disabled : true"
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="isFloatingActive"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
        (backdropClick)="closeFloatingMenu()"
      >
          <shai-floating-menu
            *ngIf="data && data.listItems"
            (handleItemClick)="onListItemClick($event)"
            [listItems]="data.listItems"
            [width]="floatingWidth ? floatingWidth : dropdownButtonWidth"
            [height]="floatingHeight"
            [adaptedTop]="size === 'big'"
            (onChange)="this.isFloatingActive = $event"
            [active]="isFloatingActive"
          >
          </shai-floating-menu>
      </ng-template>
    </div>
  `,
  styleUrls: [`./dropdown.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements OnInit {
  @ViewChild('dropdownButton', { static: false }) dropdownButtonRef!: ElementRef;
  @Input() data: any = null;
  @Input() isFloatingActive = false;
  @Input() size: 'small' | 'big' | 'stretch' = 'big';
  @Input() floatingWidth!: string;
  @Input() disabled = false;
  @Input() metadata: any | null = null;
  @Output() onListItemChange = new EventEmitter();
  @Output() status = new EventEmitter();
  widthSize = '';
  dropdownButtonWidth!: any;
  floatingHeight!: number;
  footerHeight!: number;
  headerHeight!: number;
  private subscription?: Subscription;
  bodyHeight: number = 0;
  elementHeight: number = 0;
  top: number = 0;
  bottom: number = 0;
  topScreenPosition: number = 0;
  bottomScreenPosition: number = 0;
  largest: number = 0;
  hasFooter: boolean = false;
  hasHeader: boolean = false;

  constructor(
    private _dropdownService: DropdownService,
    public _hs: HelperService
  ){}

  ngOnInit(): void {
    if (this.data && this.data.size === 'small' || (this.size && this.size === 'small')) {
      this.widthSize = 'small';
    }
    else if (this.data && this.data.size === 'big') {
      this.widthSize = 'big';
    }
    else if (this.data && this.data.size === 'stretch' || (this.size && this.size === 'stretch')) {
      this.widthSize = 'stretch';
    }
  }

  floatingHeightSubscription() {
    this.bodyHeight = document.body.offsetHeight;
    this.elementHeight = this.dropdownButtonRef.nativeElement.offsetHeight;
    // Representa el tamaño de un elemento y su posición relativa a la ventana gráfica
    this.top = this.dropdownButtonRef.nativeElement.getBoundingClientRect().top;
    this.bottom = this.bodyHeight - this.top - this.elementHeight;
    // El valor top, que es su posicion en Y, lo convertimos en números positivos
    this.topScreenPosition =  Math.abs(this.top);
    this.bottomScreenPosition =  Math.abs(this.bottom);
    this.largest = Math.max(this.topScreenPosition, this.bottomScreenPosition);

    this.subscription = combineLatest([
      this._hs.obsHeader$,
      this._hs.obsFooter$,
    ])
    .subscribe(([headerHeight, footerHeight]) => {
      this.headerHeight = 0;
      this.footerHeight = 0;

      if (footerHeight || headerHeight) {
        this.footerHeight = footerHeight;
        this.headerHeight = headerHeight;
        if (footerHeight > 0 && headerHeight > 0 && this.hasFooter && this.hasHeader) {
          this.getFloatingHeightWithFooterWithHeader(this.largest);
        }
        if (headerHeight === 0 && footerHeight > 0 && !this.hasHeader && this.hasFooter) {
          this.getFloatingHeightWithFooterNoHeader(this.largest);
        }
        if (footerHeight === 0 && headerHeight > 0 && this.hasHeader && !this.hasFooter) {
          this.getFloatingHeightWithHeaderNoFooter(this.largest);
        }
        this.subscription?.unsubscribe();
      }
      if (footerHeight === 0 && headerHeight === 0 && !this.hasHeader && !this.hasFooter) {
        this.getFloatingHeightNoFooterNoHeader(this.largest);
        this.subscription?.unsubscribe();
      }
    });
  }

  getFloatingHeightWithFooterNoHeader(largest: number) {
    if (largest > 350) {
      this.floatingHeight = 300 - this.footerHeight;
    } else {
      this.floatingHeight = largest - 55 - this.footerHeight;
    }
  }

  getFloatingHeightWithHeaderNoFooter(largest: number) {
    if (largest > 350) {
      this.floatingHeight = 300 - this.headerHeight;
    } else {
      this.floatingHeight = largest - 55 - this.headerHeight;
    }
  }

  getFloatingHeightNoFooterNoHeader(largest: number) {
    if (largest > 350) {
      this.floatingHeight = 300;
    } else {
      this.floatingHeight = largest - 55;
    }
  }

  getFloatingHeightWithFooterWithHeader(largest: number) {
    if (largest > 350) {
      this.floatingHeight = 300 - this.headerHeight - this.footerHeight;
    } else {
      this.floatingHeight = largest - 55 - this.headerHeight - this.footerHeight;
    }
  }

  getDropdownButtonWidth() {
    const buttonWidth = (this.dropdownButtonRef.nativeElement as HTMLElement).offsetWidth;
    if (buttonWidth > 240) {
      this.dropdownButtonWidth = (this.dropdownButtonRef.nativeElement as HTMLElement).offsetWidth;
    } else {
      this.dropdownButtonWidth = 240;
    }
  }

  toggleFloating(list?: any) {
    if (list && list.length > 0) {
      if (list.some((item: any) => item.type === 'footer')) {
        this.hasFooter = true;
      }
      if (list.some((item: any) => item.type === 'header')) {
        this.hasHeader = true;
      }
    }
    this.isFloatingActive = !this.isFloatingActive;
    this.status.emit(this.isFloatingActive);
    if (this.isFloatingActive) {
      this.getDropdownButtonWidth();
      this.floatingHeightSubscription();
    }
  }

  closeFloatingMenu() {
    this.isFloatingActive = false;
    this.status.emit(this.isFloatingActive);
    this.headerHeight = 0;
    this.footerHeight = 0;
    this.hasFooter = false;
    this.hasHeader = false;
  }

  onListItemClick(itemContent: any) {
    this.toggleFloating();
    if (this.data && this.data.title) {
      this.data.title = itemContent.title;
    }
    this.onListItemChange.emit({value: itemContent.value, metadata: this.metadata})
    this._dropdownService.changeDropdown({
      row: this.data ? this.data.row: '',
      column: this.data ? this.data.column: '',
      value: itemContent.value,
      title: itemContent.title,
      formGroup: this.data ? this.data.formGroup : ''
    } as DropdownData);
  }

}
