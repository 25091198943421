import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-aggregator-rt-24',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DCDDE0"/>
    <path d="M11.0713 12.344C12.1633 12.032 12.8833 11.168 12.8833 9.92C12.8833 8.492 11.9353 7.4 10.2193 7.4H6.89531V15.8H7.93931V12.476H9.97931L12.1873 15.8H13.3273V15.68L11.0713 12.344ZM7.93931 8.36H10.1593C11.2153 8.36 11.7913 8.948 11.7913 9.932C11.7913 10.94 11.2153 11.516 10.1593 11.516H7.93931V8.36ZM16.8544 15.908C17.2384 15.908 17.5744 15.86 17.7664 15.812V14.948H17.6464C17.5144 14.984 17.3344 15.02 17.0464 15.02C16.4944 15.02 16.2424 14.768 16.2424 14.18V10.52H17.7424V9.668H16.2424V8.024H16.1224L15.2584 8.36V9.668H14.2144V10.52H15.2344V14.264C15.2344 15.284 15.6424 15.908 16.8544 15.908Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class AggregatorRt24 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
