import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import * as fromAuth from '../state/selectors/auth.selectors';
import { AuthActions } from '../state/actions';
@Component({
  selector: 'shai-sign-in-tfa',
  template: `
    <div id="sign-in-tfa-wrapper">
      <mat-grid-list cols="12" rowHeight="220px">
        <mat-grid-tile colspan="2"></mat-grid-tile>
        <mat-grid-tile colspan="8">
          <shai-title-bar title="Enter your Authentication Code" topDescription="SIGN IN"
            bottomDescription="Enter the code from your Authenticator App to log in.">
          </shai-title-bar>
          <div class="center-wrapper">
            <div class="center-container">
              <shai-otp-input #otpInputRef [otpLength]="6" (onOtpCompleted)="onOtpCompleted($event)"></shai-otp-input>
            </div>
          </div>
          <div *ngIf="errorMsg.length > 0" class="center-wrapper">
            <div class="center-container">
              <span class="paragraph-small error-msg">{{ errorMsg }}</span>
            </div>
          </div>
          <div class="center-wrapper">
            <div class="center-container">
              <div class="icon-plus-text">
                <shai-icon-help-16></shai-icon-help-16>
                <span class="paragraph-small help-auth cursor-pointer" (click)="getVerificationCodeForResetMFA()">Problems with your Authentication app?</span>
                <!-- <shai-button *ngIf="user$ | async" (user$)="user$" [routerLink]="['/']" >Go to dashboard</shai-button> -->
              </div>
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile colspan="2"></mat-grid-tile>
      </mat-grid-list>
  </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./sign-in-tfa.component.scss']
})
export class SignInTfaComponent {
  @ViewChild('otpInputRef', { static: false }) otpInputRef: any;
  errorMsg: string = '';
  private errorSubscription!: Subscription;
  private subscription?: Subscription;
  private signInMFASubscription?: Subscription;
  errorMFA$: Observable<boolean | undefined> = this.store.pipe(
    select(fromAuth.isLoginError)
  );

  constructor(
    private store: Store
  ) {}

  onOtpCompleted(otpValue: string) {
    if (otpValue) {
      this.errorMsg = '';
      this.signInMFA(otpValue);
    }
  }

  signInMFA(code: string) {
    let hasResp = false;
    this.signInMFASubscription = combineLatest([
      this.store.pipe(select(fromAuth.getCredentials)),
      this.store.pipe(select(fromAuth.getLoginResponse))
    ]).subscribe(([credentials, loginResponse]) => {
      if (credentials && loginResponse && !hasResp) {
        const data = {
          authFlow: "SOFTWARE_TOKEN_MFA",
          session: loginResponse?.session,
          organizationSuid: credentials?.organizationSuid,
          username: credentials?.username,
          userCode: code
        }
        hasResp = true;
        this.store.dispatch(AuthActions.signInMFA({ signInMFA: data }));
        this.errorSubscription = this.errorMFA$.subscribe((error) => {
          if (error) {
            this.errorMsg = 'The code doesn’t match.';
            setTimeout(()=> {
              this.otpInputRef.otpInputRef.otpForm.enable();
              this.otpInputRef.otpInputRef.otpForm.reset();
              this.errorSubscription.unsubscribe();
            }, 1000);
          }
        });
        this.signInMFASubscription?.unsubscribe();
      }
    })
  }

  getVerificationCodeForResetMFA() {
    let hasResp = false;
    this.subscription = combineLatest([
      this.store.pipe(select(fromAuth.getCredentials)),
      this.store.pipe(select(fromAuth.getLoginResponse))
    ]).subscribe(([credentials, loginResponse]) => {
      if (credentials && loginResponse && !hasResp) {
        const data = {
          authFlow: "RESET_MFA_REQUIRED",
          session: loginResponse?.session,
          organizationSuid: credentials?.organizationSuid,
          username: credentials?.username,
        }
        hasResp = true;
        this.store.dispatch(AuthActions.resetMFA({ resetMFA: data }));
        this.subscription?.unsubscribe();
      }
    })
  }
}
