import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { CheckboxData, CheckboxService } from '../services/checkbox.service';
import { ExtraCellElement } from './table/models/extra-cell-element';
import { AdComponent } from './table/models/table.model';

@Component({
    selector: 'shai-checkbox',
    template: `
        <div class="checkbox">
            <div [ngClass]="(data.disabled === true) ? 'disabled' : ''" class="outer-square" (click)="toggleCheck()" >
                <div class="inner-square" [class.checked]="data.checked"></div>
            </div>
            <label *ngIf="data.label" class="checkbox-label">{{ data.label }}</label>
        </div>
    `,
    styleUrls: [`./checkbox.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent implements AdComponent {
    @Input() data: any;
    @Output() onToggleCheckbox = new EventEmitter();
    extraCellElements?: ExtraCellElement[] | undefined;
    checkbox: any;

    constructor (
        private _checkboxService: CheckboxService
    ) {}

    toggleCheck(): void {
        if (!this.data.disabled) {
          this.onToggleCheckbox.emit();
        } else {
            console.log('check disabled');
        }
    }
}
