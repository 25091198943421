import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AccessControlService } from 'src/app/shared/services/access-control.service';

@Component({
  selector: 'shai-sticky-bar',
  template: `
    <div class="sticky-bar-wrap">
      <shai-project-bar
        [showTitle]="isStickyTitle"
        [backTitle]="projectBarbackTitle"
        [showBackButton]="projectBarShowBackButton"
        [title]="title"
        [showTitle]="isStickyTitle"
        [navigationUrl]="navigationUrl"
      >
      </shai-project-bar>
      <div class="home-btns" *ngIf="homeButtons">
        <div class="home-btn-wrapper right-align">
          <div class="home-btn" routerLink="/assets" routerLinkActive="active">
            Assets
          </div>
        </div>
        <div class="home-btn-wrapper center-align">
          <div class="home-btn" routerLink="/trainings" routerLinkActive="active">
            Trainings
          </div>
        </div>
        <div class="home-btn-wrapper left-align" [ngClass]="{'disabled': categoryInferenceAccessControl}">
          <div class="home-btn" routerLink="/inferences" routerLinkActive="active">
            Inference
          </div>
        </div>
      </div>

      <mat-grid-list class="separation" cols="12" [rowHeight]="desiredRowHeight">
        <mat-grid-tile colspan="2"></mat-grid-tile>
        <mat-grid-tile colspan="8">
          <div #projectBar>
            <shai-title-bar
              [title]="title"
              [bottomDescription]="titleBarBottomDescription"
              [topDescription]="titleBarTopDescription"
              [showExtraSpace]="titleBarShowExtraSpace"
              [allowEdit]="titleBarAllowEdit"
              (handleEditClick)="
                titleBarAllowEdit ? handleEdit($event) : $event.preventDefault()
              "
            >
            </shai-title-bar>
          </div>
        </mat-grid-tile>
        <mat-grid-tile colspan="2"></mat-grid-tile>
      </mat-grid-list>
    </div>
  `,
  styleUrls: [`./sticky-bar.component.scss`],
})
export class StickyBarComponent implements OnInit {
  isStickyTitle = false;
  scrollMax = 70;
  desiredRowHeight = '0px';
  categoryInferenceAccessControl: boolean = true;

  @Input() set check(value: any) {
    if(value) {
      this.categoryInferenceAccessControl = this._accessControlService.newCheckAccessControl('inference-card');
      this.cdRef.detectChanges();
    }
  }

  @Input() hasHeader: boolean = true;
  @Input() projectBarbackTitle: string = 'Back to projects';
  @Input() projectBarShowBackButton: boolean = true;
  @Input() homeButtons: boolean = false;
  @Input() title: string | null | undefined = '';
  @Input() titleBarTopDescription?: string;
  @Input() titleBarBottomDescription?: string;
  @Input() titleBarShowExtraSpace?: boolean;
  @Input() titleBarAllowEdit: boolean = false;
  @Input() navigationUrl: string | undefined;

  @Output() handleEditClick = new EventEmitter();
  @ViewChild('projectBar', { static: false }) projectBarRef?: ElementRef;
  @ViewChild('titleBar', { static: false }) titleBarRef?: ElementRef;

  @HostListener('window:scroll', ['$event']) onscroll() {
    window.scrollY > this.scrollMax
      ? (this.isStickyTitle = true)
      : (this.isStickyTitle = false);
  }

  @HostBinding('class.extra-top') get extraTopClass() {
    return this.hasHeader;
  }

  constructor(private _accessControlService: AccessControlService,private cdRef: ChangeDetectorRef) {
    this.categoryInferenceAccessControl = this._accessControlService.newCheckAccessControl('inference-card');
  }

  ngOnInit() {
    if (this.titleBarTopDescription) {
      this.scrollMax = 105;
    }
  }

  ngAfterViewChecked(): void {
    const height = this.projectBarRef?.nativeElement.offsetHeight + 52; // 52 => project-bar height
    this.desiredRowHeight = `${height}px`;
    this.cdRef.detectChanges();
  }
  handleEdit(titleBarContent: any) {
    this.handleEditClick.emit(titleBarContent);
  }
}
