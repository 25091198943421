import { EntityStatus, Stamp } from './node';
import { Config } from './organization';
import { GenericParameter } from './training-session.model';

export interface Training {
  trainingSuid: string;
  config: Config;
  paradigm: ParadigmResponse;
  entityResolution?: TrainingEntityResolution;
  model: Model;
  aggregation: ParadigmResponse;
  differentialPrivacy: DifferentialPrivacyData;
  entityStatus: EntityStatus[];
  stamp: Stamp;
  trainingSessionTimestamp: string;
}

export interface Model {
  id: string;
  hyperparameters: GenericParameter[];
}

export interface TrainingList {
  page: number;
  pageSize: number;
  totalItemCount: number;
  items: Training[];
}

export interface DataTraining {
  name?: string;
  description?: string;
  tags?: string[];
}

export interface TrainingResponse {
  trainingSuid?: string;
  trainingSessionTimestamp?: string;
}

export interface ParadigmResponse {
  id: string;
  parameters: GenericParameter[];
}

export interface DifferentialPrivacyResponse {
  mechanism: string;
  parameters: GenericParameter[];
}

export interface DifferentialPrivacyData {
  mechanism?: Mechanism;
  sensitivity?: Sensitivity;
}

export interface EntityResolutionData {
  id: string;
  orderPreservation: boolean;
  output: string;
  threshold: number;
}

export interface DifferentialPrivacyDataShared {
  node?: string;
  differentialPrivacyData?: DifferentialPrivacyData
}

export interface Mechanism {
  type: 'none' | 'laplace' | 'gaussian' | 'exponential';
  epsilon?: number;
  delta?: number;
}

export interface Sensitivity {
  calculationMethod: 'maximum' | 'mean';
  calculationBasedOn: CalculationBasedOn;
  maxDataSize: number;
}

export interface CalculationBasedOn {
  sensitivityIterations?: number;
  gamma?: number;
}

export interface TrainingCondition {
  type: stopConditionTypes;
  operator: stopConditionOperators;
  value: number;
}

export interface TrainingStatus {
  uui: string;
  iteration: number;
  stamp: string;
  accuracy: number;
  status: string;
  animation?: number;
  startNodes: number;
  nodes: number;
  startStamp: string;
}

export enum TrainingAnimationStatus {
  INITIALIZING = 1,
  INITIALIZING_FINISHED = 1,
  ROUND_START = 1,
  ROUND_TRAIN_MODEL = 1,
  ROUND_AGGREGATE_WEIGHTS = 2,
  ROUND_DELIVER_GLOBAL_MODEL = 2,
  ROUND_EVALUATE_GLOBAL_MODEL = 2,
  ROUND_END = 4,
  FINISHED = 5,
}

export type NodeListIds = string[] | [];
export interface Infrastructure {
  status: InfrastructureStatus;
  orchestratorQueue?: boolean;
  orchestratorRealtimeQueue?: boolean;
  orchestratorCredentials?: boolean;
  aggregatorCredentials?: boolean;
  orchestratorTask?: boolean;
  resolverUuid?: boolean;
}
export interface UrlModelParameters {
  url?: string;
}

export type InfrastructureStatus =
  | 'PENDING'
  | 'CANCELED_AND_PENDING'
  | 'DEPLOYING'
  | 'READY'
  | 'UNDEPLOYING'
  | 'ERROR'
  | 'DONE'

export interface TrainingEntityResolution {
  id: 'psi' | 'psu' | 'none' | undefined;
  orderPreservation: boolean;
  threshold?: number;
  output?: 'ids' | 'ids+uids' | 'ids+uids+data' | 'ids+uids+data+synth';
  parameters?: GenericParameter[];
}

export interface NodeMetrics {
  nodeSuid: string;
  nodeName: string;
  trainingSuid: string;
  trainingSessionTimestamp: string;
  round: number;
  epoch: number;
  metricsData?: MetricsData;
  entityStatus?: EntityStatus;
  stamp?: Stamp;
}

export interface MetricsData {
  metrics: MetricItem,
  metricsType: string
}

export enum MetricsType {
  metricsForKMeans = 'metricsForKMeans',
  metricsForLinearRegression = 'metricsForLinearRegression',
  metricsForNeuralNetworksClassification = 'metricsForNeuralNetworksClassification',
  metricsForGradientBoosting = 'metricsForGradientBoosting',
  metricsForLLMs = 'metricsForLLMs',
  metricsForRandomForests = 'metricsForRandomForests',
  metricsForNeuralNetworksRegression = 'metricsForNeuralNetworksRegression',
  metricsForLogisticRegression = 'metricsForLogisticRegression',
  metricsForSupportVectorMachineClassification = 'metricsForSupportVectorMachineClassification',
  metricsForNaiveBayes = 'metricsForSupportVectorMachineClassification',
  metricsForTreeAugmentedNaiveBayes = 'metricsForTreeAugmentedNaiveBayes',
  metricsForFedPer = 'metricsForFedPer',
  metricsForFedProx = 'metricsForFedProx',
  metricsForLgFedAvg = 'metricsForLgFedAvg',
  metricsForPFedMe = 'metricsForPFedMe',
  metricsForId3 = 'metricsForPFedMe'
}

export const isClassificationMetricType = (metricsType: any): boolean => {
  switch(metricsType) {
    case MetricsType.metricsForNeuralNetworksClassification:
    case MetricsType.metricsForRandomForests:
    case MetricsType.metricsForGradientBoosting:
    case MetricsType.metricsForLLMs:
    case MetricsType.metricsForLogisticRegression:
    case MetricsType.metricsForSupportVectorMachineClassification:
    case MetricsType.metricsForNaiveBayes:
    case MetricsType.metricsForTreeAugmentedNaiveBayes:
    case MetricsType.metricsForFedPer:
    case MetricsType.metricsForFedProx:
    case MetricsType.metricsForLgFedAvg:
    case MetricsType.metricsForPFedMe:
    case MetricsType.metricsForId3:
      return true
    default:
      return false
  }
}

export const isRegressionMetricType = (metricsType: any): boolean => {
  switch(metricsType) {
    case MetricsType.metricsForNeuralNetworksRegression:
    case MetricsType.metricsForLinearRegression:
      return true
    default:
      return false
  }
}

export const isClusteringMetricType = (metricsType: any): boolean => {
  switch(metricsType) {
    case MetricsType.metricsForKMeans:
      return true
    default:
      return false
  }
}

export interface MetricItem {
  adjustedRandomScore?: number;
  homogeneity?: number;
  completeness?: number;
  vMeasure?: number;
  r2Score?: number;
  rmse?: number;
  macroAccuracy?: number;
  accuracy?: number[] | null;
  confusionMatrix?: number[][];
  sensitivity?: number[];
  macroSensitivity?: number;
  precision?: number[];
  macroPrecision?: number;
  f1Score?: number[];
  macroF1Score?: number;
  specificity?: number[];
  macroSpecificity?: number;
  informedness?: number[];
  macroInformedness?: number;
  markedness?: number[];
  macroMarkedness?: number;
  mCC?: number[];
  macroMCC?: number;
  aUC?: number[];
  macroAUC?: number;
  trainLoss?: number;
  testLoss?: number;
}

// TODO: Review new entities below this line

export enum Periodicity {
  'Monthly',
  'Weekly',
  'Daily',
}

export enum stopConditionTypes {
  'Number of iterations',
  'Accuracy in the last round',
  'Total accuracy',
  'Time Elapsed',
  'Error messages',
  'Attacks',
  'Data sent',
}

export enum stopConditionOperators {
  'Equals to',
  'Not equals to',
}

export enum AnimationStatus {
  'launch',
  'connecting',
  'learning',
  'transferAggregator',
  'aggregationStart',
  'aggregationLoop',
  'transferNodes',
  'completed',
  'restart',
}

export interface TrainingConfigurationStep {
  name: string;
  active: boolean;
}
