import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ExtraCellElement } from '../../models/extra-cell-element';
import { AdComponent } from '../../models/table.model';

@Component({
    selector: 'shai-table-cell-lottie',
    template: `
        <div class="table-cell-lottie-container">
            <div class="table-cell-lottie">

                <p *ngIf="!data" class="paragraph"><ng-content></ng-content></p>

                <div *ngIf="data" class="loading-content">
                    <ng-lottie class="loading-lottie" [options]="{ animationData: data.animation, loop: true }"></ng-lottie>
                </div>

            </div>
        </div>
    `,
    styleUrls: [`./table-cell-lottie.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCellLottieComponent implements AdComponent {
  @Input() data: any = null;

  constructor() { }
}
