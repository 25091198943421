
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs/internal/Subscription';
import { combineLatest } from 'rxjs';
import { fromAuth } from '../state';
import { User } from '../models/auth.models';
import { BreackpointService } from 'src/app/platform/services/breackpoint.service';

@Component({
  selector: 'shai-enable-tfa',
  template: `
    <div id="enable-tfa-wrapper">
      <mat-grid-list cols="12" rowHeight="fit">
        <mat-grid-tile colspan="2"></mat-grid-tile>
        <mat-grid-tile colspan="8">
          <shai-title-bar
            title="Enable Two-Factor Authentication"
            [showExtraSpace]="true"
            topDescription="STEP 1 OF 3"
            bottomDescription="Two-factor Authentication provides an enhanced security measure. Once enabled, you’ll be required to give two types of identification when you log into Sherpa.ai. ">
          </shai-title-bar>
          <div class="qr-container">
            <div id="qr-cell" [ngStyle]="{'width': (_bkp.obsScreen$ | async) === 'desktop' ? '60%' : '100%'}">
              <div *ngIf="myAngularxQrCode">
                <qrcode [qrdata]="myAngularxQrCode" [width]="156" [errorCorrectionLevel]="'M'"></qrcode>
                <!-- <img src="assets/images/qr.png" width="156px"> -->
              </div>
              <p class="paragraph-bold">Scan this QR Code with your Authenticator App.</p>
              <p class="paragraph-small">Or enter the following code manually: </p>
              <div id="qr-char-code">
                <p class="grey-p tfa-char-code">
                  <span>{{ firstHalfQrCode | qrFormatter | uppercase }}</span>
                  <br>
                  <span>{{ secondHalfQrCode | qrFormatter | uppercase }}</span>
                </p>
                <!-- <p class="grey-p tfa-char-code">3CL6 SBYX MEYO YHUJ CBAA B6OV GOTP<br>DAGU 7IQM BUFD RWNM QIZY IWBA</p> -->
              </div>
            </div>
            <div [ngStyle]="{'width': (_bkp.obsScreen$ | async) === 'desktop' ? '40%' : '100%'}">
              <div class="google-auth-block">
                <img src="assets/images/google_auth.png" width="64px">
                <p class="paragraph-bold">Don’t have an Authenticator app?</p>
                <p class="paragraph-small">
                  <span class="auth-app">Google Authenticator is a free 2FA app.</span><br>
                  Download it from your mobile device by searching for "Google Authenticator" on the
                  <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&gl=ES">Play Store</a> or the
                  <a href="https://apps.apple.com/es/app/google-authenticator/id388497605">App Store.</a>
                </p>
                <p class="paragraph-small">Other 2FA apps: Lastpass, Microsoft Authenticator, Authy by Twilio, 1Password</p>
              </div>
            </div>
          </div>
          <div class="bottom-text">
            <p class="grey-p">Once Sherpa.ai is registered, you'll start seeing 6-digit verification codes in the app.</p>
          </div>
        </mat-grid-tile>
        <mat-grid-tile colspan="2"></mat-grid-tile>
      </mat-grid-list>
      <shai-action-bar>
        <ng-container left-cell>
          <shai-button
            [type]="'button'"
            [stretch]="true"
            [route]="['/auth/verify-tfa']"
            buttonStyle="primary"
          >Next</shai-button>
        </ng-container>
      </shai-action-bar>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./enable-tfa.component.scss']
})
export class EnableTfaComponent {
  user = <User>JSON.parse(sessionStorage.getItem('USER_DATA') || '{}');
  myAngularxQrCode: string = '';
  stringQrCode: string = '';
  firstHalfQrCode: string[] = [];
  secondHalfQrCode: string[] = [];
  private subscription?: Subscription;

  constructor(
    private store: Store,
    public _bkp: BreackpointService,
  ) {
    this.getQrCode();
    this.getCodeToFormat(this.stringQrCode);
  }

  getQrCode() {
    this.subscription = combineLatest([
      this.store.pipe(select(fromAuth.getCredentials)),
      this.store.pipe(select(fromAuth.getLoginResponse))
    ]).subscribe(([credentials, loginResponse]) => {
      if (loginResponse) {
        this.stringQrCode = loginResponse?.secretCode ? loginResponse?.secretCode : '';
      }
      if (credentials?.username) {
        this.myAngularxQrCode = 'otpauth://totp/Sherpa%20(' + credentials?.username + ')?secret=' + this.stringQrCode;
      } else {
        this.myAngularxQrCode = 'otpauth://totp/?secret=' + this.stringQrCode;
      }
      console.log('[Login] qrCode', this.stringQrCode);
      this.subscription?.unsubscribe();
    })
  }

  getCodeToFormat(stringCode: string | null) {
    if (stringCode) {
      const arr = stringCode.split('');
      const half = Math.ceil(arr.length / 2) + 2;
      this.firstHalfQrCode = arr.slice(0, half);
      this.secondHalfQrCode = arr.slice(half, arr.length);
    }
  }

}