import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-aggregator-b-24',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DCDDE0"/>
    <path d="M9.20391 15.8H12.6599C14.3039 15.8 15.3359 14.912 15.3359 13.376C15.3359 12.188 14.6159 11.612 13.9679 11.348V11.3C14.5919 11.024 15.0239 10.496 15.0239 9.584C15.0239 8.204 13.9919 7.4 12.5759 7.4H9.20391V15.8ZM10.2359 11V8.336H12.4559C13.4159 8.336 13.9559 8.792 13.9559 9.584C13.9559 10.568 13.2959 11 12.3239 11H10.2359ZM10.2359 14.852V11.912H12.5399C13.6079 11.912 14.2559 12.392 14.2559 13.376C14.2559 14.336 13.5359 14.852 12.5399 14.852H10.2359Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class AggregatorB24 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}