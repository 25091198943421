import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({providedIn: 'root'})
export class DropdownData {
    row: string | undefined;
    column: string | undefined;
    value: string | undefined;
    title: string | undefined;
    formGroup: string | undefined;
}
export class DropdownService {
    private dropdownSource = new BehaviorSubject({
        row: '',
        value: '',
        formGroup: ''
    } as DropdownData);
    currentDropdown = this.dropdownSource.asObservable();

    constructor() { }

    changeDropdown(dropdown: DropdownData) {
      this.dropdownSource.next(dropdown);
    }
}
