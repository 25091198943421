import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  ProjectActions,
  TrainingActions,
  TrainingSessionActions,
} from '../actions';
import { ProjectService } from '../services/project.service';
import * as fromAuth from '../../auth/state/selectors/auth.selectors';
import * as fromPlatform from 'src/app/platform/reducers';
import { UploadFileService } from '../services/upload-file.service';

@Injectable()
export class ProjectEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store,
    private _projectService: ProjectService,
    private _uploadFileService: UploadFileService
  ) {}

  // CREATE PROJECT
  createProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.createProject),
      withLatestFrom(this.store.pipe(select(fromAuth.getOrg))),
      switchMap(([{ name, description }, org]) =>
        this._projectService.createProject(name, description, org).pipe(
          switchMap((projectRes) => {
            return [
              ProjectActions.createProjectSuccess({projectId: projectRes.projectSuid})
            ];
          }),
          catchError((error) => [
            ProjectActions.createProjectFailure({ error }),
          ])
        )
      )
    )
  );

  createProjectSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.createProjectSuccess),
      switchMap((project) => {
        return [
          ProjectActions.loadProject({ suid: project.projectId })
        ];
      })
    )
  );

  // UPDATE PROJECT
  updateProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.updateProject),
      withLatestFrom(this.store.pipe(select(fromAuth.getOrg))),
      switchMap(([{ suid, name, description }, org]) =>
        this._projectService.updateProject(suid, name, description, org).pipe(
          switchMap((projectRes) => {
            return [
              ProjectActions.updateProjectSuccess({projectId: suid}),
            ];
          }),
          catchError((error) => [
            ProjectActions.updateProjectFailure({ error }),
          ])
        )
      )
    )
  );

  updateProjectSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.updateProjectSuccess),
      switchMap((project) => {
        return [
          ProjectActions.loadProject({ suid: project.projectId }),
          ProjectActions.loadProjectList()
        ];
      })
    )
  );

  // DELETE PROJECT
  deleteProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.deleteProject),
      withLatestFrom(this.store.pipe(select(fromAuth.getOrg))),
      switchMap(([{ suid }, orgId]) =>
        this._projectService.deleteProject(orgId, suid).pipe(
          map(() => ProjectActions.deleteProjectSuccess()),
          catchError((error) => [
            ProjectActions.deleteProjectFailure({ error }),
          ])
        )
      )
    )
  );

  deleteProjectSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.deleteProjectSuccess),
      map(() => ProjectActions.loadProjectList())
    )
  );

  loadProjectList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.loadProjectList),
      withLatestFrom(this.store.pipe(select(fromAuth.getOrg))),
      switchMap(([, org]) =>
        this._projectService.loadProjectList(org).pipe(
          // switchMap(list => {
          //   const actions = [];
          //   for(let project of list.items) {
          //     actions.push(ProjectActions.countModelsByProject({ project }))
          //   }
          //   return [
          //     ...actions,
          //     ProjectActions.loadProjectListSuccess({list})
          //   ]
          // }),
          switchMap((list) => [
            ProjectActions.loadProjectListSuccess({ list }),
          ]),
          catchError((error) => [
            ProjectActions.loadProjectListFailure({ error }),
          ])
        )
      )
    )
  );

  loadProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.loadProject),
      withLatestFrom(this.store.pipe(select(fromAuth.getOrg))),
      switchMap(([{ suid }, org]) =>
        this._projectService.loadProject(suid, org).pipe(
          switchMap((project) => [
            ProjectActions.loadProjectSuccess({ project }),
            // TrainingActions.setTrainingTabPending({ pending: true }),
          ]),
          catchError((res) => {
            if (res.error.code === '404') {
              // this.router.navigate(['/project/not-found']);
              // return [];
            }
            return [
              ProjectActions.loadProjectFailure({ error: res.error }),
              // TrainingActions.setTrainingTabPending({ pending: false })
            ];
          })
        )
      )
    )
  );

  clearCurrent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.clearCurrent),
      switchMap(({ route, extras }) => {
        // map(() => ProjectActions.loadProjectList());
        this.router.navigate([route], extras);
        return [TrainingActions.clearTraining()];
      })
    )
  );

  // setProject$ = createEffect(() => this.actions$.pipe(
  //   ofType(ProjectActions.setProject),
  //   tap(({route}) => {
  //     if(route) {
  //       this.router.navigate([route]);
  //     }
  //   })
  // ), {dispatch: false});

  getAllFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.getAllFiles),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId))
      ),
      switchMap(([, org, project]) => {
        return this._projectService.getAllFiles(org, project).pipe(
          map((response) => {
            return ProjectActions.getAllFilesSuccess({
              filesList: response,
            });
          }),
          catchError((error) => {
            return [ProjectActions.getAllFilesFailure({ error })];
          })
        );
      })
    )
  );

  updateFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.updateFile),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId))
      ),
      switchMap(([{ uuid, modelId }, org, project]) => {
        return this._uploadFileService
          .updateFileModel(org, project, modelId, uuid)
          .pipe(
            map((response) => {
              return ProjectActions.updateFileSuccess();
            }),
            catchError((error) => {
              return [ProjectActions.updateFileFailure({ error })];
            })
          );
      })
    )
  );

  // updateFileSuccess$ = createEffect(() => this.actions$.pipe(
  //   ofType(ProjectActions.updateFileSuccess),
  //   map(() => ProjectActions.getAllFiles())
  // ));

  updateFileSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ProjectActions.updateFileSuccess),
    withLatestFrom(this.store.pipe(select(fromPlatform.getCurrentProjectId))),
    switchMap(([,suid]) => [
      ProjectActions.loadProject({ suid }),
      // ProjectActions.getAllFiles(),
    ])
  )
);

  updateFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.updateFiles),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId))
      ),
      switchMap(([{ ids }, orgId, projectId]) =>
        this._uploadFileService.updateFiles(orgId, projectId, ids).pipe(
          switchMap(() => [ProjectActions.updateFileSuccess()]),
          catchError((error) => [ProjectActions.updateFileFailure({ error })])
        )
      )
    )
  );

  // DELETE FILE
  deleteFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.deleteFile),
      withLatestFrom(this.store.pipe(select(fromAuth.getOrg))),
      switchMap(([{ suid, uuid }, orgId]) =>
        this._projectService.deleteFile(orgId, suid, uuid).pipe(
          map(() => ProjectActions.deleteFileSuccess({ suid: suid })),
          catchError((error) => [ProjectActions.deleteFileFailure({ error })])
        )
      )
    )
  );

  deleteFileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.deleteFileSuccess),
      switchMap((project) => [
        ProjectActions.getAllFiles(),
        ProjectActions.loadProject({ suid: project.suid }),
      ])
    )
  );

  deleteFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.deleteFiles),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId))
      ),
      switchMap(([{ filenames }, orgId, projectId]) => {
        return this._projectService.deleteFiles(orgId, projectId, filenames)
        .pipe(
          switchMap(() => [ProjectActions.deleteFilesSuccess({ suid: projectId })]),
          catchError((error) => [ProjectActions.deleteFilesFailure({ error })])
        )
      })
    )
  );

  deleteFilesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.deleteFilesSuccess),
      switchMap((project) => [
        ProjectActions.getAllFiles(),
        ProjectActions.loadProject({ suid: project.suid }),
      ])
    )
  );
}
