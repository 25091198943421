import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'shai-page-header',
  template: `
    <header>
      <ng-content select="[header-pre]"></ng-content>
      <div class="heading" *ngIf="type === 'primary'">{{text}}</div>
      <ng-content select="[header-description]"></ng-content>
    </header>
  `,
  styleUrls: [`./page-header.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderComponent {
  @Input() type : 'primary' | 'secondary' = 'primary';
  @Input() text : string | undefined;

  @HostBinding('class') get headerTypeClass() {
    return `page-header-${this.type}`
  }
}
