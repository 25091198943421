import { ChangeDetectionStrategy, Component, Input, OnInit, ViewContainerRef, ComponentFactoryResolver, ViewChild, Renderer2, ElementRef, ViewChildren, QueryList, AfterViewInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { hasClass } from 'src/app/utils/dom-utils';
import { CheckboxData, CheckboxService } from '../../services/checkbox.service';
import { CheckboxComponent } from '../checkbox.component';
// Add new icons here ...

@Component({
    selector: 'shai-list-item-filter',
    template: `
        <ng-container *ngIf="_data">
            <div class="list-item-filter-container" (click)="onFilterClick(_data.title)">
                <div class="list-item-filter">
                    <div #checkRef class="checkbox-left">
                        <shai-checkbox [data]="_data"></shai-checkbox>
                    </div>
                    <span class="paragraph">{{ _data.title }}</span>
                </div>
            </div>
        </ng-container>
    `,
    styleUrls: [`./list-item-filter.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListItemFilterComponent implements AfterViewInit {
    _data: any;
    @ViewChild(CheckboxComponent) checkComp!: CheckboxComponent;
    infoCheck: any;
    @ViewChild('checkRef', { static: false }) checkRef?: ElementRef;
    @ViewChild('leftFilter', { read: ViewContainerRef}) leftFilter: ViewContainerRef | undefined;
    @Input() set data (value: any) {
        this._data = value;
    }
    // @Input() checked : boolean = false;
    // @Input() disabled : boolean = false;
    // @Input() title : string = 'Text';

    constructor(
        private _checkboxService: CheckboxService,
        private render: Renderer2,
        private cdRef: ChangeDetectorRef,
    ) { }

    ngAfterViewInit(): void {
        this.infoCheck = this.checkRef?.nativeElement as HTMLElement;
    }

    onFilterClick(event: string) {
        if (!this.checkComp.data.disabled) {
            this.checkComp.data.checked = !this.checkComp.data.checked;
            this._checkboxService.changeCheckbox({
                checked: this.checkComp.data.checked,
                value: this.checkComp.data.value,
                formGroup: this.checkComp.data.formGroup
            } as CheckboxData);

            const checkReference = this.infoCheck.childNodes[0].childNodes[0].childNodes[0].childNodes[0];
            if (!this.checkComp.data.checked && hasClass(checkReference, 'checked')) {
                this.render.removeClass(checkReference, 'checked');
            } else if (this.checkComp.data.checked && !hasClass(checkReference, 'checked')) {
                this.render.addClass(checkReference, 'checked');
            }
            this.cdRef.detectChanges();
        }
    }
}
