import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-user-new-16',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11 4C11 5.65685 9.65685 7 8 7C6.34315 7 5 5.65685 5 4C5 2.34315 6.34315 1 8 1C9.65685 1 11 2.34315 11 4ZM10 4C10 5.10457 9.10457 6 8 6C6.89543 6 6 5.10457 6 4C6 2.89543 6.89543 2 8 2C9.10457 2 10 2.89543 10 4Z" fill="#1F1F1F"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 9.79086 3.79086 8 6 8H10C12.2091 8 14 9.79086 14 12V15H2V12ZM3 12C3 10.3431 4.34315 9 6 9H10C11.6569 9 13 10.3431 13 12V14H3V12Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class IconUserNew16 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}