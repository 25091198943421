import { CdkStep } from '@angular/cdk/stepper';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { InputErrorMessage } from 'src/app/shared/components/form-controls/input-text/form-input-text.component';
import { StepState } from 'src/app/shared/models/step-state';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { BreackpointService } from 'src/app/platform/services/breackpoint.service';
import { fromAuth } from '../state';
import { AuthActions } from '../state/actions';
import { SnackbarService, SnackbarType } from 'src/app/shared/components/snackbar/snackbar.service';

@Component({
  selector: 'shai-reset-password-step-two',
  template: `
  <div id="reset-password-step-two">
    <mat-grid-list cols="12" rowHeight="fit">
      <mat-grid-tile colspan="2"
        *ngIf="(_bkp.obsScreen$ | async) === 'desktop'">
      </mat-grid-tile>
      <mat-grid-tile [colspan]="(_bkp.obsScreen$ | async) === 'desktop' ? 8 : 12">
        <shai-title-bar
          [topDescription]="
            'Step ' +
            (this.step && this.step?._stepper && this.step._stepper.selectedIndex < this.newLength ? this.step._stepper.selectedIndex + 1 : this.step?._stepper?.selectedIndex) +
            ' of ' +
            this.newLength"
          title="Reset password"
          bottomDescription="Enter a new password following the requirements. And then enter the verification code which we have emailed you. Remember that the lifetime of the code is only 1 hour.">
        </shai-title-bar>
        <div class="container-form">
          <form [formGroup]="resetPasswordForm">
            <div class="form-field-group">
              <shai-form-input-text
                [parentForm]="resetPasswordForm"
                formControlName="password"
                fieldName="password"
                label="New password"
                maxLength="99"
                [isSecureField]="true"
                [errorMessages]="passwordErrorMessages"
                [hasSubmitAttemps]="hasSubmitAttemps">
              </shai-form-input-text>
            </div>
            <div id="password-conditions" class="paragraph">
              Required:
              <ul>
                <li>
                  At least 12 characters
                  <shai-icon-checkmark-16 *ngIf="passwordHasValue() && atLeastTwelveCharacters()"></shai-icon-checkmark-16>
                  <shai-icon-warning-16 *ngIf="passwordHasValue() && !atLeastTwelveCharacters() || hasSubmitAttemps && !atLeastTwelveCharacters()"></shai-icon-warning-16>
                </li>
                <li>
                  A mixture of both uppercase and lowercase letters
                  <shai-icon-checkmark-16 *ngIf="passwordHasValue() && hasLowerCaseCharacters() && hasUpperCaseCharacters()"></shai-icon-checkmark-16>
                  <shai-icon-warning-16 *ngIf="passwordHasValue() && (!hasLowerCaseCharacters() || !hasUpperCaseCharacters()) || hasSubmitAttemps && (!hasLowerCaseCharacters() || !hasUpperCaseCharacters())"></shai-icon-warning-16>
                </li>
                <li>
                  A mixture of letters and numbers
                  <shai-icon-checkmark-16 *ngIf="passwordHasValue() && hasNumberCharacters()"></shai-icon-checkmark-16>
                  <shai-icon-warning-16 *ngIf="passwordHasValue() && !hasNumberCharacters() || hasSubmitAttemps && !hasNumberCharacters()"></shai-icon-warning-16>
                </li>
                <li>
                  Inclusion of at least one special character, e.g., ! @ # ? ]
                  <shai-icon-checkmark-16 *ngIf="passwordHasValue() && hasSpecialCharacters()"></shai-icon-checkmark-16>
                  <shai-icon-warning-16 *ngIf="passwordHasValue() && !hasSpecialCharacters() || hasSubmitAttemps && !hasSpecialCharacters()"></shai-icon-warning-16>
                </li>
              </ul>
            </div>
            <div class="form-field-group">
              <shai-form-input-text
                [parentForm]="resetPasswordForm"
                [isDisabled]="isCodeDisabled()"
                formControlName="code"
                fieldName="code"
                label="Verification code"
                maxLength="99"
                [errorMessages]="codeErrorMessages"
                [hasSubmitAttemps]="hasSubmitAttemps">
              </shai-form-input-text>
            </div>
          </form>
        </div>
      </mat-grid-tile>
      <mat-grid-tile colspan="2"
        *ngIf="(_bkp.obsScreen$ | async) === 'desktop'">
      </mat-grid-tile>
    </mat-grid-list>
    <shai-action-bar>
      <ng-container left-cell>
        <shai-button
          [type]="'button'"
          (click)="onPreviousHandler()"
          buttonStyle="secondary"
        >Previous</shai-button>
      </ng-container>
      <ng-container right-cell>
        <shai-button
          [type]="'button'"
          (click)="handleSubmit()"
          buttonStyle="primary"
        >Next</shai-button>
      </ng-container>
    </shai-action-bar>
  </div>
  `,
  styleUrls: ['./reset-password-step-two.component.scss'],
})
export class ResetPasswordStepTwoComponent implements OnInit, AfterViewInit, OnDestroy {
  newLength!: number;
  hasSubmitAttemps = false;
  firstSubmitAttemps = true;
  @Input() step: CdkStep | undefined;
  @Output() resetPassComplete = new EventEmitter();
  @Output() onChange = new EventEmitter<{ state: StepState; data: any }>();
  error$ : Observable<any> = new Observable();
  pending$ : Observable<any> = new Observable();
  stepNumber!: number;
  mailto = "mailto:engineering@sherpa.ai";
  private subscriptionReset?: Subscription;
  private subscription?: Subscription;
  private subscriptionError?: Subscription;

  resetPasswordForm = this.formBuilder.group({
    password: ['', [Validators.required]],
    code: ['', [Validators.required]],
  });

  passwordErrorMessages: InputErrorMessage[] = [
    { type: 'required', message: 'The password field is required.' },
  ];

  codeErrorMessages: InputErrorMessage[] = [
    { type: 'required', message: 'The verification code field is required.' },
  ];

  constructor(
    private store: Store,
    public _bkp: BreackpointService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.error$ = this.store.pipe(select(fromAuth.isLoginError)) as Observable<any>;
    this.pending$ = this.store.pipe(select(fromAuth.isLoginPending)) as Observable<any>;
  }

  ngOnInit(): void {
    this._checkResetPassCompleted();
  }

  ngAfterViewInit() {
    if (this.step) {
      this.newLength = (this.step._stepper._steps && this.step._stepper._steps.length) ? (this.step._stepper._steps.length - 1) : this.step._stepper._steps?.length;
      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.subscriptionError?.unsubscribe();
    this.subscriptionReset?.unsubscribe();
  }

  onPreviousHandler() {
    if (this.step) {
      this.step._stepper.previous();
    }
  }

  passwordHasValue() {
    return this.resetPasswordForm.value.password.length > 0;
  }

  atLeastTwelveCharacters() {
    return this.resetPasswordForm.value.password.length >= 12;
  }

  hasLowerCaseCharacters() {
    const lowerCaseCharacters = /[a-z]+/g
    return lowerCaseCharacters.test(this.resetPasswordForm.value.password);
  }

  hasUpperCaseCharacters() {
    const upperCaseCharacters = /[A-Z]+/g
    return upperCaseCharacters.test(this.resetPasswordForm.value.password);
  }

  hasNumberCharacters() {
    const numberCharacters = /[0-9]+/g
    return numberCharacters.test(this.resetPasswordForm.value.password);
  }

  hasSpecialCharacters() {
    const specialCharacters = /[!@#%^&*()_+\-=\[\]{};':"\\|,.\/?]+/
    return specialCharacters.test(this.resetPasswordForm.value.password);
  }

  isCodeDisabled() {
    if (this.atLeastTwelveCharacters() && this.hasLowerCaseCharacters() && this.hasUpperCaseCharacters() && this.hasNumberCharacters() && this.hasSpecialCharacters()) {
      return false;
    }
    return true;
  }

  handleSubmit() {
    this.hasSubmitAttemps = true;
    if(this.resetPasswordForm.valid) {
      this.subscription = this.store.pipe(select(fromAuth.getResetPassCredentials))
      .subscribe(credentials => {
        if (credentials) {
          const data = {
            authFlow: "RESET_PASSWORD_STAGED",
            organizationSuid: credentials?.organizationSuid,
            username: credentials?.username,
            newPassword: this.resetPasswordForm.value.password,
            confirmationCode: this.resetPasswordForm.value.code
          }
          this.store.dispatch(AuthActions.resetPassword({ resetPassCredentials: data }));
          this.subscription?.unsubscribe();
        }
      });
      this.subscriptionError = combineLatest([this.error$, this.pending$])
        .subscribe((([error, pending]) => {
          if (this.step && error && !pending) {
            if (this.firstSubmitAttemps) {
              this.firstSubmitAttemps = false;
              this.snackbar.show(
                'Sorry, something went wrong',
                SnackbarType.twoButtons,
                'Please try again.',
                false,
                'Try Again',
                'Cancel',
                () => this.primaryButtonHandler(),
                () => this.cancelButtonHandler()
              );
            } else {
              setTimeout(() => {
                this.snackbar.show(
                  'Sorry, the password cannot be changed at this time. ',
                  SnackbarType.twoButtons,
                  'Please try again later or contact us.',
                  false,
                  'Try Again Later',
                  'Contact sherpa',
                  () => this.goToHome(),
                  () => this.contactSherpa()
                );
              }, 1000);
            }
            this.subscriptionError?.unsubscribe();
          }
        })
      )
    }
  }

  private _checkResetPassCompleted() {
    this.subscriptionReset = combineLatest([
      this.store.pipe(select(fromAuth.getResetPasswordCompleted)),
      this.store.pipe(select(fromAuth.getResetPassCredentials))
    ]).subscribe(([resetPasswordCompleted, credentials]) => {
        if (this.step && credentials && resetPasswordCompleted && credentials.authFlow === "RESET_PASSWORD_STAGED") {
          this.step?._stepper.next();
          this.resetPassComplete.emit(true);
        }
      })
  }

  primaryButtonHandler() {
    this.cancelButtonHandler();
    setTimeout(() => {
      this.handleSubmit();
    }, 700)
  }

  cancelButtonHandler() {}

  goToHome() {
    this.onChange.emit({ state: StepState.finish, data: null });
    this.store.dispatch(AuthActions.clearReset());
    this.cdRef.detectChanges();
  }

  contactSherpa() {
    const link = document.createElement('a');
    link.target = "_blank";
    link.href = this.mailto;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
