import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-zoom-increase',
  template: `
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 26C2 12.7452 12.7452 2 26 2V2C39.2548 2 50 12.7452 50 26V26C50 39.2548 39.2548 50 26 50V50C12.7452 50 2 39.2548 2 26V26Z"
        fill="white"
      />
      <rect width="16" height="16" transform="translate(18 18)" fill="white" />
      <line x1="26" y1="18" x2="26" y2="34" stroke="#1F1F1F" />
      <line x1="18" y1="25.75" x2="34" y2="25.75" stroke="#1F1F1F" />
      <path
        d="M26 49C13.2975 49 3 38.7025 3 26H1C1 39.8071 12.1929 51 26 51V49ZM49 26C49 38.7025 38.7025 49 26 49V51C39.8071 51 51 39.8071 51 26H49ZM26 3C38.7025 3 49 13.2975 49 26H51C51 12.1929 39.8071 1 26 1V3ZM26 1C12.1929 1 1 12.1929 1 26H3C3 13.2975 13.2975 3 26 3V1Z"
        fill="#DCDDE0"
      />
    </svg>
  `,
})
export class IconZoomIncreaseComponent {
  @HostBinding('class.shai-icon') get iconClass() {
    return true;
  }
}
