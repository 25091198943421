import { createReducer, on } from "@ngrx/store";
import { DataTraining, TrainingResponse, DifferentialPrivacyData, EntityResolutionData } from "../models";
import { NewTrainingActions } from "../actions";
import { GenericParameter } from "../models/training-session.model";

export const newTrainingFeatureKey = 'newTraining';
const newTrainingStorageKey = 'flp_storage_new_training_state';

export interface State {
  trainingData: DataTraining | null;
  paradigmData: { paradigmId: string | null, parametersData: GenericParameter[] | undefined },
  entityResolutionData: EntityResolutionData | null,
  modelData: { modelId: string | null, hyperparametersData: GenericParameter[] | undefined },
  aggregatorData: string | null,
  differentialPrivacyData: DifferentialPrivacyData | null,
  newTraining?: TrainingResponse | null,
}

const initialState: State = {
  trainingData: null,
  paradigmData: { paradigmId: null, parametersData: undefined },
  entityResolutionData: null,
  modelData: { modelId: null, hyperparametersData: undefined },
  aggregatorData: null,
  differentialPrivacyData: null,
  newTraining: null,
}

export const reducer = createReducer(
  initialState,

  // Clear data
  on(NewTrainingActions.clearDataNewTraining, state => ({ ...state, trainingData: initialState.trainingData})),
  on(NewTrainingActions.clearDataParadigm, state => ({ ...state, paradigmData: initialState.paradigmData})),
  on(NewTrainingActions.clearDataEntityResolution, state => ({ ...state, entityResolutionData: initialState.entityResolutionData})),
  on(NewTrainingActions.clearDataModel, state => ({ ...state, modelData: initialState.modelData})),
  on(NewTrainingActions.clearDataAggregator, state => ({ ...state, aggregatorData: initialState.aggregatorData})),
  on(NewTrainingActions.clearDataDifferentialPrivacy, state => ({ ...state, differentialPrivacyData: initialState.differentialPrivacyData})),
  on(NewTrainingActions.clearNewTraining, state => ({ ...state, newTraining: initialState.newTraining})),

  // Create new training
  on(NewTrainingActions.createNewTraining, (state) => ({ ...state, status: { pending: true }})),
  on(NewTrainingActions.createNewTrainingSuccess, (state, { training }) => ({...state, newTraining: training, status: { pending: false }})),
  on(NewTrainingActions.createNewTrainingFailure, (state, { error }) => ({ ...state, status: { pending: false, error } })),
);

export const getNewTrainingId = (state: State) => state.newTraining?.trainingSuid;
export const getNewTrainingTimestamp = (state: State) => state.newTraining?.trainingSessionTimestamp;
export const getNewTrainingData = (state: State) => state?.trainingData;
export const getParadigmData = (state: State) => state?.paradigmData;
export const getEntityResolutionData = (state: State) => state?.entityResolutionData;
export const getModelData = (state: State) => state?.modelData;
export const getAggregatorData = (state: State) => state?.aggregatorData;
export const getDifferentialPrivacyData = (state: State) => state?.differentialPrivacyData;
