import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ItemFile, Project } from 'src/app/platform';
import { ProjectActions, NewTrainingActions } from 'src/app/platform/actions';
import { OverlayQuestionModel } from 'src/app/shared/components/overlay.component';
import { getDocumentationRoute } from 'src/app/utils';
import { configMock } from 'src/app/platform/mocks/global-config';
import { Observable, Subscription } from 'rxjs';
import * as fromPlatform from 'src/app/platform/reducers';
import { BreackpointService } from 'src/app/platform/services/breackpoint.service';
import { AccessControlService } from 'src/app/shared/services/access-control.service';
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: 'shai-upload-files-overlay',
  template: `
    <shai-overlay
      (onChange)="onChangeOverlay($event)"
      [active]="isActive"
      [complete]="isComplete"
      [hasHeader]="false"
      [fullWidth]="true"
      (onLeave)="closeProjectFilesOverlay()"
      [isSecondaryOverlay]="isSecondaryOverlay"
    >
      <div id="project-files-overlay-wrapper">
        <div id="project-files-overlay-wrapper-body">
          <mat-grid-list cols="12" rowHeight="fit">
            <mat-grid-tile
              colspan="1"
              *ngIf="(_bkp.obsScreen$ | async) === 'desktop'"
            >
            </mat-grid-tile>
            <mat-grid-tile
              [colspan]="(_bkp.obsScreen$ | async) === 'desktop' ? 10 : 12"
            >
              <shai-title-bar
                title="Model files"
                bottomDescription="You can add the different model files that will be used, as well as code files you might
                                  want to use in combination with aggregators when executing a training session."
                [showExtraSpace]="true"
              >
              </shai-title-bar>
              <shai-upload-new-files-overlay
                (uploadCompleted)="handleUploadCompleted()"
                (validFileSelectionOnChange)="handleFileSelectionStatus($event)"
                [clickCount]="clickCount"
              ></shai-upload-new-files-overlay>
              <mat-grid-tile
                colspan="1"
                *ngIf="(_bkp.obsScreen$ | async) === 'desktop'"
              >
              </mat-grid-tile>

              <mat-grid-tile
                colspan="1"
                *ngIf="(_bkp.obsScreen$ | async) === 'desktop'"
              >
              </mat-grid-tile>
            </mat-grid-tile>
            <mat-grid-tile
              colspan="1"
              *ngIf="(_bkp.obsScreen$ | async) === 'desktop'"
            >
            </mat-grid-tile>
          </mat-grid-list>
        </div>
        <shai-action-bar>
          <ng-container left-cell>
            <shai-button
              [type]="'button'"
              (click)="closeProjectFilesOverlay()"
              buttonStyle="secondary"
              >Leave</shai-button
            >
          </ng-container>
          <ng-container right-cell>
            <shai-button
              *ngIf="!isUploading"
              [disabled]="!isValidFileSelection"
              [type]="'submit'"
              (click)="onSaveHandler()"
              buttonStyle="primary"
              >Save</shai-button
            >
            <shai-button
              [type]="'button'"
              *ngIf="isUploading">
                <div class="spinner-wrapper inside-button-spinner">
                  <ng-lottie [options]="blackLoaderAnimation"></ng-lottie>
                </div>
            </shai-button>
          </ng-container>
        </shai-action-bar>
      </div>
    </shai-overlay>
  `,
  styleUrls: ['./upload-files-overlay.component.scss'],
})
export class UploadFilesOverlayComponent implements OnInit, OnDestroy {
  @Output() toggleProjectFilesEmitter = new EventEmitter();
  algorithmsMock = configMock.algorithms;
  algorithms: any[] = [];
  algorithmListItems: any[] = [];
  dataset: any[] = [];
  files: ItemFile[] = [];
  _project?: Project | null;
  _projectId?: string;
  rowHeight = 'fit';
  closeQuestion: OverlayQuestionModel = {
    title: 'Quit managing model files?',
  };
  isActive: boolean = true;
  isComplete: boolean = false;
  isUploading: boolean = false;
  isSecondaryOverlay: boolean = false;
  disabledDeleteBtn: boolean = true;
  isValidFileSelection: boolean | null = true;
  clickCount: number = 0;

  blackLoaderAnimation: AnimationOptions = {
    path: '/assets/lotties/loader.json',
    loop: true
  }

  isLoadProjectRequired: boolean = false;
  isLoadProjectFilesRequired: boolean = false;

  @Input() set loadProjectRequired(value: boolean) {
    if (value === true) {
      this.isLoadProjectRequired = true;
    }
  }

  @Input() set loadProjectFilesRequired(value: boolean) {
    if (value === true) {
      this.isLoadProjectFilesRequired = true;
    }
  }

  @Input() set isOpenOverOverlay(value: boolean) {
    if (value === true) {
      this.isSecondaryOverlay = true;
    }
  }
  private projectSubscription?: Subscription;
  project$: Observable<Project | null | undefined> = this.store.pipe(select(fromPlatform.getCurrentProject));

  @HostListener('window:resize', ['$event']) onResize() {
    this.rowHeight = window.innerHeight - 144 - 44 + 'px'; // 44px => header, 144px => bottom-button
  }

  constructor(
    private store: Store,
    public _bkp: BreackpointService,
    private _accessControlService: AccessControlService,
  ) {
    this.onResize();
  }

  ngOnInit() {
    this._loadAlgorithmsListItems();
    this._setProjectSubscription();
  }

  ngOnDestroy(): void {
    this.projectSubscription?.unsubscribe();
  }

  handleFileSelectionStatus(value: boolean) {
    this.isValidFileSelection = value;
  }

  handleUploadCompleted(): void {
    if (this.isLoadProjectRequired) {
      this.store.dispatch(ProjectActions.loadProject({ suid: this._project?.projectSuid }));
    }
    if (this.isLoadProjectFilesRequired) {
      this.store.dispatch(ProjectActions.getAllFiles());
    }

    this.isUploading = false;
  }

  private _loadAlgorithmsListItems() {
    this.algorithms = this.algorithmsMock.reduce(function (map: any, obj: any) {
        map[obj.id] = {
            name: obj.name,
            optGroup: obj.optGroup,
            optItem: obj.optItem
        };
        return map;
    }, {});

    Object.entries(this.algorithms).forEach(([key, value]) => {
        this.algorithmListItems.push({
            type: 'body',
            title: value.name,
            value: key,
            optGroup: value.optGroup,
            optItem: value.optItem
        });
    });
}

  private _setProjectSubscription() {
    this.projectSubscription = this.project$.subscribe((project) => {
      if (project) {
        this._project = { ...project };
        this._projectId = this._project.projectSuid;
        if (this._project.files) {
          this.dataset = [];
          this.files = [];
        }
      }
    });
  }

  onSaveHandler(): void {
    this.isUploading = true;
    this.clickCount++;
  }

  onChangeOverlay(event: boolean) {
    //TODO show snackbar
    if (!event) {
      this.finishProcess();
      this.store.dispatch(NewTrainingActions.clearAllDataTraining());
    } else {
      this.isActive = true;
    }
  }

  closeProjectFilesOverlay() {
    this.isActive = false;
  }

  private finishProcess() {
    this.toggleProjectFilesEmitter.emit(false);
  }

  viewDocumentation(id: string) {
    return this._accessControlService.getBaseUrlByUserRole() + getDocumentationRoute(id);
  }
}
