import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-aggregator-rf-24',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DCDDE0"/>
    <path d="M11.1358 12.344C12.2278 12.032 12.9478 11.168 12.9478 9.91999C12.9478 8.49199 11.9998 7.39999 10.2838 7.39999H6.95977V15.8H8.00377V12.476H10.0438L12.2518 15.8H13.3918V15.68L11.1358 12.344ZM8.00377 8.35999H10.2238C11.2798 8.35999 11.8558 8.94799 11.8558 9.93199C11.8558 10.94 11.2798 11.516 10.2238 11.516H8.00377V8.35999ZM15.2628 15.8H16.2708V10.508H17.7588V9.66799H16.2708V8.59999C16.2708 7.99999 16.6188 7.66399 17.2188 7.66399C17.4228 7.66399 17.6388 7.68799 17.8188 7.72399V6.88399C17.6028 6.83599 17.3748 6.79999 17.0508 6.79999C15.9468 6.79999 15.2628 7.54399 15.2628 8.59999V9.66799H14.1708V10.508H15.2628V15.8Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class AggregatorRf24 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
