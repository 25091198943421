import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AnimationOptions } from 'ngx-lottie';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { MetricMenu, OrgTrainingStatus, Organization, TrainingFilter, TrainingsMenu } from 'src/app/platform/models/organization';
import * as fromPlatform from 'src/app/platform/reducers';
import { HelperService } from 'src/app/platform/services/helper.service';
import { OrganizationService } from 'src/app/platform/services/organization.service';
import { TableColumn } from 'src/app/shared/components/table/models/table.model';
import { TableCellDoubleComponent } from 'src/app/shared/components/table/rows/cells/table-cell-double.component';
import { TableCellLottieComponent } from 'src/app/shared/components/table/rows/cells/table-cell-lottie.component';
import { TableCellSingleComponent } from 'src/app/shared/components/table/rows/cells/table-cell-single.component';

const MAIN_MENU_OVERLAY = 'main-menu-overlay';

@Component({
  selector: 'shai-org-projects-overlay',
  template: `
    <shai-overlay
      [active]="isActive"
      [fullWidth]="false"
      [hasHeader]="true"
      heading=""
      (onChange)="onChangeOverlay($event)"
      (onLeave)="onLeaveOverlay()"
    >
      <mat-grid-list cols="12" rowHeight="fit" id="rounds-download-overlay-wrapper">
        <mat-grid-tile colspan="12">

          <shai-title-bar title="{{orgName}}" bottomDescription="{{orgDescription}}">
          </shai-title-bar>

          <shai-tip type="oneLine" text="{{ tipTitle }}"></shai-tip>

          <div *ngIf="loadingData" class="loading">
            <div class="loading-content">
              <ng-lottie class="loading-lottie" [options]="blackLoaderAnimation"></ng-lottie>
            </div>
          </div>

          <div *ngIf="!loadingData && showOnGoingSection" style="margin-top: 24px;">

            <div style="display: flex; margin-top: 10px; margin-bottom: 10px;">
              <div *ngIf="ongoingTrainingsTitle" class="table-header color-sherpa">{{ongoingTrainingsTitle}}</div>
            </div>

            <shai-table
              class="table-row-clickable"
              [dataset]="onGoingDatasetTrainings"
              [columns]="onGoingTrainingColumns"
              [paginationId]="onGoingPaginationId"
              [paginationItemsPage]="onGoingPaginationItemsPage"
              [paginationCurrentPage]="onGoingPaginationCurrentPage"
              [paginationTotalItems]="onGoingPaginationTotalItems"
              [showPaginator]="true"
              [showTableHeader]="true"
              (goToTraining)="goToTraining($event)"
            >
            </shai-table>

            <div *ngIf="showOnGoingPagination" class="pagination-btns">
              <shai-button
                [type]="'button'"
                (click)="clickPreviousPage('ongoing')"
                buttonStyle="tertiary"
                [disabled]="disabledPreviousPageOnGoing"
              >Previous</shai-button>

              <shai-button
              style="width: 10px;"
                [type]="'button'"
                buttonStyle="tertiary"
                [disabled]="true"
              >{{ currentPageOnGoing }}</shai-button>

              <shai-button
                [type]="'button'"
                (click)="clickNextPage('ongoing')"
                buttonStyle="tertiary"
                [disabled]="disabledNextPageOnGoing"
              >Next</shai-button>
            </div>
          </div>

          <div *ngIf="!loadingData && showFinishedSection" [ngStyle]="{'margin-top': showOnGoingSection ? '48px' : '24px'}">
            <div style="display: flex; margin-top: 10px; margin-bottom: 10px;">
              <div *ngIf="finishedTrainingsTitle" class="table-header">{{finishedTrainingsTitle}}</div>
              <div *ngIf="finishedloading" class="loading-content" style="margin-left: 25px;">
                <ng-lottie class="loading-lottie" [options]="blackLoaderAnimation"></ng-lottie>
              </div>
            </div>

            <shai-table
              class="table-row-clickable"
              [dataset]="finishedDatasetTrainings"
              [columns]="finishedTrainingColumns"
              [paginationId]="finishedPaginationId"
              [paginationItemsPage]="finishedPaginationItemsPage"
              [paginationCurrentPage]="finishedPaginationCurrentPage"
              [paginationTotalItems]="finishedPaginationTotalItems"
              [showPaginator]="true"
              [showTableHeader]="true"
              (goToTraining)="goToTraining($event)"
            >
            </shai-table>

            <div *ngIf="showFinishedPagination" class="pagination-btns">
              <shai-button
                [type]="'button'"
                (click)="clickPreviousPage('finished')"
                buttonStyle="tertiary"
                [disabled]="disabledPreviousPageFinished"
              >Previous</shai-button>

              <shai-button
              style="width: 10px;"
                [type]="'button'"
                buttonStyle="tertiary"
                [disabled]="true"
              >{{ currentPageFinished }}</shai-button>

              <shai-button
                [type]="'button'"
                (click)="clickNextPage('finished')"
                buttonStyle="tertiary"
                [disabled]="disabledNextPageFinished || finishedloading"
              >Next</shai-button>
            </div>
          </div>

          <div style="height: 50px;"></div>

        </mat-grid-tile>
      </mat-grid-list>
    </shai-overlay>
  `,
  styleUrls: ['./org-projects-overlay.component.scss']
})
export class OrgProjectsOverlayComponent implements OnInit, OnDestroy {

  ongoingTrainingsTitle: string;
  onGoingDatasetTrainings: any[] = [];
  onGoingTrainingColumns: TableColumn[] = [];
  onGoingPaginationId: string = 'onGoingPagination';
  onGoingPaginationItemsPage: number = 10;
  onGoingPaginationCurrentPage: number = 1;
  onGoingPaginationTotalItems: number = 0;
  onGoingNextPageToken: string = '';
  onGoingCurrent: OrgTrainingStatus[] = [];
  currentPageOnGoing: number = 1;
  lastPageOnGoing: number = 1;
  disabledPreviousPageOnGoing: boolean = true;
  disabledNextPageOnGoing: boolean = false;
  showOnGoingPagination: boolean = false;
  showOnGoingSection: boolean = false;

  finishedTrainingsTitle: string;
  finishedDatasetTrainings: any[] = [];
  finishedTrainingColumns: TableColumn[] = [];
  finishedPaginationId: string = 'finishedPagination';
  finishedPaginationItemsPage: number = 10;
  finishedPaginationCurrentPage: number = 1;
  finishedPaginationTotalItems: number = 0;
  finishedNextPageToken: string = '';
  finishedloading: boolean = false;
  currentPageFinished: number = 1;
  lastPageFinished: number = 1;
  disabledPreviousPageFinished: boolean = true;
  disabledNextPageFinished: boolean = false;
  showFinishedSection: boolean = false;
  showFinishedPagination: boolean = false;

  orgName: string;
  orgDescription: string;
  orgSuid: string;
  tipTitle: string;
  isActive: boolean = true;
  organization$: Observable<Organization> = new Observable();
  blackLoaderAnimation: AnimationOptions = {
    path: '/assets/lotties/loader.json',
    loop: true
  }

  loadingData: boolean = true;

  @Input() onGoingLast: OrgTrainingStatus[] = [];
  @Output() onClose = new EventEmitter();
  @Output() saveOnGoingLast = new EventEmitter<OrgTrainingStatus[]>();

  private subscription?: Subscription;
  private subscriptionData?: Subscription;

  constructor(
    public _hs: HelperService,
    private store: Store,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private _organizationService: OrganizationService
  ) {
    this.orgName = '';
    this.orgDescription = 'Projects & Training Sessions';
    this.orgSuid = '';
    this.ongoingTrainingsTitle = 'Ongoing Trainings';
    this.finishedTrainingsTitle = 'Finished Trainings';
    this.tipTitle = 'Select a row to access the training';
    this.organization$ = this.store.pipe(select(fromPlatform.getOrganization)) as Observable<Organization>;
  }

  ngOnInit(): void {

    this._hs.obsOpenMainMenu$.subscribe((openOverlay) => {
      this.isActive = openOverlay;
    });

    this.subscription = combineLatest([
      this.organization$
    ])
    .pipe(first())
    .subscribe(async ([org]) => {

      if (org && org.config?.name && org.organizationSuid) {

        this.orgSuid = org.organizationSuid;
        this.orgName = org.config?.name;

        this.finishedloading = true;

        this.loadingData = true;
        
        const ongoing$ = this._organizationService.getOrgTrainingStatus(this.orgSuid, TrainingFilter.ONGOING);
        const finished$ = this._organizationService.getOrgTrainingStatus(this.orgSuid, TrainingFilter.FINISHED);

        this.subscriptionData = combineLatest([ongoing$, finished$]).pipe(first()).subscribe(([ ongoingResult, finishedResult ]) => {
          if (ongoingResult && finishedResult) {

            this.loadingData = false;

            let onGoingResponse = ongoingResult;
            this.onGoingCurrent = onGoingResponse.items;

            if (onGoingResponse.nextPage) {
              this.onGoingNextPageToken = onGoingResponse.nextPage;
            }
            
            let finishedResponse = finishedResult;
            this.finishedloading = false;
            
            if (finishedResponse.nextPage) {
              this.finishedNextPageToken = finishedResponse.nextPage;
            }
    
            const finishedWithError = finishedResponse.items.filter((item) => item.finishStatus.id !== 3021);
    
            if (finishedWithError.length > 0) {
              finishedWithError.forEach((finishedItem) => {
                const match = this.onGoingLast.find((item) => item.trainingSuid === finishedItem.trainingSuid);
                if (match) {
                  onGoingResponse.items.push(finishedItem);
                  finishedResponse.items = finishedResponse.items.filter((items) => items.trainingSuid !== finishedItem.trainingSuid);
                }
              });
            }
            
            this.showOnGoingSection = onGoingResponse.items.length === 0 ? false : true;
            this.showOnGoingPagination = onGoingResponse.items.length >= 10 || onGoingResponse.nextPage ? true : false;
            this.showFinishedSection = finishedResponse.items.length === 0 ? false : true;
            this.showFinishedPagination = finishedResponse.items.length >= 10 || finishedResponse.nextPage ? true : false;

            this._loadTrainingsColumns(TrainingFilter.ONGOING);
            this.onGoingDatasetTrainings = this._generateDataSet(onGoingResponse, TrainingFilter.ONGOING);
    
            this.onGoingPaginationCurrentPage = this.currentPageOnGoing;
            this.onGoingPaginationTotalItems = onGoingResponse.items.length;
    
            this.lastPageOnGoing = this._getLastPage(onGoingResponse.items.length, this.onGoingPaginationItemsPage);
    
            this.checkNextBtn('ongoing');
            this.cdRef.detectChanges();
    
            this._loadTrainingsColumns(TrainingFilter.FINISHED);
            this.finishedDatasetTrainings = this._generateDataSet(finishedResponse, TrainingFilter.FINISHED);
    
            this.finishedPaginationCurrentPage = this.currentPageFinished;
            this.finishedPaginationTotalItems = finishedResponse.items.length;
    
            this.lastPageFinished = this._getLastPage(finishedResponse.items.length, this.finishedPaginationItemsPage);
    
            this.checkNextBtn('finished');
            this.cdRef.detectChanges();
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscriptionData?.unsubscribe();
    this.saveOnGoingLast.emit(this.onGoingCurrent);
  }

  onChangeOverlay(event: boolean): void {
    if (!event) {
      setTimeout(() => {
        this.onClose.emit(false);
      }, 700);
    }
  }

  onLeaveOverlay(): void {
    this.isActive = false;
  }

  clickPreviousPage(state: string): void {

    if (state === 'ongoing') {
      if (this.currentPageOnGoing > 1) {
        this.currentPageOnGoing--;
      }
      this.onGoingPaginationCurrentPage = this.currentPageOnGoing;
      this.checkNextBtn('ongoing');
    }

    if (state === 'finished') {
      if (this.currentPageFinished > 1) {
        this.currentPageFinished--;
      }
      this.finishedPaginationCurrentPage = this.currentPageFinished;
      this.checkNextBtn('finished');
    }

  }

  clickNextPage(state: string): void {

    if (state === 'ongoing') {

      if (this.currentPageOnGoing < this.lastPageOnGoing) {  
        this.currentPageOnGoing++;
        this.onGoingPaginationCurrentPage = this.currentPageOnGoing;
        this.checkNextBtn('ongoing');
      }

      else if (this.currentPageOnGoing === this.lastPageOnGoing && this.onGoingNextPageToken) {
        this._organizationService.getOrgTrainingStatus(this.orgSuid, TrainingFilter.ONGOING, this.onGoingNextPageToken).subscribe((resp) => {
    
          this.currentPageOnGoing++;
          this.onGoingPaginationCurrentPage = this.currentPageOnGoing;
          this.onGoingPaginationTotalItems = resp.items.length + this.onGoingDatasetTrainings.length;

          this.cdRef.detectChanges();
          this.lastPageOnGoing++;
    
          const result = this.onGoingDatasetTrainings.concat(this._generateDataSet(resp, TrainingFilter.ONGOING));

          for (let i = 0; i < result.length; i++) {
            result[i].row = i;
          }
    
          this._loadTrainingsColumns(TrainingFilter.ONGOING);
          this.onGoingDatasetTrainings = result;
          this.cdRef.detectChanges();
    
          if (resp.nextPage) {
            this.onGoingNextPageToken = resp.nextPage;
          }
          else {
            this.onGoingNextPageToken = '';
          }
          this.checkNextBtn('ongoing');
        });
      }
    }

    if (state === 'finished') {

      if (this.currentPageFinished < this.lastPageFinished) {  
        this.currentPageFinished++;
        this.finishedPaginationCurrentPage = this.currentPageFinished;
        this.checkNextBtn('finished');
      }

      else if (this.currentPageFinished === this.lastPageFinished && this.finishedNextPageToken) {
        this.finishedloading = true;
        this._organizationService.getOrgTrainingStatus(this.orgSuid, TrainingFilter.FINISHED, this.finishedNextPageToken).subscribe((resp) => {
    
          this.currentPageFinished++;
          this.finishedPaginationCurrentPage = this.currentPageFinished;
          this.finishedPaginationTotalItems = resp.items.length + this.finishedDatasetTrainings.length;

          this.finishedloading = false;
          this.cdRef.detectChanges();
          this.lastPageFinished++;
    
          const result = this.finishedDatasetTrainings.concat(this._generateDataSet(resp, TrainingFilter.FINISHED));

          for (let i = 0; i < result.length; i++) {
            result[i].row = i;
          }
    
          this._loadTrainingsColumns(TrainingFilter.FINISHED);
          this.finishedDatasetTrainings = result;
          this.cdRef.detectChanges();
    
          if (resp.nextPage) {
            this.finishedNextPageToken = resp.nextPage;
          }
          else {
            this.finishedNextPageToken = '';
          }
          this.checkNextBtn('finished');
        });
      }
    }
  }

  checkNextBtn(state: string): void {

    if (state === 'ongoing') {
      if (this.currentPageOnGoing === 1) {
        this.disabledPreviousPageOnGoing = true;
      }
      else {
        this.disabledPreviousPageOnGoing = false;
      } 
  
      if (this.currentPageOnGoing === this.lastPageOnGoing && !this.onGoingNextPageToken) {
        this.disabledNextPageOnGoing = true;
      }
      else {
        this.disabledNextPageOnGoing = false;
      }
    }

    if (state === 'finished') {
      if (this.currentPageFinished === 1) {
        this.disabledPreviousPageFinished = true;
      }
      else {
        this.disabledPreviousPageFinished = false;
      } 
  
      if (this.currentPageFinished === this.lastPageFinished && !this.finishedNextPageToken) {
        this.disabledNextPageFinished = true;
      }
      else {
        this.disabledNextPageFinished = false;
      }
    }
    
    this.cdRef.detectChanges();
  }

  goToTraining(event: any): void {
    const projectSuid = event.projectSuid;
    const trainingSuid = event.trainingSuid;
    const trainingSessionTimestamp = event.trainingSessionTimestamp;
    this.router.navigate(['/projects/' + projectSuid + '/trainings/' + trainingSuid + '/sessions/' + trainingSessionTimestamp]);
    this.isActive = false;
  }

  private _getLastPage(arrayLength: number, itemsPage: number): number {
    let result: number = 1;
    if (arrayLength > itemsPage) {
      let numTotalPag = Math.trunc(arrayLength / itemsPage);
      let numPagTemp = arrayLength % itemsPage;
      if (numPagTemp > 0) {
        numTotalPag++;
      }
      // if dataset has no data, consider lastPage = 1 to compare with current page for check disable next btn
      result = numTotalPag === 0 ? 1 : numTotalPag;
    }
    return result;
  }

  private _generateDataSet(resp: TrainingsMenu, trainingFilter: string) {

    let buffer: any[] = [];

    if (trainingFilter === TrainingFilter.ONGOING) {
      for (let i = 0; i < resp.items.length; i++) {

        let statusTitle = 'Rounds';
        let round = 0;
        let msgRounds = '';
        if (resp.items[i].finishStatus) {
          statusTitle = 'Error';
          msgRounds = resp.items[i].finishStatus.message;
        }
        else {
          if (resp.items[i].round !== undefined) {
            round = resp.items[i].round + 1;
          }
          msgRounds = round + ' of ' + resp.items[i].totalRounds;
        }

        let metric1: MetricMenu = { name: '', value: 0 };
        let metric2: MetricMenu = { name: '', value: 0 };

        if (resp.items[i].mainMetrics) {
          let valueTemp = parseFloat(resp.items[i].mainMetrics.metric1.value.toFixed(1));
          metric1.name = this._checkMetricName(resp.items[i].mainMetrics.metric1.name);
          metric1.value = valueTemp;
          valueTemp = parseFloat(resp.items[i].mainMetrics.metric2.value.toFixed(1));
          metric2.name = this._checkMetricName(resp.items[i].mainMetrics.metric2.name);
          metric2.value = valueTemp;
        }

        const model = resp.items[i].model.toLocaleUpperCase();

        const paradigm = resp.items[i].paradigm.toLocaleUpperCase();

        let animation = '/assets/lotties/T05_1_transfer_aggregating_loop.json';

        buffer.push({
          row: i,
          formGroup: MAIN_MENU_OVERLAY,
          checked: false,
          projectName: resp.items[i].projectName,
          model: model,
          paradigm: paradigm, 
          trainingName: resp.items[i].trainingName,
          metric1: metric1,
          metric2: metric2,
          status: statusTitle,
          rounds: msgRounds,
          animation: animation,
          error: resp.items[i].finishStatus ? true : false,
          trainingSuid: resp.items[i].trainingSuid,
          projectSuid: resp.items[i].projectSuid,
          trainingSessionTimestamp: resp.items[i].trainingSessionTimestamp,
          emitToParent: true
        });
      }
    }

    if (trainingFilter === TrainingFilter.FINISHED) {
      for (let i = 0; i < resp.items.length; i++) {

        const createDate = new Date(resp.items[i].trainingSessionTimestamp);
        const date = createDate.getDate() + '/' + (createDate.getMonth()+1) + '/' + createDate.getFullYear();

        const hours = createDate.getHours();
        const hoursFix = hours <= 9 ? '0' + hours : hours;

        const minutes = createDate.getMinutes();
        const minutesFix = minutes <= 9 ? '0' + minutes : minutes;

        const time = hoursFix + ':' + minutesFix;

        let metric1: MetricMenu = { name: '', value: 0 };
        let metric2: MetricMenu = { name: '', value: 0 };

        if (resp.items[i].mainMetrics) {
          let valueTemp = parseFloat(resp.items[i].mainMetrics.metric1.value.toFixed(1));
          metric1.name = this._checkMetricName(resp.items[i].mainMetrics.metric1.name);
          metric1.value = valueTemp;
          valueTemp = parseFloat(resp.items[i].mainMetrics.metric2.value.toFixed(1));
          metric2.name = this._checkMetricName(resp.items[i].mainMetrics.metric2.name);
          metric2.value = valueTemp;
        }

        const model = resp.items[i].model.toLocaleUpperCase();

        const paradigm = resp.items[i].paradigm.toLocaleUpperCase();

        buffer.push({
          row: i,
          formGroup: MAIN_MENU_OVERLAY,
          checked: false,
          projectName: resp.items[i].projectName,
          model: model,
          paradigm: paradigm, 
          trainingName: resp.items[i].trainingName,
          metric1: metric1,
          metric2: metric2,
          createdDate: date,
          createdTime: time,
          trainingSuid: resp.items[i].trainingSuid,
          projectSuid: resp.items[i].projectSuid,
          trainingSessionTimestamp: resp.items[i].trainingSessionTimestamp,
          emitToParent: true
        });
      }
    }
    return buffer;
  }

  private _checkMetricName(metricName: string): string {
    let response: string = metricName;

    switch (metricName) {
      case 'rmse': response = 'rmse';
        break;
      case 'r2Score': response = 'r2';
        break;
      case 'macroAccuracy': response = 'acc';
        break;
      case 'macroF1Score': response = 'f1';
        break;
      case 'vMeasure': response = 'vMeasure';
        break;
      case 'inertia': response = 'inertia';
        break;
    }
    return response;
  }

  private _loadTrainingsColumns(trainingFilter: string): void {

    if (trainingFilter === TrainingFilter.ONGOING) {
      this.onGoingTrainingColumns = [];
      this.onGoingTrainingColumns = [
        {
          columnDef: 'colorStatus',
          header: '',
          width: '50px',
          sortingDisabled: true,
          component: TableCellLottieComponent,
          cellData: (element: any) => {
            return { text: '', animation: element.animation };
          },
        },
        {
          columnDef: 'trainingName',
          header: 'Name',
          width: '300px',
          sortingDisabled: false,
          component: TableCellDoubleComponent,
          cellData: (element: any) => {
            return { text: element.trainingName, subText: element.projectName, error: element.error };
          },
        },
        {
          columnDef: 'model',
          header: 'Model',
          width: '112px',
          sortingDisabled: false,
          component: TableCellSingleComponent,
          cellData: (element: any) => {
            return { text: element.model };
          },
        },
        {
          columnDef: 'paradigm',
          header: 'Paradigm',
          width: '112px',
          sortingDisabled: false,
          component: TableCellSingleComponent,
          cellData: (element: any) => {
            return { text: element.paradigm };
          },
        },
        {
          columnDef: 'metric1',
          header: 'Metric 1',
          width: '100px',
          sortingDisabled: true,
          component: TableCellDoubleComponent,
          cellData: (element: any) => {
            return { text: element.metric1.value, subText: element.metric1.name };
          },
        },
        {
          columnDef: 'metric2',
          header: 'Metric 2',
          width: '100px',
          sortingDisabled: true,
          component: TableCellDoubleComponent,
          cellData: (element: any) => {
            return { text: element.metric2.value, subText: element.metric2.name };
          },
        },
        {
          columnDef: 'status',
          header: 'Status',
          width: 'auto',
          sortingDisabled: true,
          component: TableCellDoubleComponent,
          cellData: (element: any) => {
            return { text: element.status, subText: element.rounds, error: element.error };
          },
        }
      ];
    }
  
    if (trainingFilter === TrainingFilter.FINISHED) {

      this.finishedTrainingColumns = [];
      this.finishedTrainingColumns = [
        {
          columnDef: 'trainingName',
          header: 'Name',
          width: '357px',
          sortingDisabled: false,
          component: TableCellDoubleComponent,
          cellData: (element: any) => {
            return { text: element.trainingName, subText: element.projectName };
          },
        },
        {
          columnDef: 'model',
          header: 'Model',
          width: '112px',
          sortingDisabled: false,
          component: TableCellSingleComponent,
          cellData: (element: any) => {
            return { text: element.model };
          },
        },
        {
          columnDef: 'paradigm',
          header: 'Paradigm',
          width: '112px',
          sortingDisabled: false,
          component: TableCellSingleComponent,
          cellData: (element: any) => {
            return { text: element.paradigm };
          },
        },
        {
          columnDef: 'metric1',
          header: 'Metric 1',
          width: '100px',
          sortingDisabled: true,
          component: TableCellDoubleComponent,
          cellData: (element: any) => {
            return { text: element.metric1.value, subText: element.metric1.name };
          },
        },
        {
          columnDef: 'metric2',
          header: 'Metric 2',
          width: '100px',
          sortingDisabled: true,
          component: TableCellDoubleComponent,
          cellData: (element: any) => {
            return { text: element.metric2.value, subText: element.metric2.name };
          },
        },
        {
          columnDef: 'status',
          header: 'Status',
          width: 'auto',
          sortingDisabled: true,
          component: TableCellDoubleComponent,
          cellData: (element: any) => {
            return { text: element.createdDate, subText: element.createdTime };
          },
        }
      ];
    }
  }
}
