import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-minus',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line y1="7.5" x2="16" y2="7.5" stroke=""/>
    </svg>
  `,
})
export class IconMinus {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}