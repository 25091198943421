import { createAction, props, union } from "@ngrx/store";
import { NodeOriginData, RocMetrics } from "src/app/training/components/charts/models/chart.model";
import { GenericParameter, TrainingParadigm, StopCondition, TrainingModel, TrainingSession, Infrastructure, PrivateAttrs, TrainingSessionList, TrainingSessionStatus, TrainingSessionRounds, MetricsConfig } from "../models/";
import { Node } from '../models/node';

export const getTrainingSession = createAction('[Training Session] Get training session', props<{ timestamp: string}>());
export const getTrainingSessionSuccess = createAction('[Training Session] Get training sessions success', props<{ session: TrainingSession }>());
export const getTrainingSessionFailure = createAction('[Training Session] Get training session failure', props<{ error: any }>());
export const clearTrainingSession = createAction('[Training Session] Clear training session');

export const getTrainingSessionStatus = createAction('[Training Session] Load training session status');
export const getTrainingSessionStatusSuccess = createAction('[Training Session] Load training session status success', props<{ status: TrainingSessionStatus}>());
export const getTrainingSessionStatusFailure = createAction('[Training Session] Load training session status failure', props<{ error: any }>() );
export const getTrainingSessionStatusRetry = createAction('[Training Session] Load training session status retry', props<{attemp: number}>() );

export const getTrainingSessionRounds = createAction('[Training Session] Load training session rounds');
export const getTrainingSessionRoundsSuccess = createAction('[Training Session] Load training session rounds success', props<{ rounds: TrainingSessionRounds}>());
export const getTrainingSessionRoundsFailure = createAction('[Training Session] Load training session rounds failure', props<{ error: any }>());

export const createTrainingSession = createAction('[Training Session] Create training session',props<{ trainingId: string; timestamp: string }>());
export const createTrainingSessionSuccess = createAction('[Training Session] Create training session success',props<{ session: string }>());

export const getTrainingModel = createAction('[Training Session] Get training model');
export const getTrainingModelSuccess = createAction('[Training Session] Get training model success', props<{ model: TrainingModel }>());
export const getTrainingModelFailure = createAction('[Training Session] Get training model failure', props<{ error: any }>());

export const getTrainingParadigm = createAction('[Training Session] Get training paradigm');
export const getTrainingParadigmSuccess = createAction('[Training Session] Get training paradigm success', props<{ paradigm: TrainingParadigm }>());
export const getTrainingParadigmFailure = createAction('[Training Session] Get training paradigm failure', props<{ error: any }>());

export const updateTrainingMechanics = createAction('[Training Session] Update training mechanics', props<{ idModel: string, hyperparameters: GenericParameter[], idParadigm: string, parameters: GenericParameter[]}>());
export const updateTrainingMechanicsSuccess = createAction('[Training Session] Update training mechanics success', props<{ paradigm: TrainingParadigm, model: TrainingModel }>());
export const updateTrainingMechanicsFailure = createAction('[Training Session] Update training mechanics failure', props<{ error: any }>());

export const linkNode = createAction('[Training Session] Link node', props<{ nodeSuid: string }>());
export const linkNodeSuccess = createAction('[Training Session] Link node success', props<{ nodeSuid: string }>());
export const linkNodeFailure = createAction('[Training Session] Link node failure', props<{ nodeSuid: string, error: any }>());

export const unlinkNode = createAction('[Training Session] Unlink node', props<{ nodeSuid: string }>());
export const unlinkNodeSuccess = createAction('[Training Session] Unlink node success', props<{ nodeSuid: string }>());
export const unlinkNodeFailure = createAction('[Training Session] Unlink node failure', props<{ nodeSuid: string, error: any }>());

export const getLinkedNodes = createAction('[Training Session] Get linked nodes');
export const getLinkedNodesSuccess = createAction('[Training Session] Get linked nodes success', props<{ nodes: Node[] | null}>());
export const getLinkedNodesFailure = createAction('[Training Session] Get linked nodes failure', props<{ error: any }>());

export const getHistoryNodes = createAction('[Training Session] Get history nodes');
export const getHistoryNodesSuccess = createAction('[Training Session] Get history nodes success', props<{ nodes: Node[] | null}>());
export const getHistoryNodesFailure = createAction('[Training Session] Get history nodes failure', props<{ error: any }>());

export const stopCondition = createAction('[Training Session] Stop condition', props<{ stopCondition: StopCondition, metricsConfig?: MetricsConfig}>());
export const stopConditionSuccess = createAction('[Training Session] Stop condition success');
export const stopConditionFailure = createAction('[Training Session] Stop condition failure', props<{ error: any }>());

export const getStopConditions = createAction('[Training Session] Get stop conditions');
export const getStopConditionsSuccess = createAction('[Training Session] Get stop conditions success', props<{ stopConditions: StopCondition[]}>());
export const getStopConditionsFailure = createAction('[Training Session] Get stop conditions failure', props<{ error: any }>());

export const getMetricsConfig = createAction('[Training Session] Get metrics config');
export const getMetricsConfigSuccess = createAction('[Training Session] Get metrics config success', props<{ metricsConfig: MetricsConfig}>());
export const getMetricsConfigFailure = createAction('[Training Session] Get metrics config failure', props<{ error: any }>());

export const cancelTrainingSession = createAction('[Training Session] Cancel training session');
export const cancelTrainingSessionSuccess = createAction('[Training Session] Cancel training session success');
export const cancelTrainingSessionFailure = createAction('[Training Session] Cancel training session failure', props<{ error: any }>());

export const deployInfra = createAction('[Training Session] Deploy infra');
export const deployInfraSuccess = createAction('[Training Session] Deploy infra success');
export const deployInfraFailure = createAction('[Training Session] Deploy infra failure', props<{ error?: any }>());

export const cancelDeployInfra = createAction('[Training Session] Cancel Deploy infra');
export const cancelDeployInfraSuccess = createAction('[Training Session] Cancel Deploy infra success');
export const cancelDeployInfraFailure = createAction('[Training Session] Cancel Deploy infra failure', props<{ error?: any }>());

export const loadInfra= createAction('[Training Session] Load infra');
export const loadInfraRetry= createAction('[Training Session] Load infra retry', props<{ infrastructure?: Infrastructure}>());
export const loadInfraSuccess= createAction('[Training Session] Load infra success', props<{ infrastructure: Infrastructure}>());
export const loadInfraFailure= createAction('[Training Session] Load infra failure', props<{ infrastructure: Infrastructure}>());
export const clearInfra = createAction('[Training Session] Clear infra');

export const getPrivateAttrs = createAction('[Training Session] Get private attrs', props<{ round: number }>());
export const getPrivateAttrsSuccess = createAction('[Training Session] Get private attrs success', props<{ privateAttrs: PrivateAttrs | null }>());
export const getPrivateAttrsFailure = createAction('[Training Session] Get private attrs failure', props<{ error: any }>());

export const createPrivateAttrs = createAction('[Training Session] Create private attrs', props<{ round: number, datasetLength: number}>());
export const createPrivateAttrsSuccess = createAction('[Training Session] Create private attrs success', props<{ privateAttrs: PrivateAttrs | null }>());
export const createPrivateAttrsFailure = createAction('[Training Session] Create private attrs failure', props<{ error: any }>());

export const finishTrainingSession = createAction('[Training Session] Finish training session');

export const startTraining = createAction('[Training Session] Start training');
export const startTrainingSuccess = createAction('[Training Session] Start training success');
export const startTrainingFailure = createAction('[Training Session] Start training failure', props<{error: any}>());

export const stopTraining = createAction('[Training Session] Stop training');
export const stopTrainingSuccess = createAction('[Training Session] Stop training success');
export const stopTrainingFailure = createAction('[Training Session] Stop training failure', props<{error: any}>());

export const stopTrainingByParams = createAction('[Training Session] Stop training by params', props<{ projectId: string, trainingId: string, trainingSessionId: string }>());
export const stopTrainingByParamsSuccess = createAction('[Training Session] Stop training by params success');
export const stopTrainingByParamsFailure = createAction('[Training Session] Stop training by params failure', props<{error: any}>());

export const finishTraining = createAction('[Training Session] Finish training');

export const getNodeMetric = createAction('[Training Session] Get node metric', props<{ nodeSuid: string }>());
export const getNodeMetricSuccess = createAction('[Training Session] Get node metric success', props<{ aggregatorMetrics: any }>());
export const getNodeMetricFailure = createAction('[Training Session] Get node metric failure', props<{ error: any }>());

export const getNodeMetrics = createAction('[Training Session] Get node metrics', props<{ nodes: any[] }>());
export const getNodeMetricsSuccess = createAction('[Training Session] Get node metrics success', props<{ metrics: any }>());
export const getNodeMetricsFailure = createAction('[Training Session] Get node metrics failure', props<{ error: any }>());

export const getRocMetrics = createAction('[Training Session] Get roc metrics', props<{ node: string, round: number }>());
export const getRocMetricsSuccess = createAction('[Training Session] Get roc metrics success', props<{ rocMetrics: RocMetrics }>());
export const getRocMetricsFailure = createAction('[Training Session] Get roc metrics failure', props<{ error: any }>());

export const downloadModelParameters = createAction('[Training Session] Download model parameters from a node', props<{node: string, round: number}>());
export const downloadModelParametersSuccess = createAction('[Training Session] Download model parameters from a node success', props<{ url: any}>());
export const downloadModelParametersFailure = createAction('[Training Session] Download model parameters from a node failure', props<{ error: any }>());
export const clearDownloadModelParameters = createAction('[Training Session] Clear download model parameters from a node');

const actionsUnion = union({
  createTrainingSession,
  createTrainingSessionSuccess,
  getTrainingModel,
  getTrainingModelSuccess,
  getTrainingModelFailure,
  getTrainingParadigm,
  getTrainingParadigmSuccess,
  getTrainingParadigmFailure,
  updateTrainingMechanics,
  updateTrainingMechanicsSuccess,
  updateTrainingMechanicsFailure,
  linkNode,
  linkNodeSuccess,
  linkNodeFailure,
  unlinkNode,
  unlinkNodeSuccess,
  unlinkNodeFailure,
  stopCondition,
  stopConditionSuccess,
  stopConditionFailure,
  cancelTrainingSession,
  cancelTrainingSessionSuccess,
  cancelTrainingSessionFailure,
  getTrainingSession,
  getTrainingSessionSuccess,
  getTrainingSessionFailure,
  getLinkedNodes,
  getLinkedNodesSuccess,
  getLinkedNodesFailure,
  deployInfra,
  deployInfraSuccess,
  deployInfraFailure,
  loadInfra,
  loadInfraRetry,
  loadInfraSuccess,
  loadInfraFailure,
  getStopConditions,
  getStopConditionsSuccess,
  getStopConditionsFailure,
  finishTrainingSession,
  getPrivateAttrs,
  getPrivateAttrsSuccess,
  getPrivateAttrsFailure,
  createPrivateAttrs,
  createPrivateAttrsSuccess,
  createPrivateAttrsFailure,
  startTraining,
  startTrainingSuccess,
  startTrainingFailure,
  stopTraining,
  stopTrainingSuccess,
  stopTrainingFailure,
  clearTrainingSession,
  getTrainingSessionRounds,
  getTrainingSessionRoundsSuccess,
  getTrainingSessionRoundsFailure,
  getNodeMetric,
  getNodeMetricSuccess,
  getNodeMetricFailure,
  getNodeMetrics,
  getNodeMetricsSuccess,
  getNodeMetricsFailure,
  getRocMetrics,
  getRocMetricsSuccess,
  getRocMetricsFailure,
  downloadModelParameters,
  downloadModelParametersSuccess,
  downloadModelParametersFailure,
  clearDownloadModelParameters,
  getMetricsConfig,
  getMetricsConfigSuccess,
  getMetricsConfigFailure
})

export type TrainingSessionActionsUnion = typeof actionsUnion
