import { createReducer, on } from "@ngrx/store";
import { ConfigActions } from "../actions";
import { GlobalConfig } from "../models";

export const configFeatureKey = 'globalConfig'

export interface State {
  config: GlobalConfig;
  pending: boolean;
  error?: any;
}

const initialState: State = {
  config: {
    aggregators: [],
    algorithms: [],
    periodicity: [],
    stopConditionTypes: [],
    stopConditionOperators: []
  },
  pending: false
}

export const reducer = createReducer(
  initialState,
  on(ConfigActions.loadConfig, state => ({...state, pending: true})),
  on(ConfigActions.loadConfigSuccess, (state, {config}) => ({...state, config, pending: false})),
  on(ConfigActions.loadConfigFailure, (state, {error}) => ({...state, pending: false, error}))
);

export const getConfig = (state: State) => state?.config;
export const isConfigPending = (state: State) : boolean | undefined => state?.pending;
export const isConfigError = (state: State) : boolean | undefined => state?.error;
