import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { catchError, exhaustMap, filter, map, switchMap, withLatestFrom } from "rxjs/operators";
import { OrganizationActions } from "../actions";
import * as fromAuth from '../../auth/state/selectors/auth.selectors';
import { OrganizationService } from "../services/organization.service";
import { Organization } from "../models/organization";
import * as fromPlatform from 'src/app/platform/reducers';
import { of } from "rxjs";

@Injectable()
export class OrganizationEffects {

  constructor(
    private actions$: Actions,
    private store: Store,
    private _organizationService: OrganizationService
  ) {}

  loadOrganization$ = createEffect(() => this.actions$.pipe(
    ofType(OrganizationActions.loadOrganization),
    withLatestFrom(this.store.pipe(select(fromAuth.getOrg))),
    switchMap(([,org]) =>  this._organizationService.loadOrganization(org).pipe(
      map((organization: Organization) => OrganizationActions.loadOrganizationSuccess({organization})),
      catchError((error) => [OrganizationActions.loadOrganizationFailure({error})])
    ))
  ))

  loadCapabilities$ = createEffect(() => this.actions$.pipe(
    ofType(OrganizationActions.loadCapabilities),
    withLatestFrom(this.store.pipe(select(fromPlatform.getOrganization))),
    switchMap(([,org]) =>  this._organizationService.loadCapabilities(org).pipe(
      map((capabilities: any) => {
        return OrganizationActions.loadCapabilitiesSuccess({capabilities})
      }),
      catchError((error) => [OrganizationActions.loadCapabilitiesFailure({error})])
    ))
  ))

  loadPlan$ = createEffect(() => this.actions$.pipe(
    ofType(OrganizationActions.loadPlan),
    withLatestFrom(this.store.pipe(select(fromPlatform.getOrganization))),
    filter(([, org]) => !!org),
    switchMap(([, org]) => this._organizationService.loadPlanWithCapabilities(org).pipe(
      map((plan: any) => OrganizationActions.loadPlanSuccess({ plan })),
      catchError((error) => of(OrganizationActions.loadPlanFailure({ error })))
    ))
  ));

  updateSecret$ = createEffect(() => this.actions$.pipe(
    ofType(OrganizationActions.updateSecret),
    withLatestFrom(this.store.pipe(select(fromAuth.getOrg))),
    switchMap(([{ secret }, org]) =>
      this._organizationService.updateSecret(secret, org).pipe(
        switchMap((response) => {
          return [
            OrganizationActions.updateSecretSuccess(),
          ];
        }),
        catchError((error) => [
          OrganizationActions.updateSecretFailure({ error }),
        ])
      )
    )
  ));

  changePasswordOrg$ = createEffect(() => this.actions$.pipe(
    ofType(OrganizationActions.changePasswordOrg),
    withLatestFrom(this.store.pipe(select(fromAuth.getOrg))),
    switchMap(([{ changePassword }, org]) =>
      this._organizationService.changePasswordOrg(changePassword, org).pipe(
        switchMap((resp) => {
          return [
            OrganizationActions.changePasswordOrgSuccess(),
          ];
        }),
        catchError((error) => [
          OrganizationActions.changePasswordOrgFailure({ error }),
        ])
      )
    )
  ));
}
