
import { createReducer, on } from "@ngrx/store";
import * as AuthActions from '../actions/auth.actions';
import { AuthStatus, User, AuthResponse } from "../../models/auth.models";

export const statusFeatureKey = 'status';

export interface State {
  loginStatus?: AuthStatus;
  user?: User | null;
  credentials?: AuthResponse;
  loginResponse?: AuthResponse;
  resetMFAResponse?: AuthResponse;
  verifyMFAResponse?: AuthResponse;
  resetPassCredentials?: AuthResponse;
  resetPasswordCompleted?: boolean;
  authResponse?: AuthResponse;
  requestAccessCompleted?: boolean;
  requestAccessErrorMsg?: string;
}

const initialState : State = {
  loginStatus: {
    pending: false
  }
};

export const reducer = createReducer(
  initialState,

  on(AuthActions.login, AuthActions.autologin, state => ({...state, loginStatus: { pending: true }})),
  on(AuthActions.loginSuccess, (state, { loginResponse, credentials }) => ({...state, loginStatus: { pending: false }, loginResponse, credentials })),
  on(AuthActions.autologinSuccess, (state, { user }) => ({...state, loginStatus: { pending: false }, user })),
  on(AuthActions.loginError, AuthActions.autologinError, (state, { error }) => ({...state, loginStatus: { pending: false, error } })),

  on(AuthActions.changePassword, state => ({...state, loginStatus: { pending: true } })),
  on(AuthActions.changePasswordSuccess, (state, { loginResponse }) => ({...state, loginStatus: { pending: false }, loginResponse })),
  on(AuthActions.changePasswordError, state => ({...state, loginStatus: { pending: false } })),

  on(AuthActions.verifyMFA, state => ({...state, loginStatus: { pending: true } })),
  on(AuthActions.verifyMFASuccess, (state, { verifyMFAResponse }) => ({...state, loginStatus: { pending: false }, verifyMFAResponse, resetMFAResponse: undefined })),
  on(AuthActions.verifyMFAError, (state, { error }) => ({...state, loginStatus: { pending: false, error } })),

  on(AuthActions.signInMFA, state => ({...state, loginStatus: { pending: true, error: null } })),
  on(AuthActions.signInMFASuccess, (state, { authResponse, user }) => ({...state, loginStatus: { pending: false }, authResponse, user })),
  on(AuthActions.signInMFAError, (state, { error }) => ({...state, loginStatus: { pending: false, error } })),

  on(AuthActions.resetMFA, state => ({...state, loginStatus: { pending: true, error: null } })),
  on(AuthActions.resetMFASuccess, (state, { resetMFAResponse }) => ({...state, loginStatus: { pending: false }, resetMFAResponse })),
  on(AuthActions.resetMFAError, (state, { error }) => ({...state, loginStatus: { pending: false, error } })),

  on(AuthActions.resetPassword, state => ({...state, loginStatus: { pending: true }})),
  on(AuthActions.resetPasswordSuccess, (state, { resetPassCredentials, resetPasswordCompleted }) => ({...state, loginStatus: { pending: false }, resetPassCredentials, resetPasswordCompleted })),
  on(AuthActions.resetPasswordError, (state, { error }) => ({...state, loginStatus: { pending: false, error } })),

  on(AuthActions.requestAccess, state => ({...state, requestAccessErrorMsg: undefined, requestAccessCompleted: false})),
  on(AuthActions.requestAccessSuccess, (state) => ({...state, requestAccessCompleted: true })),
  on(AuthActions.requestAccessError, (state, { message }) => ({...state, requestAccessErrorMsg: message })),
  on(AuthActions.clearRequestAccessData, (state) => ({...state, requestAccessCompleted: undefined, requestAccessErrorMsg: undefined })),

  on(AuthActions.clearReset, () => initialState),

  on(AuthActions.logout, AuthActions.autologout, () => initialState)

)

export const isLoginPending = (state: State) : boolean | undefined => state?.loginStatus?.pending;
export const isLoginError = (state: State) : boolean | undefined => state?.loginStatus?.error;

export const getUser = (state: State) => state?.user;
export const getAuthResponse = (state: State) => state?.authResponse;
export const getOrg = (state: State) => state?.user ? state?.user['custom:org_suid'] : undefined;
export const getAuthStatus = (state: State) => state?.loginStatus;

export const getCredentials = (state: State) => state?.credentials;
export const getResetPassCredentials = (state: State) => state?.resetPassCredentials;
export const getResetPasswordCompleted = (state: State) => state?.resetPasswordCompleted;
export const getLoginResponse = (state: State) => state?.loginResponse;
export const getResetMFAResponse = (state: State) => state?.resetMFAResponse;
export const getVerifyMFAResponse = (state: State) => state?.verifyMFAResponse;
export const getRequestAccessCompleted = (state: State) => state?.requestAccessCompleted;
export const getRequestAccessError = (state: State) => state?.requestAccessErrorMsg;
