
export enum CellElementType {
  label,
  dropDown,
  counter,
  range,
  checkbox,
  button,
  slider
}

export interface ExtraCellElement {
  elementType: CellElementType;
  data: any;
}
