import { Component, HostBinding, Input } from '@angular/core';
@Component({
  selector: 'shai-icon-federated-horizontal-24',
  template: `
    <svg
      *ngIf="!disabled"
      width="26"
      height="26"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.6743 37.6742C32.6949 44.6535 21.708 45.1828 14.1209 39.262C16.3413 40.3741 18.8476 41 21.5001 41C30.6128 41 38.0001 33.6127 38.0001 24.5C38.0001 15.3873 30.6128 8.00001 21.5001 8.00001C17.5357 8.00001 13.8979 9.39812 11.0528 11.7282C11.4502 11.2453 11.8745 10.7772 12.3259 10.3258C19.8779 2.77379 32.1222 2.77379 39.6743 10.3258C47.2263 17.8779 47.2263 30.1221 39.6743 37.6742ZM21.5001 39.1667C17.0241 39.1667 13.0164 37.1617 10.3262 34.0007C14.9976 37.1254 21.3749 36.6251 25.5001 32.5C30.1945 27.8056 30.1945 20.1944 25.5001 15.5C22.0353 12.0352 16.9816 11.1277 12.6844 12.7774C15.1383 10.929 18.1913 9.83335 21.5001 9.83335C29.6003 9.83335 36.1667 16.3998 36.1667 24.5C36.1667 32.6002 29.6003 39.1667 21.5001 39.1667ZM7.77075 28.8036C7.47727 28.2401 7.23888 27.6554 7.0556 27.0574C6.90957 26.2269 6.83341 25.3723 6.83341 24.5C6.83341 22.6044 7.19303 20.7928 7.84778 19.1296C8.96547 18.1758 10.4155 17.6 12 17.6C15.5346 17.6 18.4 20.4654 18.4 24C18.4 27.5346 15.5346 30.4 12 30.4C10.3788 30.4 8.89846 29.7972 7.77075 28.8036ZM10.0828 31.7688C14.1673 35.4123 20.436 35.2744 24.3552 31.3552C28.4174 27.293 28.4174 20.707 24.3552 16.6449C20.436 12.7256 14.1673 12.5877 10.0828 16.2312C10.697 16.0801 11.3391 16 12 16C16.4183 16 20 19.5817 20 24C20 28.4183 16.4183 32 12 32C11.3391 32 10.697 31.9199 10.0828 31.7688ZM5.21149 28.2348C6.01126 32.1795 7.94079 35.9407 11.0001 39C19.2843 47.2843 32.7158 47.2843 41.0001 39C49.2844 30.7157 49.2844 17.2843 41.0001 9.00001C32.7158 0.715735 19.2843 0.715736 11.0001 9.00001C7.94079 12.0593 6.01126 15.8205 5.21149 19.7652C4.44373 20.9934 4 22.4449 4 24C4 25.5552 4.44373 27.0066 5.21149 28.2348Z"
        fill="url(#paint0_linear_3511_2417)"
      />
      <rect
        x="-2"
        y="24"
        width="12"
        height="12"
        rx="6"
        transform="rotate(-45 -2 24)"
        fill="#0087E0"
      />
      <rect
        x="44"
        y="26"
        width="4"
        height="4"
        transform="rotate(-90 44 26)"
        fill="#0C0E11"
      />
      <rect
        x="17"
        y="26"
        width="4"
        height="4"
        transform="rotate(-90 17 26)"
        fill="#0C0E11"
      />
      <rect
        x="26"
        y="26"
        width="4"
        height="4"
        transform="rotate(-90 26 26)"
        fill="#0C0E11"
      />
      <rect
        x="35"
        y="26"
        width="4"
        height="4"
        transform="rotate(-90 35 26)"
        fill="#0C0E11"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3511_2417"
          x1="45.9776"
          y1="23"
          x2="4.72666"
          y2="22.4813"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DCDDE0" />
          <stop offset="1" stop-color="#EBEBEB" />
        </linearGradient>
      </defs>
    </svg>

    <svg
      *ngIf="disabled"
      width="26"
      height="26"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.6743 37.6742C32.6949 44.6535 21.708 45.1828 14.1209 39.262C16.3413 40.3741 18.8476 41 21.5001 41C30.6128 41 38.0001 33.6127 38.0001 24.5C38.0001 15.3873 30.6128 8.00001 21.5001 8.00001C17.5357 8.00001 13.8979 9.39812 11.0528 11.7282C11.4502 11.2453 11.8745 10.7772 12.3259 10.3258C19.8779 2.77379 32.1222 2.77379 39.6743 10.3258C47.2263 17.8779 47.2263 30.1221 39.6743 37.6742ZM21.5001 39.1667C17.0241 39.1667 13.0164 37.1617 10.3262 34.0007C14.9976 37.1254 21.3749 36.6251 25.5001 32.5C30.1945 27.8056 30.1945 20.1944 25.5001 15.5C22.0353 12.0352 16.9816 11.1277 12.6844 12.7774C15.1383 10.929 18.1913 9.83335 21.5001 9.83335C29.6003 9.83335 36.1667 16.3998 36.1667 24.5C36.1667 32.6002 29.6003 39.1667 21.5001 39.1667ZM7.77075 28.8036C7.47727 28.2401 7.23888 27.6554 7.0556 27.0574C6.90957 26.2269 6.83341 25.3723 6.83341 24.5C6.83341 22.6044 7.19303 20.7928 7.84778 19.1296C8.96547 18.1758 10.4155 17.6 12 17.6C15.5346 17.6 18.4 20.4654 18.4 24C18.4 27.5346 15.5346 30.4 12 30.4C10.3788 30.4 8.89846 29.7972 7.77075 28.8036ZM10.0828 31.7688C14.1673 35.4123 20.436 35.2744 24.3552 31.3552C28.4174 27.293 28.4174 20.707 24.3552 16.6449C20.436 12.7256 14.1673 12.5877 10.0828 16.2312C10.697 16.0801 11.3391 16 12 16C16.4183 16 20 19.5817 20 24C20 28.4183 16.4183 32 12 32C11.3391 32 10.697 31.9199 10.0828 31.7688ZM5.21149 28.2348C6.01126 32.1795 7.94079 35.9407 11.0001 39C19.2843 47.2843 32.7158 47.2843 41.0001 39C49.2844 30.7157 49.2844 17.2843 41.0001 9.00001C32.7158 0.715735 19.2843 0.715736 11.0001 9.00001C7.94079 12.0593 6.01126 15.8205 5.21149 19.7652C4.44373 20.9934 4 22.4449 4 24C4 25.5552 4.44373 27.0066 5.21149 28.2348Z"
        fill="url(#paint0_linear_3744_2695)"
      />
      <rect
        x="-2"
        y="24"
        width="12"
        height="12"
        rx="6"
        transform="rotate(-45 -2 24)"
        fill="#DCDDE0"
      />
      <rect
        x="44"
        y="26"
        width="4"
        height="4"
        transform="rotate(-90 44 26)"
        fill="#BCBFC4"
      />
      <rect
        x="17"
        y="26"
        width="4"
        height="4"
        transform="rotate(-90 17 26)"
        fill="#BCBFC4"
      />
      <rect
        x="26"
        y="26"
        width="4"
        height="4"
        transform="rotate(-90 26 26)"
        fill="#BCBFC4"
      />
      <rect
        x="35"
        y="26"
        width="4"
        height="4"
        transform="rotate(-90 35 26)"
        fill="#BCBFC4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3744_2695"
          x1="45.9776"
          y1="23"
          x2="4.72666"
          y2="22.4813"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EBEBEB" />
          <stop offset="1" stop-color="#FAFAFA" />
        </linearGradient>
      </defs>
    </svg>
  `,
})
export class IconFederatedHorizontal24 {
  @HostBinding('class.shai-icon') get iconClass() {
    return true;
  }
  @Input() disabled: boolean = false;
}
