import {
  Directive,
  DoCheck,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { AccessControlService } from '../services/access-control.service';
import { combineLatest } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromPlatform from 'src/app/platform/reducers';

@Directive({
  selector: '[shaiNewAccessControl]',
})
export class NewAccessControlDirective implements OnInit, DoCheck {
  capabilities: string[] = []
  prevCapabilities: string[] = [];
  @Input() componentType?: string;
  @Output() updateProperty: EventEmitter<any> = new EventEmitter();

  constructor(
    private _accessControlService: AccessControlService,
    private store: Store
  ) {}

  ngOnInit() {
    combineLatest([
      this.store.pipe(select(fromPlatform.getCapabilities))
    ])
      .subscribe(([capabilities]) => {
        if (capabilities) {
          this.capabilities = capabilities;
        }
      });
    this.checkAccess();
  }

  ngDoCheck() {
    if (this.prevCapabilities !== this.capabilities) {
      this.prevCapabilities = this.capabilities;
      this.checkAccess();
    }
  }

  private checkAccess() {
    if (!this.componentType) {
      return;
    }
    const checkControlResult = this._accessControlService.newCheckAccessControl(
      this.componentType
    );

    if (checkControlResult === null) {
      return;
    }

    this.updateProperty.emit(checkControlResult);
  }
}
