import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  CellElementType,
  ExtraCellElement,
} from '../models/extra-cell-element';

@Component({
  selector: 'shai-extra-cell-elements',
  template: `
    <div
      class="extra-cell-elements-wrapper"
      [ngStyle]="{ 'align-items': data.cellAlign ? data.cellAlign : 'center' }"
    >
      <div
        class="extra-cell-element-container"
        *ngFor="let element of elements"
      >
        <div [ngSwitch]="element.elementType">
          <span class="paragraph-small" *ngSwitchCase="cellType.label">{{
            element.data.value
          }}</span>

          <shai-button
            *ngSwitchCase="cellType.button"
            [type]="'button'"
            [disabled]="element.data.disabled"
            [iconName]="element.data.iconName" 
            (click)="element.data.onButtonClick(data.text)"
            [buttonStyle]="element.data.buttonStyle"
            >{{ element.data.value }}</shai-button
          >

          <shai-counter
            *ngSwitchCase="cellType.counter"
            (onCounterChange)="element.data.onCounterChange($event)"
            [avoidNegative]="element.data.avoidNegative"
            [decimals]="element.data.decimals"
            [floor]="element.data.floor"
            [ceil]="element.data.ceil"
          ></shai-counter>

          <shai-checkbox
            *ngSwitchCase="cellType.checkbox"
            (onFilterClick)="element.data.onFilterClick(data.text)"
            [data]="element.data"
          ></shai-checkbox>

          <shai-dropdown
            *ngSwitchCase="cellType.dropDown"
            [data] = "{
              listItems: element.data.listItems, 
              title: element.data.title,
              size: element.data.size,
              floatingWidth: element.data.floatingWidth
            }"
          ></shai-dropdown>

          <shai-slider
            *ngSwitchCase="cellType.slider"
            [type]="element.data.sliderType"
            [minLimit]="element.data.minLimit"
            [maxLimit]="element.data.maxLimit"
            [low]="element.data.low"
            [floor]="element.data.floor"
            [ceil]="element.data.ceil"
          ></shai-slider>
        </div>
      </div>
    </div>
  `,
  styleUrls: [`./extra-cell-elements.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtraCellElements implements OnInit {
  @Input() elements: ExtraCellElement[] = [];
  @Input() data: any;

  public get cellType(): typeof CellElementType {
    return CellElementType;
  }

  ngOnInit(): void {
    // console.log('ExtraCellElements ', this.elements);
  }
}
