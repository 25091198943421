// this is how you can call the component ([heading] is optional)

// <shai-overlay [heading]="'YOUR_HEADER'" (onChange)="this.isOverlayActive = $event" [active]="isOverlayActive"></shai-overlay>
// <shai-button [class.active]="isOverlayActive" (click)="toggleOverlay()">BUTTON_NAME</shai-button>
// @Input() isOverlayActive: boolean = false;
// toggleOverlay() { this.isOverlayActive = !this.isOverlayActive; }

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { hasClass } from 'src/app/utils/dom-utils';
import { fadeIn, slideInOutRight, zoomInOut } from '../animations';
import { SnackbarService, SnackbarType } from './snackbar/snackbar.service';
import { OnChanges } from '@angular/core';
import { HelperService } from 'src/app/platform/services/helper.service';
import { BreackpointService } from 'src/app/platform/services/breackpoint.service';

export interface OverlayQuestionModel {
  title: string;
  subtitle?: string;
  primaryButtonText?: string;
  cancelButtonText?: string;
  type?: string;
  destructive?: boolean,
  primaryAction?: any;
  cancelAction?: any;
}
@Component({
  selector: 'shai-overlay',
  animations: [fadeIn, slideInOutRight, zoomInOut],
  template: `
    <div class="modal" [ngClass]="{ 'has-header': hasHeader }">
     <div
        (click)="outsideClick()"
        class="modal-rest"
        [ngClass]="{ 'full-width': fullWidth }"
      ></div>
      <!-- <div class="modal-wrapper" [@slideInOutRight]="showAnimation && !fullWidth" [@zoomInOut]="showAnimation && fullWidth" [ngClass]="fullWidth?'full-width':''"> -->
      <div
        class="modal-wrapper"
        [@slideInOutRight]="showAnimation"
        [ngClass]="fullWidth ? 'full-width' : (((_bkp.obsScreen$ | async) !== 'desktop') ? 'full-width' : '')"
      >
        <shai-scroll-hint *ngIf="showScrollHint && scrollContentY !== undefined && wrapperRef && containerHeight !== undefined" [scrollContentY]="scrollContentY" [bottomSpace]="120" [refElement]="wrapperRef" [totalContentHeight]="containerHeight"> </shai-scroll-hint>
        <div (scroll)="onScroll($event)" class="scroll-wrapper" #wrapper [style.height]="showActionBar ? 'calc(100vh - 96px)' : '100vh'">
          <shai-button-icon class="cross-icon" (click)="close()">
            <shai-icon-close-16 *ngIf="closeIcon === 'cross'"></shai-icon-close-16>
            <shai-icon-arrow-left-16 *ngIf="closeIcon === 'arrow'"> </shai-icon-arrow-left-16>
          </shai-button-icon>
          <w-wrapper-centered>
            <div class="overlay-inner" class="page-header-primary">
              <ng-container *ngIf="heading">
                <shai-page-header [text]="heading"></shai-page-header>
              </ng-container>
              <ng-content></ng-content>
            </div>
          </w-wrapper-centered>
        </div>
      </div>
    </div>
    <div class="bg-shadow" [ngClass]="{ 'full-width': fullWidth }"></div>
  `,
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent implements OnChanges {
  [x: string]: any;
  isActive = false;
  isAborted = false;
  showAnimation = false;
  scrollContentY: number = 0;
  @ViewChild('overlayInner', { static: true }) overlayInnerRef?: ElementRef;
  @Input() showActionBar: boolean = true;
  @Input() showScrollHint: boolean = false;
  @Input() hasHeader: boolean = true;
  @Input() heading: string = '';
  @Input() fullWidth: boolean = false;
  @Input() closeQuestion?: OverlayQuestionModel;
  @Input() abortCloseQuestion?: OverlayQuestionModel;
  @Input() forceStayOpen: boolean = false;
  @Input() forceStayOpenMessage?: string;
  @Input() closeIcon: 'cross' | 'arrow' = 'cross';
  @Input() isSecondaryOverlay: boolean = false;
  currentSecondaryOverlay: boolean = false;
  @Input() set abortUpload(value: boolean) {
    if (value === true) {
      if (this.abortCloseQuestion) {
          this.snackbarService.show(
            this.abortCloseQuestion.title,
            SnackbarType.twoButtonsDestructive,
            this.abortCloseQuestion.subtitle ?? '',
            this.abortCloseQuestion.destructive,
            this.abortCloseQuestion.primaryButtonText,
            this.abortCloseQuestion.cancelButtonText,
            () => this.extraButtonHandler(),
            () => this.primaryButtonHandler(),
          );
      }
    }
  }

  @Input() set active(value: boolean) {
    if (value) {
      this.open();
    } else {
      this.close();
    }
  }
  @Input() set complete(value: boolean) {
    if (value === true) {
      this.showAnimation = false;
      this.removeActiveClass();
      setTimeout(() => {
        this.isActive = false;
        this.checkOverlayState(false);
        this.checkScrollBehaviour();
        if (this.abortCloseQuestion) {
          this.onChange.emit(true);
        } else {
          this.onChange.emit(false);
        }
      }, 0);
      return;
    }
  }
  @Output() onExtra = new EventEmitter();
  @Output() onChange = new EventEmitter();
  @Output() onLeave = new EventEmitter();

  @ViewChild('wrapper') wrapperRef?: ElementRef<any>;
  get containerHeight() {
    return this.wrapperRef?.nativeElement.scrollHeight;
  }

  constructor(
    private snackbarService: SnackbarService,
    private render: Renderer2,
    private hostElement: ElementRef,
    public _bkp: BreackpointService,
    public _hs: HelperService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.isSecondaryOverlay) {
      this.currentSecondaryOverlay = changes.isSecondaryOverlay.currentValue;
    }
  }

  addActiveClass() {
    if (this.isActive && !hasClass(this.hostElement.nativeElement, 'active')) {
      this.render.addClass(this.hostElement.nativeElement, 'active');
    }
  }

  removeActiveClass() {
    if (!this.isActive && hasClass(this.hostElement.nativeElement, 'active')) {
      this.render.removeClass(this.hostElement.nativeElement, 'active');
    }
  }

  overlayState(status: any) {
    this._hs.setOverlayState(status);
  }

  open() {
    this.addActiveClass();
    this.isActive = true;
    setTimeout(() => {
      this.showAnimation = true;
      this.checkOverlayState(true);
      this.checkScrollBehaviour();
      this.onChange.emit(this.isActive);
    }, 0);
  }

  close() {
    if (this.forceStayOpen && !this.forceStayOpenMessage) {
      this.onChange.emit(null);
      return;
    }

    if (this.forceStayOpen && this.forceStayOpenMessage) {
      this.snackbarService.show(this.forceStayOpenMessage, SnackbarType.simple);
      return;
    }

    if (this.isActive === true) {
      if (this.closeQuestion) {
        this.snackbarService.show(
          this.closeQuestion.title,
          SnackbarType.twoButtons,
          this.closeQuestion.subtitle ?? '',
          false,
          'Stay',
          'Leave',
          () => this.primaryButtonHandler(),
          () => this.cancelButtonHandler()
        );
      } else {
        this.cancelButtonHandler();
      }
    }
  }

  outsideClick() {
    this.close();
  }

  primaryButtonHandler() {
    this.isActive = true;
    this.onChange.emit(this.isActive);
  }

  extraButtonHandler() {
    this.showAnimation = false;
    this.removeActiveClass();
    setTimeout(() => {
      this.isActive = false;
      this.checkOverlayState(false);
      this.checkScrollBehaviour();
      this.onExtra.emit(this.isActive);
    }, 0);
    return;
  }

  cancelButtonHandler() {
    this.showAnimation = false;
    this.removeActiveClass();
    setTimeout(() => {
      this.isActive = false;
      this.checkOverlayState(false);
      this.checkScrollBehaviour();
      this.onChange.emit(this.isActive);
      this.onLeave.emit();
    }, 0);
    return;
  }

  private checkScrollBehaviour() {
    if (!this.isSecondaryOverlay) {
      if (this.isActive && !hasClass(document.body, 'no-scroll')) {
        this.render.addClass(document.body, 'no-scroll');
      } else if (!this.isActive && hasClass(document.body, 'no-scroll')) {
        this.render.removeClass(document.body, 'no-scroll');
      }
    }
  }

  private checkOverlayState(state: boolean) {
    this.overlayState({isOpen: state, isSecondaryOverlay: this.currentSecondaryOverlay});
  }

  onScroll(event: any) {
    this.scrollContentY = event.target.scrollTop;
  }
}
