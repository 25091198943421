import { CdkStep } from '@angular/cdk/stepper';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, AfterViewChecked, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { InputErrorMessage } from 'src/app/shared/components/form-controls/input-text/form-input-text.component';
import { StepState } from 'src/app/shared/models/step-state';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { BreackpointService } from 'src/app/platform/services/breackpoint.service';
import { AuthStatus } from '../models/auth.models';
import { AuthActions } from '../state/actions';
import { fromAuth } from '../state';
import { SnackbarService, SnackbarType } from 'src/app/shared/components/snackbar/snackbar.service';

@Component({
  selector: 'shai-reset-password-step-one',
  template: `
  <div id="reset-password-step-one">
    <mat-grid-list cols="12" rowHeight="fit">
      <mat-grid-tile colspan="2"
        *ngIf="(_bkp.obsScreen$ | async) === 'desktop'">
      </mat-grid-tile>
      <mat-grid-tile [colspan]="(_bkp.obsScreen$ | async) === 'desktop' ? 8 : 12">
        <shai-title-bar
          [topDescription]="
            'Step ' +
            (this.step && this.step?._stepper && this.step._stepper.selectedIndex < this.newLength ? this.step._stepper.selectedIndex + 1 : this.step?._stepper?.selectedIndex) +
            ' of ' +
            this.newLength"
          title="Reset password"
          bottomDescription="Introduce Organization SUID and your email. We'll send you an email with instructions to change your password."
          [showExtraSpace]="true">
        </shai-title-bar>
        <div class="container-form">
          <form [formGroup]="resetPasswordForm">
            <div class="form-field-group">
              <shai-form-input-text
                [parentForm]="resetPasswordForm"
                formControlName="organizationSuid"
                fieldName="organizationSuid"
                label="Organization SUID"
                [errorMessages]="suidErrorMessages"
              >
              </shai-form-input-text>
            </div>
            <div class="form-field-group">
              <shai-form-input-text
                [parentForm]="resetPasswordForm"
                formControlName="username"
                fieldName="username"
                label="Your email"
                [errorMessages]="usernameErrorMessages"
              >
              </shai-form-input-text>
            </div>
            <mat-error *ngIf="hasSubmitAttemps && resetPasswordForm.invalid">
              Invalid form data
            </mat-error>
            <mat-error *ngIf="hasSubmitAttemps && resetPasswordForm.valid && (authStatus$ | async)?.error">
              This details don't match our database.
            </mat-error>
            <span *ngIf="hasSubmitAttemps && resetPasswordForm.valid && (authStatus$ | async)?.error">
              Please double-check for typos or <a target="_blank" [href]="mailto">contact us</a>.
            </span>
          </form>
        </div>
      </mat-grid-tile>
      <mat-grid-tile colspan="2"
        *ngIf="(_bkp.obsScreen$ | async) === 'desktop'">
      </mat-grid-tile>
    </mat-grid-list>
    <shai-action-bar>
      <ng-container left-cell>
        <shai-button
          [type]="'button'"
          (click)="onCancel()"
          buttonStyle="secondary"
        >Cancel</shai-button>
      </ng-container>
      <ng-container right-cell>
        <shai-button
          [type]="'button'"
          (click)="handleSubmit()"
          buttonStyle="primary"
        >Next</shai-button>
      </ng-container>
    </shai-action-bar>
  </div>
  `,
  styleUrls: ['./reset-password-step-one.component.scss'],
})
export class ResetPasswordStepOneComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {
  newLength!: number;
  hasSubmitAttemps = false;
  authStatus$ : Observable<AuthStatus> = new Observable();
  @Input() step: CdkStep | undefined;
  @Output() onChange = new EventEmitter<{ state: StepState; data: any }>();
  error$ : Observable<any> = new Observable();
  pending$ : Observable<any> = new Observable();
  nameCustomError: string | undefined = undefined;
  stepNumber!: number;
  firstSubmitAttemps = true;
  mailto = "mailto:engineering@sherpa.ai";
  private subscription?: Subscription;
  private subscriptionReset?: Subscription;

  resetPasswordForm = this.formBuilder.group({
    organizationSuid: ['', [Validators.required]],
    username: ['', [Validators.required, Validators.email]],
  });

  usernameErrorMessages: InputErrorMessage[] = [
    { type: "required", message: "Email is required" },
    { type: "email", message: "Invalid email format" }
  ];

  suidErrorMessages: InputErrorMessage[] = [
    { type: "required", message: "Organization SUID is required" },
  ];

  constructor(
    private store: Store,
    private cdRef: ChangeDetectorRef,
    public _bkp: BreackpointService,
    private snackbar: SnackbarService,
    private formBuilder: FormBuilder
  ) {
    this.error$ = this.store.pipe(select(fromAuth.isLoginError)) as Observable<any>;
    this.pending$ = this.store.pipe(select(fromAuth.isLoginPending)) as Observable<any>;
  }

  ngOnInit(): void {
    this._checkResetPassCompleted();
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterViewInit() {
    if (this.step) {
      this.newLength = (this.step._stepper._steps && this.step._stepper._steps.length) ? (this.step._stepper._steps.length - 1) : this.step._stepper._steps?.length;
      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.subscriptionReset?.unsubscribe();
  }

  onCancel() {
    this.onChange.emit({ state: StepState.cancel, data: null });
  }

  private _checkResetPassCompleted() {
    if (this.step) {
      this.subscriptionReset = this.store.pipe(select(fromAuth.getResetPassCredentials))
      .subscribe(credentials => {
        if (this.hasSubmitAttemps && credentials) {
          this.step?._stepper.next();
        }
      });
    }
  }

  handleSubmit() {
    this.hasSubmitAttemps = true;
    if (this.resetPasswordForm.valid) {
      const data = {
        authFlow: "RESET_PASSWORD_REQUIRED",
        ...this.resetPasswordForm.value
      }
      this.store.dispatch(AuthActions.resetPassword({ resetPassCredentials: data }));
      this.subscription = combineLatest([this.error$, this.pending$])
        .subscribe((([error, pending]) => {
          if (this.step && error && !pending) {
            if (this.firstSubmitAttemps) {
              this.firstSubmitAttemps = false;
              this.snackbar.show(
                'Sorry, something went wrong',
                SnackbarType.twoButtons,
                'Please try again.',
                false,
                'Try Again',
                'Cancel',
                () => this.primaryButtonHandler(),
                () => this.cancelButtonHandler()
              );
            } else {
              setTimeout(() => {
                this.snackbar.show(
                  'Sorry, the password cannot be changed at this time. ',
                  SnackbarType.twoButtons,
                  'Please try again later or contact us.',
                  false,
                  'Try Again Later',
                  'Contact sherpa',
                  () => this.goToHome(),
                  () => this.contactSherpa()
                );
              }, 1000);
            }
            this.subscription?.unsubscribe();
          }
        })
      )
    }
  }

  primaryButtonHandler() {
    this.cancelButtonHandler();
    setTimeout(() => {
      this.handleSubmit();
    }, 700)
  }

  cancelButtonHandler() {}

  goToHome() {
    this.onChange.emit({ state: StepState.finish, data: null });
    this.store.dispatch(AuthActions.clearReset());
    this.cdRef.detectChanges();
  }

  contactSherpa() {
    const link = document.createElement('a');
    link.target = "_blank";
    link.href = this.mailto;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
