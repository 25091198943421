import { AbstractControl, ValidationErrors } from "@angular/forms";

export const PasswordStrengthValidator = function (control: AbstractControl): ValidationErrors | null {

  let value: string = control.value || '';

  if (!value || value.length < 12) { return { message: 'The password should contain at least 12 characters' }}

  let lowerCaseCharacters = /[a-z]+/g
  if (lowerCaseCharacters.test(value) === false) {
    return { message: 'The password should contain at least one lowercase letter' };
  }

  let upperCaseCharacters = /[A-Z]+/g
  if (upperCaseCharacters.test(value) === false) {
    return { message: 'The password should contain at least one uppercase letter' };
  }

  let numberCharacters = /[0-9]+/g
  if (numberCharacters.test(value) === false) {
    return { message: 'The password should contain at least one number' };
  }

  let specialCharacters = /[!@#%^&*()_+\-=\[\]{};':"\\|,.\/?]+/
  if (specialCharacters.test(value) === false) {
    return { message: 'The password should contain at least one special character, e.g., ! @ # ? ]' };
  }

  let  invalidCharacters = /[<>$]+/;
  if (invalidCharacters.test(value) === true) {
    return { message: 'Does not include the following characters: $ < >' };
  }

  return null;
}
