import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProject from './project.reducer';
import * as fromTraining from './training.reducer';
import * as fromNewTraining from './new-training.reducer';
import * as fromTrainingSession from './training-session.reducer';
import * as fromNewTrainingSession from './new-training-session.reducer';
import * as fromRoot from '../../reducers';
import * as fromConfig from './config.reducer';
import * as fromLog from './log.reducer';
import * as fromNode from './node.reducer';
import * as fromOrganization from './organization.reducer';
import * as fromInference from './inference.reducer';
import * as fromNewInference from './new-inference.reducer';

export const platformKey = 'platform';

export interface PlatformState {
  [fromProject.projectStatusKey]: fromProject.State;
  [fromConfig.configFeatureKey]: fromConfig.State;
  [fromTraining.trainingFeatureKey]: fromTraining.State;
  [fromNewTraining.newTrainingFeatureKey]: fromNewTraining.State;
  [fromNewTrainingSession.newTrainingSessionFeatureKey]: fromNewTrainingSession.State;
  [fromTrainingSession.trainingSessionFeatureKey]: fromTrainingSession.State;
  [fromLog.logFeatureKey]: fromLog.State;
  [fromNode.nodeFeatureKey]: fromNode.State;
  [fromOrganization.organizationFeatureKey]: fromOrganization.State;
  [fromInference.inferenceFeatureKey]: fromInference.State;
  [fromNewInference.newInferenceFeatureKey]: fromNewInference.State;
};

export interface State extends fromRoot.State {
  [platformKey]: fromProject.State
}

export function reducer(state: PlatformState | undefined, action: Action) {
  return combineReducers({
    [fromProject.projectStatusKey]: fromProject.reducer,
    [fromConfig.configFeatureKey]: fromConfig.reducer,
    [fromTraining.trainingFeatureKey]: fromTraining.reducer,
    [fromNewTraining.newTrainingFeatureKey]: fromNewTraining.reducer,
    [fromTrainingSession.trainingSessionFeatureKey]: fromTrainingSession.reducer,
    [fromNewTrainingSession.newTrainingSessionFeatureKey]: fromNewTrainingSession.reducer,
    [fromLog.logFeatureKey]: fromLog.reducer,
    [fromNode.nodeFeatureKey]: fromNode.reducer,
    [fromOrganization.organizationFeatureKey]: fromOrganization.reducer,
    [fromInference.inferenceFeatureKey]: fromInference.reducer,
    [fromNewInference.newInferenceFeatureKey]: fromNewInference.reducer
  })(state, action);
}

//export const reducer = (state: any, action: Action) => fromProject.reducer(state, action);

// export function reducers(state: ProjectState | undefined, action: Action) {
//   return combineReducers({
//     [fromProject.projectStatusKey]: fromProject.reducer
//   })(state, action);
// }


export const selectPlatformState = createFeatureSelector<PlatformState>(platformKey);

// PROJECT SELECTORS

export const selectProjectsState = createSelector(
  selectPlatformState,
  state => state.projects
);

export const getCurrentProjectName = createSelector(
  selectProjectsState,
  fromProject.getCurrentProjectName
)

export const getCurrentProject = createSelector(
  selectProjectsState,
  fromProject.getCurrentProject
)

export const getCurrentProjectId = createSelector(
  selectProjectsState,
  fromProject.getCurrentProjectId
)

export const isCurrentProjectPending = createSelector(
  selectProjectsState,
  fromProject.isCurrentProjectPending
)

export const getProjectError = createSelector(
  selectProjectsState,
  fromProject.getProjectError
)

export const getProjectList = createSelector(
  selectProjectsState,
  fromProject.getProjectList
)

export const isProjectListPending = createSelector(
  selectProjectsState,
  fromProject.isProjectListPending
)

export const getProjectListError = createSelector(
  selectProjectsState,
  fromProject.getProjectListError
)

export const getAllProjectFiles = createSelector(
  selectProjectsState,
  fromProject.getProjectFileList
)

export const getDeleteProjectError = createSelector(
  selectProjectsState,
  fromProject.getDeleteProjectError
)

// CONFIG SELECTORS

export const selectConfigState = createSelector(
  selectPlatformState,
  state => state.globalConfig
);

export const isConfigPending = createSelector(
  selectConfigState,
  fromConfig.isConfigPending
);

export const isConfigError = createSelector(
  selectConfigState,
  fromConfig.isConfigError
);

export const getConfig =createSelector(
  selectConfigState,
  fromConfig.getConfig
)

// Training Session Selectors

export const selectTrainingSessionState = createSelector(
  selectPlatformState,
  state => state.trainingSession
);

export const getAllTrainingSessions = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getAllTrainingSessions
);

export const getCurrentTrainingSession = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getCurrentTrainingSession
);

export const getCurrentTrainingSessionRounds = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getCurrentTrainingSessionRounds
);

export const getCurrentTrainingSessionLastRound = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getCurrentTrainingSessionLastRound
);

export const getCurrentTrainingSessionTimestamp = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getCurrentTrainingSessionTimestamp
);

export const getTrainingSessionModel = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getTrainingSessionModel
);

export const getTrainingSessionParadigm = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getTrainingSessionParadigm
);

export const isParadigmAndModelUpdated = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getIsMechanicsDataUpdated
);

export const linkedNodes = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getLinkedNodes
);

export const nodeToLink = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.nodeToLink
);

export const isNodeToLinkPending = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.isNodeToLinkPending
);

export const isNodeToLinkError = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.isNodeToLinkError
);

export const nodeToUnLink = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.nodeToUnLink
);

export const isNodeToUnLinkPending = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.isNodeToUnLinkPending
);

export const isNodeToUnLinkError = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.isNodeToUnLinkError
);

export const historyNodes = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getHistoryNodes
);

export const isMappingNodesCompleted = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getIsMappingNodesCompleted
);

export const isStopConditionCompleted = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getIsStopConditionCompleted
);

export const getTrainingSessionError = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getTrainingSessionError
);

export const getInfra = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getInfra
)

export const getStopConditions = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getStopConditions
);

export const isDatasetLengthUpdated = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getIsDatasetLengthCompleted
);

export const getPrivateAttrs = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getPrivateAttrs
);

export const getMetrics = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getMetrics);

export const getAggregatorMetrics = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getAggregatorMetrics);

export const getUrlModelParameters = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getUrlModelParameters
);

export const getUrlModelParametersError = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getUrlModelParametersError
);

export const getHistoryNodes = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getHistoryNodes
);

export const getRocMetricsData = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getRocMetrics
);

export const startTrainingStatus = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getStartTrainingStatus
);

export const deployInfraStatus = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getDeployInfraStatus
);

export const stopTrainingStatus = createSelector(
  selectTrainingSessionState,
  fromTrainingSession.getStopTrainingByParamsStatus
);

// TRAINING SELECTORS

export const selectTrainingsState = createSelector(
  selectPlatformState,
  state => state.trainings
);

export const selectNewTrainingsState = createSelector(
  selectPlatformState,
  state => state.newTraining
);

export const selectNewTrainingSessionState = createSelector(
  selectPlatformState,
  state => state.newTrainingSession
);

export const getAllTrainings = createSelector(
  selectTrainingsState,
  fromTraining.getAllTrainings
)

export const getTraining = createSelector(
  selectTrainingsState,
  fromTraining.getTraining
)

export const getCurrentTraining = createSelector(
  selectTrainingsState,
  fromTraining.getCurrentTraining
)

export const getCurrentTrainingId = createSelector(
  selectTrainingsState,
  fromTraining.getCurrentTrainingId
)

export const getNewTrainingData = createSelector(
  selectNewTrainingsState,
  fromNewTraining.getNewTrainingData
)

export const getIsEntityResolutionCompleted = createSelector(
  selectTrainingsState,
  fromTraining.getIsEntityResolutionCompleted
)

export const getNewTrainingId = createSelector(
  selectNewTrainingsState,
  fromNewTraining.getNewTrainingId
)

export const getNewTrainingTimestamp = createSelector(
  selectNewTrainingsState,
  fromNewTraining.getNewTrainingTimestamp
)

export const getTrainingParadigmId = createSelector(
  selectTrainingsState,
  fromTraining.getTrainingParadigmId
)

export const getParadigmData = createSelector(
  selectNewTrainingsState,
  fromNewTraining.getParadigmData
)

export const getTrainingParadigmError = createSelector(
  selectTrainingsState,
  fromTraining.getTrainingParadigmError
)

export const getTrainingParadigmPending = createSelector(
  selectTrainingsState,
  fromTraining.getTrainingParadigmPending
)

export const getEntityResolutionData = createSelector(
  selectNewTrainingsState,
  fromNewTraining.getEntityResolutionData
)

export const getEntityResolutionError = createSelector(
  selectTrainingsState,
  fromTraining.getEntityResolutionError
)

export const getEntityResolutionPending = createSelector(
  selectTrainingsState,
  fromTraining.getEntityResolutionPending
)

export const getModelData = createSelector(
  selectNewTrainingsState,
  fromNewTraining.getModelData
)

export const getAggregatorData = createSelector(
  selectNewTrainingsState,
  fromNewTraining.getAggregatorData
)

export const getAggregatorError = createSelector(
  selectTrainingsState,
  fromTraining.getAggregatorError
)

export const getAggregatorPending = createSelector(
  selectTrainingsState,
  fromTraining.getAggregatorPending
)

export const getDifferentialPrivacyData = createSelector(
  selectNewTrainingsState,
  fromNewTraining.getDifferentialPrivacyData
)

export const getDifferentialPrivacyResponse = createSelector(
  selectTrainingsState,
  fromTraining.getDifferentialPrivacyResponse
)

export const getAggregatorResponse = createSelector(
  selectTrainingsState,
  fromTraining.getAggregatorResponse
)

export const getModelResponse = createSelector(
  selectTrainingsState,
  fromTraining.getModelResponse
)

export const getParadigmResponse = createSelector(
  selectTrainingsState,
  fromTraining.getParadigmResponse
)

export const getDifferentialPrivacyError = createSelector(
  selectTrainingsState,
  fromTraining.getDifferentialPrivacyError
)

export const getDifferentialPrivacyPending = createSelector(
  selectTrainingsState,
  fromTraining.getDifferentialPrivacyPending
)

export const getMetricsConfig = createSelector(
  selectNewTrainingSessionState,
  fromNewTrainingSession.getMetricsConfig
)

export const getStopCondition = createSelector(
  selectNewTrainingSessionState,
  fromNewTrainingSession.getStopCondition
)

export const getParticipatingNodes = createSelector(
  selectNewTrainingSessionState,
  fromNewTrainingSession.getParticipatingNodes
)

export const getMappings = createSelector(
  selectNewTrainingSessionState,
  fromNewTrainingSession.getMappings
)

export const isTrainingTabPending = createSelector(
  selectTrainingsState,
  fromTraining.isTrainingTabPending
)

export const getDeleteTrainingStatus = createSelector(
  selectTrainingsState,
  fromTraining.getDeleteTrainingStatus
)

// LOG SELECTORS
export const selectLogsState = createSelector(
  selectPlatformState,
  state => state.logs
);
export const getLogList = createSelector(
  selectLogsState,
  fromLog.getLogList
)
export const getLogListError = createSelector(
  selectLogsState,
  fromLog.getLogListError
)
export const isLogListPending = createSelector(
  selectLogsState,
  fromLog.isLogListPending
)
export const getAllLogs = createSelector(
  selectLogsState,
  fromLog.getAllLogs
)
export const getAllLogsError = createSelector(
  selectLogsState,
  fromLog.getAllLogsError
)
export const getAllLogsPending = createSelector(
  selectLogsState,
  fromLog.getAllLogsPending
)

// NODE SELECTORS
export const selectNodesState = createSelector(
  selectPlatformState,
  state => state.nodes
);
export const getNodeList = createSelector(
  selectNodesState,
  fromNode.getNodeList
)
export const getNodeArray = createSelector(
  selectNodesState,
  fromNode.getNodeArray
)
export const isNodeListPending = createSelector(
  selectNodesState,
  fromNode.isNodeListPending
)
export const isNodeListError = createSelector(
  selectNodesState,
  fromNode.isNodeListError
)
export const getDockerImage = createSelector(
  selectNodesState,
  fromNode.getDockerImage
)
export const getDockerImageError = createSelector(
  selectNodesState,
  fromNode.getDockerImageError
)
export const getPingNodePending = createSelector(
  selectNodesState,
  fromNode.getPingNodePending
)
export const getPingNodeError = createSelector(
  selectNodesState,
  fromNode.getPingNodeError
)
export const getDeleteNodeError = createSelector(
  selectNodesState,
  fromNode.getDeleteNodeError
)

export const getDeleteNodeStatus = createSelector(
  selectNodesState,
  fromNode.getDeleteNodeStatus
)

// ORGANIZATION SELECTORS
export const selectOrganizationState = createSelector(
  selectPlatformState,
  state => state.organization
);
export const getOrganization = createSelector(
  selectOrganizationState,
  fromOrganization.getOrganization
)
export const getOrganizationName = createSelector(
  selectOrganizationState,
  fromOrganization.getOrganizationName
)
export const getOrganizationPlan = createSelector(
  selectOrganizationState,
  fromOrganization.getOrganizationPlan
)
export const isOrganizationPending = createSelector(
  selectOrganizationState,
  fromOrganization.isOrganizationPending
)
export const isOrganizationError = createSelector(
  selectOrganizationState,
  fromOrganization.isOrganizationError
)
export const getCapabilities = createSelector(
  selectOrganizationState,
  fromOrganization.getCapabilities
)
export const getPlan = createSelector(
  selectOrganizationState,
  fromOrganization.getPlan
);
export const isUpdateSecretCompleted = createSelector(
  selectOrganizationState,
  fromOrganization.isUpdateSecretCompleted
)
export const getUpdateSecretError = createSelector(
  selectOrganizationState,
  fromOrganization.getUpdateSecretError
)
export const isChangePasswordCompleted = createSelector(
  selectOrganizationState,
  fromOrganization.isChangePasswordCompleted
)
export const getChangePasswordError = createSelector(
  selectOrganizationState,
  fromOrganization.getChangePasswordError
)

// INFERENCE SELECTORS

export const selectInferenceState = createSelector(
  selectPlatformState,
  state => state.inference
);

export const saveFilesForInferenceStatus = createSelector(
  selectInferenceState,
  fromInference.saveFilesForInferenceStatus
);

export const getAllInferences = createSelector(
  selectInferenceState,
  fromInference.getAllInferences
)

export const getSavedModelsForInference = createSelector(
  selectInferenceState,
  fromInference.getSavedModelsForInference
)

export const savedModelsListStatus = createSelector(
  selectInferenceState,
  fromInference.savedModelsListStatus
);

export const getDeleteInferenceStatus = createSelector(
  selectInferenceState,
  fromInference.getDeleteInferenceStatus
)

// NEW INFERENCE SELECTORS

export const selectNewInferenceState = createSelector(
  selectPlatformState,
  state => state.newInference
);

export const getNewInference = createSelector(
  selectNewInferenceState,
  fromNewInference.getNewInference
);


export const getNewInferenceSavedFiles = createSelector(
  selectNewInferenceState,
  fromNewInference.getSavedFiles
)

export const getInferenceParticipatingNodes = createSelector(
  selectNewInferenceState,
  fromNewInference.getParticipatingNodes
)

export const getInferenceMappings = createSelector(
  selectNewInferenceState,
  fromNewInference.getMappings
)


export const getInferenceCreationCompleted = createSelector(
  selectNewInferenceState,
  fromNewInference.getInferenceCreationCompleted
)
