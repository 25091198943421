import { AccessControl } from './access-control';

export const expProUserAccessControlsValues: AccessControl[] = [
  {
    component: 'project',
    createAction: true,
    readAction: false,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'nodes',
    createAction: true,
    readAction: false,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'logs',
    createAction: false,
    readAction: false,
    updateAction: false,
    deleteAction: false,
  },
  {
    component: 'org-secret',
    createAction: true,
    readAction: false,
    updateAction: true,
    deleteAction: true,
  },

  {
    component: 'files',
    createAction: true,
    readAction: false,
    updateAction: false,
    deleteAction: true,
  },
  //Paradigm
  {
    component: 'vfl-card',
    createAction: false,
    readAction: false,
    updateAction: false,
    deleteAction: false,
  },
  //Models
  {
    component: 'nn-card',
    createAction: true,
    readAction: false,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'nnr-card',
    createAction: false,
    readAction: false,
    updateAction: false,
    deleteAction: false,
  },
  {
    component: 'lir-card',
    createAction: false,
    readAction: false,
    updateAction: false,
    deleteAction: false,
  },
  {
    component: 'lor-card',
    createAction: false,
    readAction: false,
    updateAction: false,
    deleteAction: false,
  },
  {
    component: 'svm-card',
    createAction: false,
    readAction: false,
    updateAction: false,
    deleteAction: false,
  },
  {
    component: 'kmeans-card',
    createAction: false,
    readAction: false,
    updateAction: false,
    deleteAction: false,
  },
  {
    component: 'nbayes-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'tan-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'pfedme-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'lgfedavg-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'fedprox-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'fedper-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'dt-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  //Aggregators
  {
    component: 'fedavg-card',
    createAction: true,
    readAction: false,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'weightedfedavg-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'ddaba-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'normclip-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'bulyan-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'multikrum-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'robustfedmedian-card',
    createAction: true,
    readAction: false,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'robustfedtrimmedmean-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'robustlearningrate-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'fednova-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'pfedme-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'rfout-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'fedconcat-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'fedsum-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'fedextratress-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'fedgradboostdt-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'clusterfedavg-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'svmaverage-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'fedid3-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'biasfedavg-card',
    createAction: true,
    readAction: true,
    updateAction: true,
    deleteAction: true,
  },
  {
    component: 'exponential-mechanism',
    createAction: true,
    readAction: false,
    updateAction: true,
    deleteAction: true,
  },
];
