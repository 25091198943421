import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'toFileSize'})
export class ToFileSize implements PipeTransform {
  transform(value: string | number | undefined, unitOrigin: 'mb' | 'kb' | 'byte' = 'kb' ): string {
    if(value === undefined) return '';
    const valueNumber = typeof value === 'string' ? parseFloat(value) : value;
    switch(unitOrigin) {
      case 'kb': return this.kbToFileSize(valueNumber);
      case 'mb': return this.mbToFileSize(valueNumber);
      case 'byte': return this.byteToFileSize(valueNumber);
      default: return '';
    }
  }

  kbToFileSize(value: number) {
    if(value > 1024) {
      return (value / 1024).toFixed(1) + ' mb';
    } 
    else if(value < 1) {
      return (value * 1024).toFixed(0) + ' bytes';  
    }
    else {
      return (value).toFixed(1) + ' kb';
    }
  }

  mbToFileSize(value: number) {
    if(value < 1) {
      return (value * 1024).toFixed(0) + ' kb';  
    }
    else {
      console.info((value).toFixed(1))
      return (value).toFixed(1) + ' mb';
    }
  }

  byteToFileSize(value: number) {
    if(value > 1024 * 1024) {
      return ((value / 1024) / 1024).toFixed(0) + ' mb';  
    }
    else if(value > 1024) {
      return (value / 1024).toFixed(1) + ' kb';  
    }
    else {
      return (value).toFixed(0) + ' bytes';
    }
  }
}