import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-sound-16',
  template: `
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill="#0372be"
        fill-opacity="0.996"
        d="
  M 6.14 5.65
  L 8.13 4.06
  Q 12.28 0.73 12.28 6.05
  L 12.28 15.48
  A 1.80 1.80 0.0 0 1 9.38 16.90
  L 6.07 14.31
  Q 5.55 13.90 4.90 14.03
  C 3.27 14.36 2.15 13.36 2.01 11.74
  C 1.84 9.72 1.51 5.47 4.62 6.04
  Q 5.47 6.19 6.14 5.65
  Z
  M 10.96 4.57
  A 0.60 0.60 0.0 0 0 9.98 4.11
  L 6.60 6.89
  A 0.60 0.60 0.0 0 0 6.38 7.35
  L 6.38 12.65
  A 0.60 0.60 0.0 0 0 6.60 13.11
  L 9.98 15.88
  A 0.60 0.60 0.0 0 0 10.96 15.41
  L 10.96 4.57
  Z
  M 5.25 12.30
  L 5.25 7.70
  A 0.43 0.43 0.0 0 0 4.82 7.27
  L 4.43 7.27
  A 1.44 1.23 -0.1 0 0 2.99 8.50
  L 2.99 11.50
  A 1.44 1.23 -0.1 0 0 4.43 12.73
  L 4.82 12.73
  A 0.43 0.43 0.0 0 0 5.25 12.30
  Z"
      />
      <path
        fill="#0372be"
        fill-opacity="0.996"
        d="
  M 15.64 6.55
  A 0.58 0.57 -35.7 0 1 15.78 5.74
  L 16.15 5.49
  Q 16.23 5.43 16.30 5.51
  Q 19.35 8.94 17.00 13.82
  A 0.98 0.95 7.1 0 1 16.30 14.35
  Q 15.43 14.53 15.47 13.96
  Q 15.50 13.54 15.73 13.24
  Q 18.25 10.01 15.64 6.55
  Z"
      />
      <path
        fill="#0372be"
        fill-opacity="0.996"
        d="
  M 13.69 12.22
  C 14.45 10.90 14.50 9.36 13.80 8.03
  Q 13.65 7.75 13.79 7.46
  Q 13.86 7.30 14.08 7.29
  Q 14.46 7.26 14.69 7.55
  Q 16.68 9.97 14.73 12.50
  Q 14.53 12.77 14.19 12.78
  Q 14.00 12.79 13.84 12.71
  A 0.35 0.35 0.0 0 1 13.69 12.22
  Z"
      />
    </svg>
  `,
})
export class IconSound16 {
  @HostBinding('class.shai-icon') get iconClass() {
    return true;
  }
}
