import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { BreackpointService } from 'src/app/platform/services/breackpoint.service';
import { IconChart16 } from '../../icons/16/icon-chart';
import { IconCheckmark16 } from '../../icons/16/icon-checkmark';
import { IconClose16 } from '../../icons/16/icon-close';
import { IconSoundOn16 } from '../../icons/16/icon-sound-on';
import { IconSoundOff16 } from '../../icons/16/icon-sound-off';
import { IconDeploy16 } from '../../icons/16/icon-deploy';
import { IconDocument16 } from '../../icons/16/icon-document';
import { IconDownload16 } from '../../icons/16/icon-download';
import { IconFetch16 } from '../../icons/16/icon-fetch';
import { IconDuplicate16 } from '../../icons/16/icon-duplicate';
import { IconEdit16 } from '../../icons/16/icon-edit';
import { IconFilter16 } from '../../icons/16/icon-filter';
import { IconInfo16 } from '../../icons/16/icon-info';
import { IconLink16 } from '../../icons/16/icon-link';
import { IconMore16 } from '../../icons/16/icon-more';
import { IconMoreVertical16 } from '../../icons/16/icon-more-vertical';
import { IconPlus16 } from '../../icons/16/icon-plus';
import { IconMinus16 } from '../../icons/16/icon-minus';
import { IconPrivacy16 } from '../../icons/16/icon-privacy';
import { IconRetry16 } from '../../icons/16/icon-retry';
import { IconSettings16 } from '../../icons/16/icon-settings';
import { IconSound16 } from '../../icons/16/icon-sound';
import { IconVolume16 } from '../../icons/16/icon-volume';
import { IconTip16 } from '../../icons/16/icon-tip';
import { IconTrash16 } from '../../icons/16/icon-trash';
import { IconDisable16 } from '../../icons/16/icon-disable';
import { IconLaunch16 } from '../../icons/16/icon-launch';
import { IconDocumentModel16 } from '../../icons/16/icon-document-model';
// Add new icons here ...
@Component({
  selector: 'shai-button',
  template: `
    <ng-template #content><ng-content></ng-content></ng-template>
    <button [ngClass]="{fontStyle: fontStyle, 'button-text-style': !fontStyle, 'stretch': stretch, fixAnimationTrainingWidth: fixWidth }"
      *ngIf="!href"
      [type]="type"
      [disabled]="disabled"
      [routerLink]="route"
      [matTooltip]="(_bkp.obsScreen$ | async) !== 'desktop' && tooltipText ? tooltipText : ''"
      matTooltipPosition="above"
    >
      <ng-content select="[icon-pre]"></ng-content>
      <span *ngIf="buttonStyle === 'special-complex-icon'" class="paragraph-small">
        <ng-container #leftIcon></ng-container>
      </span>
      <span *ngIf="buttonStyle === 'contextual'" class="paragraph-small">
        <ng-container #leftIcon></ng-container>
      </span>
      <span *ngIf="buttonStyle === 'tag'" class="caption">
        <ng-container #leftIcon></ng-container>
      </span>

      <span *ngIf="buttonStyle === 'special-complex-icon' && ((_bkp.obsScreen$ | async) === 'desktop' && tooltipText || !tooltipText)" class="paragraph-small"><ng-container *ngTemplateOutlet="content"></ng-container></span>
      <span *ngIf="buttonStyle === 'contextual' && ((_bkp.obsScreen$ | async) === 'desktop' && tooltipText || !tooltipText)" class="paragraph-small"><ng-container *ngTemplateOutlet="content"></ng-container></span>
      <span *ngIf="buttonStyle === 'tag'" class="caption truncate-1-line"
        [ngStyle]="{'max-width': (tagLength && tagLength > 32) ? '215px' : ''}"
        title="{{ titleText && tagLength && (tagLength > 32) ? titleText : '' }}">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </span>
      <span *ngIf="buttonStyle !== 'tag' && buttonStyle !== 'contextual'"><ng-container *ngTemplateOutlet="content"></ng-container></span>
      <ng-content select="[icon-post]"></ng-content>
    </button>
    <a
      *ngIf="href"
      [href]="href"
      [target]="target ? target : '_self'"
    >
      <ng-content select="[icon-pre]"></ng-content>
      <span *ngIf="buttonStyle === 'special-complex-icon'" class="paragraph-small">
        <ng-container #leftIcon></ng-container>
      </span>
      <span *ngIf="buttonStyle === 'contextual'" class="paragraph-small">
        <ng-container #leftIcon></ng-container>
      </span>
      <span *ngIf="buttonStyle === 'tag'" class="caption">
        <ng-container #leftIcon></ng-container>
      </span>
      <!-- <ng-container *ngTemplateOutlet="content"></ng-container> -->
      <span *ngIf="buttonStyle === 'special-complex-icon' && ((_bkp.obsScreen$ | async) === 'desktop' && tooltipText || !tooltipText)" class="paragraph-small"><ng-container *ngTemplateOutlet="content"></ng-container></span>
      <span *ngIf="buttonStyle === 'contextual' && ((_bkp.obsScreen$ | async) === 'desktop' && tooltipText || !tooltipText)" class="paragraph-small"><ng-container *ngTemplateOutlet="content"></ng-container></span>
      <span *ngIf="buttonStyle === 'tag'" class="caption"><ng-container *ngTemplateOutlet="content"></ng-container></span>
      <span *ngIf="buttonStyle !== 'tag' && buttonStyle !== 'contextual'"><ng-container *ngTemplateOutlet="content"></ng-container></span>
      <ng-content select="[icon-post]"></ng-content>
    </a>
  `,
  styleUrls: [`./button.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnInit {
  @ViewChild('leftIcon', { read: ViewContainerRef}) leftIcon: ViewContainerRef | undefined;
  @Input() fixWidth: boolean = false;
  @Input() href: string | undefined;
  @Input() route: Array<string> | undefined;
  @Input() target: string = '_self';
  @Input() type: string = 'button';
  @Input() tagLength?: number;
  @Input() titleText?: string;
  @Input() outline: boolean = false;
  @Input() stretch: boolean = false;
  @Input() disabled = false;
  @Input() tooltipText!: string;
  @Input() buttonStyle : 'primary' | 'secondary' | 'secondary-destructive' | 'tertiary' | 'link' | 'light' | 'light-outline' | 'danger' | 'contextual' | 'special-complex-icon' | 'tag' = 'primary';
  fontStyle: string | undefined;
  @Input() iconName : 'icon-trash-16' | 'icon-retry-16' | 'icon-close-16' | 'icon-plus-16' | 'icon-minus-16' | 'icon-more-16' | 'icon-chart-16' | 'icon-document-16' | 'icon-info-16'
                    | 'icon-filter-16' | 'icon-edit-16' | 'icon-tip-16' | 'icon-deploy-16' | 'icon-download-16' | 'icon-link-16' | 'icon-privacy-16' | 'icon-settings-16' | 'icon-sound-16'
                    | 'icon-duplicate-16' | 'icon-checkmark-16' | 'icon-disable-16' | 'icon-launch-16' | 'icon-document-model-16' | 'icon-fetch-16' |  'icon-volume-16' | 'icon-more-vertical-16' | 'none' = 'none'; // ... here ...
  modals = { // ... and here
      'icon-plus-16': IconPlus16,
      'icon-minus-16': IconMinus16,
      'icon-privacy-16': IconPrivacy16,
      'icon-more-16': IconMore16,
      'icon-more-vertical-16': IconMoreVertical16,
      'icon-chart-16': IconChart16,
      'icon-checkmark-16': IconCheckmark16,
      'icon-document-16': IconDocument16,
      'icon-edit-16': IconEdit16,
      'icon-info-16': IconInfo16,
      'icon-filter-16': IconFilter16,
      'icon-tip-16': IconTip16,
      'icon-deploy-16': IconDeploy16,
      'icon-close-16': IconClose16,
      'icon-sound-on-16': IconSoundOn16,
      'icon-sound-off-16': IconSoundOff16,
      'icon-trash-16': IconTrash16,
      'icon-download-16': IconDownload16,
      'icon-fetch-16': IconFetch16,
      'icon-link-16': IconLink16,
      'icon-retry-16': IconRetry16,
      'icon-settings-16': IconSettings16,
      'icon-sound-16': IconSound16,
      'icon-volume-16': IconVolume16,
      'icon-duplicate-16': IconDuplicate16,
      'icon-disable-16': IconDisable16,
      'icon-launch-16': IconLaunch16,
      'icon-document-model-16': IconDocumentModel16
  };

  @HostBinding('class') get reducedClass() {
    return `button-${this.buttonStyle}`;
  }

  @HostBinding('class.button-outline') get outlineClass() {
    return this.outline;
  }

  constructor(
    public viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    public _bkp: BreackpointService
  ) { }

  ngOnInit(): void {
    if (this.buttonStyle === 'contextual') {
      this.fontStyle = 'paragraph-small';
    }
    if (this.buttonStyle === 'tag') {
      this.fontStyle = 'caption';
    }
  }
  ngAfterViewInit() {
    if (this.iconName != 'none' && this.leftIcon) {
      this.leftIcon.clear();
      let component = this.modals[this.iconName];
      const cFactory = this.componentFactoryResolver.resolveComponentFactory(component);
      const componentRef = this.leftIcon.createComponent(cFactory);
      componentRef.changeDetectorRef.detectChanges();
    }
  }
}
