import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InputErrorMessage } from 'src/app/shared/components/form-controls/input-text/form-input-text.component';

@Component({
  selector: 'shai-request-access-form',
  template: `
    <div class="form-container">
      <form autocomplete="off" [formGroup]="form">
        <div class="form-field-group" *ngIf="getFormField('name')">
          <shai-form-input-text
            [parentForm]="form"
            formControlName="name"
            fieldName="name"
            [label]="placeholderKeys['name']"
            fieldSize="small"
          >
          </shai-form-input-text>
        </div>
        <div class="form-field-group" *ngIf="getFormField('email')">
          <shai-form-input-text
            [parentForm]="form"
            formControlName="email"
            fieldName="email"
            [label]="placeholderKeys['email']"
            fieldSize="small"
          >
          </shai-form-input-text>
        </div>

        <div class="form-field-group" *ngIf="getFormField('repeatEmail')">
          <shai-form-input-text
            [parentForm]="form"
            formControlName="repeatEmail"
            fieldName="repeatEmail"
            [label]="placeholderKeys['repeatEmail']"
            fieldSize="small"
          >
          </shai-form-input-text>
        </div>

        <div class="form-field-group" *ngIf="getFormField('company')">
          <shai-form-input-text
            [parentForm]="form"
            formControlName="company"
            fieldName="company"
            [label]="placeholderKeys['company']"
            fieldSize="small"
          >
          </shai-form-input-text>
        </div>

        <div class="form-field-group" *ngIf="getFormField('title')">
          <shai-form-input-text
            [parentForm]="form"
            formControlName="title"
            fieldName="title"
            [label]="placeholderKeys['title']"
            fieldSize="small"
          >
          </shai-form-input-text>
        </div>

        <div class="form-field-group" *ngIf="getFormField('phoneNumber')">
          <shai-form-input-text
            [parentForm]="form"
            formControlName="phoneNumber"
            fieldName="phoneNumber"
            [label]="placeholderKeys['phoneNumber']"
            fieldSize="small"
          >
          </shai-form-input-text>
        </div>

        <div class="form-field-group" *ngIf="getFormField('observations')">
          <shai-form-input-text
            type="textarea"
            maxLength="256"
            [parentForm]="form"
            formControlName="observations"
            fieldName="observations"
            [label]="placeholderKeys['observations']"
            fieldSize="small"
          >
          </shai-form-input-text>
        </div>

        <div class="checkbox-container">
          <shai-checkbox
            [data]="privacyCheckboxData"
            (onToggleCheckbox)="
              toggleCheckboxForm('privacyAgree', privacyCheckboxData)
            "
          >
          </shai-checkbox>
          <span class="paragraph">
            I have read and agree the
            <a class="paragraph link" target="_blank" [href]="privacyUrl">privacy policies</a>
            and the
            <a class="paragraph link" target="_blank" [href]="termsUrl">terms of use.</a>
          </span>
        </div>

        <div class="checkbox-container extra-bottom">
          <shai-checkbox
            [data]="commercialCheckboxData"
            (onToggleCheckbox)="
              toggleCheckboxForm('commercialAgree', commercialCheckboxData)
            "
          >
          </shai-checkbox>
          <span class="paragraph extra-padding"
            >I agree to my e-mail being stored and used to receive Sherpa.ai
            commercial communications.
          </span>
        </div>
        <mat-error *ngIf="error">
          {{ error }}
        </mat-error>
      </form>
    </div>
  `,
  styleUrls: ['./request-access-form.component.scss'],
})
export class RequestAccessFormComponent implements OnInit {
  @Input() form: FormGroup = new FormGroup({});
  @Input() placeholderKeys: { [key: string]: string } = {};
  @Input() error: string | null = null;

  privacyCheckboxData = { disabled: false, checked: false };
  commercialCheckboxData = { disabled: false, checked: false };
  privacyUrl = 'https://sherpa.ai/privacy-policy';
  termsUrl = 'https://sherpa.ai/terms-of-use';

  constructor() {}

  ngOnInit(): void {}

  getFormField(key: string): FormControl {
    return this.form?.get(key) as FormControl;
  }

  toggleCheckboxForm(key: string, item: any) {
    item.checked = !item.checked;
    const currentValue = this.getFormField(key).value;
    this.getFormField(key).setValue(!currentValue);
  }
}
