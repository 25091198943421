import { ConnectionPositionPair } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  Renderer2,
  ElementRef,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { User } from 'src/app/auth/models/auth.models';
import { ProjectActions } from 'src/app/platform';
import { ListItem } from 'src/app/shared/components/list-items/list-item.model';
import { HelperService } from 'src/app/platform/services/helper.service';
import { OrgTrainingStatus, Organization } from 'src/app/platform/models/organization';
import { AccessControlService } from 'src/app/shared/services/access-control.service';

@Component({
  selector: 'shai-nav-bar',
  template: `
    <header class="container">
      <a (click)="handleBrandClick()" class="brand-wrapper">
        <img src="assets/images/logo.png" alt="Logo" />
        <shai-beta-svg></shai-beta-svg>
      </a>
      <div class="navigation-menu">


      </div>

      <div
        class="right-menu"
        (click)="toggleFloatingMenu()"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
      >
        <a [ngClass]="_name && (_name.length > 32) ? 'truncate-1-line' : ''" title="{{ _name && (_name.length > 32) ? _name : '' }}">{{ _name }}</a>
        <span class="icon-menu" *ngIf="isFloatingActive">
          <shai-icon-arrow-up-16></shai-icon-arrow-up-16>
        </span>
        <span class="icon-menu" *ngIf="!isFloatingActive">
          <shai-icon-arrow-down-16></shai-icon-arrow-down-16>
        </span>
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="isFloatingActive"
          [cdkConnectedOverlayPositions]="positionPairs"
          [cdkConnectedOverlayHasBackdrop]="true"
          [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
          (backdropClick)="isFloatingActive = false"
        >
          <shai-floating-menu
            #floatingElement
            (handleItemClick)="onListItemClick($event)"
            [listItems]="listItems"
            width="320"
            (onChange)="this.isFloatingActive = $event"
            [active]="isFloatingActive"
          >
          </shai-floating-menu>
        </ng-template>
      </div>
    </header>

    <shai-change-password-overlay
      *ngIf="changePassOverlayActive"
      (changePassEmitter)="toggleChangePassOverlay()"
      [userName]="_userName"
      [userOrg]="_userOrg"
    ></shai-change-password-overlay>

    <shai-user-role-overlay
      *ngIf="userRoleOverlayActive"
      (changePassEmitter)="toggleUserRoleOverlay()"
      [userName]="_userName"
      [userOrg]="_userOrg"
    ></shai-user-role-overlay>

    <shai-change-org-secret-overlay
      *ngIf="changeOrgSecretOverlayActive"
      (changeOrgSecretEmitter)="toggleChangeOrgSecretOverlay()"
    ></shai-change-org-secret-overlay>

    <shai-org-projects-overlay
      *ngIf="changeProjectOverlayActive"
      [onGoingLast]="onGoingLast"
      (onClose)="closeOrgProjectsOverlay()"
      (saveOnGoingLast)="saveOnGoingLast($event)">
    </shai-org-projects-overlay>
  `,
  styleUrls: [`./navigation-bar.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationBarComponent implements OnInit, OnDestroy {
  @Input() set organization(value: Organization | null) {
    if (value) {
      this._orgName = value.config?.name;
      this._orgSuid = value.organizationSuid;
    }
  };
  @Input() set user(value: User | null) {
    if (value) {
      this._userName = value['cognito:username']
      this._name = value.preferred_username;
      this._email = value.email;
      this._userOrg = value['custom:org_suid'];
    }
  };
  @Output() onLogout = new EventEmitter();
  changePassOverlayActive: boolean = false;
  userRoleOverlayActive: boolean = false;
  changeOrgSecretOverlayActive: boolean = false;
  activeLink = '';
  _orgName: string | undefined;
  _orgSuid: string | undefined;
  _userName: string | undefined;
  _name: string | undefined;
  _userOrg: string | undefined;
  _email: string | undefined;
  isFirstTimeOverlayOpen: boolean = true;
  hasSubmitAttemps = false;
  private subscription?: Subscription;
  listItems: Array<ListItem> = [];
  isFloatingActive: boolean = false;
  positionPairs: ConnectionPositionPair[] = [
    {
      offsetX: -5,
      offsetY: 40,
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  nameNavigationOrg!: string;
  nameChangeProjectAction!: string;
  changeProjectOverlayActive: boolean = false;
  onGoingLast: OrgTrainingStatus[] = [];

  constructor(
    public router: Router,
    private store: Store,
    private render: Renderer2,
    private hostElement: ElementRef,
    public _hs: HelperService,
    private cdRef: ChangeDetectorRef,
    private _accessControlService: AccessControlService
  ) {
    this.nameNavigationOrg = 'Organization & Nodes';
    this.nameChangeProjectAction = 'Training Sessions';
  }

  ngOnInit(): void {
    this._overlayStatusSubscription();
  }

  ngAfterViewChecked(): void {
    this._getListItems();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  saveOnGoingLast(event: OrgTrainingStatus[]) {
    this.onGoingLast = event;
  }

  changeProject(): void {
    this.isFloatingActive = false;
    this.changeProjectOverlayActive = true;
    this._hs.setOpenMainMenuState(true);
  }

  closeOrgProjectsOverlay(): void {
    this.changeProjectOverlayActive = false;
  }

  private _getListItems() {
    const changeOrgAccessControl = this._accessControlService.newCheckAccessControl('org-secret');
    const manageUsersAccessControl = this._accessControlService.newCheckAccessControl('manage-users');
    this.listItems = [
      {
        'type': 'body',
        'title': this._name,
        'subtitle': this._email,
        'value': 'user',
        'iconLeft': 'icon-user-new-16',
        'unclickable': true
      },
      {
        'type': 'body',
        'title': this._orgName,
        'subtitle': this._orgSuid,
        'value': 'organization',
        'iconLeft': 'icon-model-16',
        'unclickable': true
      },
      {
        'type': 'body',
        'title': 'Manage Plans',
        'value': 'manage-platform',
        'iconLeft': 'icon-chart-16',
      },
      {
        'type': 'body',
        'title': 'User & Role Management',
        'value': 'user-rol-management',
        'iconLeft': 'icon-user-group-16',
        'disabled': manageUsersAccessControl
      },
      {
        'type': 'body',
        'title': "Change Organization's Secret",
        'value': 'change-organization-secret',
        'iconLeft': 'icon-privacy-16',
        'disabled': changeOrgAccessControl
      },
      {
        'type': 'body',
        'title': 'Change Password',
        'value': 'change-password',
        'iconLeft': 'icon-secure-16',
      },
      {
        'type': 'body',
        'title': 'Help',
        'value': 'help',
        'iconLeft': 'icon-help-16',
      },
      {
        'type': 'body',
        'title': 'Log out',
        'value': 'logout',
        'iconLeft': 'icon-logout-16',
      },
    ] as ListItem[];
  }

  helpLink() {
    const link = document.createElement('a');
    link.target = "_blank";
    link.href = 'https://docs.sherpa.ai';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  toggleChangePassOverlay() {
    if (this.changePassOverlayActive === true) {
      setTimeout(() => {
        this.changePassOverlayActive = false;
        this.cdRef.detectChanges();
      }, 700);
    }
  }

  toggleUserRoleOverlay() {
    if (this.userRoleOverlayActive === true) {
      setTimeout(() => {
        this.userRoleOverlayActive = false;
        this.cdRef.detectChanges();
      }, 700);
    }
  }

  toggleChangeOrgSecretOverlay() {
    if (this.changeOrgSecretOverlayActive === true) {
      setTimeout(() => {
        this.changeOrgSecretOverlayActive = false;
        this.cdRef.detectChanges();
      }, 700);
    }
  }

  addSmallZIndexClass() {
    this.render.removeClass(this.hostElement.nativeElement, 'bigZIndex');
    this.render.addClass(this.hostElement.nativeElement, 'smallZIndex');
  }

  addBigZIndexClass() {
    this.render.removeClass(this.hostElement.nativeElement, 'smallZIndex');
    this.render.addClass(this.hostElement.nativeElement, 'bigZIndex');
  }

  handleBrandClick() {
    this.store.dispatch(ProjectActions.clearCurrent({ route: '/' }));
  }

  onListItemClick(event: any) {
    switch (event.value) {
      case 'logout':
        this.onLogout.emit();
        break;
      case 'help':
        this.helpLink();
        break;
      case 'change-password':
        this.changePassOverlayActive = true;
        break;
      case 'user-rol-management':
        this.userRoleOverlayActive = true;
        break;
      case 'manage-platform':
        this.router.navigate(['/dashboard']);
        break;
      case 'change-organization-secret':
        this.changeOrgSecretOverlayActive = true;
        break;
      default:
          break;
    }
    this.isFloatingActive = false;
  }

  onChangePassDataComplete(value: any) {
    if (value) {
      this.hasSubmitAttemps = true;
    }
  }

  onChangeOrgSecretDataComplete(value: any) {
    if (value) {
      this.hasSubmitAttemps = true;
    }
  }

  toggleFloatingMenu() {
    this.isFloatingActive = !this.isFloatingActive;
  }

  private _overlayStatusSubscription() {
    this.subscription = this._hs.obsOverlayState$.subscribe(resp => {
      if (resp) {
        if (resp.isOpen) {
          this.isFirstTimeOverlayOpen = false;
          this.addSmallZIndexClass();
        } else {
          if (!resp.isSecondaryOverlay) {
            if (this.isFirstTimeOverlayOpen) {
              this.addBigZIndexClass();
            } else {
              setTimeout(() => {
                this.addBigZIndexClass();
              }, 700);
            }
          }
        }
      }
    });
  }
}
