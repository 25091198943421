import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-aggregator-fn-24',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DCDDE0"/>
    <path d="M6.18047 15.8H7.22447V12.14H11.0405V11.18H7.22447V8.36H11.4485V7.4H6.18047V15.8ZM13.0097 15.8H14.0177V12.416C14.0177 11.18 14.6177 10.46 15.6377 10.46C16.6097 10.46 17.0537 11.012 17.0537 11.972V15.8H18.0617V11.816C18.0617 10.412 17.2697 9.56 15.9137 9.56C14.9657 9.56 14.3417 10.004 14.0177 10.628H13.9697L13.8857 9.668H13.0097V15.8Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class AggregatorFn24 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
