import { createReducer, on } from "@ngrx/store";
import * as NodeActions from '../actions/node.actions';
import { Status, NodeList, DockerImage } from "../models";
import { createRehydrateReducer } from "./rehydrate.reducer";

export const nodeFeatureKey = 'nodes';
const nodeStorageKey = 'flp_storage_node_state';

export interface State {
  list?: NodeList | null;
  listStatus: Status;
  deleteStatus: Status,
  pingStatus: Status,
  dockerImage?: DockerImage | null,
  dockerImageUrlStatus: Status,
}

const initialState: State = {
  listStatus: {
    pending: false
  },
  deleteStatus: { pending: false },
  pingStatus: { pending: false },
  dockerImage: null,
  dockerImageUrlStatus: { pending: false }
};

export const reducer = createReducer(
  initialState,
  on(NodeActions.loadNodes, (state) => ({
      ...state,
      listStatus: {
        ...state.listStatus,
        pending: true
      }
    })
  ),

  on(NodeActions.loadNodesSuccess, (state, { list }) => ({
      ...state,
      listStatus: {
        ...state.listStatus,
        pending: false
      },
      list
    })
  ),

  on(NodeActions.loadNodesFailure, (state, { error }) => ({
    ...state,
    listStatus: {
      ...state.listStatus,
      pending: false,
      error
    }
  })),

  on(NodeActions.loadDockerImageUrl, (state) => ({
    ...state,
    dockerImageUrlStatus: { ...state.dockerImageUrlStatus, pending: true },
  })),
  on(NodeActions.loadDockerImageUrlSuccess, (state, { image }) => {
    return {
      ...state,
      dockerImageUrlStatus: { ...state.dockerImageUrlStatus, pending: false },
      dockerImage: image
    }
  }),
  on(NodeActions.loadDockerImageUrlFailure, (state, { error }) => {
    return {
      ...state,
      dockerImageUrlStatus: { ...state.dockerImageUrlStatus, pending: false, error }
    }
  }),
  on(NodeActions.clearLoadDockerImageUrl, state => ({ ...state, dockerImage: initialState.dockerImage})),

  on(NodeActions.pingNode, state => ({ ...state, pingStatus: { pending: true, error: null } })),
  on(NodeActions.pingNodeSuccess, state => ({ ...state, pingStatus: { pending: false, error: null } })),
  on(NodeActions.pingNodeFailure, (state, {error}) => ({ ...state, pingStatus: { pending: false, error }})),

  on(NodeActions.deleteNode, state => ({ 
    ...state, deleteStatus: { pending: true, error: null } 
  })),
  on(NodeActions.deleteNodeSuccess, (state, { nodeSuid }) => ({ 
    ...state, deleteStatus: { pending: false, error: null, nodeSuid } 
  })),
  on(NodeActions.deleteNodeFailure, (state, {error}) => ({ 
    ...state, deleteStatus: { pending: false, error }
  })),
);

export const getNodeList = (state: State) => state?.list;
export const getNodeArray = (state: State) => state?.list?.items;
export const isNodeListPending = (state: State) => state?.listStatus?.pending;
export const isNodeListError = (state: State) => state?.listStatus?.error;
export const getDockerImage = (state: State)  => state?.dockerImage;
export const getDockerImageError = (state: State): boolean => state.dockerImageUrlStatus?.error;
export const getDeleteNodeStatus = (state: State) => state?.deleteStatus;
export const getDeleteNodeError = (state: State) => state?.deleteStatus.error;
export const getPingNodeError = (state: State) => state?.pingStatus.error;
export const getPingNodePending = (state: State) => state?.pingStatus.error;

