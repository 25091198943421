import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-aggregator-fx-24',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DCDDE0"/>
    <path d="M6.70781 15.8H7.75181V12.14H11.5678V11.18H7.75181V8.36H11.9758V7.4H6.70781V15.8ZM12.8114 15.8H13.8794L15.5834 13.376H15.6314L17.3114 15.8H18.3674V15.68L16.2434 12.692L18.3314 9.788V9.668H17.2394L15.6314 12.032H15.5834L13.9634 9.668H12.8834V9.788L14.9594 12.716L12.8114 15.68V15.8Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class AggregatorFx24 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}

