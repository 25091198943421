import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AdDirective } from '../../directives/ad.directive';
import { AdComponent, TableColumn } from './models/table.model';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'shai-new-table',
  template: `
    <div class="table-container">
      <mat-table
        #table
        matSort
        matSortStart="desc"
        matSortDisableClear="true"
        matSortDisableClear="true"
        [dataSource]="dataSource"
        style="box-shadow: none;"
        (matSortChange)="sortData()"
      >
        <ng-container
          *ngFor="let column of columnsData"
          [matColumnDef]="column.columnDef"
        >
          <mat-header-cell
            *matHeaderCellDef
            [ngStyle]="{ width: column.width ? column.width : '' }"
            mat-sort-header
            [disabled]="column.sortingDisabled"
          >
            <div class="header-cell-title-container">
              <span class="paragraph-small">{{ column.header }}</span>
              <shai-icon-sort-arrow
                *ngIf="!column.sortingDisabled"
                class="arrow-down"
                style="padding-left: 5px;"
              >
              </shai-icon-sort-arrow>
              <shai-icon-sort-arrow
                *ngIf="!column.sortingDisabled"
                class="arrow-up"
                style="margin-left: 5px; transform: rotate(180deg);"
              >
              </shai-icon-sort-arrow>
            </div>
          </mat-header-cell>
          <mat-cell
            (click)="emitToParent(row, column)"
            #cellData
            [id]="column.columnDef"
            *matCellDef="let row; let i = index"
            [style.min-height]="'76px'"
            [ngClass]="{ disabled: row.disabled }"
          >
            <ng-template
              adHost
              [component]="column.component"
              [data]="column.cellData(row)"
              [extraCellElements]="column.extraCellElements"
            >
            </ng-template>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *ngIf="!showTableHeader; else headerRow"
        ></mat-header-row>
        <ng-template #headerRow>
          <mat-header-row
            #headerRow
            *matHeaderRowDef="displayedColumns"
          ></mat-header-row>
        </ng-template>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <span [hidden]="hideControls">
        <mat-paginator *ngIf="showPaginator" [pageSize]="pageSize" (page)="onPageChange()"></mat-paginator>
      </span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: [`./new-table.component.scss`],
})
export class NewTableComponent implements AfterViewChecked, AfterViewInit {
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort?: MatSort;

  dataSource = new MatTableDataSource<any>();
  originalData: any[] = [];
  displayedColumns: string[] = [];
  size = 0;
  pageIndex = 1;
  isActivePagination: boolean = false;
  @ViewChildren('cellData') cellData!: QueryList<any>;
  getCellData: any;
  checkboxArray: any[] = [];
  radioArray: any[] = [];
  @Input() filterValue: string = '';
  @Input() filterColumn: string = '';
  @Input() pageSize: number = 10;

  private flag = false;
  private viewHasShow = false;
  private firstLoad: boolean = true;

  @Input() set dataset(value: Array<any> | undefined) {
    if (value && value.length > 0) {
      if(this.firstLoad) {
        this.firstLoad = false;
        this.originalData = value;
      }
      this.dataSource.data = value;
      this.cdRef.detectChanges();
      this.loadComponents();
    }
  }

  @Input() set columns(value: Array<TableColumn>) {
    if (value) {
      this.columnsData = value;
      this.displayedColumns = value.map((x) => x.columnDef);
      this.cdRef.detectChanges();
      this.loadComponents();
    }
  }
  @Input() showPaginator: boolean = true;
  @Input() hideControls: boolean = false;
  showTableHeader: boolean = true;
  columnsData: Array<TableColumn> = Array<TableColumn>();
  @Output() onRowClicked = new EventEmitter();
  @Output() onDatasetChange = new EventEmitter();
  @Output() goToEntity = new EventEmitter();
  @Output() onSortClicked = new EventEmitter();
  @ViewChildren(AdDirective) matCellsContent?: QueryList<AdDirective>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cdRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
        const value = data[sortHeaderId];
        if (value instanceof Date) {
          return value.getTime();
        } else if (typeof value === 'string') {
          return value.toLowerCase();
        }
        return value;
      };
    }
    this.setupFilterPredicate();
    this.cdRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filterValue || changes.filterColumn) {
      this.applyFilter();
    }
  }

  private setupFilterPredicate() {
    this.dataSource.filterPredicate = (data, filter: string) => {
      const columnValue = data[this.filterColumn]
        ? data[this.filterColumn].toLowerCase()
        : '';
      return columnValue.includes(filter.toLowerCase());
    };
  }

  public getOriginalData(): any[] {
    return this.originalData;
  }

  public setOriginalData(newData: any[]): void {
    this.originalData = newData;
    this.dataSource.data = newData;
  }

  private applyFilter() {
    this.dataSource.data = [...this.originalData]
    if (this.filterValue && this.filterColumn) {
      this.dataSource.filter = this.filterValue.trim().toLowerCase();
    } else {
      this.dataSource.filter = '';
    }
    for(let i = 0; i < this.dataSource.filteredData.length; i++) {
      this.dataSource.filteredData[i].row = i;
    }

    this.dataSource.data = [...this.dataSource.filteredData];
    this.sortData();

    this.onDatasetChange.emit(this.dataSource.data);
    this.cdRef.detectChanges();
    this.loadComponents();
  }

  ngAfterViewChecked(): void {
    if (!this.flag) {
      this.loadComponents();
      this.flag = true;
    }
    if (!this.viewHasShow) {
      this.getCellData = this.cellData.toArray();
      this.checkboxArray = this.getCellData.filter(
        (element: any) => element.nativeElement.id === 'checkbox'
      );
      this.radioArray = this.getCellData.filter(
        (element: any) => element.nativeElement.id === 'radio'
      );
    }
  }

  onPageChange() {
    this.dataSource.data = [...this.dataSource.data];
    this.cdRef.detectChanges();
    this.loadComponents();
  }

  sortData() {
    if(this.dataSource.sort) {
      const orderedData = this.dataSource.sortData(this.dataSource.data, this.dataSource.sort)
      this.dataSource.data = orderedData;
      for(let i = 0; i < this.dataSource.data.length; i++) {
        this.dataSource.data[i].row = i;
      }
      this.onDatasetChange.emit(this.dataSource.data);
    }
    this.cdRef.detectChanges();
    this.loadComponents();
  }

  private loadComponents() {
    this.matCellsContent?.forEach((cell) => {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(cell.component);
      const viewContainerRef = cell.viewContainerRef;
      viewContainerRef.clear();

      const componentRef =
        viewContainerRef.createComponent<AdComponent>(componentFactory);
      componentRef.instance.data = cell.data;
      componentRef.instance.extraCellElements = cell.extraCellElements;
    });
    this.cdRef.detectChanges();
  }

  emitToParent(row: any, column: any) {
    const emitToParent = row.emitToParent;
    if (emitToParent === undefined || emitToParent) {
      this.goToEntity.emit(row);
    } else {
      if (
        this.checkboxArray.length > 0 &&
        column.columnDef !== 'type' &&
        column.columnDef !== 'more'
      ) {
        this.onRowClicked.emit(row);
      }
      if (this.radioArray.length > 0) {
        const radioData = { rowIndex: row.row, columnName: column.columnDef };
        this.onRowClicked.emit(radioData);
      }
    }
  }
}
