import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'shai-title-bar',
  template: `
    <div class="title-bar-wrap">
      <div class="title-bar-container" [ngClass]="{'extra-top': !allowEdit, 'allow-hover': allowEdit}" (click)="handleEdit()">
         <shai-button-icon *ngIf="allowEdit"><shai-icon-edit> </shai-icon-edit></shai-button-icon>
        <div class="text-container">
          <span class="button-text-style" *ngIf="topDescription">
            {{topDescription}}
          </span>
          <span class="title">{{ title }}</span>
          <span class="paragraph" *ngIf="bottomDescription" [innerHtml]="bottomDescription">
          </span>
          <div class="spacer" *ngIf="showExtraSpace"></div>
        </div>
      </div>
    </div>
  `,
  styleUrls: [`./title-bar.component.scss`],
})
export class TitleBarComponent implements OnInit {
  @Input() title: string | undefined | null = '';
  @Input() topDescription?: string;
  @Input() bottomDescription?: string;
  @Input() showExtraSpace?: boolean = false;
  @Input() allowEdit: boolean = false;
  @Output() handleEditClick = new EventEmitter();

  constructor() {}
  
  ngOnInit() {}

  handleEdit() {
    this.handleEditClick.emit({
      title: this.title, 
      bottomDescription: this.bottomDescription
    });
  }
}
