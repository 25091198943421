import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreackpointService {
  public subjectBreackpointState = new BehaviorSubject<string>('');
  public obsScreen$ = this.subjectBreackpointState.asObservable();

  constructor() {}

  setBreackpointState(status: any){
    this.subjectBreackpointState.next(status);
  }

}
