import { Component } from '@angular/core';
@Component({
  selector: 'shai-beta-svg',
  template: `<svg
    width="39"
    height="20"
    viewBox="0 0 39 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="39" height="20" rx="3" fill="#1D9781" />
    <path
      d="M5.044 14H8.812C10.456 14 11.488 13.112 11.488 11.576C11.488 10.424 10.888 9.836 10.216 9.548V9.5C10.756 9.212 11.176 8.696 11.176 7.784C11.176 6.404 10.144 5.6 8.692 5.6H5.044V14ZM6.436 9.044V6.848H8.512C9.328 6.848 9.748 7.244 9.748 7.892C9.748 8.66 9.256 9.044 8.428 9.044H6.436ZM6.436 12.74V10.268H8.608C9.568 10.268 10.048 10.76 10.048 11.504C10.048 12.26 9.52 12.74 8.608 12.74H6.436ZM13.1362 14H18.8002V12.74H14.5402V10.304H18.4282V9.032H14.5402V6.872H18.8002V5.6H13.1362V14ZM22.3604 14H23.7764V6.872H26.3084V5.6H19.8284V6.872H22.3604V14ZM31.0074 5.6H29.2434L26.2434 13.88V14H27.7314L28.3914 12.056H31.8234L32.4834 14H34.0194V13.88L31.0074 5.6ZM28.8114 10.82L30.0834 7.076H30.1314L31.4034 10.82H28.8114Z"
      fill="white"
    />
  </svg>`,
})
export class BetaSVGComponent {}
