import { HttpEventType } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Output,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnInit,
  OnDestroy
} from '@angular/core';
import { finalize } from 'rxjs/operators';
import {
  ConfigItem,
  ModelFile,
  ModelFileUploadStatus,
} from 'src/app/platform';
import { UploadFileService } from 'src/app/platform/services/upload-file.service';
import { SnackbarService, SnackbarType } from 'src/app/shared/components/snackbar/snackbar.service';
import { ListItem } from '../list-items/list-item.model';
import { Observable, Subscription } from 'rxjs';
import * as fromPlatform from 'src/app/platform/reducers';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
@Component({
  selector: 'shai-new-model-upload',
  template: `
    <div class="upload-wrapper elevated-table">
      <div class="uploaded-files-list">
        <ul>
          <li class="li-drag">
            <div
              shaiDragDropFileUpload
              [fileCount]="totalFilesCount"
              (click)="this.fileField.click()"
              (fileDropped)="prepareBufferFiles($event)"
              class="upload-content"
            >
              <shai-icon-document-32></shai-icon-document-32>
              <span class="paragraph">Upload or drop file here</span>
              <div class="help">
                <span class="paragraph-small">Supports:</span>
                <span class="badge paragraph-small">.py</span>
              </div>
              <input
                type="file"
                name="fileField"
                accept=".py"
                #fileField
                hidden
                multiple
              />
              <input
                type="file"
                name="fileField"
                accept=".py"
                #fileField
                hidden
                multiple
              />
            </div>
          </li>
          <li
            class="li-file-item"
            *ngFor="let file of bufferFiles; let i = index"
          >
            <shai-icon-document-24></shai-icon-document-24>
            <div class="item-text-container">
              <span class="paragraph">{{ file.file?.name }}</span>
              <span
                [ngClass]="{
                  'error-status': file.status === uploadStatus.Failed
                }"
                class="paragraph-small"
              >
                {{ getFileUploadStatusMessage(file).text }}
              </span>
               <span *ngIf="checkFileExtension(file.file?.name)" class="caption error-status">This file type is not supported.</span>
            </div>
            <div class="item-actions-container">
              <div class="actions-right-container">
                <shai-dropdown
                  [metadata]="{ index: i }"
                  (onListItemChange)="updateAlgorithm($event)"
                  [data]="{
                    title: 'Select model type... ',
                    listItems: algorithmsListItems
                  }"
                >
                </shai-dropdown>
                <shai-icon-trash-16 class="delete-icon" (click)="deleteFile(i)">
                </shai-icon-trash-16>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  `,
  styleUrls: ['./new-model-upload.component.scss'],
})
export class NewModelUploadComponent implements OnInit, OnDestroy {
  @Output() fileCountChange = new EventEmitter();
  @Output() uploadCompleted = new EventEmitter();
  @Input() hasSubmitAttempts: boolean = false;
  @Input() algorithms: any[] = [];
  @ViewChild('fileField', { static: false }) fileField!: ElementRef;
  @Output() validFileSelectionOnChange = new EventEmitter();

  @Input() projectId!: string;
  @Input() orgId!: string;
  @Input() set resetCount(value: Number) {
    if (value) {
      this.uploadFiles();
    }
  }

  algorithmsListItems: ListItem[] = [];
  bufferFiles: ModelFile[] = [];
  private plan?: any;
  private plan$: Observable<any> = new Observable();
  private planSubscription?: Subscription;

  constructor(
    private store: Store,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private uploadFileService: UploadFileService,
    private _snackbarService: SnackbarService,
    public authService: AuthService,
  ) {
    this.plan$ = this.store.pipe(select(fromPlatform.getPlan)) as Observable<any>;
  }

  ngOnInit() {
    this._setPlanSubscription();
  }

  ngOnDestroy() {
    this.planSubscription?.unsubscribe();
  }

  private _setPlanSubscription() {
    this.planSubscription = this.plan$.subscribe((plan) => {
      if(plan) {
        this.plan = plan;
        this.generateAlgoritmsListItems();
      }
    })
  }

  checkFileExtension(fileName: string | undefined): boolean {
    if (fileName) {
      const extension = fileName.split('.').pop();
      if (extension === 'py') {
        return false;
      }
    }
    return true;
  }

  public get uploadStatus(): typeof ModelFileUploadStatus {
    return ModelFileUploadStatus;
  }

  public get totalFilesCount(): number {
    return this.bufferFiles.length;
  }

  private uploadFiles(): void {
    if(this.bufferFiles.length === 0) {
      this.uploadCompleted.emit();
    }
    for(let i = 0; i < this.bufferFiles.length; i++){
      if(this.bufferFiles[i].status === 'Ready') {
        this.uploadBuffer([this.bufferFiles[i]]);
      }
    }
    this.checkBufferFiles();
  }

  checkFiles(files: any[]) {
    for (let file of files) {
      if(file.name.includes(' ')) {
        this._snackbarService.show(
          'Filenames with spaces are not supported',
          SnackbarType.simple
        );
        return false;
      }
    }
    return true;
  }

  public prepareBufferFiles(incomingFiles: any[]): void {
    if(!this.checkFiles(incomingFiles)) return
    for (let i = 0; i < incomingFiles.length; i++) {
      let incomingFile = incomingFiles[i];
      let selectedFile: ModelFile = {
        file: incomingFile,
        status: ModelFileUploadStatus.Ready,
        error: null,
        progress: null,
        model: 'undefined',
      };

      this.bufferFiles.push(selectedFile);
    }
    this.fileField.nativeElement.value = '';
    this.fileCountChange.emit(this.bufferFiles.length);
    this.checkValidFileSelection();
  }

  private checkValidFileSelection(): void {
    for (let i = 0; i < this.bufferFiles.length; i++) {
      let file = this.bufferFiles[i];
      if (!file.model || file.model === 'undefined') {
        this.validFileSelectionOnChange.emit(false);
        return;
      }
    }

    this.validFileSelectionOnChange.emit(true);
  }

  private updateProgress(bufferFiles:ModelFile[], value: number): void {
    bufferFiles.forEach((file) => {
      file.progress = value;
    });
  }

  private updateUploadStatus(bufferFiles:ModelFile[], status: ModelFileUploadStatus): void {
    for(let i = 0; i < bufferFiles.length; i++){
      bufferFiles[i].status = status;
    }
  }

  private checkUploadingFinished(): void {
    if(this.bufferFiles.length === 0) {
      this.uploadCompleted.emit();
    }

    for(let i = 0; i < this.bufferFiles.length; i++){
      if(this.bufferFiles.every(el => el.status === 'Failed' || el.status === 'Completed')) {
        this.uploadCompleted.emit();
        break;
      }
    }
  }

  private checkBufferFiles(): void {
    this.checkUploadingFinished();
    this.fileCountChange.emit(this.bufferFiles.length);
  }

  private updateErrorMessage(bufferFiles:ModelFile[], error: string): void {
    bufferFiles.forEach((file) => {
      file.error = error;
    });
  }

  private uploadBuffer(bufferFiles: ModelFile[]): void {
    let filesForUpload = bufferFiles.map((element) => element.file!);
    let model = bufferFiles[0].model ? bufferFiles[0].model : 'undefined';

    const bufferSub = this.uploadFileService
      .uploadFiles(this.orgId,this.projectId, model, filesForUpload)
      .pipe(
        finalize(() => {
          bufferSub?.unsubscribe();
          this.cdRef.detectChanges();
        })
      )
      .subscribe(
        (event) => {
          if (event.type == HttpEventType.UploadProgress && event.total) {
            const uploadProgress = Math.round(
              100 * (event.loaded / event.total)
            );
            this.updateProgress(bufferFiles, uploadProgress);
          } else if (event.type == HttpEventType.Response) {
            if (event.body) {
              this.updateUploadStatus(bufferFiles, ModelFileUploadStatus.Completed);
              this.checkBufferFiles();
            }
          } else if (event.type == HttpEventType.Sent) {
            this.updateUploadStatus(bufferFiles, ModelFileUploadStatus.Started);
          }
        },
        (error) => {
          if (error) {
            console.log('[Upload_File] ERROR ', error);
            this.updateUploadStatus(bufferFiles, ModelFileUploadStatus.Failed);
            if (error.status === 410) {
              if(error?.error?.message) {
                this.updateErrorMessage(bufferFiles, error?.error?.message);
                this.authService.upgradePlan("You reached your upload model limit", "to increase this limit");
              }
              else {
                this.updateErrorMessage(bufferFiles, 'resource conflict');
              }
            } else if (error.status === 409) {
              if(error?.error?.message) {
                this.updateErrorMessage(bufferFiles, error?.error?.message);
                this._snackbarService.show(
                  'The file names must be unique.',
                  SnackbarType.simple
                );
              }
              else {
                this.updateErrorMessage(bufferFiles, 'resource conflict');
              }
            }
          }
          this.checkBufferFiles();
        }
      );
  }

  private generateAlgoritmsListItems() {
    const availableModels = this.plan?.availableModels;

    if(availableModels) {
      this.algorithmsListItems = this.algorithms
      .filter(algorithm => {
        return !(!availableModels.includes(algorithm.id));
      })
      .map((algorithm) => {
        return {
          type: 'body',
          title: algorithm.name,
          value: algorithm.id,
          code: algorithm.id,
          optGroup: algorithm.optGroup,
          optItem: algorithm.optItem,
        };
      });
    }
  }

  public deleteFile(index: number): void {
    this.bufferFiles.splice(index, 1);
    this.cdRef.detectChanges();
    this.fileCountChange.emit(this.bufferFiles.length);
    this.checkValidFileSelection();
  }

  public updateAlgorithm(event: any): void {
    this.bufferFiles[event.metadata.index].model = event.value;
    this.checkValidFileSelection();
  }

  public getFileUploadStatusMessage(model: ModelFile) {
    switch (model.status) {
      case ModelFileUploadStatus.Ready:
        return { text: '', button: 'btnReady' };
      case ModelFileUploadStatus.Completed:
        return { text: `${model.file?.size} kb`, button: 'btnCompleted' };
      case ModelFileUploadStatus.Failed:
        return {
          text: `${model.error ?? ''}`,
          button: 'try again',
        };
      case ModelFileUploadStatus.Requested:
        return { text: 'requested', button: 'btnRequested' };
      case ModelFileUploadStatus.Started:
        return {
          text: `${model.file?.size} kb, Uploading (${model.progress}%)`,
          button: 'Cancel',
        };
    }
  }

  openFileWindow(): void {
    this.fileField?.nativeElement.click();
  }
}
