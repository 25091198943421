import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-aggregator-fa-24',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DCDDE0"/>
    <path d="M6.40313 15.8H7.44713V12.14H11.2631V11.18H7.44713V8.36H11.6711V7.4H6.40313V15.8ZM14.9469 15.908C15.8109 15.908 16.4949 15.56 16.8669 14.936H16.9149L16.9869 15.8H17.8389V11.672C17.8389 10.508 17.0109 9.56 15.4389 9.56C14.0949 9.56 13.1109 10.34 13.1109 11.516H14.0829C14.0949 10.928 14.5389 10.424 15.4389 10.424C16.4349 10.424 16.8549 11.012 16.8549 11.696V12.308C16.4709 12.2 15.9909 12.092 15.3429 12.092C13.5429 12.092 12.8109 12.98 12.8109 14.012C12.8109 15.08 13.6149 15.908 14.9469 15.908ZM15.1269 15.068C14.2389 15.068 13.8309 14.612 13.8309 14C13.8309 13.28 14.3469 12.896 15.3429 12.896C15.8829 12.896 16.3509 12.956 16.8549 13.112V13.424C16.8549 14.312 16.1709 15.068 15.1269 15.068Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class AggregatorFa24 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}