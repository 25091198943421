import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'shai-not-found',
  template: `
    <shai-error-template
      title="Under maintainance"
      description="The page you’re looking for is under maintainance and will be back soon."
      errorCode="503"
      imageName="404"
    >
    </shai-error-template>
  `,
  styleUrls: ['./global-error-page.scss'],
})
export class UnderMaintainanceComponent implements OnInit {
  ngOnInit(): void {}
}
