import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-privacy-16',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.75 8.29933C9.19835 8.03997 9.5 7.55521 9.5 7C9.5 6.17157 8.82843 5.5 8 5.5C7.17157 5.5 6.5 6.17157 6.5 7C6.5 7.55521 6.80165 8.03997 7.25 8.29933V10.75H8.75V8.29933Z" fill="#1F1F1F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 3V7.75851C14 10.4663 12.6302 12.9903 10.3599 14.4661L8 16L5.6401 14.4661C3.36975 12.9903 2 10.4663 2 7.75851V3L3.13821 2.7439C4.42156 2.45515 5.33333 1.31543 5.33333 0H10.6667C10.6667 1.31543 11.5784 2.45515 12.8618 2.7439L14 3ZM9.79972 1H6.20028C5.83708 2.33693 4.76135 3.4037 3.35772 3.71951L3 3.8V7.75851C3 10.1278 4.19853 12.3364 6.18508 13.6276L8 14.8073L9.81492 13.6276C11.8015 12.3364 13 10.1278 13 7.75851V3.8L12.6423 3.71951C11.2386 3.4037 10.1629 2.33693 9.79972 1Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class IconPrivacy16 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}


