import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-aggregator-id3-24',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DCDDE0"/>
    <path d="M4.32305 15.8H5.37905V7.4H4.32305V15.8ZM9.78408 15.908C10.6961 15.908 11.3081 15.488 11.6321 14.948H11.6801L11.8121 15.8H12.6881V6.92H11.6801V10.52H11.6321C11.3081 9.98 10.6961 9.56 9.78408 9.56C8.05608 9.56 7.19208 10.916 7.18008 12.728C7.19208 14.708 8.17608 15.908 9.78408 15.908ZM9.95208 15.008C8.68008 15.008 8.20008 13.964 8.20008 12.728C8.20008 11.504 8.68008 10.46 9.95208 10.46C11.1761 10.46 11.7041 11.516 11.7041 12.728C11.7041 13.952 11.1761 15.008 9.95208 15.008ZM17.2585 15.908C19.0465 15.908 20.0425 14.828 20.0425 13.436C20.0425 12.428 19.5025 11.66 18.5185 11.348V11.3C19.3585 10.976 19.7785 10.4 19.7785 9.464C19.7785 8.108 18.6985 7.256 17.2585 7.256C15.6265 7.256 14.6665 8.144 14.5945 9.62H15.6145C15.6505 8.756 16.1545 8.156 17.2225 8.156C18.2665 8.156 18.7225 8.78 18.7225 9.536C18.7225 10.4 18.0625 10.976 17.1025 10.976H16.3945V11.876H17.1265C18.3025 11.876 18.9865 12.44 18.9865 13.448C18.9865 14.384 18.3625 15.008 17.2465 15.008C16.1065 15.008 15.5065 14.336 15.4945 13.472H14.4865C14.4865 14.936 15.5785 15.908 17.2585 15.908Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class AggregatorId324 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
