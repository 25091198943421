import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-edit',
  template: `<svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2225_10841)">
      <path
        d="M2.30959 13.7609L2.82367 10.5397L12.5625 0.800857L15.2696 3.50797L5.53078 13.2468L2.30959 13.7609Z"
        stroke="#1F1F1F"
      />
      <line
        x1="10.3536"
        y1="2.64645"
        x2="13.3536"
        y2="5.64645"
        stroke="#1F1F1F"
      />
    </g>
    <defs>
      <clipPath id="clip0_2225_10841">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>`,
})
export class IconEdit {
  @HostBinding('class.shai-icon') get iconClass() {
    return true;
  }
}
