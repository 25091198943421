export enum ModelFileUploadStatus {
  Ready = 'Ready',
  Requested = 'Requested',
  Started = 'Started',
  Failed = 'Failed',
  Completed = 'Completed',
}
export interface ModelFile {
  status: ModelFileUploadStatus;
  error: string | null;
  progress: number | null;
  file?: File;
  info?: ModelFileInfo;
  url?: string;
  uuid?: string;
  uploadSub?: any;
  model?: string | null;
}

export interface ModelFileUploaded {
  uuid: string;
  path: string;
  filename: string;
  size: number;
  model: string;
  timestamp: string;
}

export interface ModelFileInfo {
  name: string;
  size: string;
  stamp: string;
}


export interface FileList {
  pageSize?: number;
  page?: number;
  items?: ItemFile[];
}

export interface FileName {
  filename: string;
}

export interface ItemFile {
  uuid: string;
  path: string;
  filename: string;
  size: number;
  model: string;
  timestamp: string;
  alg: string;
  hash: string;
  protected: boolean;
}

export interface DockerImage {
  url?: string;
  description?: string;
  imageSuid?: string;
  stamp?: any;
}
