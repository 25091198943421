import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-aggregator-fs-24',
  template: `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DCDDE0"/>
<path d="M6.66094 15.8H7.70494V12.14H11.5209V11.18H7.70494V8.36H11.9289V7.4H6.66094V15.8ZM15.5182 15.908C16.8382 15.908 17.9422 15.26 17.9422 14.132C17.9422 13.076 17.3542 12.62 16.2022 12.356L15.2422 12.176C14.5702 12.068 14.1742 11.792 14.1742 11.312C14.1742 10.712 14.6902 10.412 15.4342 10.412C16.2022 10.412 16.7782 10.76 16.8142 11.54H17.7982C17.7982 10.316 16.8142 9.56 15.4342 9.56C14.0782 9.56 13.2022 10.292 13.2022 11.312C13.2022 12.212 13.7542 12.824 14.7622 13.028L15.9862 13.256C16.6462 13.4 16.9222 13.724 16.9222 14.132C16.9222 14.72 16.3822 15.008 15.5182 15.008C14.6422 15.008 14.0182 14.588 14.0182 13.772H13.0102C13.0462 15.116 14.0662 15.908 15.5182 15.908Z" fill="#1F1F1F"/>
</svg>
  `
})
export class AggregatorFs24 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
