import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map, filter } from 'rxjs/operators';
import { LogList } from '../models';
import * as fromPlatform from '../../platform/reducers';
import { environment } from 'src/environments/environment';

const headers = new HttpHeaders({'Content-Type':'application/json'});
@Injectable({
  providedIn: 'root'
})
export class LogService {
  baseUrl: string = environment.baseUrl;
  FRIENDLY = 1;
  LOGLEVEL = 10;
  apiUrl: string = 'http://localhost:3000';

  constructor(
    private http: HttpClient,
  ) { }

  /**
   *
   * Request to create the training and set the max iterations
   *
   * @param orgId
   * @returns {Observable<any>}
   */
  loadLogsList(org: string | undefined, nodes: any): Observable<any> { // TO DO - include org condition
    return this.http.get<LogList>(`${this.apiUrl}/logs`).pipe(
      map(logs => {
        logs.items = logs.items.sort((a, b) => new Date (b.timestamp_from).getTime() - new Date (a.timestamp_from).getTime());
        if (nodes) {
          logs.items.forEach(function(log, index, arr) {
            let found = false;
            nodes.items.forEach((node: any) => {
              if (!found) {
                if (node.name && log.nod == node.uuid) {
                  log.nod_name = node.name;
                  found = true;
                } else {
                  arr[index].nod_name = log.nod.charAt(0).toUpperCase() + log.nod.slice(1).toLowerCase();
                }
              }
            })
          });
        }
        return logs;
      })
    );
  }

  getAllLogs(
    org: any,
    project: any,
    timestamp: any
  ): Observable<any | never> {
    const params = new HttpParams()
      .set('page', '0')
      .set('pageSize', '100')
      .set('pagination', false);

    return this.http.get(
      `${this.baseUrl}orgs/${org}/projects/${project}/sessions/${timestamp}/logs`,
      { headers, params }
    )
    .pipe(
      map((list: any) => {
        if (list && list.items) {
          list.items.filter((item: any) => item.friendly === this.FRIENDLY && item.logLevel >= this.LOGLEVEL);
        }
        return list;
      }),
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }
}
