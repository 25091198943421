import { createReducer, on } from "@ngrx/store";
import { Status, Training, Infrastructure, TrainingList, TrainingStatus, TrainingResponse, DifferentialPrivacyData } from "../models";
import { TrainingActions } from "../actions";
import { TrainingParadigm, TrainingModel } from "../models/training-session.model";

export const trainingFeatureKey = 'trainings';
const trainingStorageKey = 'flp_storage_training_state';

export interface State {
  current: Training | null;
  currentStatus: Status;
  newTraining?: TrainingResponse | null,
  paradigm?: TrainingParadigm;
  paradigmStatus: Status,
  model?: TrainingModel;
  modelStatus: Status,
  entity?: TrainingParadigm;
  entityUpdated: boolean;
  entityStatus: Status,
  aggregator?: TrainingParadigm,
  aggregatorStatus: Status,
  differentialPrivacy?: DifferentialPrivacyData,
  differentialPrivacyStatus: Status,
  training?: Training,
  trainingStatus?: TrainingStatus,
  status: Status,
  list?: TrainingList,
  listStatus: Status,
  deployInfrastructureStatus: Status,
  infrastructure?: Infrastructure,
  loadInfrastructureStatus: Status,
  linkedNodesStatus: 'waiting' | 'linking' | 'linked' | 'not-nodes',
  trainingTabPending: boolean,
  loaderInfo: string,
  iterationsStatus: Status,
  deleteStatus: Status
}

const initialState: State = {
  current: null,
  currentStatus: { pending: false },
  newTraining: null,
  list: { page: 0, pageSize: 0, totalItemCount: 0, items: [] },
  listStatus: { pending: false },
  paradigmStatus: { pending: false },
  entityStatus: { pending: false },
  entityUpdated: false,
  modelStatus: { pending: false },
  aggregatorStatus: { pending: false },
  differentialPrivacyStatus: { pending: false },
  linkedNodesStatus: 'waiting',
  status: { pending: false },
  deployInfrastructureStatus: { pending: false },
  loadInfrastructureStatus: { pending: false},
  trainingTabPending: false,
  loaderInfo: '',
  iterationsStatus: { pending: false},
  deleteStatus: { pending: false }
}

export const reducer = createReducer(
  initialState,

  // Get all trainings
  on(TrainingActions.getAllTrainings, (state) => ({ ...state, listStatus: {...state.listStatus, pending: true}, trainingTabPending: true})),
  on(TrainingActions.getAllTrainingsSuccess, (state, {list}) => ({...state, list ,listStatus: {...state.listStatus, pending: false}})),
  on(TrainingActions.getAllTrainingsFailure, (state, error) => ({ ...state, listStatus: {...state.listStatus, pending: false, error}})),
  on(TrainingActions.clearTrainings, state => ({ ...state, list: initialState.list })),

  on(TrainingActions.deleteTrainings, state => ({ ...state, deleteStatus: { pending: true, error: null } })),
  on(TrainingActions.deleteTrainingsSuccess, state => ({ ...state, deleteStatus: { pending: false, error: null } })),
  on(TrainingActions.deleteTrainingsFailure, (state, {error}) => ({ ...state, deleteStatus: { pending: false, error }})),
  on(TrainingActions.clearDeleteTrainings, state => ({ ...state, deleteStatus: initialState.deleteStatus })),

  on(TrainingActions.loadTraining, (state, { projectId, trainingSessionTimestamp }) => ({
    ...state,
    currentStatus: {
      ...state.currentStatus,
      pending: true
    }
  })),

  on(TrainingActions.loadTrainingSuccess, (state, { training }) => ({
    ...state,
    current: training,
    currentStatus: {
      ...state.currentStatus,
      pending: false
    }
  })),

  on(TrainingActions.loadTrainingStatus, (state) => {
    if(state.training) return {...state, training: {...state.training, statusPending: true}, loaderInfo: 'Getting  training status'}
    return state;
  }),
  on(TrainingActions.loadTrainingStatusSuccess, (state, { status }) => {
    if(state.training) {
      return {...state, training: {...state.training, statusPending: false, status}, loaderInfo: ''}
    }
    return state;
  }),
  on(TrainingActions.loadTrainingStatusFailure, (state) => {
    if(state.training) {
      return {...state, training: {...state.training, statusPending: false}}
    }
    return state;
  }),
  on(TrainingActions.loadTrainingStatusRetry, (state, { attemp }) => ({...state, loaderInfo: `Retrying to get the training status (${attemp - 1}/2)`})),


  on(TrainingActions.clearTraining, _ => initialState),
);

export const getAllTrainings = (state: State) => state?.list;
export const getTraining = (state: State) => state.training;
export const getCurrentTraining = (state: State) => state.current;
export const getCurrentTrainingId = (state: State) => state.current?.trainingSuid;
export const isTrainingTabPending = (state: State) => state.trainingTabPending;

export const getTrainingParadigmId = (state: State) => state?.paradigm?.id;
export const getTrainingParadigmError = (state: State) => state?.paradigmStatus?.error;
export const getTrainingParadigmPending = (state: State) => state?.paradigmStatus?.pending;
export const getEntityResolutionError = (state: State) => state?.entityStatus?.error;
export const getEntityResolutionPending = (state: State) => state?.entityStatus?.pending;

export const getAggregatorError = (state: State) => state?.aggregatorStatus?.error;
export const getAggregatorPending = (state: State) => state?.aggregatorStatus?.pending;
export const getDifferentialPrivacyError = (state: State) => state?.differentialPrivacyStatus?.error;
export const getDifferentialPrivacyPending = (state: State) => state?.differentialPrivacyStatus?.pending;

export const getIsEntityResolutionCompleted = (state: State) => state?.entityUpdated;

export const getDifferentialPrivacyResponse = (state: State) => state?.differentialPrivacy;
export const getAggregatorResponse = (state: State) => state?.aggregator;
export const getModelResponse = (state: State) => state?.model;
export const getParadigmResponse = (state: State) => state?.paradigm;

export const getDeleteTrainingStatus = (state: State) => state?.deleteStatus;
