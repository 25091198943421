import { Validators } from '@angular/forms';
import { RequestAccessMode } from '../request-access/request-access.model';

export interface IRequestAccessForm {
  getFormObject(): { [key: string]: any };
  getFormPlaceholders(): { [key: string]: string };
  getErrorMessages(): { [key: string]: any };
}

class RequestAccessFormExpImpl implements IRequestAccessForm {
  getFormObject(): { [key: string]: any } {
    return {
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern('^([A-Za-z0-9]+[._-])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+$')]],
      repeatEmail: ['', [Validators.required, Validators.pattern('^([A-Za-z0-9]+[._-])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+$')]],
      phoneNumber: [undefined, []],
      privacyAgree: [false, [Validators.requiredTrue]],
      commercialAgree: [false, []],
    };
  }

  getFormPlaceholders(): { [key: string]: string } {
    return {
      name: "Name*",
      email: "Your email*",
      repeatEmail: "Repeat your email*",
      phoneNumber: "Phone number (optional)",
    }
  }

  getErrorMessages(): { [key: string]: any } {
    return {
      name: [{type: "required", message: "Name is required"}],
      email: [{type: "required", message: "Your email is required"}, {type: "pattern", message: "The format of this email is incorrect"}],
      repeatEmail: [{type: "required", message: "You must repeat your email"}, {type: "pattern", message: "The format of this email is incorrect"}],
      privacyAgree: [{type: "required", message: "You must agree to our privacy policy and terms of use before requesting early access."}]
    }
  }
}

class RequestAccessFormEntImpl implements IRequestAccessForm {
  getFormObject(): { [key: string]: any } {
    return {
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern('^([A-Za-z0-9]+[._-])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+$')]],
      repeatEmail: ['', [Validators.required, Validators.pattern('^([A-Za-z0-9]+[._-])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+$')]],
      company: ['', [Validators.required]],
      title: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      observations: ['', [Validators.required]],
      privacyAgree: [false, [Validators.requiredTrue]],
      commercialAgree: [false, []],
    };
  }

  getFormPlaceholders(): { [key: string]: string } {
    return {
      name: "Name*",
      email: "Corporate email*",
      repeatEmail: "Repeat your email*",
      company: "Company*",
      title: "Position*",
      phoneNumber: "Phone number*",
      observations: "Tell us about your use case for Sherpa.ai Privacy-Preserving platform*",
    }
  }

  getErrorMessages(): { [key: string]: any } {
    return {
      name: [{type: "required", message: "Name is required"}],
      email: [{type: "required", message: "Corporate email is required"}, {type: "pattern", message: "The format of this email is incorrect"}],
      repeatEmail: [{type: "required", message: "You must repeat your email"}, {type: "pattern", message: "The format of this email is incorrect"}],
      company: [{type: "required", message: "Company name is required"}],
      title: [{type: "required", message: "Title is required"}],
      phoneNumber: [{type: "required", message: "Phone number is required"}],
      observations: [{type: "required", message: "You must tell us about your use case and what would you like to use Sherpa.ai for"}],
      privacyAgree: [{type: "required", message: "You must agree to our privacy policy and terms of use before requesting early access."}],
    }
  }
}

export function createRequestAccessFormDatasource(mode: RequestAccessMode) {
  switch (mode) {
    case RequestAccessMode.Experimental:
      return new RequestAccessFormExpImpl();
    default:
      return new RequestAccessFormEntImpl();
  }
}
