import { Component, EventEmitter, OnInit, Output, OnDestroy, ViewChild, AfterViewInit, ViewContainerRef, ComponentFactoryResolver, Input, Renderer2 } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Training, TrainingSession } from 'src/app/platform';
import { Router } from '@angular/router';
import { hasClass } from 'src/app/utils';
import * as fromPlatform from 'src/app/platform/reducers';
import { ListItem } from 'src/app/shared/components/list-items/list-item.model';
import { combineLatest, Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { capitalizeFirstLetter } from 'src/app/utils/dom-utils';
import { getAggregatorTitle, getEntityResolutionTitle, getModelTitle, getParadigmTitle } from 'src/app/utils/file-utils';
import { IconModel24 } from 'src/app/shared/icons/24/icon-model';
import { IconFederatedHorizontal24 } from 'src/app/shared/icons/24/icon-federated-horizontal';
import { IconFederatedVertical24 } from 'src/app/shared/icons/24/icon-federated-vertical';
import { AggregatorFa24 } from '../../../shared/icons/aggregators/24/aggregator-fa-24';
import { AggregatorW24 } from 'src/app/shared/icons/aggregators/24/aggregator-w-24';
import { AggregatorFc24 } from 'src/app/shared/icons/aggregators/24/aggregator-fc-24';
import { AggregatorC24 } from 'src/app/shared/icons/aggregators/24/aggregator-c-24';
import { AggregatorFs24 } from 'src/app/shared/icons/aggregators/24/aggregator-fs-24';
import { AggregatorIo24 } from 'src/app/shared/icons/aggregators/24/aggregator-io-24';
import { AggregatorN24 } from 'src/app/shared/icons/aggregators/24/aggregator-n-24';
import { AggregatorB24 } from 'src/app/shared/icons/aggregators/24/aggregator-b-24';
import { AggregatorMk24 } from 'src/app/shared/icons/aggregators/24/aggregator-mk-24';
import { AggregatorRm24 } from 'src/app/shared/icons/aggregators/24/aggregator-rm-24';
import { AggregatorRt24 } from 'src/app/shared/icons/aggregators/24/aggregator-rt-24';
import { AggregatorRl24 } from 'src/app/shared/icons/aggregators/24/aggregator-rl-24';
import { AggregatorFn24 } from 'src/app/shared/icons/aggregators/24/aggregator-fn-24';
import { AggregatorP24 } from 'src/app/shared/icons/aggregators/24/aggregator-p-24';
import { AggregatorFx24 } from 'src/app/shared/icons/aggregators/24/aggregator-fx-24';
import { AggregatorFg24 } from 'src/app/shared/icons/aggregators/24/aggregator-fg-24';
import { AggregatorSv24 } from 'src/app/shared/icons/aggregators/24/aggregator-sv-24';
import { AggregatorId324 } from 'src/app/shared/icons/aggregators/24/aggregator-id3-24';
import { AggregatorRf24 } from 'src/app/shared/icons/aggregators/24/aggregator-rf-24';
import { IconPrivacy24 } from '../../../shared/icons/24/icon-privacy';
import { TableColumn } from 'src/app/shared/components/table/models/table.model';
import { TableCellDoubleComponent } from 'src/app/shared/components/table/rows/cells/table-cell-double.component';
import { TableCellSingleComponent } from 'src/app/shared/components/table/rows/cells/table-cell-single.component';
import { TrainingSessionService } from 'src/app/platform/services/training-session.service';
import { fromAuth } from 'src/app/auth/state';
import { first } from 'rxjs/operators';
import { SnackbarService, SnackbarType } from 'src/app/shared/components/snackbar/snackbar.service';
import { AggregatorBi24 } from 'src/app/shared/icons/aggregators/24/aggregator-bi-24';

interface NodeListItem {
  row: number;
  checked: boolean;
  name: string | undefined;
  fileset: string;
  role: string;
  privacy: string;
  emitToParent: boolean;
}

@Component({
  selector: 'shai-configuration-training-overlay',
  template: `
    <shai-overlay
      [active]="isActive"
      [fullWidth]="false"
      [hasHeader]="true"
      heading=""
      (onChange)="onChangeOverlay($event)"
      (onLeave)="onLeaveOverlay()"
    >
      <mat-grid-list cols="12" rowHeight="fit" id="rounds-download-overlay-wrapper">
        <mat-grid-tile colspan="12">
          <shai-title-bar title="Configuration"> </shai-title-bar>
          <div *ngIf="hasTraining" class="section-conf">
            <shai-table-header [headerTabs]="['Training']">
            </shai-table-header>
            <shai-tip tip type="oneLine" text="Training configuration is locked upon session configuration."></shai-tip>
            <div class="table-container dropdown-table">
              <div class="mat-table">
                <div class="mat-row">
                  <div class="left-column">
                    <shai-table-cell-double [data]="dataCellDouble(_training, 'name')"></shai-table-cell-double>
                  </div>
                </div>
                <div class="mat-row">
                  <div class="left-column">
                    <shai-table-cell-double [data]="dataCellDouble(_training, 'description')"></shai-table-cell-double>
                  </div>
                </div>
                <div class="mat-row" *ngIf="dataCellDouble(_training, 'paradigm')?.text">
                  <div class="left-column">
                    <div class="icon">
                      <ng-container #paradigmIcon></ng-container>
                    </div>
                    <shai-table-cell-double [data]="dataCellDouble(_training, 'paradigm')"></shai-table-cell-double>
                  </div>
                </div>
                <div class="mat-row" *ngIf="dataCellDouble(_training, 'model')?.text">
                  <div class="left-column">
                    <div class="icon">
                      <ng-container #modelIcon></ng-container>
                    </div>
                    <shai-table-cell-double [data]="dataCellDouble(_training, 'model')"></shai-table-cell-double>
                  </div>
                  <div class="right-column paragraph-small">

                  </div>
                </div>
                <div class="mat-row" *ngIf="dataCellDouble(_training, 'aggregator')?.text">
                  <div class="left-column">
                    <div class="icon">
                      <ng-container #aggregatorIcon></ng-container>
                    </div>
                    <shai-table-cell-double [data]="dataCellDouble(_training, 'aggregator')"></shai-table-cell-double>
                  </div>
                </div>
                <div class="mat-row" *ngIf="dataCellDouble(_training, 'differential')?.text">
                  <div class="left-column">
                    <div class="icon">
                      <ng-container #differentialIcon></ng-container>
                    </div>
                    <shai-table-cell-double [data]="dataCellDouble(_training, 'differential')"></shai-table-cell-double>
                    <!-- <shai-table-cell-single [data]="dataCellSingle('Download the model after round:')"></shai-table-cell-single> -->
                  </div>
                  <div *ngIf="showMechanismData" class="right-column paragraph-small">
                    Differential Privacy Settings
                  </div>
                </div>

                <div *ngIf="hasTraining && hasSession && loadNodeDataCompleted">
                    <div class="mat-row" *ngIf="dataCellDouble(_training, 'notes', session)?.text">
                      <div class="left-column">
                        <shai-table-cell-double [data]="dataCellDouble(_training, 'notes', session)"></shai-table-cell-double>
                      </div>
                    </div>
                    <div class="mat-row" *ngIf="dataCellDouble(_training, 'entityResolution')?.text">
                      <div class="left-column">
                        <shai-table-cell-double [data]="dataCellDouble(_training, 'entityResolution')"></shai-table-cell-double>
                      </div>
                      <div class="right-column paragraph-small">
                        View order matching
                      </div>
                    </div>
                    <div *ngIf="session.stopConditions && session.stopConditions.length > 0">
                      <div class="mat-row" *ngFor="let stopCondition of session.stopConditions">
                        <div class="left-column">
                          <shai-table-cell-double [data]="dataCellDouble(_training, 'stopCondition', stopCondition)"></shai-table-cell-double>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="hasTraining && hasSession && loadNodeDataCompleted">
              <div class="table-container" *ngIf="nodeListDataset.length > 0">
                <shai-table-header [headerTabs]="['Participating Nodes']">
                </shai-table-header>
                <shai-table
                  [dataset]="nodeListDataset[0]"
                  [columns]="columns"
                  [showPaginator]="false"
                  [showTableHeader]="true"
                  [showViewAll]="true"
                  [itemsPerPageBlocks]="[5, 10, 15, 20]"
                ></shai-table>
              </div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </shai-overlay>
  `,
  styleUrls: ['./configuration-training-overlay.component.scss'],
})
export class ConfigurationTrainingOverlayComponent implements OnInit, AfterViewInit, OnDestroy {
  currentTrainingSession$ = this.store.pipe(select(fromPlatform.getCurrentTrainingSession));
  isActive: boolean = true;
  subscription!: Subscription;
  _training!: Training;
  session!: any;

  @Input() trainingStatus: string | undefined;
  @Output() onClose = new EventEmitter();
  listItems: ListItem[] = [];
  showMechanismData: boolean = false;
  columns: TableColumn[] = [];
  dataset: any[] = [];
  nodeListDataset: any[] = [];
  orgSuid!: string;
  projectSuid!: string;
  disabledDeleteSession: boolean = false;
  disabledEditTraining: boolean = false;
  disabledEditSession: boolean = false;
  hasTraining: boolean = false;
  hasSession: boolean = false;
  loadNodeDataCompleted: boolean = false;
  @ViewChild('modelIcon', { read: ViewContainerRef}) modelIcon: ViewContainerRef | undefined;
  @ViewChild('paradigmIcon', { read: ViewContainerRef}) paradigmIcon: ViewContainerRef | undefined;
  @ViewChild('aggregatorIcon', { read: ViewContainerRef}) aggregatorIcon: ViewContainerRef | undefined;
  @ViewChild('differentialIcon', { read: ViewContainerRef}) differentialIcon: ViewContainerRef | undefined;
  modelIconName?: 'icon-model-24' = 'icon-model-24';
  differentialIconName?: 'icon-privacy-24' = 'icon-privacy-24';
  aggregatorIconName?: 'aggregator-fa-24' | 'aggregator-w-24' | 'aggregator-fc-24' | 'aggregator-c-24' | 'aggregator-fs-24' |
  'aggregator-io-24' | 'aggregator-n-24' | 'aggregator-b-24' | 'aggregator-mk-24' | 'aggregator-rm-24' | 'aggregator-rt-24' |
  'aggregator-rl-24' | 'aggregator-fn-24' | 'aggregator-p-24' | 'aggregator-fx-24' | 'aggregator-fg-24' | 'aggregator-sv-24' |
  'aggregator-id3-24' | 'aggregator-rf-24' | 'aggregator-bi-24';
  paradigmIconName?: 'icon-federated-horizontal-24' | 'icon-federated-vertical-24';
  modals = { // ... and here
    'icon-federated-horizontal-24': IconFederatedHorizontal24,
    'icon-federated-vertical-24': IconFederatedVertical24,
    'icon-model-24': IconModel24,
    'icon-privacy-24': IconPrivacy24,
    'aggregator-fa-24': AggregatorFa24,
    'aggregator-w-24': AggregatorW24,
    'aggregator-fc-24': AggregatorFc24,
    'aggregator-c-24': AggregatorC24,
    'aggregator-fs-24': AggregatorFs24,
    'aggregator-io-24': AggregatorIo24,
    'aggregator-n-24': AggregatorN24,
    'aggregator-b-24': AggregatorB24,
    'aggregator-mk-24': AggregatorMk24,
    'aggregator-rm-24': AggregatorRm24,
    'aggregator-rt-24': AggregatorRt24,
    'aggregator-rl-24': AggregatorRl24,
    'aggregator-fn-24': AggregatorFn24,
    'aggregator-p-24': AggregatorP24,
    'aggregator-fx-24': AggregatorFx24,
    'aggregator-fg-24': AggregatorFg24,
    'aggregator-sv-24': AggregatorSv24,
    'aggregator-id3-24': AggregatorId324,
    'aggregator-rf-24': AggregatorRf24,
    'aggregator-bi-24': AggregatorBi24
  };

  constructor(
    public viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private store: Store,
    private _trainingSessionService: TrainingSessionService,
    private _snackbarService: SnackbarService,
    private router: Router,
    private render: Renderer2
  ) {}

  ngOnInit(): void {
    this._checkTrainingAndSessions();
    this._generateColumns();
    this.disabledDeleteSession = this.isDisabledDeleteSession();
    this.disabledEditTraining = this.isDisabledEditTraining();
    this.disabledEditSession = this.isDisabledEditSession();
  }

  ngAfterViewInit(): void {
    this.showModelIcon();
    this.showAggregatorIcon();
    this.showParadigmIcon();
    this.showDifferentialIcon();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private isDisabledDeleteSession(): boolean {
    let response = false;
    if (this.trainingStatus === 'DEPLOYING' || this.trainingStatus === 'IN_USE') {
      response = true;
    }
    return response;
  }

  private isDisabledEditTraining(): boolean {
    let response = false;
    if (this.trainingStatus === 'NOT_DEPLOYED' || this.trainingStatus === 'DEPLOYING' || this.trainingStatus === 'IN_USE' || this.trainingStatus === 'READY' || this.trainingStatus === 'DONE') {
      response = true;
    }
    return response;
  }

  private isDisabledEditSession(): boolean {
    let response = false;
    if (this.trainingStatus === 'DEPLOYING' || this.trainingStatus === 'IN_USE' || this.trainingStatus === 'READY' || this.trainingStatus === 'DONE') {
      response = true;
    }
    return response;
  }

  private _generateColumns() {
    this.columns = [
      {
        columnDef: 'name',
        header: 'Name',
        sortingDisabled: false,
        width: '25%',
        component: TableCellDoubleComponent,
        cellData: (element: any) => {
          return {
            text: element.name,
          };
        },
      },
      {
        columnDef: 'fileset',
        header: 'Model',
        width: '25%',
        sortingDisabled: false,
        component: TableCellSingleComponent,
        cellData: (element: any) => {
          return {
            text: element.fileset,
          };
        },
      },
      {
        columnDef: 'role',
        header: 'Role',
        width: '25%',
        sortingDisabled: false,
        component: TableCellSingleComponent,
        cellData: (element: any) => {
          return {
            text: element.role,
          };
        },
      },
      {
        columnDef: 'privacy',
        header: 'Privacy',
        width: '25%',
        sortingDisabled: false,
        component: TableCellSingleComponent,
        cellData: (element: any) => {
          return {
            text: element.privacy,
          };
        },
      },
    ];
  }

  showModelIcon() {
    if (this.modelIconName && this.modelIcon) {
      this.modelIcon.clear();
      let component = this.modals[this.modelIconName];
      const cFactory = this.componentFactoryResolver.resolveComponentFactory(component);
      const componentRef = this.modelIcon.createComponent(cFactory);
      componentRef.changeDetectorRef.detectChanges();
    }
  }

  showParadigmIcon() {
    if (this.paradigmIconName && this.paradigmIcon) {
      this.paradigmIcon.clear();
      let component = this.modals[this.paradigmIconName];
      const cFactory = this.componentFactoryResolver.resolveComponentFactory(component);
      const componentRef = this.paradigmIcon.createComponent(cFactory);
      componentRef.changeDetectorRef.detectChanges();
    }
  }

  showAggregatorIcon() {
    if (this.aggregatorIconName && this.aggregatorIcon) {
      this.aggregatorIcon.clear();
      let component = this.modals[this.aggregatorIconName];
      const cFactory = this.componentFactoryResolver.resolveComponentFactory(component);
      const componentRef = this.aggregatorIcon.createComponent(cFactory);
      componentRef.changeDetectorRef.detectChanges();
    }
  }

  showDifferentialIcon() {
    if (this.differentialIconName && this.differentialIcon) {
      this.differentialIcon.clear();
      let component = this.modals[this.differentialIconName];
      const cFactory = this.componentFactoryResolver.resolveComponentFactory(component);
      const componentRef = this.differentialIcon.createComponent(cFactory);
      componentRef.changeDetectorRef.detectChanges();
    }
  }

  dataCellDouble(training: any, type: string, session?: any) {
    let result;

    switch (type) {
      case 'name':
        return {
          label: 'Name',
          text: training.config.name ? training.config.name : '',
        };
      case 'description':
        return {
          label: 'Description',
          text: training.config.description ? training.config.description : '',
        };
      case 'paradigm':
        return {
          label: 'Training Method',
          text: training.paradigm ? getParadigmTitle(training.paradigm.id) : undefined,
        };
      case 'model':
        return {
          label: 'Model Type',
          text: training.model ? getModelTitle(training.model.id) : undefined,
        };
      case 'aggregator':
        return {
          label: 'Aggregator',
          text: training.aggregation ? getAggregatorTitle(training.aggregation.id) : undefined,
        };
      case 'differential':
        return {
          label: 'Differential Privacy',
          text: training.differentialPrivacy ? this.getDifferentialPrivacy(training.differentialPrivacy.mechanism.type) : undefined,
        };
      case 'notes':
        return {
          label: 'Notes',
          text: session.notes ? session.notes : undefined,
        };
      case 'batchSize':
        return {
          label: training.model ? getModelTitle(training.model.id) : undefined,
          text: 'Batch Size',
        };
      case 'entityResolution':
        return {
          label: 'Entity Resolution',
          text: training.entityResolution ? getEntityResolutionTitle(training.entityResolution.orderPreservation) : undefined,
        };
      case 'stopCondition':
        return {
          label: 'Stopping Condition',
          text: session.condition ? session.condition + ' is ' + session.value : undefined,
        };
      default:
        return {
          label: undefined,
          text: undefined,
        };
    }
    return result;
  }


  getAggregatorIconName(aggregator: string): 'aggregator-fa-24' | 'aggregator-w-24' | 'aggregator-fc-24' | 'aggregator-c-24' | 'aggregator-fs-24' |
  'aggregator-io-24' | 'aggregator-n-24' | 'aggregator-b-24' | 'aggregator-mk-24' | 'aggregator-rm-24' | 'aggregator-rt-24' |
  'aggregator-rl-24' | 'aggregator-fn-24' | 'aggregator-p-24' | 'aggregator-fx-24' | 'aggregator-fg-24' | 'aggregator-sv-24' |
  'aggregator-id3-24' | 'aggregator-rf-24' | 'aggregator-bi-24' | undefined {
    switch (aggregator) {
      case 'fedavg':
        return 'aggregator-fa-24';
      case 'defaultweightedfedavg':
        return 'aggregator-w-24';
      case 'fedconcat':
        return 'aggregator-fc-24';
      case 'clusterfedavg':
        return 'aggregator-c-24';
      case 'fedsum':
        return 'aggregator-fs-24';
      case 'ddaba':
        return 'aggregator-io-24';
      case 'normclip':
        return 'aggregator-n-24';
      case 'bulyan':
        return 'aggregator-b-24';
      case 'multikrum':
        return 'aggregator-mk-24';
      case 'robustfedmedian':
        return 'aggregator-rm-24';
      case 'robustfedtrimmedmean':
        return 'aggregator-rt-24';
      case 'robustlearningrate':
        return 'aggregator-rl-24';
      case 'fednova':
        return 'aggregator-fn-24';
      case 'pfedme':
        return 'aggregator-p-24';
      case 'fedextratress':
        return 'aggregator-fx-24';
      case 'fedgradboostdt':
        return 'aggregator-fg-24';
      case 'svmaverage':
        return 'aggregator-sv-24';
      case 'fedid3':
        return 'aggregator-id3-24';
      case 'rfout':
        return 'aggregator-rf-24';
      case 'biasfedavg':
        return 'aggregator-bi-24';
      default:
        return undefined;
    }
  }

  getParadigmIconName(paradigm: string): 'icon-federated-horizontal-24' | 'icon-federated-vertical-24' | undefined {
    switch (paradigm) {
      case 'hfl':
        return 'icon-federated-horizontal-24';
      case 'vfl':
        return 'icon-federated-vertical-24';
      case 'bvfl':
        return 'icon-federated-vertical-24';
      default:
        return undefined;
    }
  }

  getDifferentialPrivacy(mechanism: string) {
    if (mechanism !== 'none') {
      this.showMechanismData = true;
    }
    if (mechanism === 'none') {
      mechanism = 'standard';
    }
    return capitalizeFirstLetter(mechanism);
  }

  dataCellSingle(element: any) {
    return { text: element };
  }

  onChangeOverlay(event: boolean) {
    if (!event) {
      setTimeout(() => {
        this.onClose.emit(false);
      }, 700);
    }
  }

  private async _checkTrainingAndSessions() {

    combineLatest([
      this.store.pipe(select(fromAuth.getOrg)),
      this.store.pipe(select(fromPlatform.getCurrentProjectId)),
    ])
      .pipe(first())
      .subscribe(([org, project]) => {
      if (org && project) {
       this.orgSuid = org;
       this.projectSuid = project;
      }
    });

    this.subscription = combineLatest([this.currentTrainingSession$]).subscribe(async ([session]) => {

      if (session) {
        this._training = session.training;
        this.hasTraining = true;
        this.aggregatorIconName = session.training.aggregation ? this.getAggregatorIconName(session.training.aggregation.id) : undefined;
        this.paradigmIconName = session.training.paradigm ? this.getParadigmIconName(session.training.paradigm.id) : undefined;
        this.session = session;
        this.hasSession = true;
        this.nodeListDataset = [];
        let tempNodeList: NodeListItem[] = [];

        if (session.mappings && session.mappings.length > 0) {

          const historyNodes = await this._trainingSessionService
            .getNodeHistoryList(this.orgSuid, this.projectSuid, session.trainingSessionTimestamp).toPromise();

          for (let i = 0; i < session.mappings.length; i++) {
            const element = session.mappings[i];
            let nameValue: string | undefined = element.nodeSuid;

            if (historyNodes && historyNodes.length > 0) {
              const historyNode = historyNodes.find((node) => node.suid === element.nodeSuid);
              if (historyNode?.config.name) {
                nameValue = historyNode?.config.name;
              }
            }

            tempNodeList.push({
              row: i,
              checked: false,
              name: nameValue,
              fileset: element.file?.filename ? element.file?.filename : '',
              role: capitalizeFirstLetter(element.role),
              privacy: this.getDifferentialPrivacy(this._training.differentialPrivacy.mechanism?.type ?? ''),
              emitToParent: false,
            });
          }

          this.nodeListDataset.push(tempNodeList);
          tempNodeList = [];
        } else {
          this.nodeListDataset.push([]);
        }
        this.loadNodeDataCompleted = true;
      }
    });
  }

  onLeaveOverlay() {
    this.isActive = false;
  }

  deleteSession(session: TrainingSession): void {
    if (session) {
      this._snackbarService.show(
        'Delete the session "'+ session.trainingSessionTimestamp +'"?',
        SnackbarType.twoButtonsDestructive,
        'This cannot be undone and the session will be lost.',
        false,
        'YES, DELETE IT',
        'CANCEL',
        () => this.deleteSessionConfirm(session.trainingSuid, session.trainingSessionTimestamp),
      );
    }
  }

  async deleteSessionConfirm(trainingSuid: string, trainingSessionTimestamp: string): Promise<void> {
    await this._trainingSessionService.unlinkNodesFromTrainingSession(this.orgSuid, this.projectSuid, trainingSuid, trainingSessionTimestamp).toPromise();
    await this._trainingSessionService.deleteTrainingSession(this.orgSuid, this.projectSuid, trainingSuid, trainingSessionTimestamp).toPromise();
    this.isActive = false;
    if (hasClass(document.body, 'no-scroll')) {
      this.render.removeClass(document.body, 'no-scroll');
    }
    this.router.navigate(['/projects'+ '/' + this.projectSuid]);
  }

}
