import { createAction, props, union } from '@ngrx/store';
import { RequestAccessParams } from '../../components/request-access/request-access.model';
import { AuthResponse, ChangePassword, User } from '../../models/auth.models';

export const login = createAction('[Auth] Login', props<{ credentials: AuthResponse }>());
export const loginSuccess = createAction('[Auth] Login success', props<{ loginResponse: AuthResponse, credentials: AuthResponse }>());
export const loginError = createAction('[Auth] Login error', props<{ error: { message: string} }>());

export const changePassword = createAction('[Auth] Change password', props<{ changePassword: ChangePassword }>());
export const changePasswordSuccess = createAction('[Auth]  Change password success', props<{ loginResponse: AuthResponse }>());
export const changePasswordError = createAction('[Auth]  Change password error', props<{ error: { message: string} }>());

export const verifyMFA = createAction('[Auth] Verify MFA', props<{ verifyMFA: AuthResponse }>());
export const verifyMFASuccess = createAction('[Auth] Verify MFA success', props<{ verifyMFAResponse: AuthResponse }>());
export const verifyMFAError = createAction('[Auth] Verify MFA error', props<{ error: { message: string} }>());

export const signInMFA = createAction('[Auth] Sign In MFA', props<{ signInMFA: AuthResponse }>());
export const signInMFASuccess = createAction('[Auth] Sign In MFA success', props<{ authResponse: AuthResponse, user: User | null }>());
export const signInMFAError = createAction('[Auth] Sign In MFA error', props<{ error: { message: string} }>());

export const resetMFA = createAction('[Auth] Reset MFA', props<{ resetMFA: AuthResponse }>());
export const resetMFASuccess = createAction('[Auth] Reset MFA success', props<{ resetMFAResponse: AuthResponse }>());
export const resetMFAError = createAction('[Auth] Reset MFA error', props<{ error: { message: string} }>());

export const resetPassword = createAction('[Auth] Reset Password Credentials', props<{ resetPassCredentials: AuthResponse }>());
export const resetPasswordSuccess = createAction('[Auth] Reset Password Credentials success', props<{ resetPassCredentials: AuthResponse, resetPasswordCompleted: boolean }>());
export const resetPasswordError = createAction('[Auth] Reset Password Credentials error', props<{ error: { message: string} }>());

export const requestAccess = createAction('[Auth] Request access', props<{ params: RequestAccessParams }>());
export const requestAccessSuccess = createAction('[Auth] Request access success');
export const requestAccessError = createAction('[Auth] Request access error', props<{ message: string} >());
export const clearRequestAccessData = createAction('[Auth] Clear request access data');

export const clearReset = createAction('[Auth] Clear Reset');

export const autologin = createAction('[Auth] Autologin');
export const autologinSuccess = createAction('[Auth] Autologin success', props<{ user: User | null }>());
export const autologinError = createAction('[Auth] Autologin error', props<{ error: { message: string} }>());

export const logout = createAction('[Auth] Logout');
export const autologout = createAction('[Auth] Autologout');

export const timeOut = createAction('[Auth] Start user inactivity timer');
export const extendUserSession = createAction('[Auth] Start timer to extend user session');


const actionsUnion = union({
  login,
  loginSuccess,
  loginError,
  autologin,
  autologinSuccess,
  autologinError,
  logout,
  autologout,
  timeOut,
  extendUserSession
})

export type AuthActionsUnion = typeof actionsUnion
