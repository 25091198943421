import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import * as fromAuth from '../state/selectors/auth.selectors';
import { select, Store } from '@ngrx/store';
import { first, map, take, withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResetMFAGuard implements CanActivate {

  constructor(private store: Store, private router: Router) { }

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.pipe(
      select(fromAuth.isLoginPending),
      first(pending => !pending),
      withLatestFrom(
        this.store.pipe(select(fromAuth.isLoggedIn)),
        this.store.pipe(select(fromAuth.getLoginResponse)),
        this.store.pipe(select(fromAuth.getResetMFAResponse))
      ),
      map(([, isLoggedIn, loginResponse, resetResponse]) =>{
        if (!isLoggedIn) {
          if(loginResponse && resetResponse && (resetResponse.authFlow === "RESET_MFA_STAGED")) {
            return true;
          }
        }
        return this.router.parseUrl('/');
      }),
      take(1)
    );
  }
}
