import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  NodeOriginData,
  RocData,
  RocMetrics,
} from 'src/app/training/components/charts/models/chart.model';
import { environment } from 'src/environments/environment';
import { NodeMetrics, RocMetricsUrl, TrainingSessionRound } from '../models';

const headers = new HttpHeaders({'Content-Type':'application/json'});

@Injectable({
  providedIn: 'root',
})
export class MetricsService {
  baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) {}

  getNodeMetrics(
    org: any,
    project: any,
    session: any,
    node: string
  ): Observable<NodeMetrics[]> {
    const params = new HttpParams()
      .set('page', '0')
      .set('pageSize', '100')
      .set('pagination', false);

    return this.http.get<{ pageSize: number; page: number; items: NodeMetrics[] }>(
      `${this.baseUrl}orgs/${org}/projects/${project}/sessions/${session}/nodes/${node}/metrics`,
      { headers, params }
    )
    .pipe(
      map((res) => {
        res.items = res.items.sort((a, b) => a.round - b.round); // sort ASC
        return res.items;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getAllNodesMetrics(
    org: any,
    project: any,
    session: any,
    nodes: NodeOriginData[]
  ): Observable<NodeMetrics[][]> {
    const calls = nodes.map((node) => {
      return this.getNodeMetrics(org, project, session, node.uuid);
    });
    return combineLatest(calls);
  }

  getAggregatorMetrics(
    org: any,
    project: any,
    training: any,
    session: any
  ): Observable<TrainingSessionRound[]> {
    const params = new HttpParams().set('pagination', false);

    return this.http.get<{ pageSize: number; page: number; items: TrainingSessionRound[] }>(
      `${this.baseUrl}orgs/${org}/projects/${project}/trainings/${training}/sessions/${session}/rounds`,
      { headers, params }
    )
    .pipe(
      map((res) => res.items),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getRocMetricsUrl(
    org: any,
    project: any,
    session: any,
    node: string,
    round: number
  ): Observable<RocMetricsUrl> {
    return this.http.get<RocMetricsUrl>(
      `${this.baseUrl}orgs/${org}/projects/${project}/sessions/${session}/nodes/${node}/rounds/${round}/roc?base64=true`,
      { headers }
    )
    .pipe(
      map((res) => res),
      catchError((error) => {
        console.log("[getRocMetricsUrl] ERROR")
        return throwError(error);
      })
    );
  }

  getRocMetricsValuesFromUrl(data: RocMetricsUrl): Observable<RocData[]> {
    let rocData: RocData[] = [];
    const headers = new HttpHeaders().set("InterceptorSkipHeader", '');
    headers.append('Content-type', 'text')
    return this.http.get(
      data.url,
      { headers, responseType: 'text' }
    ).pipe(
      map((result: any) => {
        const values = JSON.parse(atob(result)) as any[];
        if (values) {
           values.forEach((value, index) => {
            const data: RocData = {
              className: `${index}`,
              x: value[0] ? value[0] : [],
              y: value[1] ? value[1] : [],
            }
            rocData.push(data)
          })
        }
        return rocData;
      }),
      catchError((error) => {
        console.log("[getRocMetricsValuesFromUrl] ERROR ", error)
        return throwError(error);
      })
    );
  }

  getRocMetric(
    org: any,
    project: any,
    session: any,
    node: string,
    round: number
  ): Observable<RocMetrics> {
    return this.getRocMetricsUrl(
      org,
      project,
      session,
      node,
      round
    ).pipe(
      switchMap((data: RocMetricsUrl) => {
        return this.getRocMetricsValuesFromUrl(data);
      }),
      map((rocData: RocData[]) => {
        const rocMetrics: RocMetrics = {
          nodeSuid: node,
          round: round,
          rocData,
        };
        return rocMetrics;
      })
    );
  }

  getRocMetrics(
    org: any,
    project: any,
    training: any,
    session: any,
    nodes: NodeOriginData[],
    round: number
  ): Observable<RocMetrics[]> {
    const calls = nodes.map((node) => {
      return this.getRocMetric(
        org,
        project,
        session,
        node.uuid,
        round
      );
    });
    return combineLatest(calls);
  }
}
