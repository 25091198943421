import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-aggregator-n-24',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DCDDE0"/>
    <path d="M8.74102 15.8H9.76102V9.152H9.80902L14.105 15.8H15.257V7.4H14.225V14.084H14.177L9.88102 7.4H8.74102V15.8Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class AggregatorN24 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
