import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'secondsFormatter' })
export class SecondsFormatterPipe implements PipeTransform {
  transform(value: number): string {
    if(value < 0) return '';

    const totalMinutes = Math.floor(value / 60);
    const secs = value % 60;
    const hours = Math.floor(totalMinutes / 60);
    const mins = totalMinutes % 60;

    if (hours) {
      return `${hours}h ${mins}m ${secs}s`
    } else {
      if (mins) {
        return `${mins}m ${secs}s`
      } else {
        return `${secs}s`;
      }
    }
  }
}
