import { Injectable, Inject } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { configMock } from '../mocks/global-config'

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(

  ) { }

  public loadConfig() {
  
    return of(configMock).pipe(
      catchError(res => {
        return throwError(res?.error?.message)
      })
    );
  }

}