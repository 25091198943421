import { backInOut, slideInOutRight } from './../../animations/index';
import { Component, OnInit, ViewChild, ViewContainerRef, AfterViewInit, ComponentFactoryResolver, AfterViewChecked } from '@angular/core';
import { Subscription } from 'rxjs';
import { Snackbar, SnackbarService, SnackbarType } from './snackbar.service';
import { DomSanitizer } from '@angular/platform-browser';
import { IconFederatedVertical24 } from '../../icons/24/icon-federated-vertical';
import { IconFederatedHorizontal24 } from '../../icons/24/icon-federated-horizontal';

@Component({
  selector: 'shai-snackbar',
  template: `
  <div *ngIf="show">
    <div [@backInOut]="animationStatus" class="snackbar-wrap">
      <div class="snackbar-content">
        <button *ngIf="!data.hideCloseButton" (click)="close()" class="close-icon">
          <shai-icon-close></shai-icon-close>
        </button>
        <div *ngIf="data.icon" class="icon-container">
          <ng-container #leftIcon></ng-container>
        </div>
        <span class="paragraph-small-bold"> {{ data.title }} </span>
        <span *ngIf="data.description" class="caption" [innerHtml]="data.description">
        </span>
        <div [ngSwitch]="data.type" class="snackbar-actions">
          <shai-button
            *ngSwitchCase="snackbarType.oneButton"
            buttonStyle="secondary"
            (click)="primaryButtonHandler()"
          >
            {{ data.primaryButtonText }}
          </shai-button>
          <div *ngSwitchCase="snackbarType.twoButtons" class="snackbar-buttons-group">
            <shai-button buttonStyle="primary" (click)="cancelButtonHandler()">
              {{ data.cancelButtonText }}
            </shai-button>
            <div class="spacer"></div>
            <shai-button buttonStyle="secondary" (click)="primaryButtonHandler()">
              {{ data.primaryButtonText }}
            </shai-button>
          </div>
          <div *ngSwitchCase="snackbarType.twoButtonsInverted" class="snackbar-buttons-group">
            <shai-button buttonStyle="secondary" (click)="cancelButtonHandler()">
              {{ data.cancelButtonText }}
            </shai-button>
            <div class="spacer"></div>
            <shai-button buttonStyle="primary" (click)="primaryButtonHandler()">
              {{ data.primaryButtonText }}
            </shai-button>
          </div>
          <div *ngSwitchCase="snackbarType.twoButtonsDestructive"  class="snackbar-buttons-group">
          <shai-button buttonStyle="secondary" (click)="cancelButtonHandler()">
              {{ data.cancelButtonText }}
          </shai-button>
          <div class="spacer"></div>
          <shai-button buttonStyle="danger" (click)="primaryButtonHandler()">
              {{ data.primaryButtonText }}
          </shai-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  `,
  styleUrls: [`./snackbar.component.scss`],
  animations: [slideInOutRight, backInOut]
})
export class SnackbarComponent implements OnInit, AfterViewChecked  {
  @ViewChild('leftIcon', { read: ViewContainerRef}) leftIcon: ViewContainerRef | undefined;
  show = false;
  animationStatus = false;
  data: Snackbar = {title: '', type: SnackbarType.simple};
  modals = {
    'icon-federated-vertical-24': IconFederatedVertical24,
    'icon-federated-horizontal-24': IconFederatedHorizontal24
  }
  private destroyTime = 3000;
  private snackbarSubscription?: Subscription;

  constructor(
    private snackbarService: SnackbarService,
    public sanitizer: DomSanitizer,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  ngOnInit() {
    this.snackbarSubscription = this.snackbarService.snackbarState?.subscribe(
      (snackbarData) => {
        if (!snackbarData) {
          this.animationStatus = false;
          setTimeout(() => {
            this.show = false;
          }, 500);
          return;
        }

        this.data = snackbarData;
        setTimeout(() => {
          this.animationStatus = true;
        }, 500);
        this.show = true;
        if (snackbarData?.autoDestroy) {
          setTimeout(() => {
            this.animationStatus = false;
            setTimeout(() => {
              this.show = false;
            }, 500);
          }, this.destroyTime);
        }
      }
    );
  }

  ngAfterViewChecked(): void {
    if (this.data.icon && this.leftIcon) {
      this.setIcon();
    }
  }

  setIcon() {
    if (this.data.icon && this.leftIcon) {
      this.leftIcon.clear();
      let component = this.modals[this.data.icon];
      const cFactory = this.componentFactoryResolver.resolveComponentFactory(component);
      const componentRef = this.leftIcon.createComponent(cFactory);
      componentRef.changeDetectorRef.detectChanges();
    }
  }

  public get snackbarType(): typeof SnackbarType {
    return SnackbarType;
  }

  ngOnDestroy() {
    this.snackbarSubscription?.unsubscribe();
  }

  primaryButtonHandler() {
    if (this.data.primaryButtonHandler) {
      this.data.primaryButtonHandler();
    }
    this.close();
  }

  cancelButtonHandler() {
    if (this.data.cancelButtonHandler) {
      this.data.cancelButtonHandler();
    }
    this.close();
  }

  close() {
    this.animationStatus = false;
    setTimeout(() => {
      this.show = false;
    }, 700);
  }
}
