import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { InferenceService } from '../../inferences/services/inference.service';
import { catchError, map, mergeMap, switchMap, toArray, withLatestFrom } from 'rxjs/operators';
import { InferenceActions, NewInferenceActions, InferenceFiles, InferencesList } from '..';
import { fromAuth } from 'src/app/auth/state';
import * as fromPlatform from '../../platform/reducers';
import { EMPTY, forkJoin, from, of } from 'rxjs';

@Injectable()
export class InferenceEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private _inferenceService: InferenceService
  ) {}

  saveFilesForInference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InferenceActions.saveFilesForInference),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSession))
      ),
      switchMap(([{ node, round, paradigmId }, orgId, projectId, session]) => {

        if (orgId && projectId) {
          return this._inferenceService
            .saveFilesForInference(
              orgId,
              projectId,
              session?.trainingSessionTimestamp,
              round,
              node,
              paradigmId
            )
            .pipe(
              map(() => {
                return InferenceActions.saveFilesForInferenceSuccess();
              }),
              catchError((error) => [
                InferenceActions.saveFilesForInferenceFailure({ error }),
              ])
            );
        }
        return EMPTY;
      })
    )
  );

  getAllInferences$ = createEffect(() => this.actions$.pipe(
    ofType(InferenceActions.getAllInferences),
    withLatestFrom(
      this.store.pipe(select(fromAuth.getOrg)),
      this.store.pipe(select(fromPlatform.getCurrentProjectId))
    ),
    switchMap(([_, orgId, projectId]) => {

        if (orgId && projectId) {
            return this._inferenceService.getAllInferences(orgId, projectId).pipe(
                switchMap((list: InferencesList) => {
                    return [
                      InferenceActions.getAllInferencesSuccess({ list }),
                    ]
                }),
                catchError(error => {
                  return [
                    InferenceActions.getAllInferencesFailure({ error }),
                  ]
                })
            )
        }

        return EMPTY;

    })
  ));

  getSavedModelsForInference$ = createEffect(() => this.actions$.pipe(
    ofType(InferenceActions.getSavedModelsForInference),
    withLatestFrom(
      this.store.pipe(select(fromAuth.getOrg)),
      this.store.pipe(select(fromPlatform.getCurrentProjectId))
    ),
    switchMap(([{ paradigmId }, orgId, projectId]) => {

        if (orgId && projectId) {
            return this._inferenceService.getSavedFilesByParadigmForInference(orgId, projectId, paradigmId).pipe(
                switchMap((savedModelsList: InferenceFiles) => {
                    return [
                      InferenceActions.getSavedModelsForInferenceSuccess({ savedModelsList }),
                    ]
                }),
                catchError(error => {
                  return [
                    InferenceActions.getSavedModelsForInferenceFailure({ error }),
                  ]
                })
            )
        }

        return EMPTY;

    })
  ));

  deleteInferences$ = createEffect(() => this.actions$.pipe(
    ofType(InferenceActions.deleteInferences),
    withLatestFrom(
      this.store.pipe(select(fromAuth.getOrg))
    ),
    switchMap(([{ inferences }, orgId]) => {
      return this._inferenceService.deleteInferences(orgId, inferences)
      .pipe(
        switchMap(() => [InferenceActions.deleteInferencesSuccess()]),
        catchError(error => [InferenceActions.deleteInferencesFailure({error})])
      )
    })
  ));

  createNewInference$ = createEffect(() => this.actions$.pipe(
    ofType(NewInferenceActions.createNewInference),
    withLatestFrom(
      this.store.pipe(select(fromAuth.getOrg)),
      this.store.pipe(select(fromPlatform.getCurrentProjectId)),
      this.store.pipe(select(fromPlatform.getNewInferenceSavedFiles))
    ),
    switchMap(([{ name, description }, orgId, projectId, savedFiles]) => {
      return this._inferenceService.createInference(
        orgId as string,
        projectId as string,
        name,
        description,
        savedFiles).pipe(
          switchMap((inference) => {
            return [NewInferenceActions.createNewInferenceSuccess({ inference })]
          }),
          catchError(error => [NewInferenceActions.createNewInferenceFailure({error})])
        )
    })
  ))

  createNewInferenceSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(NewInferenceActions.createNewInferenceSuccess),
    withLatestFrom(
      this.store.pipe(select(fromAuth.getOrg)),
      this.store.pipe(select(fromPlatform.getCurrentProjectId)),
    ),
    withLatestFrom(
      this.store.pipe(select(fromPlatform.getInferenceParticipatingNodes))
    ),
    switchMap(([[{inference}], participatingNodes]) => {
      if(inference.inferenceSuid) {
        return [
           NewInferenceActions.chooseInferenceParticipatingNodes({inferenceId: inference.inferenceSuid, participatingNodes}),
         ];
      }
      return EMPTY;
    }),
  ))

  chooseInferenceParticipatingNodes$ = createEffect(() =>
  this.actions$.pipe(
    ofType(NewInferenceActions.chooseInferenceParticipatingNodes),
    withLatestFrom(
      this.store.pipe(select(fromAuth.getOrg)),
      this.store.pipe(select(fromPlatform.getCurrentProjectId))
    ),
    switchMap(
      ([{ inferenceId, participatingNodes }, orgId, projectId]) =>
        from(participatingNodes).pipe(
          mergeMap((node: any) =>
            this._inferenceService
              .linkNodeInference(orgId as string, projectId as string, inferenceId, node.nodeSuid)
              .pipe(map(() => ''))
          ),
          toArray(),
          map((participatingNodes: string[]) =>
            NewInferenceActions.chooseInferenceParticipatingNodesSuccess({
              inferenceId,
              participatingNodes,
            })
          ),
          catchError((error: any) =>
            of(
              NewInferenceActions.chooseInferenceParticipatingNodesFailure({
                error,
              })
            )
          )
        )
    )
  )
  );

  chooseInferenceParticipatingNodesSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(NewInferenceActions.chooseInferenceParticipatingNodesSuccess),
    switchMap((action) => {
      let { inferenceId } = action;
      return this.store.pipe(
        select(fromPlatform.getInferenceMappings),
        map((mappings) =>
          NewInferenceActions.chooseInferenceMappings({ inferenceId, mappings })
        )
      );
    })
  ));



  chooseInferenceMappings$ = createEffect(() =>
  this.actions$.pipe(
    ofType(NewInferenceActions.chooseInferenceMappings),
    withLatestFrom(
      this.store.pipe(select(fromAuth.getOrg)),
      this.store.pipe(select(fromPlatform.getCurrentProjectId))
    ),
    switchMap(([{ inferenceId, mappings }, orgId, projectId]) => {
      const observables = mappings.map(mapping => {
        const nodeId = mapping.nodeSuid;
        const nodeRole = mapping.role === 'server' ? 'server' : 'node';
        const file = mapping.file;

        return this._inferenceService.mapNodeInference(
          orgId as string,
          projectId as string,
          inferenceId,
          nodeId,
          nodeRole,
          file
        );
      });

      return forkJoin(observables).pipe(
        mergeMap(() => [
          NewInferenceActions.chooseInferenceMappingsSuccess({ inferenceId: inferenceId! }),
          NewInferenceActions.inferenceCreationCompleted()
        ]),
        catchError(error => of(NewInferenceActions.chooseInferenceMappingsFailure({ error })))
      );
    })
  ));
}
