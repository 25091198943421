// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const defaultHost = 'flpapi.sherpa.ai';
const defaultProtocol = 'https://';
const defaultPort = '443';
const defaultEndpoint = '/v1/';

export const environment = {
    production: true,
    endpoint: ((defaultProtocol) + (defaultHost) + ':' + (defaultPort) + (defaultEndpoint)),
    baseUrl: ((defaultProtocol) + (defaultHost) + ':' + (defaultPort) + (defaultEndpoint)),
    googleMapsApi: "https://maps.googleapis.com/maps/api/js",
    bearerAuth: "nicetry"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
