import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { AuthService } from '../../services/auth.service';
import { AuthActions } from '../actions';
import { map, catchError, exhaustMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private _authService: AuthService,
    private router: Router
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      exhaustMap(({ credentials }) =>
        this._authService.auth(credentials).pipe(
          map((loginResponse) => {
            return AuthActions.loginSuccess({ loginResponse, credentials });
          }),
          catchError((error) => {
            return of(AuthActions.loginError({ error }));
          })
        )
      )
    )
  );

  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.changePassword),
      exhaustMap(({ changePassword }) =>
        this._authService.auth(changePassword).pipe(
          map((loginResponse) => {
            this.router.navigate(['/auth/password-changed']);
            this._authService.setAuthDataInSessionStorage(loginResponse);
            return AuthActions.changePasswordSuccess({ loginResponse });
          }),
          catchError((error) => {
            return of(AuthActions.changePasswordError({ error }));
          })
        )
      )
    )
  );

  verifyMFA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.verifyMFA),
      exhaustMap(({ verifyMFA }) =>
        this._authService.auth(verifyMFA).pipe(
          map((verifyMFAResponse) => {
            // this.router.navigate(['/welcome']);
            this.router.navigate(['/auth/password-verified']);
            return AuthActions.verifyMFASuccess({ verifyMFAResponse });
          }),
          catchError((error) => {
            return of(AuthActions.verifyMFAError({ error }));
          })
        )
      )
    )
  );

  signInMFA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signInMFA),
      exhaustMap(({ signInMFA }) =>
        this._authService.auth(signInMFA).pipe(
          map((authResponse) => {
            this._authService.setAuthDataInSessionStorage(authResponse);
            const user = this._authService.getUserFromIdToken();
            this.router.navigate(['/']);
            return AuthActions.signInMFASuccess({ authResponse, user });
          }),
          catchError((error) => {
            return of(AuthActions.signInMFAError({ error }));
          })
        )
      )
    )
  );

  resetMFA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resetMFA),
      exhaustMap(({ resetMFA }) =>
        this._authService.auth(resetMFA).pipe(
          map((resetMFAResponse) => {
            if (
              resetMFA.authFlow === 'RESET_MFA_REQUIRED' &&
              resetMFAResponse.authFlow === 'RESET_MFA_STAGED'
            ) {
              // Incluye tu codigo de 6 digitos recibido por correo!
              this.router.navigate(['/auth/reset-mfa']);
            } else if (
              resetMFA.authFlow === 'RESET_MFA_STAGED' &&
              resetMFAResponse === null
            ) {
              // Codigo correcto!
              resetMFAResponse = resetMFA;
              this.router.navigate(['/auth/reset-mfa-success']);
            }
            return AuthActions.resetMFASuccess({ resetMFAResponse });
          }),
          catchError((error) => {
            if (resetMFA.authFlow === 'RESET_MFA_STAGED') {
              this.router.navigate(['/auth/reset-mfa-error']);
            }
            return of(AuthActions.resetMFAError({ error }));
          })
        )
      )
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resetPassword),
      exhaustMap(({ resetPassCredentials }) =>
        this._authService.auth(resetPassCredentials).pipe(
          map((loginResponse) => {
            let resetPasswordCompleted = false;
            if (resetPassCredentials.authFlow === 'RESET_PASSWORD_STAGED') {
              resetPasswordCompleted = true;
            }
            return AuthActions.resetPasswordSuccess({
              resetPassCredentials,
              resetPasswordCompleted,
            });
          }),
          catchError((error) => {
            const err = error.error;
            return of(AuthActions.resetPasswordError({ error: err }));
          })
        )
      )
    )
  );

  autologin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.autologin),
      exhaustMap(() =>
        this._authService.autologin().pipe(
          map((user) => {
            return AuthActions.autologinSuccess({ user });
          }),
          catchError((error) => {
            sessionStorage.clear();
            return of(AuthActions.autologinError({ error }));
          })
        )
      )
    )
  );

  // autologinSuccess$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthActions.autologinSuccess),
  //     tap((res) => {
  //       this._authService.setUserInSessionStorage(res['user'])
  //     }),
  //     switchMap( _ => [ConfigActions.loadConfig()])
  //   )
  // );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => {
          this._authService.logout();
        })
      ),
    { dispatch: false }
  );

  // autologout$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthActions.autologout),
  //     tap(() => {
  //       this._authService.autologout()
  //     }),
  //   ), { dispatch: false }
  // );

  timeOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.timeOut),
        tap(() => {
          this._authService.getEvents();
        })
      ),
    { dispatch: false }
  );

  extendUserSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.extendUserSession),
        tap(() => {
          this._authService.initTimerForRefreshToken();
        })
      ),
    { dispatch: false }
  );

  requestAcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.requestAccess),

      exhaustMap(({ params }) =>
        this._authService.requestAcess(params).pipe(
          map(() => {
            return AuthActions.requestAccessSuccess();
          }),
          catchError((error) => {
            let message =
              'We are experiencing technical difficulties at the moment. Try again in a few minutes.';

            if (error && error.status) {
              if (error.status === 409) {
                message =
                  'The access request with this email has been validated.';
              } else if (error.status === 402) {
                message = 'Early access has reached its user quota.';
              }
            }
            return of(AuthActions.requestAccessError({ message }));
          })
        )
      )
    )
  );
}
