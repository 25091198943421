import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'shai-error-template',
  template: `
    <div class="template-wrapper">
      <div class="template-container">
        <div class="left-wrapper">
          <div class="left-container">
          <span class="heading"> {{ title }} </span>
          <span class="paragraph"> {{ description }} </span>
          <span class="paragraph-small">Error {{ errorCode }} </span>
          <shai-button (click)="goHome()" [type]="'button'" [buttonStyle]="'secondary'">Go back to home</shai-button>
          </div>
        </div>
        <div class="image-container">
          <img [src]="imageAsset" />
        </div>
        <div class="right-wrapper"> </div>
      </div>
    </div>
  `,
  styleUrls: ['./error-template.component.scss'],
})
export class ErrorTemplateComponent {
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() errorCode: string = '';
  @Input() imageName: string = '404';

  imageAsset = `assets/images/${this.imageName}.svg`

  constructor(private router: Router) {}

  goHome() {
    this.router.navigateByUrl('/');
  }
}
