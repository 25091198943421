import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { onlyNumberKeys } from 'src/app/utils/dom-utils';
import { FormInputTextComponent } from '../form-controls/input-text/form-input-text.component';

@Component({
  selector: 'shai-slider',
  template: `
    <ngx-slider *ngIf="type === 'range' && this.options" [ngClass]="{'stretch': stretch }"
      [(value)]="low"
      [(highValue)]="high"
      [options]="options"
      (userChange)="onUserChange($event)">
    </ngx-slider>
    <ngx-slider *ngIf="type === 'single' && this.options" [ngClass]="{'stretch': stretch }"
      [(value)]="low"
      [options]="options"
      (userChange)="onUserChange($event)">
    </ngx-slider>
    <shai-form-input-text
      [parentForm]="loginForm"
      [value]="value"
      (onInputChange)="onInputChange($event)"
      (keydown)="onKeyDown($event)"
    ></shai-form-input-text>
  `,
  styleUrls: ['./slider.component.scss']
})
export class Slider implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(FormInputTextComponent) inputComp!: FormInputTextComponent;
  @Input() type: 'single' | 'range' = 'single';
  @Input() size: 'small' | 'big' = 'small';
  @Input() low!: number;
  @Input() high!: number;
  @Input() floor!: number;
  @Input() ceil!: number;
  @Input() minLimit!: number;
  @Input() maxLimit!: number;
  @Input() showNumber: boolean = false;
  @Input() value: string = '';
  @Input() stretch: boolean = false;
  @Input() decimals: number = 0;
  @Input() avoidNegative: boolean = false;
  @Output() onSliderChange = new EventEmitter();
  options: Options | undefined;
  stepValue: number = 1;
  getTextInput: any;

  constructor(private formBuilder: FormBuilder) {}

  loginForm = this.formBuilder.group({
    sliderValue: ['slider', [Validators.required]],
  });

  ngOnInit(): void {
    this._setSlideOptions();
  }

  ngAfterViewInit(): void {
    this.getTextInput = this.inputComp.fieldContainerRef?.nativeElement.childNodes[0].childNodes[0].childNodes[0].childNodes[2].childNodes[0].childNodes[0];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.decimals && changes.decimals.currentValue) {
        this.stepValue = 1 / (Math.pow(10, changes.decimals.currentValue));
      }
    }
  }

  private _setSlideOptions() {
    if (typeof this.low === 'number' && typeof this.floor === 'number' && typeof this.ceil === 'number') {
      //this.value = String(this.low);
      if (this.type === 'range' && typeof this.high === 'number') {
        this.options = {
          floor: this.floor,
          ceil: this.ceil,
          getPointerColor: (value: number): string => {
            return 'white';
          },
          getSelectionBarColor: (value: number): string => {
            return '#0C0E11';
          }
        };
      } else if (this.type === 'single') {
        if (typeof this.minLimit === 'number' && typeof this.maxLimit === 'number') {
          this.options = {
            floor: this.floor,
            ceil: this.ceil,
            minLimit: this.minLimit,
            maxLimit: this.maxLimit,
            showSelectionBar: true,
            getPointerColor: (value: number): string => {
              return 'white';
            },
            getSelectionBarColor: (value: number): string => {
              return '#0C0E11';
            }
          };
        } else {
          this.options = {
            floor: this.floor,
            ceil: this.ceil,
            showSelectionBar: true,
            step: this.stepValue,
            getPointerColor: (value: number): string => {
              return 'white';
            },
            getSelectionBarColor: (value: number): string => {
              return '#0C0E11';
            }
          };
        }
      }
    }
  }

  onUserChange(changeContext: ChangeContext): void {
    this.getChangeContextString(changeContext);
  }

  getChangeContextString(changeContext: ChangeContext): void {
    if (changeContext.highValue) {
      this.high = changeContext.highValue;
      this.onSliderChange.emit(this.high);
    } else {
      this.low = changeContext.value;
      this.onSliderChange.emit(this.low);
      this.value = changeContext.value.toString();
    }
  }

  setValidNumber(value: string) {
    const firstDot = value.search(/\./) + 1;
    const oneDotLimited = value.substr(0, firstDot) + value.slice(firstDot).replace(/\./g, '');
    return oneDotLimited.replace(/[^0-9.\-]/g, '')
  }

  onInputChange(changedValue: string) {
    if (!changedValue && this.floor) {
      changedValue = String(this.floor);
    }

    changedValue = this.setValidNumber(changedValue)

    if (changedValue && Number(changedValue) <= this.floor) {
      changedValue = String(this.floor);
    }
    if (changedValue && Number(changedValue) >= this.ceil) {
      changedValue = String(this.ceil);
    }
    this.getTextInput.value = changedValue;
    this.low = Number(changedValue);
    this.loginForm.setValue({
      'sliderValue': changedValue
    });
    this.onSliderChange.emit(this.low);
  }

  onKeyDown(event: any): void {
    if (!onlyNumberKeys(event)) {
      if ((event.key === 'Backspace') || (event.key === 'Delete') || (event.key === 'ArrowRight') || (event.key === 'ArrowLeft')) {
        // Allowed!!
      } else {
        if (((this.decimals > 0) && ((event.key === '.') || (event.key === ','))) || (this.avoidNegative && (event.key === '-'))) {
          // Allowed!!
        } else {
          // Not Allowed!!
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  }
}
