import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'shai-table-header-tabs',
    template: `
        <ng-container *ngFor="let headerTab of headerTabs; let i = index">
            <span *ngIf="selected == i + 1; then thenBlock else elseBlock"></span>
            <!-- active tab -->
            <ng-template #thenBlock><span [ngClass]="{'truncate-1-line': showEllipsis,'active-class': classForTrainingsLanding}" title="{{ showEllipsis ? headerTab : '' }}" class="selected-tab" (click)="selectTab(i+1)">{{ headerTab }}</span></ng-template>
            <!-- inactive tab/s -->
            <ng-template #elseBlock><span [ngClass]="{'truncate-1-line': showEllipsis,'inactive-class': classForTrainingsLanding}" title="{{ showEllipsis ? headerTab : '' }}" class="not-selected-tabs" (click)="selectTab(i+1)">{{ headerTab }}</span></ng-template>
        </ng-container>
    `,
    styleUrls: [`./table-header-tabs.component.scss`],
})
export class TableHeaderTabsComponent {
    @Input() headerTabs: string[] = [];
    @Input() selected: number = 1;
    @Input() showEllipsis: boolean = false;
    @Input() classForTrainingsLanding: boolean = false;
    @Output() onTabSelected = new EventEmitter();
    
    selectTab(tabNumber: number) {
        this.selected = tabNumber;
        this.onTabSelected.emit(this.selected);
    }
}

