import { createAction, props, union } from "@ngrx/store";
import { Training } from "../../platform/";
import { DataTraining, DifferentialPrivacyData, EntityResolutionData, TrainingList, TrainingResponse, TrainingStatus } from "../models";
import { GenericParameter } from '../models/training-session.model';

export const dataNewTraining = createAction('[NewTraining] Set data new training', props<{trainingData: DataTraining}>());
export const dataParadigm = createAction('[NewTraining] Set data paradigm', props<{paradigmId: string | null, parametersData: GenericParameter[] | undefined}>());
export const dataEntityResolution = createAction('[NewTraining] Set data entity resolution', props<{entityResolutionData: EntityResolutionData | null}>());
export const dataModel = createAction('[NewTraining] Set data model', props<{modelId: string | null, hyperparametersData: GenericParameter[] | undefined}>());
export const dataAggregator = createAction('[NewTraining] Set data aggregator', props<{aggregatorData: string | null}>());
export const dataDifferentialPrivacy = createAction('[NewTraining] Set data differential privacy', props<{differentialPrivacyData: DifferentialPrivacyData | null}>());
export const clearDataNewTraining = createAction('[NewTraining] Clear data new training');
export const clearDataParadigm = createAction('[NewTraining] Clear data paradigm');
export const clearDataEntityResolution = createAction('[NewTraining] Clear data entity resolution');
export const clearDataModel = createAction('[NewTraining] Clear data model');
export const clearDataAggregator = createAction('[NewTraining] Clear data aggregator');
export const clearDataDifferentialPrivacy = createAction('[NewTraining] Clear data differential privacy');
export const clearNewTraining = createAction('[NewTraining] Clear data new training');
export const clearAllDataTraining = createAction('[Training] Clear all data training');

export const createNewTraining = createAction('[NewTraining] Create new training', props<{name: string; description: string, paradigm: any, model: any, differentialPrivacy: any, aggregation: any, entityResolution: any, metricsConfig: any}>());
export const createNewTrainingSuccess = createAction('[NewTraining] Create new training success', props<{training: TrainingResponse}>());
export const createNewTrainingFailure = createAction('[NewTraining] Create new training failure', props<{error: any}>());

const actionsUnion = union({
    dataNewTraining,
    dataParadigm,
    dataEntityResolution,
    dataModel,
    dataAggregator,
    dataDifferentialPrivacy,
    clearDataNewTraining,
    clearDataParadigm,
    clearDataEntityResolution,
    clearDataModel,
    clearDataAggregator,
    clearDataDifferentialPrivacy,
    clearNewTraining,
    clearAllDataTraining,
    createNewTraining,
    createNewTrainingSuccess,
    createNewTrainingFailure
})

export type TrainingActionsUnion = typeof actionsUnion
