import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-arrow-left',
  template: `<svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="1" y1="8" x2="16" y2="8" stroke="#1F1F1F" />
    <path d="M8 1L1 8L8 15" stroke="#1F1F1F" />
  </svg>`,
})
export class IconArrowLeft {
  @HostBinding('class.shai-icon') get iconClass() {
    return true;
  }
}
