import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AdDirective } from '../../directives/ad.directive';
import { AdComponent, TableColumn } from './models/table.model';

@Component({
  selector: 'shai-table',
  template: `
    <div class="table-container">
    <mat-table #table
      matSort
      matSortStart="desc"
      matSortDisableClear="true"
      matSortDisableClear="true"
      [dataSource]="dataSource"
      style="box-shadow: none;"
      (matSortChange)="sortData($event)"
    >
    <!-- table dynamic columns -->
        <ng-container
          *ngFor="let column of columnsData"
          [matColumnDef]="column.columnDef"
        >
          <mat-header-cell
            *matHeaderCellDef
            [ngStyle]="{'width': column.width ? column.width : '' }"
            mat-sort-header
            [disabled]="column.sortingDisabled">
            <div class="header-cell-title-container">
              <span class="paragraph-small">{{ column.header }}</span>
              <shai-icon-sort-arrow *ngIf="!column.sortingDisabled" class="arrow-down" style="padding-left: 5px;"> </shai-icon-sort-arrow>
              <shai-icon-sort-arrow *ngIf="!column.sortingDisabled" class="arrow-up" style="margin-left: 5px; transform: rotate(180deg);"> </shai-icon-sort-arrow>
            </div>
          </mat-header-cell>
          <mat-cell (click)="emitToParent(row, column)"
            #cellData
            [id]="column.columnDef"
            *matCellDef="let row; let i = index"
            [style.min-height]="rowHeight"
            [ngClass]="{'disabled': row.disabled}"
          >
            <ng-template
              adHost
              [component]="column.component"
              [data]="column.cellData(row)"
              [extraCellElements]="column.extraCellElements"
            >
            </ng-template>
          </mat-cell>
        </ng-container>

        <mat-header-row *ngIf="!showTableHeader; else headerRow"></mat-header-row>
        <ng-template #headerRow>
          <mat-header-row #headerRow *matHeaderRowDef="displayedColumns"></mat-header-row>
        </ng-template>
        <mat-row
          *matRowDef="let row; columns: displayedColumns | paginate: {id: confPaginationId, itemsPerPage: size, currentPage: pageIndex, totalItems: totalItemsDataset}">
        </mat-row>
      </mat-table>

      <!-- table pagination -->
      <!--
      <shai-paginator *ngIf="showPaginator" [showViewAll]="showViewAll"
      [showItemsPerPage]="showitemsPerPage" [viewAllText]="viewAllText"
      [itemsPerPageBlocks]="itemsPerPageBlocks"
      (onPaginate)="paginate($event)" (onShowAll)="showAll()" >
      </shai-paginator>
      -->
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: [`./table.component.scss`],
})
export class TableComponent implements OnInit, AfterViewInit, AfterViewChecked {
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [];
  size = 0;
  pageIndex = 1;
  totalItemsDataset = 0;
  confPaginationId = '';
  isActivePagination: boolean = false;
  private flag = false;
  private viewHasShow = false;
  @ViewChildren('cellData') cellData!: QueryList<any>;
  getCellData: any;
  checkboxArray: any[] = [];
  radioArray: any[] = [];
  //dataset & columns are mandatory to this component

  @Input() showTableHeader: boolean = true;
  @Input() showitemsPerPage: boolean = false;
  @Input() showViewAll: boolean = false;
  @Input() viewAllText: string = 'View all'
  @Input() itemsPerPageBlocks = [5];
  @Input() rowHeight = "76px";
  inputDataset: any[] = [];
  @Input() set dataset(value: Array<any> | undefined) {
    if (value) {
      this.inputDataset = value;
      this.totalItemsDataset = this.inputDataset.length;
      this.dataSource.data = this.isActivePagination ? value.slice(this.pageIndex * this.size - this.size, this.pageIndex * this.size) : value;
      this.cdRef.detectChanges();
      this.loadComponents();
    }
  }

  @Input() set showPaginator(paginationActivated: boolean) {
    if (paginationActivated === true) {
      this.isActivePagination = paginationActivated;
    }
  }

  columnsData: Array<TableColumn> = Array<TableColumn>()
  @Input() set columns(value: Array<TableColumn>) {
    if (value) {
      this.columnsData = value;
      this.displayedColumns = value.map((x) => x.columnDef)
      this.cdRef.detectChanges();
      this.loadComponents();
    }
  }

  @Input() set paginationId(value: string) {
    if (value) {
      this.confPaginationId = value;
    }
  }

  @Input() set paginationItemsPage(value: number) {
    if (value) {
      this.size = value;
      this.dataSource.data = this.inputDataset.slice(this.pageIndex * this.size - this.size, this.pageIndex * this.size);
      this.flag = false;
    }
  }

  @Input() set paginationCurrentPage(value: number) {
    if (value) {
      this.pageIndex = value;
      this.dataSource.data = this.inputDataset.slice(this.pageIndex * this.size - this.size, this.pageIndex * this.size);
      this.flag = false;
    }
  }

  @Input() set paginationTotalItems(value: number) {
    if (value) {
      this.totalItemsDataset = value;
      this.dataSource.data = this.inputDataset.slice(this.pageIndex * this.size - this.size, this.pageIndex * this.size);
      this.flag = false;
    }
  }

  @Output() onShowAll = new EventEmitter();
  @Output() onRowClicked = new EventEmitter();
  @Output() goToEntity = new EventEmitter();
  @Output() onSortClicked = new EventEmitter();

  @ViewChildren(AdDirective) matCellsContent?: QueryList<AdDirective>;
  @ViewChild(MatSort, { static: true }) sort?: MatSort;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cdRef:ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource<any>(this.inputDataset);
    // set table columns
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort || null;
    this._sortingDataSource();
  }

  ngAfterViewChecked(): void {
    if (!this.flag) {
      this.loadComponents();
      this.flag = true;
    }
    if (!this.viewHasShow) {
      this.getCellData = this.cellData.toArray();
      this.checkboxArray = this.getCellData.filter((element: any) => element.nativeElement.id === 'checkbox');
      this.radioArray = this.getCellData.filter((element: any) => element.nativeElement.id === 'radio');
    }
  }

  private _sortingDataSource() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      let value;
      switch (property) {
        case 'name': {
          if (item.formGroup === "project_files_group") {
            value = item['property'];
            return typeof value === 'string' ? value.toUpperCase() : value;
          } else {
            value = item[property];
            return typeof value === 'string' ? value.toUpperCase() : value;
          }
        }
        case 'trained': {
          value = item['trainedInfo'];
          return typeof value === 'string' ? value.toUpperCase() : value;
        }
        case 'role':
        case 'fileset': {
          value = item[property]['title'];
          return typeof value === 'string' ? value.toUpperCase() : value;
        }
        case 'uploaded': {
          value = item[property]['diff'];
          return typeof value === 'string' ? value.toUpperCase() : value;
        }
        case 'type':
        default: {
          value = item[property];
          return typeof value === 'string' ? value.toUpperCase() : value;
        }
      }
    };
  }

  sortData(event: any) {
    
    const columnName = event.active;
    const direction = event.direction;

    const sortedData = [...this.dataSource.data];

    sortedData.sort((a, b) => {
      const aValue = a[columnName];
      const bValue = b[columnName];

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return direction === 'asc' ? aValue - bValue : bValue - aValue;
      } 
      else if (this.isDateField(aValue, 'time') && this.isDateField(bValue, 'time')) {
        return direction === 'asc' ? this.compareDatesAsc(aValue, bValue) : this.compareDatesDesc(aValue, bValue);
      }
      else {
        return direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
    });

    this.dataSource.data = sortedData;
    this.onSortClicked.emit(this.dataSource.data);
  }

  isDateField(obj: any, property: string): boolean {
    return obj.hasOwnProperty(property);
  }

  compareDatesAsc(a: any, b: any): number {
    const dateA = new Date(a.time);
    const dateB = new Date(b.time);
  
    return dateA.getTime() - dateB.getTime();
  }
  
  compareDatesDesc(a: any, b: any): number {
    const dateA = new Date(a.time);
    const dateB = new Date(b.time);
  
    return dateB.getTime() - dateA.getTime();
  }

  private loadComponents() {
    this.matCellsContent?.forEach((cell) => {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(cell.component);
      const viewContainerRef = cell.viewContainerRef;
      viewContainerRef.clear();

      const componentRef = viewContainerRef.createComponent<AdComponent>(componentFactory);
      componentRef.instance.data = cell.data;
      componentRef.instance.extraCellElements = cell.extraCellElements;
    });
    this.cdRef.detectChanges();
  }

  paginate(event: any) {
    const {pageIndex, itemsPerPage} = event;
    this.size = itemsPerPage
    this.pageIndex = pageIndex
    this.dataSource.data = this.inputDataset.slice(pageIndex * itemsPerPage - itemsPerPage, pageIndex * itemsPerPage);
    this.flag = false;
  }

  showAll() {
    this.onShowAll.emit();
  }

  emitToParent(row: any, column: any) {
    const emitToParent = row.emitToParent;
    if (emitToParent === undefined || emitToParent) {
      this.goToEntity.emit(row);
    } else {
      if (this.checkboxArray.length > 0 && column.columnDef !== 'type' && column.columnDef !== 'more') {
        this.onRowClicked.emit(row);
      }
      if (this.radioArray.length > 0) {
        const radioData = { rowIndex: row.row, columnName: column.columnDef};
        this.onRowClicked.emit(radioData);
      }
    }
  }
}
