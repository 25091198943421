import { Component, Input, Output, EventEmitter } from "@angular/core";
import { onlyNumberKeys } from "src/app/utils/dom-utils";

@Component({
    selector: 'shai-counter',
    template: `
        <div class="quantity" [ngClass]="{'disabled': disable}">
            <button (click)="!disable ? decrement() : $event.preventDefault()"><shai-icon-minus></shai-icon-minus></button>
            <input class="quantity-input" style="border:none"
                [(ngModel)]="counter"
                (change)="onNumChanged($event)"
                (keydown)="onKeyDown($event)"
                [disabled]="disable"
            >
            <button (click)="!disable ? increment() : $event.preventDefault()"><shai-icon-plus></shai-icon-plus></button>
        </div>
    `,
    styleUrls: [`./counter.component.scss`],
})
export class Counter {
    @Input() mode: 'new' | 'edit' = 'new';
    @Input() floor!: number;
    @Input() disable: boolean = false;
    @Input() ceil!: number;
    @Input() avoidNegative: boolean = false;
    @Input() decimals: number = 0;
    @Output() onCounterChange = new EventEmitter();
    @Input() counter: string = '1';
    decimalsLimit = 7;

    constructor() { }

    increment() {
        const incr = 1 / (Math.pow(10, this.decimals));
        const target = (parseFloat(this.counter) + parseFloat(incr.toFixed(this.decimals))).toFixed(this.decimals);
        if (this.checkValidNumber(parseFloat(target))) {
            this.counter = parseFloat(target).toFixed(this.decimals);
            this.onCounterChange.emit(this.counter);
        }
    }
    decrement() {
        const decr = 1 / (Math.pow(10, this.decimals));
        const target = (parseFloat(this.counter) - parseFloat(decr.toFixed(this.decimals))).toFixed(this.decimals);
        if (this.checkValidNumber(parseFloat(target))) {
            this.counter = parseFloat(target).toFixed(this.decimals);
            this.onCounterChange.emit(this.counter);
        }
    }
    onNumChanged(event: any) {
        const num = this.numberWithLimitedDecimals(event.target.value);
        if (this.checkValidNumber(num)) {
            this.counter = num;
            this.onCounterChange.emit(this.counter);
        } else {
            if (num < this.floor) {
                this.counter = this.floor.toString();
                this.onCounterChange.emit(this.counter);
            }
            if (num > this.ceil) {
                this.counter = this.ceil.toString();
                this.onCounterChange.emit(this.counter);
            }
        }
    }

    numberWithLimitedDecimals(value: any) {
        const number = value.split('.')[0];
        const decimals = value.split('.')[1];
        if (decimals) {
            const decimalsLength = decimals.length;
            if (decimalsLength > this.decimalsLimit) {
                const newDecimals = decimals.substring(0,this.decimalsLimit);
                value  = number+'.'+newDecimals;
            }
        }
        return value;
    }

    checkValidNumber(num: number): boolean {
        if (num < 0 && this.avoidNegative) {
            if (this.floor && this.floor > num) {
                this.counter = this.floor.toFixed(this.decimals).toString();
            } else { this.counter = '0'; }
            return false;
        }
        if ((this.floor || this.floor === 0) && this.floor > num) {
            this.counter = this.floor.toFixed(this.decimals).toString();
            return false;
        }
        if (this.ceil && this.ceil < num) {
            this.counter = this.ceil.toFixed(this.decimals).toString();
            return false;
        }
        return true;
    }

    onKeyDown(event: any): void {
        if (!onlyNumberKeys(event) && (event.key !== '-') && (event.key !== '.') && (event.key !== 'Backspace') && (event.key !== 'Delete') && (event.key !== 'ArrowRight') && (event.key !== 'ArrowLeft')) {
            event.preventDefault();
            event.stopPropagation();
        }
    }
}
