import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { fadeIn } from 'src/app/shared/animations';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { InputErrorMessage } from 'src/app/shared/components/form-controls/input-text/form-input-text.component';
import { select, Store } from '@ngrx/store';
import * as fromPlatform from 'src/app/platform/reducers';
import { BreackpointService } from 'src/app/platform/services/breackpoint.service';
import { SnackbarService, SnackbarType } from 'src/app/shared/components/snackbar/snackbar.service';
import { OrganizationActions } from 'src/app/platform';
import { AuthService } from '../../../auth/services/auth.service';
import { OnDestroy } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { hasClass } from 'src/app/utils';
@Component({
  selector: 'shai-change-password-overlay',
  animations: [fadeIn],
  template: `
    <div #locked></div>
    <shai-overlay
      (onChange)="onChangeOverlay($event)"
      [active]="isActive"
      [complete]="isComplete"
      [hasHeader]="false"
      [fullWidth]="false"
      (onLeave)="closeChangePasswordOverlay()"
    >
      <div id="change-password-overlay-wrapper">
        <mat-grid-list cols="12" rowHeight="fit">
          <mat-grid-tile colspan="2"
            *ngIf="(_bkp.obsScreen$ | async) === 'desktop'">
          </mat-grid-tile>
          <mat-grid-tile [colspan]="(_bkp.obsScreen$ | async) === 'desktop' ? 8 : 12">
            <shai-title-bar
              title="Change password"
              bottomDescription="Enter your old password and then the new password following the requirements.">
            </shai-title-bar>
            <div class="container-form">
              <form [formGroup]="changePasswordForm">
                <div class="form-field-group">
                  <shai-form-input-text
                    [parentForm]="changePasswordForm"
                    formControlName="oldPassword"
                    fieldName="oldPassword"
                    label="Old password"
                    maxLength="99"
                    [isSecureField]="true"
                    [errorMessages]="passwordErrorMessages"
                    [hasSubmitAttemps]="hasSubmitAttemps">
                  </shai-form-input-text>
                </div>
                <div class="form-field-group">
                  <shai-form-input-text
                    [parentForm]="changePasswordForm"
                    formControlName="password"
                    fieldName="password"
                    label="New password"
                    maxLength="99"
                    [isSecureField]="true"
                    [errorMessages]="passwordErrorMessages"
                    [hasSubmitAttemps]="hasSubmitAttemps">
                  </shai-form-input-text>
                </div>
                <div id="password-conditions" class="paragraph">
                  Required:
                  <ul>
                    <li>
                      At least 12 characters
                      <span *ngIf="authService.passwordHasValue(this.changePasswordForm)">
                        <shai-icon-checkmark-16 *ngIf="authService.checkMinChars(this.changePasswordForm)"></shai-icon-checkmark-16>
                        <shai-icon-warning-16 *ngIf="!authService.checkMinChars(this.changePasswordForm)"></shai-icon-warning-16>
                      </span>
                    </li>
                    <li>
                      A mixture of both uppercase and lowercase letters
                      <span *ngIf="authService.passwordHasValue(this.changePasswordForm)">
                        <shai-icon-checkmark-16 *ngIf="authService.checkLowerAndUpper(this.changePasswordForm)"></shai-icon-checkmark-16>
                        <shai-icon-warning-16 *ngIf="!authService.checkLowerAndUpper(this.changePasswordForm)"></shai-icon-warning-16>
                      </span>
                    </li>
                    <li>
                      A mixture of letters and numbers
                      <span *ngIf="authService.passwordHasValue(this.changePasswordForm)">
                        <shai-icon-checkmark-16 *ngIf="authService.checkLettersAndNumbers(this.changePasswordForm)"></shai-icon-checkmark-16>
                        <shai-icon-warning-16 *ngIf="!authService.checkLettersAndNumbers(this.changePasswordForm)"></shai-icon-warning-16>
                      </span>
                    </li>
                    <li>
                      Inclusion of at least one special character, e.g., ! @ # ? ]
                      <span *ngIf="authService.passwordHasValue(this.changePasswordForm)">
                        <shai-icon-checkmark-16 *ngIf="authService.hasSpecialCharacters(this.changePasswordForm)"></shai-icon-checkmark-16>
                        <shai-icon-warning-16 *ngIf="!authService.hasSpecialCharacters(this.changePasswordForm)"></shai-icon-warning-16>
                      </span>
                    </li>
                    <li>
                      Does not include the following characters: $ < >
                      <span *ngIf="authService.passwordHasValue(this.changePasswordForm)">
                        <shai-icon-checkmark-16 *ngIf="authService.checkInvalidCharacters(this.changePasswordForm)"></shai-icon-checkmark-16>
                        <shai-icon-warning-16 *ngIf="!authService.checkInvalidCharacters(this.changePasswordForm)"></shai-icon-warning-16>
                      </span>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2"
            *ngIf="(_bkp.obsScreen$ | async) === 'desktop'">
          </mat-grid-tile>
        </mat-grid-list>
        <shai-action-bar>
          <ng-container left-cell>
            <shai-button
              [type]="'button'"
              (click)="closeChangePasswordOverlay()"
              buttonStyle="secondary"
            >Cancel</shai-button>
          </ng-container>
          <ng-container right-cell>
            <shai-button
              *ngIf="!changingPassword"
              [type]="'button'"
              (click)="onSaveHandler()"
              buttonStyle="primary"
            >Change password</shai-button>
            <shai-button
              *ngIf="changingPassword"
              [type]="'button'"
              [buttonStyle]="'primary'"
              [disabled]="true"
            >
              <div class="spinner-wrapper inside-button-spinner">
                <ng-lottie [options]="blackLoaderAnimation"></ng-lottie>
              </div>
            </shai-button>
          </ng-container>
        </shai-action-bar>
      </div>
    </shai-overlay>
  `,
  styleUrls: ['./change-password-overlay.component.scss'],
})
export class ChangePasswordOverlayComponent implements OnInit, OnDestroy {
  @ViewChild('locked', { static: false }) lockedRef: any;
  @Output() changePassEmitter = new EventEmitter();
  @Output() changePassDataComplete = new EventEmitter();
  @Input() userName: string | undefined;
  @Input() userOrg: string | undefined;

  blackLoaderAnimation: AnimationOptions = {
    path: '/assets/lotties/loader.json',
    loop: true
  }
  changingPassword: boolean = false;
  changePasswordForm = this.formBuilder.group({
    oldPassword: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });
  hasSubmitAttemps = false;
  passwordErrorMessages: InputErrorMessage[] = [
    { type: 'required', message: 'The password field is required.' },
  ];
  rowHeight = 'fit';
  isActive: boolean = true;
  isComplete: boolean = false;
  private subscription?: Subscription;
  private subscriptionError?: Subscription;
  @HostListener('window:resize', ['$event']) onResize() {
    this.rowHeight = window.innerHeight - 144 - 44 + 'px'; // 44px => header, 144px => bottom-button
  }

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    public _bkp: BreackpointService,
    private _snackbarService: SnackbarService,
    private cdRef: ChangeDetectorRef,
    public authService: AuthService,
    private render: Renderer2,
  ) {
    this.onResize();
  }

  ngOnInit(): void {
    this.store.dispatch(OrganizationActions.clearChangePassword());
    this._checkChangePasswordCompleted();
    this._checkChangePasswordError();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscriptionError?.unsubscribe();
  }

  private _checkChangePasswordCompleted() {
    this.subscription = this.store.pipe(select(fromPlatform.isChangePasswordCompleted))
    .subscribe((isChangePass) => {
      if (isChangePass) {
        this.changingPassword = false;
        this.removeLockedClass();
        // this.isComplete = true;
        this.cdRef.detectChanges();
        setTimeout(() => {
          this._snackbarService.show(
            "Password changed successfully",
            SnackbarType.oneButton,
            undefined,
            false,
            'Ok',
            undefined,
            () => this.closeOverlay(),
            undefined
          );
        }, 300);
      }
    })
  }

  closeOverlay() {
    setTimeout(() => {
      this.isComplete = true;
      this.cdRef.detectChanges();
    }, 1000);
  }

  private _checkChangePasswordError() {
    this.subscriptionError = this.store.pipe(select(fromPlatform.getChangePasswordError))
    .subscribe((error) => {
      if (error) {
        this.changingPassword = false;
        this.removeLockedClass();
        this.cdRef.detectChanges();
        setTimeout(() => {
          this._snackbarService.show(
            "Sorry, something went wrong.",
            SnackbarType.twoButtons,
            'Please, try again.',
            false,
            'Try Again',
            'Cancel',
            () => this.primaryButtonHandler(),
            () => this.closeOverlay()
          );
        }, 300);
      }
    })
  }

  onSaveHandler() {
    this.hasSubmitAttemps = true;
    if(this.changePasswordForm.valid) {
      this.changingPassword = true;
      this.addLockedClass();
      this.cdRef.detectChanges();
      const currentAuth = this.authService.getAuthDataInSessionStorage();
      const data = {
        "accessToken": currentAuth?.accessToken,
        "organizationSuid": this.userOrg,
        "username": this.userName,
        "password": this.changePasswordForm.value.oldPassword,
        "newPassword": this.changePasswordForm.value.password,
      };
      this.store.dispatch(OrganizationActions.changePasswordOrg({ changePassword: data }));
    } else {
      setTimeout(() => {
        this._snackbarService.show(
          'Sorry, review the requirements for changing a new password.',
          SnackbarType.simple
        );
      }, 300);
    }
  }

  primaryButtonHandler() {
    setTimeout(() => {
      this.onSaveHandler();
    }, 300);
  }

  onChangeOverlay(event: boolean) {
    if (!event) {
      this.finishProcess();
    } else {
      this.isActive = true;
    }
  }

  closeChangePasswordOverlay() {
    this.isActive = false;
  }

  private finishProcess() {
    this.changePassEmitter.emit(false);
  }

  addLockedClass() {
    if (!hasClass(this.lockedRef.nativeElement, 'locked')) {
      this.render.addClass(this.lockedRef.nativeElement, 'locked');
    }
  }

  removeLockedClass() {
    if (hasClass(this.lockedRef.nativeElement, 'locked')) {
      this.render.removeClass(this.lockedRef.nativeElement, 'locked');
    }
  }
}
