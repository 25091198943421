import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import * as fromAuth from '../state/selectors/auth.selectors';
import { AuthActions } from '../state/actions';
import { AuthResponse } from '../models/auth.models';
@Component({
  selector: 'shai-reset-mfa',
  template: `
    <div id="reset-mfa-wrapper">
      <mat-grid-list cols="12" rowHeight="220px">
        <mat-grid-tile colspan="2"></mat-grid-tile>
        <mat-grid-tile colspan="8">
          <shai-title-bar title="Reset Two-Factor Authentication" topDescription="REQUIRED"
            bottomDescription="We have emailed you a verification code. Please check your inbox and enter the code to reset Two-Factor Authentication. Remember that this code is only valid once for one hour. We must ask you to request a new one if it has expired or it has been misused.">
          </shai-title-bar>
          <div class="center-wrapper">
            <div class="center-container">
              <shai-otp-input #otpInputRef [otpLength]="6" [allowNumbersOnly]="false" (onOtpCompleted)="onOtpCompleted($event)"></shai-otp-input>
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile colspan="2"></mat-grid-tile>
      </mat-grid-list>
      <shai-action-bar>
        <ng-container left-cell>
          <shai-button
            [type]="'button'"
            [stretch]="true"
            [route]="['/welcome']"
            buttonStyle="secondary"
          >Cancel</shai-button>
        </ng-container>
      </shai-action-bar>
  </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./reset-mfa.component.scss']
})
export class ResetMfaComponent {
  @ViewChild('otpInputRef', { static: false }) otpInputRef: any;
  errorMsg: string = '';
  private subscription?: Subscription;
  errorMFA$: Observable<boolean | undefined> = this.store.pipe(
    select(fromAuth.isLoginError)
  );

  constructor(
    private store: Store
  ) {}

  onOtpCompleted(otpValue: string) {
    if (otpValue) {
      this.resetMFA(otpValue);
    }
  }

  resetMFA(code: string) {
    let hasResp = false;
    this.subscription = combineLatest([
      this.store.pipe(select(fromAuth.getCredentials)),
      this.store.pipe(select(fromAuth.getLoginResponse)),
      this.store.pipe(select(fromAuth.getResetMFAResponse))
    ]).subscribe(([credentials, loginResponse, resetResponse]) => {
      if (credentials && loginResponse && resetResponse && !hasResp) {
        const data = {
          authFlow: "RESET_MFA_STAGED",
          session: this.getSession(loginResponse, resetResponse),
          organizationSuid: credentials?.organizationSuid,
          username: credentials?.username,
          userCode: code
        }
        hasResp = true;
        this.store.dispatch(AuthActions.resetMFA({ resetMFA: data }));
        this.subscription?.unsubscribe();
      }
    })
  }

  getSession(loginResponse: AuthResponse, resetResponse: AuthResponse) {
    let session = '';
    if (loginResponse && loginResponse?.session !== undefined) {
      session = loginResponse?.session;
    }
    if (resetResponse && resetResponse?.session !== undefined) {
      session = resetResponse?.session;
    }
    return session;
  }
}
