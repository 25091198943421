import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const fadeIn = trigger('fadeIn', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('500ms ease-out', style({ opacity: 1 })),
  ]),
]);

export const slideInOutRight = trigger('slideInOutRight', [
  state(
    'false',
    style({
      opacity: 1,
      transform: 'translate3d(100%, 0, 0)',
    })
  ),
  state(
    'true',
    style({
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
    })
  ),
  transition('false <=> true', [animate('700ms ease-in-out')]),
]);

export const backInOut = trigger('backInOut', [
  state(
    'false',
    style({
      transform: 'translate3d(0, 100%, 0)',
      opacity: 0.9
    })
  ),
  state(
    'true',
    style({
      transform: 'translate3d(0, 0, 0)',
      opacity: 1
    })
  ),
  transition('false <=> true', [animate('500ms ease-in-out')]),
]);

export const zoomInOut = trigger('zoomInOut', [
  state(
    'false',
    style({
      opacity: 0.9,
      transform: 'scale3d(0.3, 0.3, 0.3)',
    })
  ),
  state(
    'true',
    style({
      opacity: 1,
      transform: 'scale(1)',
    })
  ),
  transition('false <=> true', [animate('700ms ease-in-out')]),
]);

export const stepperFadeInTransition = trigger('stepTransition', [
  state('previous', style({ opacity: 0, display: 'none' })),
  state('current', style({ opacity: 1, display: 'block' })),
  state('next', style({ opacity: 0, display: 'none' })),
  transition('* => previous', [
    style({ opacity: 0 }),
    animate(0)
  ]),
  transition('* => next', [
    style({ opacity: 0 }),
    animate(0)
  ]),
  transition('* => current', [
    style({ opacity: 0 }),
    animate('1000ms ease-out'),
  ]),
]);

export const stepperSwipeTransition = trigger('stepTransition', [
  state(
    'previous',
    style({ transform: 'translate3d(-100%, 0, 0)', opacity: 0 })
  ),
  state('current', style({ transform: 'translate3d(0, 0, 0)', opacity: 1 })),
  state('next', style({ transform: 'translate3d(100%, 0, 0)', opacity: 0 })),
  transition('* => *', animate('500ms cubic-bezier(0.35, 0, 0.25, 1)')),
]);
