import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-kmeans-32',
  template: `
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="3.57299"
        y1="7.54901"
        x2="12.7158"
        y2="11.9299"
        stroke="#78C6E4"
      />
      <line x1="5.47294" y1="14.387" x2="12.33" y2="11.9108" stroke="#78C6E4" />
      <line
        x1="10.8692"
        y1="21.6048"
        x2="12.9644"
        y2="12.2715"
        stroke="#78C6E4"
      />
      <line
        x1="21.2704"
        y1="20.9052"
        x2="13.0799"
        y2="11.7623"
        stroke="#78C6E4"
      />
      <line
        x1="19.4392"
        y1="10.779"
        x2="12.582"
        y2="11.9218"
        stroke="#78C6E4"
      />
      <line
        x1="16.3967"
        y1="2.46118"
        x2="12.9681"
        y2="11.604"
        stroke="#78C6E4"
      />
      <line
        x1="8.34443"
        y1="4.29413"
        x2="12.9159"
        y2="11.1512"
        stroke="#78C6E4"
      />
      <path
        d="M3.99986 7.42858C3.99986 8.09921 3.45621 8.64287 2.78558 8.64287C2.11494 8.64287 1.57129 8.09921 1.57129 7.42858C1.57129 6.75795 2.11494 6.21429 2.78558 6.21429C3.45621 6.21429 3.99986 6.75795 3.99986 7.42858Z"
        fill="#F5F5F5"
        stroke="#89D5DD"
      />
      <path
        d="M8.76177 3.61905C8.76177 4.28968 8.21811 4.83334 7.54748 4.83334C6.87685 4.83334 6.33319 4.28968 6.33319 3.61905C6.33319 2.94842 6.87685 2.40476 7.54748 2.40476C8.21811 2.40476 8.76177 2.94842 8.76177 3.61905Z"
        fill="#F5F5F5"
        stroke="#89D5DD"
      />
      <path
        d="M17.7141 1.71429C17.7141 2.38492 17.1704 2.92857 16.4998 2.92857C15.8292 2.92857 15.2855 2.38492 15.2855 1.71429C15.2855 1.04365 15.8292 0.5 16.4998 0.5C17.1704 0.5 17.7141 1.04365 17.7141 1.71429Z"
        fill="#F5F5F5"
        stroke="#89D5DD"
      />
      <path
        d="M19.9999 10.8572C19.9999 11.5279 19.4563 12.0715 18.7856 12.0715C18.115 12.0715 17.5714 11.5279 17.5714 10.8572C17.5714 10.1866 18.115 9.64296 18.7856 9.64296C19.4563 9.64296 19.9999 10.1866 19.9999 10.8572Z"
        fill="#F5F5F5"
        stroke="#89D5DD"
      />
      <path
        d="M23.4283 21.1428C23.4283 21.8134 22.8847 22.3571 22.214 22.3571C21.5434 22.3571 20.9998 21.8134 20.9998 21.1428C20.9998 20.4722 21.5434 19.9285 22.214 19.9285C22.8847 19.9285 23.4283 20.4722 23.4283 21.1428Z"
        fill="#F5F5F5"
        stroke="#89D5DD"
      />
      <path
        d="M11.9998 22.2858C11.9998 22.9565 11.4561 23.5001 10.7855 23.5001C10.1149 23.5001 9.57123 22.9565 9.57123 22.2858C9.57123 21.6152 10.1149 21.0715 10.7855 21.0715C11.4561 21.0715 11.9998 21.6152 11.9998 22.2858Z"
        fill="#F5F5F5"
        stroke="#89D5DD"
      />
      <path
        d="M5.90465 15.0476C5.90465 15.7183 5.36099 16.2619 4.69036 16.2619C4.01973 16.2619 3.47607 15.7183 3.47607 15.0476C3.47607 14.377 4.01973 13.8333 4.69036 13.8333C5.36099 13.8333 5.90465 14.377 5.90465 15.0476Z"
        fill="#F5F5F5"
        stroke="#89D5DD"
      />
      <path
        d="M13.9046 11.9048C13.9046 12.4176 13.4889 12.8333 12.9761 12.8333C12.4632 12.8333 12.0475 12.4176 12.0475 11.9048C12.0475 11.3919 12.4632 10.9762 12.9761 10.9762C13.4889 10.9762 13.9046 11.3919 13.9046 11.9048Z"
        fill="#005695"
        stroke="#005695"
      />
    </svg>
  `,
})
export class IconKmeans32 {
  @HostBinding('class.shai-icon') get iconClass() {
    return true;
  }
}
