import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-close',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="13.6568" y1="2.34315" x2="2.34307" y2="13.6569" stroke="#1F1F1F"/>
      <line x1="2.51981" y1="2.16637" x2="13.8335" y2="13.4801" stroke="#1F1F1F"/>
    </svg>
  `
})
export class IconClose {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}