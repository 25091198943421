import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-aggregator-fc-24',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DCDDE0"/>
    <path d="M6.46758 15.8H7.51158V12.14H11.3276V11.18H7.51158V8.36H11.7356V7.4H6.46758V15.8ZM15.6008 15.908C17.1968 15.908 18.0128 15.032 18.1328 13.736H17.1248C17.0408 14.552 16.5008 15.02 15.5888 15.02C14.3288 15.02 13.8368 14.024 13.8368 12.74C13.8368 11.48 14.3288 10.46 15.5888 10.46C16.4768 10.46 17.0408 10.952 17.1008 11.768H18.1088C18.0248 10.532 17.2328 9.56 15.5888 9.56C13.7648 9.56 12.8168 10.832 12.8168 12.74C12.8168 14.672 13.7648 15.908 15.6008 15.908Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class AggregatorFc24 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
