import { createAction, props, union } from '@ngrx/store';
import { DataInference, InferenceFiles, NewInferenceResponse } from '../models';

export const dataNewInference = createAction('[New Inference] Set data new inference', props<{inferenceData: DataInference}>());
export const dataParadigm = createAction('[NewInference] Set data paradigm', props<{paradigmId: string}>());

export const dataInferenceParticipatingNodes = createAction('[NewInference] Set participating nodes', props<{ participatingNodes: any[] }>());
export const dataInferenceMappings = createAction('[NewInference] Set mappings', props<{ mappings: any[] }>());
export const dataSavedFiles = createAction('[NewInference] Set saved files', props<{ savedFiles: InferenceFiles }>());

export const createNewInference = createAction('[NewInference] Create new inference', props<{ name: string; description: string }>());
export const createNewInferenceSuccess = createAction('[NewInference] Create new inference success', props<{ inference: NewInferenceResponse }>());
export const createNewInferenceFailure = createAction('[NewInference] Create new inference failure', props<{ error: any }>());

export const chooseInferenceParticipatingNodes = createAction('[NewInference] Choose participating nodes', props<{ inferenceId: string, participatingNodes: string[] }>());
export const chooseInferenceParticipatingNodesSuccess = createAction('[NewInference] Choose participating nodes success', props<{ inferenceId: string, participatingNodes: string[] }>());
export const chooseInferenceParticipatingNodesFailure = createAction('[NewInference] Choose participating nodes failure', props<{ error: any }>());

export const chooseInferenceMappings = createAction('[NewInference] Choose mappings', props<{ inferenceId: string, mappings: any[] }>());
export const chooseInferenceMappingsSuccess = createAction('[NewInference] Choose mappings success', props<{ inferenceId: string }>());
export const chooseInferenceMappingsFailure = createAction('[NewInference] Choose mappings failure', props<{ error: any }>());

export const inferenceCreationCompleted = createAction('[NewInference] Mark inference creation as completed');
export const resetNewInferenceState = createAction('[New Inference] Reset State');

const actionsUnion = union({
  createNewInference,
  createNewInferenceSuccess,
  createNewInferenceFailure,
  chooseInferenceParticipatingNodes,
  chooseInferenceParticipatingNodesSuccess,
  chooseInferenceParticipatingNodesFailure,
  chooseInferenceMappings,
  chooseInferenceMappingsSuccess,
  chooseInferenceMappingsFailure,
  dataInferenceParticipatingNodes,
  dataInferenceMappings,
  dataSavedFiles
});

export type NewInferenceActionsUnion = typeof actionsUnion;
