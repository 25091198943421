import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-model-24',
  template: `
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H4.5V1H1V11H6.5V7.75H7.5V12H1V15H10V16H1V19H5.38462V20H0V0Z" fill="#1F1F1F"/>
      <path d="M19 1H15V7H17.5V8H14V1H10V0H20V20H10V19H13V15H17V16H14V19H19V12H10V5H4L4 8H3L3 4L11 4V11H19V1Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class IconModel24 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}