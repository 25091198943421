import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-aggregator-rm-24',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DCDDE0"/>
    <path d="M7.91897 12.344C9.01097 12.032 9.73097 11.168 9.73097 9.92C9.73097 8.492 8.78297 7.4 7.06697 7.4H3.74297V15.8H4.78697V12.476H6.82697L9.03497 15.8H10.175V15.68L7.91897 12.344ZM4.78697 8.36H7.00697C8.06297 8.36 8.63897 8.948 8.63897 9.932C8.63897 10.94 8.06297 11.516 7.00697 11.516H4.78697V8.36ZM18.382 9.56C17.35 9.56 16.726 10.004 16.402 10.628H16.354C16.03 9.956 15.394 9.56 14.506 9.56C13.558 9.56 12.994 10.004 12.67 10.628H12.622L12.538 9.668H11.662V15.8H12.67V12.416C12.67 11.18 13.27 10.46 14.23 10.46C15.166 10.46 15.586 11.036 15.586 11.972V15.8H16.594V12.416C16.594 11.18 17.17 10.46 18.13 10.46C19.066 10.46 19.486 11.036 19.486 11.972V15.8H20.494V11.816C20.494 10.436 19.738 9.56 18.382 9.56Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class AggregatorRm24 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
