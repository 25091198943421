import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-aggregator-fg-24',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DCDDE0"/>
    <path d="M6.19219 15.8H7.23619V12.14H11.0522V11.18H7.23619V8.36H11.4602V7.4H6.19219V15.8ZM15.3374 18.656C17.0894 18.656 18.0494 17.564 18.0494 16.16V9.668H17.1734L17.0414 10.52H16.9934C16.6694 9.98 16.0574 9.56 15.1454 9.56C13.5374 9.56 12.5534 10.76 12.5414 12.74C12.5534 14.552 13.4174 15.908 15.1454 15.908C16.0574 15.908 16.6694 15.488 16.9934 14.948H17.0414V16.136C17.0414 17.06 16.5134 17.792 15.3374 17.792C14.2094 17.792 13.7414 17.168 13.6934 16.58H12.7214C12.7574 17.756 13.7654 18.656 15.3374 18.656ZM15.3134 15.008C14.0414 15.008 13.5614 13.964 13.5614 12.74C13.5614 11.504 14.0414 10.46 15.3134 10.46C16.5374 10.46 17.0654 11.516 17.0654 12.74C17.0654 13.952 16.5374 15.008 15.3134 15.008Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class AggregatorFg24 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
