export const getElementSize = (size: number) => {
    if (size.toString().length <= 3) {
        return size + ' bytes';
    } else if ((size.toString().length > 3) && (size.toString().length <= 6)) {
        size = Number((size/1024).toFixed(2));
        return size + ' kb';
    } else if ((size.toString().length > 6) && (size.toString().length <= 9)) {
        size = Number((size/1048576).toFixed(2));
        return size + ' mb';
    } else if ((size.toString().length > 9) && (size.toString().length <= 12)) {
        size = Number((size/1073741824).toFixed(2));
        return size + ' gb';
    } else {
        size = Number((size/1099511627776).toFixed(2));
        return size + ' tb';
    }
}

export const getDocumentationRoute = (id: string) => {
    switch (id) {
        case 'projects':
            return '/new-project.html';
        case 'nodes':
            return '/new-node.html';
        case 'sessions':
            return '/horizontal-session.html';
        case 'analytics':
            return '/analytics-session.html';
        case 'upload-models':
        case 'files':
            return '/manage-models.html';
        case 'stopping-hfl':
            return '/config-sess.html#stop-conditions-in-horizontal-federated-learning';
        case 'stopping-vfl':
            return '/config-sess.html#stop-conditions-in-vertical-federated-learning';
        case 'stopping-bvfl':
            return '/config-sess.html#stop-conditions-in-blind-vertical-federated-learning';
        case 'hfl':
            return '/paradigm-hfl.html';
        case 'vfl':
            return '/paradigm-vfl.html';
        case 'bvfl':
            return '/tech-paradigms.html';
        case 'inferences':
            return '/horizontal-inference.html';
        default:
            return '';
    }
}

export const getModelTitle = (model: string | undefined) => {
    switch (model) {
        case 'nn':
            return 'Neural Networks for Classification';
        case 'nnr':
            return 'Neural Networks for Regression';
        case 'lir':
            return 'Linear Regression';
        case 'lor':
            return 'Logistic Regression';
        case 'svm':
            return 'Support Vector Machines';
        case 'k-means':
            return 'K-means';
        case 'kmeans':
            return 'K-means';
        case 'nbayes':
            return 'Naive Bayes';
        case 'tan':
            return 'Tree Augmented Naive';
        case 'rf':
            return 'Random Forests';
        case 'gb':
            return 'Gradient Boosting';
        case 'llms':
            return 'Large Language Models (LLMs)';
        case 'pfedme':
            return 'Personalized Deep Neural Networks - pFedMe';
        case 'lgfedavg':
            return 'Personalized Deep Neural Networks - LG-FedAVG';
        case 'fedprox':
            return 'Personalized Deep Neural Networks - FedProx';
        case 'fedper':
            return 'Personalized Deep Neural Networks - FedPer';
        case 'id3':
            return 'Iterative Dichotomiser 3 - ID3';
        default:
            return '';
    }
}

export const getParadigmTitle = (paradigm: string | undefined) => {
    switch (paradigm) {
        case 'hfl':
            return 'Horizontal Federated Learning';
        case 'vfl':
            return 'Vertical Federated Learning';
        case 'bvfl':
            return 'Blind Vertical Federated Learning';
        default:
            return '';
    }
}

export const getEntityResolutionTitle = (orderPreservation: boolean | undefined) => {
    if (orderPreservation) {
        return 'Entity resolution with order preservation';
    }
    return 'Entity resolution without order preservation';
}

export const getAggregatorTitle = (aggregator: string | undefined) => {
    switch (aggregator) {
        case 'fedavg':
            return 'Federated Averaging';
        case 'defaultweightedfedavg':
            return 'Weighted Federated Averaging';
        case 'fedconcat':
            return 'Federated Concatenation';
        case 'clusterfedavg':
            return 'Cluster Federated Averaging';
        case 'fedsum':
            return 'Federated Sum';
        case 'ddaba':
            return 'Dynamic Defense against Byzantine Attacks';
        case 'normclip':
            return 'Norm Clip Aggregator';
        case 'bulyan':
            return 'Bulyan Aggregator';
        case 'multikrum':
            return 'Multi-krum Aggregator';
        case 'robustfedmedian':
            return 'Robust Median-based Aggregator';
        case 'robustfedtrimmedmean':
            return 'Robust Trimmed-mean-based Aggregator';
        case 'robustlearningrate':
            return 'Robust Learning Rate Aggregator';
        case 'fednova':
            return 'FedNova Aggregator';
        case 'pfedme':
            return 'Personalized Federated Learning with Moreau Envelopes';
        case 'fedextratress':
            return 'Federated Extra-Trees';
        case 'fedgradboostdt':
            return 'Federated Gradient Boosting Decision Trees';
        case 'svmaverage':
            return 'Support Vector Machines Average';
        case 'fedid3':
            return 'Federated ID3';
        case 'rfout':
            return 'Robust Filtering of Outliers';
        default:
            return '';
    }
}

export const getDifferentialPrivacyTitle = (mechanism: string | undefined) => {
    switch (mechanism) {
      case 'none':
        return 'None';
      case 'laplace':
        return 'Inherit Settings (Laplace)';
      case 'gaussian':
        return 'Inherit Settings (Gaussian)';
      case 'exponential':
        return 'Inherit Settings (Exponential)';
      default:
        return '';
    }
  }
