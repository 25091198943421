import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-arrow-right-16',
  template: `
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m7.64637 1.35359.70711-.707106 7.35352 7.353556-7.35352 7.35356-.70711-.7071 6.14653-6.1465h-13.7929v-1h13.7928z" fill="#1f1f1f"/></svg>
  `
})
export class IconArrowRight16 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}


