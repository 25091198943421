import { createAction, props, union } from "@ngrx/store";
import { Project, ProjectList, ProjectResponse, FileList, FileName } from "../models";

export const createProject = createAction('[Project] Create project', props<{name: string; description: string}>())
export const createProjectSuccess = createAction('[Project] Create project success', props<{projectId: string}>())
export const createProjectFailure = createAction('[Project] Create project failure', props<{error: any}>())

export const updateProject = createAction('[Project] Update project', props<{suid: string; name: string; description: string}>())
export const updateProjectSuccess = createAction('[Project] Update project success', props<{projectId: string}>())
export const updateProjectFailure = createAction('[Project] Update project failure', props<{error: any}>())

export const deleteProject = createAction('[Project] Delete project', props<{suid: string;}>())
export const deleteProjectSuccess = createAction('[Project] Delete project success')
export const deleteProjectFailure = createAction('[Project] Delete project failure', props<{error: any}>())

// export const setProject = createAction('[Project] Set project', props<{project: ProjectResponse; route?: string}>())

export const loadProjectList = createAction('[Project] Load project list')
export const loadProjectListSuccess = createAction('[Project] Load projects list success', props<{list: ProjectList}>())
export const loadProjectListFailure = createAction('[Project] Load projects list failure', props<{error: any}>())

export const getAllFiles = createAction('[Project] Get all files');
export const getAllFilesSuccess = createAction('[Project] Get all files success', props<{filesList: FileList}>());
export const getAllFilesFailure = createAction('[Project] Get all files failure', props<{ error: any }>() );

// Delete file - single selection
export const deleteFile = createAction('[Project] Delete file', props<{suid: string; uuid: string}>());
export const deleteFileSuccess = createAction('[Project] Delete file success', props<{suid: string}>());
export const deleteFileFailure = createAction('[Project] Delete file failure', props<{ error: any }>() );

// Delete files - multiple selection
export const deleteFiles = createAction('[Project] Delete files', props<{ filenames: string[] }>());
export const deleteFilesSuccess = createAction('[Project] Delete files success', props<{ suid: string | undefined }>());
export const deleteFilesFailure = createAction('[Project] Delete files failure', props<{ error: any }>());

export const countModelsByProject = createAction('[Project] Count models by project', props<{project: Project}>())
export const countModelsByProjectSuccess = createAction('[Project] Count models by project success', props<{project: Project}>())

export const loadProject = createAction('[Project] Load project', props<{suid: string | null | undefined;}>())
export const loadProjectSuccess = createAction('[Project] Load project success', props<{project: Project;}>())
export const loadProjectFailure = createAction('[Project] Load project failure', props<{error: any;}>())

export const updateFile = createAction('[Project] Update file', props<{uuid: string, modelId: string;}>());
export const updateFileSuccess = createAction('[Project] Update file success');
export const updateFileFailure = createAction('[Project] Update file failure', props<{ error: any }>() );

export const updateFiles = createAction('[Project] Update files', props<{ids: any[]}>());

export const resetCurrent = createAction('[Project] Reset current')
export const clearCurrent = createAction('[Project] Clear current', props<{ route?: any, extras?: any}>())

const actionsUnion = union({
  // setProject,
  createProject,
  createProjectSuccess,
  createProjectFailure,
  deleteProject,
  deleteProjectSuccess,
  deleteProjectFailure,
  resetCurrent,
  clearCurrent,
  loadProjectList,
  loadProjectListSuccess,
  loadProjectListFailure,
  loadProject,
  loadProjectSuccess,
  loadProjectFailure,
  updateFile,
  updateFileSuccess,
  updateFileFailure,
  updateFiles,
  // countModelsByProject,
  // countModelsByProjectSuccess,
  getAllFiles,
  getAllFilesSuccess,
  getAllFilesFailure,
  deleteFile,
  deleteFileSuccess,
  deleteFileFailure,
  deleteFiles
})

export type ProjectActionsUnion = typeof actionsUnion
