import * as moment from 'moment-timezone';

export const getUtcDateFormatted = () => {
  const date = moment.utc().format("yyyy-MM-DDThh:mm:ss.SSSSSS[Z]");
  return date;
}

export const getTimestampDiff = (data: any) => {
  const timeZone = moment.tz.guess();
  const currentMoment = moment().tz(timeZone);
  // console.log('aaa fecha actual', currentMoment.format('DD/MM/YYYY, HH:mm'));
  const dateMoment = moment.tz(data, timeZone);
  // console.log('aaa date', dateMoment.format('DD/MM/YYYY, HH:mm'));
  const difference = dateMoment.diff(currentMoment, 'seconds');
  return difference;
}

export const getTimestampFormatted = (data: any, short = false) => {
  const timeZone = moment.tz.guess();
  let date = '';
  if (short) {
    date = moment.tz(data, timeZone).format('DD/MM/YYYY');
  } else {
    date = moment.tz(data, timeZone).format('DD/MM/YYYY, HH:mm');
  }
  return date;
}

export const getCurrentDateFormatted = (short = true) => {
  const timeZone = moment.tz.guess();
  let date = '';
  if (short) {
    date = moment().tz(timeZone).format('YYYYMMDD');
  } else {
    date = moment().tz(timeZone).format('YYYYMMDD') + moment().tz(timeZone).format('HHmmss');
  }
  return date;
}

export const getDateDifferenceInSeconds = (data: any) => {
  const timeZone = moment.tz.guess();
  const dateMoment = moment.unix(data).tz(timeZone);
  // console.log('expira el accessToken', dateMoment.format('DD/MM/YYYY, HH:mm'));
  const currentMoment = moment().tz(timeZone);
  // console.log('fecha actual', currentMoment.format('DD/MM/YYYY, HH:mm'));
  const difference = dateMoment.diff(currentMoment, 'seconds');
  return difference;
}

export const getTimeFormatted = (stamp: string, end?: string) => {
  const startOffset = -1 * (moment.tz.zone(Intl.DateTimeFormat().resolvedOptions().timeZone)!.parse(parseInt(stamp)));
  const endOffset = end ? -1 * (moment.tz.zone(Intl.DateTimeFormat().resolvedOptions().timeZone)!.parse(parseInt(end))) : 0;
  const start =  moment(stamp).add(startOffset, 'minutes').format('YYYY-MM-DDTHH:mm:ss.SSSS'); 
  const endDate = end ? moment(end).add(endOffset, 'minutes').format('YYYY-MM-DDTHH:mm:ss.SSSS') : moment(end).format('YYYY-MM-DDTHH:mm:ss.SSSS'); 
  let hours = moment(moment(endDate)).diff(start, 'h');
  let minutes = moment(moment(endDate)).diff(start, 'm') - (hours * 60);
  let seconds = moment(moment(endDate)).diff(start, 's') - (hours * 3600) - (minutes * 60);
  return `${hours > 0 ? hours + 'h ': ''}${hours > 0 || minutes > 0 ? minutes + 'm ' : ''}${seconds}s`;
}
