import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-arrow-up-16',
  template: `
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m.646484 10.6465.707106.7071 6.64645-6.64648 6.64646 6.64648.7071-.7071-7.35356-7.35359z" fill="#1f1f1f" fill-rule="evenodd"/></svg>
  `
})
export class IconArrowUp16 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
