import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-model-16',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 14.5H14.5V1.5H8M5 14.5H1.5V1.5H4.5" stroke="#1F1F1F"/>
      <path d="M10.5 14.5V12V11.5H12.5M2 9H6V6.5M14 9.5H8.5V4" stroke="#1F1F1F"/>
      <path d="M2 11.5H8" stroke="#1F1F1F"/>
      <path d="M11 2V6.5H13" stroke="#1F1F1F"/>
      <path d="M8 4.5L4.5 4.5L3.5 4.5L3.5 7" stroke="#1F1F1F"/>
    </svg>
  `
})
export class IconModel16 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}