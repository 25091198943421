import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { ChangePassOrg, Organization, Secret, TrainingsMenu } from "../models/organization";
import { AuthService } from 'src/app/auth/services/auth.service';

const headers = new HttpHeaders({'Content-Type':'application/json'});

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  baseUrl: string = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private _authService: AuthService
  ) { }

  loadOrganization(org: string | undefined): Observable<Organization> {
    return this.http.get<Organization>(
      `${this.baseUrl}orgs/${org}`,
      { headers }
    ).pipe(
      tap((res) => {
      }),
      catchError(res => {
        return throwError(res?.error?.message)
      })
    )
  }

  loadCapabilities(org: any | undefined): Observable<string[]> {
    return this.http.get<any>(
      `${this.baseUrl}orgs/${org.suid}/plan?description=true`,
      { headers }
    ).pipe(
      map(res => {
        let capabilities = [];
        if(this._authService.isAdminUser()) {
          capabilities = res?.details?.adminCapabilities
        } else if(this._authService.isExpProUser()) {
          capabilities = res?.details?.userCapabilities
        }
        console.log(capabilities)
        return capabilities;
    }),
      catchError(res => {
        return throwError(() => new Error(res?.error?.message));
      })
    );
  }

  loadPlanWithCapabilities(org: any | undefined): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}orgs/${org.suid}/plan?description=true`,
      { headers }    ).pipe(
      map(res => {
        let capabilities = [];
        if(this._authService.isAdminUser()) {
          capabilities = res?.details?.adminCapabilities
        } else if(this._authService.isExpProUser()) {
          capabilities = res?.details?.userCapabilities
        }
        let result = Object.assign(res?.details, {capabilities: capabilities});
        console.log(result)
        return result;
      }),
      catchError(res => {
        return throwError(() => new Error(res?.error?.message));
      })
    );
  }

  getPlan(org: string | undefined): Observable<string[]> {
    return this.http.get<any>(
      `${this.baseUrl}orgs/${org}/plan?description=true`,
      { headers }
    ).pipe(
      tap((res) => {
      }),
      catchError(res => {
        return throwError(res?.error?.message)
      })
    )
  }

  getUsers(org: string | undefined): Observable<string[]> {
    return this.http.get<any>(
      `${this.baseUrl}/admin/orgs/${org}/users`,
      { headers }
    ).pipe(
      tap((res) => {
      }),
      catchError(res => {
        return throwError(res?.error?.message)
      })
    )
  }

  updateSecret(secret: string, org: string | undefined): Observable<Secret> {
    return this.http.put<Secret>(
      `${this.baseUrl}orgs/${org}/secret`,
      { secret },
      { headers }
    ).pipe(
      tap((res) => {
      }),
      catchError(res => {
        return throwError(res?.error)
      })
    )
  }

  changePasswordOrg(data: ChangePassOrg, org: string | undefined): Observable<ChangePassOrg> {
    return this.http.post<ChangePassOrg>(`${this.baseUrl}orgs/${org}/user/changePassword`,data).pipe(
      tap((res) => {
      }),
      catchError(res => {
        return throwError(res?.error)
      })
    );
  }

  getOrgTrainingStatus(org: string, trainingFilter: string, nextPage?: string): Observable<TrainingsMenu> {
    let params = new HttpParams()
      .set('org', org)
      .set('trainingFilter', trainingFilter);

    if (nextPage) {
      params = params.append('pageStartPoint', nextPage);
    }

    return this.http.get<TrainingsMenu>(
      `${this.baseUrl}orgs/${org}/trainingStatus`,
      { headers, params }
    )
    .pipe(
      map((response) => {
        return response;
      }),
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }

  newGetOrgTrainingStatus(org: string, trainingFilter: string, firstPage: boolean = false): Observable<TrainingsMenu> {
    let params = new HttpParams()
      .set('org', org)
      .set('trainingFilter', trainingFilter)

    if (firstPage) {
      params = params.append('pageStartPoint', 0);
    }

    return this.http.get<TrainingsMenu>(
      `${this.baseUrl}orgs/${org}/trainingStatus`,
      { headers, params }
    )
    .pipe(
      map((response) => {
        return response;
      }),
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }

  getOrgAnalyticsStatus(org: string, trainingFilter: string, nextPage?: string): Observable<TrainingsMenu> {
    let params = new HttpParams()
      .set('org', org)
      .set('trainingFilter', trainingFilter)
      .set('analytics', true);

    if (nextPage) {
      params = params.append('pageStartPoint', nextPage);
    }

    return this.http.get<TrainingsMenu>(
      `${this.baseUrl}orgs/${org}/trainingStatus`,
      { headers, params }
    )
    .pipe(
      map((response) => {
        return response;
      }),
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }
}
