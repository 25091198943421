import { ChangeDetectionStrategy, Output, Component, ElementRef, EventEmitter, HostBinding, Input, ViewChild, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ListItem } from './list-items/list-item.model';

@Component({
  selector: 'shai-floating-menu',
  template: `
    <div class="floating-inner" #floatingInner>
      <div class="floating-body"
        [ngStyle]="{'width.px': (width && +width > 0) ? width : '240'}"
        [ngClass]="adaptedTop ? 'adapted-top' : 'no-adapted'"
      >
        <shai-list-item-header *ngIf="listItemHeader">{{ listItemHeader }}</shai-list-item-header>
        <div class="scrollable-items"
          [ngStyle]="{'max-height.px': height }"
          [ngClass]="!listItemHeader ? 'no-header' : ''">
          <div *ngFor="let listItem of listItemBody">
            <shai-list-item *ngIf="!listItem.optGroup" [listItem]="listItem" [length]="length" (handleItemClick)="onListItemClick({
              'value': listItem.value,
              'title': listItem.title,
              'projectId': listItem.projectId
            })"></shai-list-item>
            <div *ngIf="listItem.optGroup" style="padding: 10px;">
              {{ '&nbsp; &nbsp;' + listItem.title }}
            </div>
          </div>
        </div>
        <shai-list-item-footer *ngIf="listItemFooter" [listItemFooter]="listItemFooter" (handleItemClick)="onFooterItemClick()"></shai-list-item-footer>
      </div>
    </div>
  `,
  styleUrls: [`./floating-menu.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FloatingMenuComponent implements OnInit, OnChanges {
  isActive = false;
  icon = '';
  listItemHeader: string = '';
  listItemFooter: ListItem | undefined;
  listItemBody: Array<ListItem> = [];
  @Input() length: number | undefined;
  @ViewChild('floatingInner', {static: true}) floatingInnerRef?: ElementRef;
  @Input() adaptedTop : boolean = false;
  @Input() listItems: Array<ListItem> = [];
  @Input() width: string = '0';
  @Input() height!: number;
  @Input() set active(value: boolean) {
    if (value) { this.open(); }
    else { this.close(); }
  };
  @HostBinding('class.active') get activeClass() {
    return this.isActive;
  }

  @Output() handleItemClick= new EventEmitter();
  @Output() onChange = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    document.documentElement.style.setProperty('--widthvalue', this.width);
    this.listItemBody = [];
    this.listItems.forEach(item => {
      if (item.type == "header") {
        this.listItemHeader = item.title;
      } else if (item.type == 'body') {
        this.listItemBody.push(item);
      } else if (item.type == 'footer') {
        this.listItemFooter = item;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
        this.length = changes?.length?.currentValue;
        this.listItemBody = [];
        this.listItems.forEach(item => {
          if (item.type == 'body') {
            this.listItemBody.push(item);
          }
        });
    }
  }

  onListItemClick(itemContent: any) {
    this.handleItemClick.emit(itemContent)
  }

  onFooterItemClick() {
    this.close();
  }

  open() {
    this.isActive = true;
    this.onChange.emit(this.isActive);
  }

  close() {
    this.isActive = false;
    this.onChange.emit(this.isActive);
  }
}
