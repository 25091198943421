import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';
import { NotFoundComponent } from './error-pages/not-found.component';
import { UnderMaintainanceComponent } from './error-pages/under-maintainance.component';
import { TrainingResolver } from './training/training-resolver.service';
import { WildCardGuard } from './auth/guards/wildcard.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./projects/projects.module').then((m) => m.ProjectsModule),
    canActivate: [AuthGuard],
    resolve: { site: TrainingResolver },
    runGuardsAndResolvers: 'always'
  },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'logs',
    loadChildren: () => import('./logs/logs.module').then((m) => m.LogsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'shared',
    loadChildren: () =>
      import('./shared/shared.module').then((m) => m.SharedModule),
    canActivate: [AuthGuard],
  },
  { path: '503', component: UnderMaintainanceComponent },
  { path: '404', component: NotFoundComponent },
  {
    path: '**',
    canActivate: [WildCardGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
