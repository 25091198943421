import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { fadeIn } from 'src/app/shared/animations';
@Component({
  selector: 'shai-reset-mfa-success',
  animations: [fadeIn],
  template: `
    <div id="reset-mfa-success-wrapper" @fadeIn>
      <div id="stablish-min-height">
        <ng-lottie [options]="animationOptions"></ng-lottie>
      </div>
      <shai-title-bar
        [showExtraSpace]="false"
        title="Two-Factor Authentication is reset"
        bottomDescription="We must ask you to log in again using your credentials and then you will be enforced to enable Two-Factor Authentication">
      </shai-title-bar>
      <shai-action-bar>
        <ng-container left-cell>
          <shai-button
            [type]="'button'"
            [stretch]="true"
            [route]="['/welcome']"
            buttonStyle="primary"
          >Go to login</shai-button>
        </ng-container>
      </shai-action-bar>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./reset-mfa-success.component.scss']
})
export class ResetMfaSuccessComponent {
  animationOptions: AnimationOptions = {
    path: '/assets/lotties/checkmark.json',
    loop: false
  };
}
