import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromAuth from '../state/selectors/auth.selectors';
import { Observable } from 'rxjs';
import { map, first, withLatestFrom, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WildCardGuard implements CanActivate {

  constructor(private router: Router, private store: Store) { }

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.pipe(
      select(fromAuth.isLoginPending),
      first(pending => !pending),
      withLatestFrom(
        this.store.pipe(select(fromAuth.isLoggedIn))
      ),
      map(([, isLoggedIn]) => {
        console.log('GUARD: WildCardGuard')
        if (isLoggedIn) {
          return this.router.parseUrl('');
        }
        return this.router.parseUrl('/welcome');
      }),
      take(1)
    );
  }
}
