import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class FormatDateUtilsService {

  constructor() { }

  customFormatDate(inputData: string): string {
    const createDate = new Date(inputData);
    const date = createDate.getDate() + '/' + (createDate.getMonth()+1) + '/' + createDate.getFullYear();
    const hours = createDate.getHours();
    const hoursFix = hours <= 9 ? '0' + hours : hours;
    const minutes = createDate.getMinutes();
    const minutesFix = minutes <= 9 ? '0' + minutes : minutes;
    const time = hoursFix + ':' + minutesFix;
    return date + ' ' + time;
  }
}
