import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-aggregator-c-24',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DCDDE0"/>
    <path d="M12.1741 15.908C14.1901 15.908 15.3061 14.804 15.5341 13.076H14.4661C14.2621 14.228 13.5421 14.936 12.1741 14.936C10.2181 14.936 9.54609 13.304 9.54609 11.612C9.54609 9.92 10.2181 8.276 12.1741 8.276C13.5901 8.276 14.3221 9.044 14.4541 10.208H15.5341C15.3541 8.432 14.2021 7.292 12.1741 7.292C9.77409 7.292 8.46609 9.02 8.46609 11.612C8.46609 14.168 9.75009 15.908 12.1741 15.908Z" fill="#1F1F1F"/>
    </svg>
  `
})
export class AggregatorC24 {
  @HostBinding('class.shai-icon') get iconClass() { return true }
}
