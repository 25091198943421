import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AnimationOptions } from 'ngx-lottie';
import { fadeIn } from 'src/app/shared/animations';
import { AuthActions } from '../state/actions';
@Component({
  selector: 'shai-password-verified',
  animations: [fadeIn],
  template: `
    <div id="password-verified-wrapper" @fadeIn>
      <!-- <div id="stablish-min-height">
        <ng-lottie [options]="animationOptions"></ng-lottie>
      </div> -->
      <shai-title-bar
        [showExtraSpace]="false"
        title="Almost Done!"
        bottomDescription="From now on you'll use an Authenticator app to sign in to Sherpa.ai. <br>
                            We will now clear all records of your old data to ensure maximum security.<br><br><br>
                            <b>We must ask you to log in again using your new credentials.</b>">
      </shai-title-bar>
      <shai-action-bar>
        <ng-container left-cell>
          <shai-button
            [type]="'button'"
            [stretch]="true"
            [route]="['/welcome']"
            buttonStyle="primary"
          >Go to login</shai-button>
        </ng-container>
      </shai-action-bar>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./password-verified.component.scss']
})
export class PasswordVerifiedComponent {
  animationOptions: AnimationOptions = {
    path: '/assets/lotties/checkmark.json',
    loop: false
  };

  constructor(
    private store: Store
  ) {}

  logout() {
    this.store.dispatch(AuthActions.logout());
  }
}
