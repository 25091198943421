import { createAction, props, union } from "@ngrx/store";
import { StopCondition } from "../models/";

export const dataStopConditions = createAction('[NewTrainingSession] Set stop conditions', props<{ stopCondition: StopCondition }>());
export const dataParticipatingNodes = createAction('[NewTrainingSession] Set participating nodes', props<{ participatingNodes: any[] }>());
export const dataMappings = createAction('[NewTrainingSession] Set mappings', props<{ mappings: any[] }>());

export const chooseStopConditions = createAction('[NewTrainingSession] Choose stop conditions', props<{ timestamp: string, stopConditions: any }>());
export const chooseStopConditionsSuccess = createAction('[NewTrainingSession] Choose stop conditions success', props<{ stopConditions: any }>());
export const chooseStopConditionsFailure = createAction('[NewTrainingSession] Choose stop conditions failure', props<{ error: any }>());

export const chooseParticipatingNodes = createAction('[NewTrainingSession] Choose participating nodes', props<{ timestamp: string, participatingNodes: string[] }>());
export const chooseParticipatingNodesSuccess = createAction('[NewTrainingSession] Choose participating nodes success', props<{ timestamp: string, participatingNodes: string[] }>());
export const chooseParticipatingNodesFailure = createAction('[NewTrainingSession] Choose participating nodes failure', props<{ error: any }>());

export const chooseMappings = createAction('[NewTrainingSession] Choose mappings', props<{ timestamp: string, mappings: any[], origin: string }>());
export const chooseMappingsSuccess = createAction('[NewTrainingSession] Choose mappings success', props<{ timestamp: string }>());
export const chooseMappingsFailure = createAction('[NewTrainingSession] Choose mappings failure', props<{ error: any }>());

export const clearDataStopCondition = createAction('[NewTrainingSession] Clear data stop condition');
export const clearDataMetricsConfig = createAction('[NewTrainingSession] Clear data metrics config');
export const clearDataParticipatingNodes = createAction('[NewTrainingSession] Clear data participating nodes');
export const clearDataMappings = createAction('[NewTrainingSession] Clear data mappings');

const actionsUnion = union({
  dataStopConditions,
  dataParticipatingNodes,
  dataMappings,
  chooseStopConditions,
  chooseStopConditionsSuccess,
  chooseStopConditionsFailure,
  chooseParticipatingNodes,
  chooseParticipatingNodesSuccess,
  chooseParticipatingNodesFailure,
  chooseMappings,
  chooseMappingsSuccess,
  chooseMappingsFailure,
  clearDataStopCondition,
  clearDataMetricsConfig,
  clearDataParticipatingNodes,
  clearDataMappings
})

export type NewTrainingSessionActionsUnion = typeof actionsUnion
