import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { hasClass } from 'src/app/utils';
import { fadeIn } from 'src/app/shared/animations';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { InputErrorMessage } from 'src/app/shared/components/form-controls/input-text/form-input-text.component';
import { select, Store } from '@ngrx/store';
import { BreackpointService } from 'src/app/platform/services/breackpoint.service';
import { SnackbarService, SnackbarType } from 'src/app/shared/components/snackbar/snackbar.service';
import * as Sha256 from 'crypto-js/sha256';
import * as Base64 from 'crypto-js/enc-base64';
import { AnimationOptions } from 'ngx-lottie';
import { OrganizationActions } from 'src/app/platform';
import * as fromPlatform from 'src/app/platform/reducers';
import { OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'shai-change-org-secret-overlay',
  animations: [fadeIn],
  template: `
    <div #locked></div>
    <shai-overlay
      (onChange)="onChangeOverlay($event)"
      [active]="isActive"
      [complete]="isComplete"
      [hasHeader]="false"
      [fullWidth]="false"
      (onLeave)="closeChangeOrgSecretOverlay()"
    >
      <div id="change-password-overlay-wrapper">
        <mat-grid-list cols="12" rowHeight="fit">
          <mat-grid-tile colspan="2"
            *ngIf="(_bkp.obsScreen$ | async) === 'desktop'">
          </mat-grid-tile>
          <mat-grid-tile [colspan]="(_bkp.obsScreen$ | async) === 'desktop' ? 8 : 12">
            <shai-title-bar
              title="Change organization's secret"
              bottomDescription="You are about to change your organization's secret. Any further registration attempt within your organization will require this new value.">
            </shai-title-bar>
            <div class="container-form">
              <form [formGroup]="changeOrgSecretForm">
                <div class="form-field-group">
                  <shai-form-input-text
                    [parentForm]="changeOrgSecretForm"
                    formControlName="secret"
                    fieldName="secret"
                    label="New secret"
                    maxLength="255"
                    [isSecureField]="true"
                    [errorMessages]="secretErrorMessages"
                    [hasSubmitAttemps]="hasSubmitAttemps">
                  </shai-form-input-text>
                </div>
                <div id="password-conditions" class="paragraph">
                  Required:
                  <ul>
                    <li>
                      At least 8 alphanumeric characters
                      <shai-icon-checkmark-16 *ngIf="secretHasValue() && atLeastMinCharacters()"></shai-icon-checkmark-16>
                      <shai-icon-warning-16 *ngIf="secretHasValue() && !atLeastMinCharacters() || hasSubmitAttemps && !atLeastMinCharacters()"></shai-icon-warning-16>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2"
            *ngIf="(_bkp.obsScreen$ | async) === 'desktop'">
          </mat-grid-tile>
        </mat-grid-list>
        <shai-action-bar>
          <ng-container left-cell>
            <shai-button
              [type]="'button'"
              (click)="closeChangeOrgSecretOverlay()"
              buttonStyle="secondary"
            >Cancel</shai-button>
          </ng-container>
          <ng-container right-cell>
            <shai-button
              *ngIf="!updatingSecret"
              [type]="'button'"
              (click)="onSaveHandler()"
              buttonStyle="primary"
            >Change</shai-button>
            <shai-button
              *ngIf="updatingSecret"
              [type]="'button'"
              [buttonStyle]="'primary'"
              [disabled]="true"
            >
              <div class="spinner-wrapper inside-button-spinner">
                <ng-lottie [options]="blackLoaderAnimation"></ng-lottie>
              </div>
            </shai-button>
          </ng-container>
        </shai-action-bar>
      </div>
    </shai-overlay>
  `,
  styleUrls: ['./change-org-secret-overlay.component.scss'],
})
export class ChangeOrgSecretOverlayComponent implements OnInit, OnDestroy {
  @ViewChild('locked', { static: false }) lockedRef: any;
  @Output() changeOrgSecretEmitter = new EventEmitter();
  changeOrgSecretForm = new FormGroup({
    secret: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z0-9]{8,255}$')]),
  });
  blackLoaderAnimation: AnimationOptions = {
    path: '/assets/lotties/loader.json',
    loop: true
  }
  updatingSecret: boolean = false;
  hasSubmitAttemps = false;
  minLength = 8;
  maxLength = 255;
  private subscription?: Subscription;
  private subscriptionError?: Subscription;
  secretErrorMessages: InputErrorMessage[] = [
    { type: 'required', message: 'The secret field is required.' }
  ];
  rowHeight = 'fit';
  isActive: boolean = true;
  isComplete: boolean = false;

  @HostListener('window:resize', ['$event']) onResize() {
    this.rowHeight = window.innerHeight - 144 - 44 + 'px'; // 44px => header, 144px => bottom-button
  }

  constructor(
    private store: Store,
    public _bkp: BreackpointService,
    private _snackbarService: SnackbarService,
    private cdRef: ChangeDetectorRef,
    private render: Renderer2,
  ) {
    this.onResize();
  }

  ngOnInit(): void {
    this.store.dispatch(OrganizationActions.clearUpdateSecret());
    this._checkUpdateSecretCompleted();
    this._checkUpdateSecretError();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscriptionError?.unsubscribe();
  }

  secretHasValue() {
    return this.changeOrgSecretForm.value.secret.length > 0;
  }

  atLeastMinCharacters() {
    return this.changeOrgSecretForm.value.secret.length >= this.minLength;
  }

  atMostMaxCharacters() {
    return this.changeOrgSecretForm.value.secret.length <= this.maxLength;
  }

  get getSecret() {
    return this.changeOrgSecretForm.get('secret');
  }

  onSaveHandler() {
    this.hasSubmitAttemps = true;
    if(this.changeOrgSecretForm.valid) {
      if (!this.updatingSecret) {
        setTimeout(() => {
          this._snackbarService.show(
            "Change organization's secret?",
            SnackbarType.twoButtonsDestructive,
            `By changing your organization's secret you acknowledge that any further registration attempt
            within your organization will require the new secret, that you are responsible of communicating
            this new secret within your organization and that any existent configurations with the old secret
            will no longer be valid.`,
            false,
            'I understand the implications, proceed',
            'Cancel',
            () => this.primaryButtonHandler(),
            () => this.cancelButtonHandler()
          );
        }, 300);
      }
    } else {
      setTimeout(() => {
        this._snackbarService.show(
          'Sorry, you should enter only alphanumeric characters.',
          SnackbarType.simple
        );
      }, 300);
    }
  }

  primaryButtonHandler() {
    this.updatingSecret = true;
    this.addLockedClass();
    this.cdRef.detectChanges();
    const SECRET = this.changeOrgSecretForm.value.secret;
    const encrypted = Base64.stringify(Sha256(SECRET));
    this.store.dispatch(OrganizationActions.updateSecret({ secret: encrypted }));
  }

  private _checkUpdateSecretCompleted() {
    this.subscription = this.store.pipe(select(fromPlatform.isUpdateSecretCompleted))
    .subscribe((isUpdated) => {
      if (isUpdated) {
        this.updatingSecret = false;
        this.removeLockedClass();
        this.cdRef.detectChanges();
        setTimeout(() => {
          this._snackbarService.show(
            "Organization's secret changed successfully",
            SnackbarType.oneButton,
            undefined,
            false,
            'Ok',
            undefined,
            () => this.closeOverlay(),
            undefined
          );
        }, 300);
      }
    })
  }

  closeOverlay() {
    setTimeout(() => {
      this.isComplete = true;
      this.cdRef.detectChanges();
    }, 1000);
  }

  private _checkUpdateSecretError() {
    this.subscriptionError = this.store.pipe(select(fromPlatform.getUpdateSecretError))
    .subscribe((error) => {
      if (error) {
        this.updatingSecret = false;
        this.removeLockedClass();
        this.cdRef.detectChanges();
        setTimeout(() => {
          this._snackbarService.show(
            "Sorry, something went wrong.",
            SnackbarType.twoButtons,
            'Please, try again.',
            false,
            'Try Again',
            'Cancel',
            () => this.primaryButtonHandler(),
            () => this.closeOverlay()
          );
        }, 300);
      }
    })
  }

  cancelButtonHandler() {}

  onChangeOverlay(event: boolean) {
    if (!event) {
      this.finishProcess();
    } else {
      this.isActive = true;
    }
  }

  closeChangeOrgSecretOverlay() {
    this.isActive = false;
  }

  private finishProcess() {
    this.changeOrgSecretEmitter.emit(false);
  }

  addLockedClass() {
    if (!hasClass(this.lockedRef.nativeElement, 'locked')) {
      this.render.addClass(this.lockedRef.nativeElement, 'locked');
    }
  }

  removeLockedClass() {
    if (hasClass(this.lockedRef.nativeElement, 'locked')) {
      this.render.removeClass(this.lockedRef.nativeElement, 'locked');
    }
  }
}
