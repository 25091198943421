import { createAction, props, union } from "@ngrx/store";
import { Training } from "../../platform/";
import { DataTraining, TrainingCategory, TrainingList, TrainingStatus } from "../models";

export const dataNewTraining = createAction('[Training] Set data new training', props<{trainingData: DataTraining}>());

export const getAllTrainings = createAction('[Training] Get all trainings');
export const getAllTrainingsSuccess = createAction('[Training] Get all trainings success', props<{list: TrainingList}>());
export const getAllTrainingsFailure = createAction('[Training] Get all trainings failure', props<{ error: any }>() );
export const clearTrainings = createAction('[Training] Clear trainings list');

export const loadTraining = createAction('[Training] Load training', props<{ projectId: string | undefined, trainingSessionTimestamp: string | null;}>());
export const loadTrainingSuccess = createAction('[Training] Load training success', props<{training: Training;}>());
export const loadTrainingFailure = createAction('[Training] Load training failure', props<{error: any;}>());

export const loadTrainingStatus = createAction('[Training] Load training status');
export const loadTrainingStatusSuccess = createAction('[Training] Load training status success', props<{ status: TrainingStatus}>());
export const loadTrainingStatusFailure = createAction('[Training] Load training status failure', props<{ error: any }>() );
export const loadTrainingStatusRetry = createAction('[Training] Load training status retry', props<{attemp: number}>() );

export const deleteTrainings = createAction('[Training] Delete trainings', props<{ trainings: any[]}>());
export const deleteTrainingsSuccess = createAction('[Training] Delete trainings success');
export const deleteTrainingsFailure = createAction('[Training] Delete trainings failure', props<{error: any;}>());
export const clearDeleteTrainings = createAction('[Training] Clear delete trainings');

export const clearTraining = createAction('[Training] Clear training');

const actionsUnion = union({
    dataNewTraining,
    getAllTrainings,
    getAllTrainingsSuccess,
    getAllTrainingsFailure,
    loadTrainingStatus,
    loadTrainingStatusSuccess,
    loadTrainingStatusFailure,
    deleteTrainings,
    deleteTrainingsSuccess,
    deleteTrainingsFailure,
    clearTraining,
})

export type TrainingActionsUnion = typeof actionsUnion
