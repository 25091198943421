import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-random-forest-32',
  template: `
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4209 2.2618L15.2881 2.556L15.0265 2.75074C14.5819 3.0816 13.9653 3.07781 13.5246 2.73955L13.266 2.541L13.1377 2.24526C12.9395 1.78816 13.028 1.23797 13.4005 0.865545C13.8879 0.378152 14.6781 0.378152 15.1655 0.865545C15.5424 1.2425 15.6285 1.80159 15.4209 2.2618ZM19.2493 6.97353L19.444 6.71186L19.7382 6.57912C20.1984 6.37149 20.7575 6.45759 21.1345 6.83454C21.6218 7.32194 21.6218 8.11216 21.1345 8.59955C20.6471 9.08695 19.8568 9.08694 19.3694 8.59955C18.9262 8.15634 18.8859 7.46182 19.2493 6.97353ZM8.41313 6.68074L8.68167 6.86171L8.82839 7.14807C9.00125 7.48543 9.01029 7.88659 8.85498 8.2308L8.72224 8.525L8.46058 8.71974C8.06281 9.01577 7.52637 9.04424 7.10193 8.80348L6.82142 8.64437L6.65236 8.369C6.35718 7.88819 6.41859 7.25052 6.83456 6.83455C7.2635 6.40561 7.92803 6.35383 8.41313 6.68074ZM13.2803 13.5394L13.475 13.2778L13.7692 13.145C14.2294 12.9374 14.7885 13.0235 15.1655 13.4004C15.5424 13.7774 15.6285 14.3365 15.4209 14.7967L15.2881 15.0909L15.0265 15.2856C14.5467 15.6427 13.8677 15.6099 13.424 15.1884L13.162 14.9395L13.1731 14.9284L13.0875 14.6425C12.9764 14.2715 13.0416 13.8601 13.2803 13.5394ZM19.2493 19.5084L19.444 19.2468L19.7382 19.114C20.1984 18.9064 20.7575 18.9925 21.1345 19.3694C21.6218 19.8568 21.6218 20.6471 21.1345 21.1345C20.6471 21.6218 19.8568 21.6218 19.3694 21.1345C18.9262 20.6912 18.8859 19.9967 19.2493 19.5084ZM8.37017 19.188L8.6452 19.3572L8.80399 19.6377C9.0736 20.1139 9.00467 20.7293 8.59957 21.1345C8.11217 21.6218 7.32195 21.6218 6.83456 21.1345C6.34716 20.6471 6.34717 19.8568 6.83456 19.3694L6.481 19.0159L6.83456 19.3694C7.25087 18.9531 7.88924 18.892 8.37017 19.188ZM2.83978 12.3954L2.83561 12.3994L2.93377 12.6985C3.07635 13.1328 2.97434 13.6279 2.63055 13.9716L2.98411 14.3252L2.63055 13.9716C2.14316 14.459 1.35294 14.459 0.865545 13.9716L0.513823 14.3234L0.865545 13.9716C0.378152 13.4843 0.378152 12.694 0.865545 12.2066L0.511991 11.8531L0.865545 12.2066C1.33888 11.7333 2.09816 11.7196 2.58767 12.1657L2.83978 12.3954Z"
        stroke="url(#paint0_linear_4924_2952)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4924_2952"
          x1="7.5"
          y1="21"
          x2="19"
          y2="7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3D85C7" />
          <stop offset="0.317708" stop-color="#73C2E5" />
          <stop offset="0.661458" stop-color="#89D5DD" />
          <stop offset="1" stop-color="#F2A71B" />
        </linearGradient>
      </defs>
    </svg>
  `,
})
export class IconRandomForest32 {
  @HostBinding('class.shai-icon') get iconClass() {
    return true;
  }
}
