import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { LogService } from "../services/log.service";
import { LogActions } from '../actions';
import { catchError, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { fromAuth } from "src/app/auth/state";
import * as fromPlatform from '../../platform/reducers';
import { LogItemList, LogList } from "../models/log";


@Injectable()
export class LogEffects {
    constructor(
      private actions$: Actions,
      private store: Store,
      private _logsService: LogService
    ) { }

    loadLogs$ = createEffect(() => this.actions$.pipe(
      ofType(LogActions.loadLogs),
      withLatestFrom(
        this.store.pipe(select(fromPlatform.getNodeList)),
        // this.store.pipe(select(fromAuth.getOrg))
      ),
      switchMap(([,nodes]) => {
        return this._logsService.loadLogsList("1", nodes).pipe( // "1" is the future org
          switchMap((list: LogList) => [LogActions.loadLogsSuccess({list})]),
          catchError(error => [LogActions.loadLogsFailure({error})])
        )
      })
    ))

    getAllLogs$ = createEffect(() => this.actions$.pipe(
      ofType(LogActions.getAllLogs),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getOrg)),
        this.store.pipe(select(fromPlatform.getCurrentProjectId)),
        this.store.pipe(select(fromPlatform.getCurrentTrainingSessionTimestamp))
      ),
      switchMap(([, org, project, sessionTimestamp]) => {
        return this._logsService.getAllLogs(
          org,
          project,
          sessionTimestamp,
        ).pipe(
          switchMap((logs: LogItemList) => [LogActions.getAllLogsSuccess({logs})]),
          catchError(error => [LogActions.getAllLogsFailure({error})])
        )
      })
    ))
}