import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { sortObjectArrayByConfigName } from 'src/app/utils/dom-utils';
import { environment } from 'src/environments/environment';
import { GenericParameter } from '../models/training-session.model';
import {
  Training,
  TrainingList,
  TrainingResponse,
  TrainingEntityResolution,
  DifferentialPrivacyData,
  EntityResolutionData,
} from '../models';

const headers = new HttpHeaders({'Content-Type':'application/json'});
@Injectable({
  providedIn: 'root',
})
export class TrainingService {
  baseUrl: string = environment.baseUrl;
  trainingsDeleted$ = new Subject<string[]>();

  constructor(private http: HttpClient) {}

  createNewTraining(
    orgId: string | undefined,
    projectId: string | undefined,
    name: string,
    description: string,
    paradigm: any,
    model: any,
    differentialPrivacy: any,
    aggregation: any,
    entityResolution: any,
    metricsConfig: any
  ): Observable<any | never> {

    let data: any = {
      name: name,
      description: description,
      tags: ['tag1', 'tag2'],
      paradigm,
      model,
      differentialPrivacy,
      metricsConfig
    }

    if(paradigm?.id === 'hfl') {
      data = {...data, aggregation};
    }

    if(paradigm?.id === 'vfl' || paradigm?.id === 'bvfl') {
      data = {...data, entityResolution};
    }

    return this.http.post<Training>(
      `${this.baseUrl}orgs/${orgId}/projects/${projectId}/sessions`,
      data,
      { headers }
    )
    .pipe(
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }


  createNewAnalytics(
    orgId: string | undefined,
    projectId: string | undefined,
    name: string,
    description: string,
    analyticsQueries: any
  ): Observable<any | never> {

    let data: any = {
      name: name,
      description: description,
      tags: ['tag1', 'tag2'],
      analyticsQueries: analyticsQueries,
      paradigm: { "id": "fa" }
    }

    return this.http.post<any>(
      `${this.baseUrl}orgs/${orgId}/projects/${projectId}/sessions?analytics=true`,
      data,
      { headers }
    )
    .pipe(
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }

  getAllTrainings(
    orgId: string | undefined,
    projectId: string | undefined
  ): Observable<TrainingList | never> {
    const params = new HttpParams()
      .set('page', '0')
      .set('pageSize', '100')
      .set('pagination', false);

    return this.http.get(
      `${this.baseUrl}orgs/${orgId}/projects/${projectId}/sessions`,
      { headers, params })
      .pipe(
        map((list: any) => {
          list.items = sortObjectArrayByConfigName(list.items);
          return list;
        }),
        catchError((res) => {
          return throwError(res?.error);
        })
      );
  }

  chooseParadigm(
    orgId: string | undefined,
    projectId: string | undefined,
    trainingSessionTimestamp: string | undefined,
    paradigmId: string | null,
    parametersData: GenericParameter[] | undefined
  ): Observable<any | never> {

    let data: any;
    if (parametersData) {
      data = {
        id: paradigmId,
        parameters: parametersData
      };
    } else {
      data = {
        id: paradigmId,
        parameters: [
          {
            name: 'parameter1',
            value: 'value1',
          },
          {
            name: 'parameter2',
            value: 'value2',
          },
        ],
      };
    }

    return this.http.post<any>(
      `${this.baseUrl}orgs/${orgId}/projects/${projectId}/sessions/${trainingSessionTimestamp}/paradigm`,
      data,
      { headers }
    )
    .pipe(
      map((res) => {
        return {
          ...data,
        };
      }),
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }

  chooseEntityResolution(
    orgId: string | undefined,
    projectId: string | undefined,
    trainingSessionTimestamp: string | undefined,
    entityRes: EntityResolutionData | null
  ): Observable<any | never> {
    const data = entityRes;

    return this.http.post<any>(
      `${this.baseUrl}orgs/${orgId}/projects/${projectId}/sessions/${trainingSessionTimestamp}/entityResolution`,
      data,
      { headers }
    )
    .pipe(
      map((res) => {
        return {
          ...data,
        };
      }),
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }

  updateEntityResolution(
    orgId: string | undefined,
    projectId: string | undefined,
    trainingId: string | undefined,
    entityId: "psi" | "psu" | "none" | undefined,
    orderPreservation: boolean,
    threshold: number
  ): Observable<any | never> {
    const data = {
      id: entityId,
      orderPreservation,
      threshold,
      output: 'ids+uids',
      parameters: [
        {
          name: 'parameter1',
          value: 'value1',
        },
        {
          name: 'parameter2',
          value: 'value2',
        },
      ],
    };

    return this.http.put<any>(
      `${this.baseUrl}orgs/${orgId}/projects/${projectId}/trainings/${trainingId}/entityResolution`,
      data,
      { headers }
    )
    .pipe(
      map((res) => {
        console.log('updateEntityResolution', res);
        return res;
      }),
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }

  chooseModel(
    orgId: string | undefined,
    projectId: string | undefined,
    trainingSessionTimestamp: string | undefined,
    modelId: string | null,
    hyperparametersData: GenericParameter[] | undefined
  ): Observable<any | never> {
    let data: any;
    if (hyperparametersData) {
      data = {
        id: modelId,
        hyperparameters: hyperparametersData
      };
    } else {
      data = {
        id: modelId,
      };
    }

    return this.http.post<any>(
      `${this.baseUrl}orgs/${orgId}/projects/${projectId}/sessions/${trainingSessionTimestamp}/model`,
      data,
      { headers }
    )
    .pipe(
      map((res) => {
        return {
          ...data,
        };
      }),
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }

  chooseAggregator(
    orgId: string | undefined,
    projectId: string | undefined,
    trainingSessionTimestamp: string | undefined,
    aggregatorId: string | null
  ): Observable<any | never> {
    const data = {
      id: aggregatorId,
    };
    return this.http.post<any>(
      `${this.baseUrl}orgs/${orgId}/projects/${projectId}/sessions/${trainingSessionTimestamp}/aggregation`,
      data,
      { headers }
    )
    .pipe(
      map((res) => {
        return {
          ...data,
        };
      }),
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }

  chooseDifferentialPrivacy(
    orgId: string | undefined,
    projectId: string | undefined,
    trainingSessionTimestamp: string | undefined,
    differentialPrivacyData: DifferentialPrivacyData | null
  ): Observable<any | never> {
    return this.http.post<any>(
      `${this.baseUrl}orgs/${orgId}/projects/${projectId}/sessions/${trainingSessionTimestamp}/differentialPrivacy`,
      differentialPrivacyData,
      { headers }
    )
    .pipe(
      map((res) => {
        return {
          ...differentialPrivacyData,
        };
      }),
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }

  /**
   *
   * GET request to get the trainings related to a model
   *
   * @param orgId
   * @param projectId
   * @param modelId
   * @returns {Observable<TrainingList | never>} Trainings related with current model | error
   */
  loadTraining(
    orgId: string | undefined,
    projectId: string | undefined,
    trainingSessionTimestamp: string | null
  ): Observable<any | never> {
    return this.http.get(
      `${this.baseUrl}orgs/${orgId}/projects/${projectId}/sessions/${trainingSessionTimestamp}`,
      { headers }
    )
    .pipe(
      map((training: any) => {
        return training;
        // list.items = list.items.sort((a: any, b: any) => {
        //   const nameA = a.config.name ? a.config.name : '';
        //   const nameB = b.config.name ? b.config.name : '';
        //   return nameA.localeCompare(nameB);
        // })
        // return list;
      }),
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }

  getEntityResolution(
    orgId: string | undefined,
    projectId: string | undefined,
    trainingId: string | undefined
  ): Observable<TrainingEntityResolution> {
    return this.http.get<TrainingEntityResolution>(
      `${this.baseUrl}orgs/${orgId}/projects/${projectId}/trainings/${trainingId}/entityResolution`,
      { headers }
    )
    .pipe(
      tap((res) => res),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  deleteTraining(orgId: any, projectId: any, trainingSessionTimestamp: string) {
    return this.http.delete(
      `${this.baseUrl}orgs/${orgId}/projects/${projectId}/sessions/${trainingSessionTimestamp}`,
      { headers }
    )
    .pipe(
      catchError((res) => {
        return throwError(res?.error);
      })
    );
  }

  deleteTrainings(orgId: any, trainings: any[]) {
    const deleteCalls = trainings.map((training) => {
      return this.deleteTraining(orgId, training.projectSuid, training.trainingSessionTimestamp);
    });
    this.trainingsDeleted$.next(trainings);
    return combineLatest(deleteCalls);
  }
}
