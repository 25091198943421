import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import {
  ModelFileUploaded,
} from '../models';
import {
  combineAll,
  switchMap,
  take,
} from 'rxjs/operators';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as SHA256 from 'crypto-js/sha256';
import * as CryptoJS from 'crypto-js';

interface FileUploadData {
  name: string;
  b64: string;
  file_hash: string;
  part_hash: string;
}

const MYME_TYPE_PYTHON = 'text/x-python';
const MYME_TYPE_TEXT = 'text/plain';
const MYME_TYPES_ALLOWED = [MYME_TYPE_PYTHON, MYME_TYPE_TEXT];

const headers = new HttpHeaders({'Content-Type':'application/json'});

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {
  baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) {}

  checkAllFileTypes(incomingFiles: any[]): boolean {
    for (let i = 0; i < incomingFiles.length; i++) {
      let incomingFile = incomingFiles[i];
      const found = MYME_TYPES_ALLOWED.some(v => incomingFile.type.includes(v))
      if (!found) return false;
     }
    return true;
  }

  uploadFiles(
    orgId: string,
    projectId: string,
    modelCode: string,
    files: File[]
  ) {
    let formData = new FormData();
    // Check if exist any .py file and transform to multi-part
    return this.checkFilesConvertion(files).pipe(
      take(1),
      switchMap((results: (FileUploadData)[]) => {
        // Append all files to form data
        /*
        results.forEach((element: FileUploadData) => {
          formData.append(modelCode, element.b64);
        });
        */

        const fileToUpload = results[0];

        formData.append(modelCode, fileToUpload.b64);

        const fileHeaders = new HttpHeaders({
          'x-sherpa-multipart-file-name': fileToUpload.name,
          'x-sherpa-multipart-part': '-1',
          'x-sherpa-model-type': modelCode,
          'x-sherpa-multipart-hash-algorithm': 'SHA256',
          'x-sherpa-multipart-file-hash': fileToUpload.file_hash,
          'x-sherpa-multipart-part-hash': fileToUpload.part_hash,
          'InterceptorSkipHeader': '',
          'InterceptorUploadFile': ''
        });

        const options = {
          headers: fileHeaders,
          reportProgress: true,
        };

        const req = new HttpRequest(
          'POST',
          `${this.baseUrl}orgs/${orgId}/projects/${projectId}/files`,
          formData,
          options
        );

        return this.http.request<ModelFileUploaded[]>(req);
      })
    );
  }

  checkFilesConvertion(files: File[]): Observable<(FileUploadData)[]> {
    return of(files).pipe(
      switchMap((files) => {
        return files.map((file) => {
          //if (file.type === MYME_TYPE_PYTHON) {
            const result = new ReplaySubject<FileUploadData>(1);
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = (event) => {
              if (event && event.target && event.target.result?.toString()) {

                const b64 = btoa(event.target.result?.toString());          
                const chunk = event.target.result?.toString();          
                // part_hash
                const part_hash = SHA256(chunk).toString(CryptoJS.enc.Base64);
                // file_hash
                let file_hash_temp = SHA256(chunk)
                let file_hash = SHA256(file_hash_temp).toString(CryptoJS.enc.Base64) + '-1';
          
                const fileData = {
                  name: file.name,
                  b64: b64,
                  file_hash: file_hash,
                  part_hash: part_hash
                }    

                result.next(fileData);
              }
            };
            return result;
          //} //else {
            //return of(file);
          //}
        });
      }),
      combineAll()
    );
  }

  updateFileModel(
    orgId: string | undefined,
    projectId: string | undefined,
    modelCode: string,
    uuid?: string
  ) {
    const params = {
      model: modelCode,
    };
    return this.http.put(
      `${this.baseUrl}orgs/${orgId}/projects/${projectId}/files/${uuid}`,
      params,
      { headers }
    );
  }

  updateFiles(orgSuid: any, projectSuid: any, ids: string[]) : Observable<any> {
    const updateCalls = ids.map((id) => {
      return this.updateFileModel(orgSuid, projectSuid, id[0], id[1])
    });
    return combineLatest(updateCalls);
  }

  deleteFileModel(
    orgId: string,
    projectId: string,
    uuid?: string
  ): Observable<any> {
    return this.http.delete(
      `${this.baseUrl}orgs/${orgId}/projects/${projectId}/files/${uuid}`,
      { headers }
    );
  }
}
