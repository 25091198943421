import { Component, HostBinding } from '@angular/core';
@Component({
  selector: 'shai-icon-fetch-16',
  template: `
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3933_6001)">
        <path
          d="M7.81096 6.9854L2.28753 6.02343L3.2495 0.5L4.23467 0.671579L3.64797 4.04026C4.9059 2.83036 6.61592 2.08578 8.5 2.08578C12.366 2.08578 15.5 5.21978 15.5 9.08577C15.5 12.9518 12.366 16.0858 8.5 16.0858C4.63401 16.0858 1.5 12.9518 1.5 9.08577H2.5C2.5 12.3995 5.18629 15.0858 8.5 15.0858C11.8137 15.0858 14.5 12.3995 14.5 9.08577C14.5 5.77207 11.8137 3.08578 8.5 3.08578C6.63048 3.08578 4.96037 3.9405 3.85942 5.28214L7.98254 6.00023L7.81096 6.9854Z"
          fill="#0073BF"
        />
      </g>
      <defs>
        <clipPath id="clip0_3933_6001">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  `,
})
export class IconFetch16 {
  @HostBinding('class.shai-icon') get iconClass() {
    return true;
  }
}
