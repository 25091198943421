import { Component } from "@angular/core";

@Component({
  selector: 'shai-base-step',
  template: ``
})
export class BaseStepComponent  {

  constructor(){}

  onEnter() {
    console.log("On enter from base ")
  }

  onLeave() {
    console.log("On leave from base ")
  }
}
